import DownOutlined from "@ant-design/icons/DownOutlined";
import RightOutlined from "@ant-design/icons/RightOutlined";
import Form from "antd/es/form";
import InputNumber from 'antd/es/input-number';
import Typography from "antd/es/typography";
import React from "react";
const { Text } = Typography;

const MoreSettings = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div>
      <div className="flex gap-4 items-center">
        <Text>More Settings</Text>
        {isOpen ? (
          <DownOutlined onClick={() => setIsOpen(false)} />
        ) : (
          <RightOutlined onClick={() => setIsOpen(true)} />
        )}
      </div>
      {isOpen && (
        <div className="flex flex-col my-4 space-y-4 w-full">
          <Form.Item
            label="Days to complete"
            name="daysToComplete"
            rules={[
              {
                validator: (_, value) => {
                  const numValue = Number(value);
                  if (value && (numValue < 0 || numValue > 100)) {
                    return Promise.reject('Value must be between 0 and 100 days');
                  }
                  return Promise.resolve();
                }
              }
            ]}          
          >
            <InputNumber
              className="w-1/2"
              placeholder="Enter value in days"
              type="number"  
              onWheel={(e) => e.currentTarget.blur()}
              maxLength={2} 
            />
          </Form.Item>
          <Form.Item
            label="Set a reminder every"
            name="reminderEvery"
            rules={[
              {
                validator: (_, value) => {
                  const numValue = Number(value);
                  if (value && (numValue < 0 || numValue > 45)) {
                    return Promise.reject('Value must be between 0 and 45 days');
                  }
                  return Promise.resolve();
                }
              }
            ]}
          >
            <InputNumber
              className="w-1/2"
              placeholder="Enter reminder frequency"
              type="number" 
              onWheel={(e) => e.currentTarget.blur()}
              maxLength={2} 
              addonAfter={<span className="text-gray-500">days</span>}
            />
          </Form.Item>
        </div>
      )}
    </div>
  );
};

export default MoreSettings;