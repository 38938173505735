import React from "react";
import AddMember from "./components/cardActions/addMember/AddMember";
import LabelPopup from "./components/cardActions/addLabel/LabelPopup";
import MoveCardPopup from "./components/cardActions/moveCard/MoveCardPopup";
import CopyCardPopup from "./components/cardActions/copyCard/CopyCardPopup";
import AddAttachment from "./components/cardActions/addAttachment/AddAttachment";
import ArchiveIcon from "./assets/Archive";
import LabelsIcon from "./assets/Labels";
import CardCopyIcon from "./assets/CardCopy";
import MoveCardIcon from "./assets/MoveCard";
import MembersSmallIcon from "./assets/MembersSmall"
import { Role } from "./lib/apiRes";
import Facebook from "./assets/Facebook";
import Twitter from "./assets/Twitter";
import Linkedin from "./assets/Linkedin";
import Github from "./assets/Github";
import Figma from "./assets/Figma";
import Dribbble from "./assets/Dribbble";
import PersonalBlog from "./assets/PersonalBlog";
import Leetcode from "./assets/Leetcode";
import Behance from "./assets/Behance";
import ResearchGate from "./assets/ResearchGate";
import GoogleScholar from "./assets/GoogleScholar";
import StackOverflow from "./assets/StackOverFlow";
import GoogleCalendarIcon from "./assets/GoogleCalender";
import { OutlookIcon } from "./assets/Outlook";
import AttachmentIcon from "./components/Icons/AttachmentIcon";

//common
export const SHOW_MORE = "Show more";
export const SHOW_LESS = "Show less";
export const PHOTOS = "Photos";
export const PHOTOS_UNSPLASH = "Photos by Unsplash";
export const COLORS = "Colors";
export const SELECT_DESTINATION = "SELECT DESTINATION";
export const DATE_TIME_FORMATE = "D MMM [at] h:mm a";
export const DATE_FORMATE = "D MMM yyyy ";
export const TIME_FORMATE = "h:mm a";
export const BACK_TO_LOGIN = "Return to login";

//board
export const FETCHING_BOARDS = "Fetching job posts";


//Navbar
export const NAV_TEXTBOX_PLACEHOLDER = "Search...";

//Textbox
export const SEARCH_PLACEHOLDER = "Find JD by name...";


//BoardModal
export const DARK = "dark";
export const LIGHT = "light";

export const COLOR_DATA = [
  {
    colorCode: "red text-white",
    isSet: false,
    showMore: false,
    brightness: DARK,
  },
  {
    colorCode: "green text-white",
    isSet: true,
    showMore: false,
    brightness: DARK,
  },
  {
    colorCode: "blue text-white",
    isSet: false,
    showMore: false,
    brightness: DARK,
  },
  {
    colorCode: "orange text-white",
    isSet: false,
    showMore: false,
    brightness: DARK,
  },
  {
    colorCode: "magenta text-white",
    isSet: false,
    showMore: false,
    brightness: DARK,
  },
  {
    colorCode: "teal text-white",
    isSet: false,
    showMore: false,
    brightness: DARK,
  },
  {
    colorCode: "maroon text-white",
    isSet: false,
    showMore: false,
    brightness: DARK,
  },
  {
    colorCode: "brown text-white ",
    isSet: false,
    showMore: false,
    brightness: DARK,
  },
  {
    colorCode: "navy text-white",
    isSet: false,
    showMore: false,
    brightness: DARK,
  },
  {
    colorCode: "grey text-white",
    isSet: false,
    showMore: false,
    brightness: DARK,
  },

  {
    colorCode: "Pink text-black",
    isSet: false,
    showMore: false,
    brightness: LIGHT,
  },
  {
    colorCode: "Lavender text-black",
    isSet: false,
    showMore: false,
    brightness: LIGHT,
  },
  {
    colorCode: "Beige text-black",
    isSet: false,
    showMore: false,
    brightness: LIGHT,
  },
  {
    colorCode: "Mint text-black",
    isSet: false,
    showMore: false,
    brightness: LIGHT,
  },
  {
    colorCode: "Cyan text-black",
    isSet: false,
    showMore: false,
    brightness: LIGHT,
  },
  {
    colorCode: "Yellow text-black",
    isSet: false,
    showMore: false,
    brightness: LIGHT,
  },
];

export const colorDictionary: { [key: string]: string } = {
  red: "#E6194B",
  green: "#3CB44B",
  blue: "#4363D8",
  orange: "#F58231",
  magenta: "#F032E6",
  teal: "#469990",
  brown: "#9A6324",
  maroon: "#800000",
  navy: "#000075",
  grey: "#A9A9A9",
  black: "#000000",
  Yellow: "#FFE119",
  Pink: "#FABED4",
  Lavender: "#DCBEFF",
  Beige: "#FFFAC8",
  Mint: "#AAFFC3",
  Cyan: "#42D4F4",
  LightGray: "#f1f1f6"
};


//Colours for Analytics Labels

export const colorDictionary1: { [key: string]: string } = {
  blue:"#1890FF",
  greekblue:"#2F54EB",
  purple:"#722ED1",
  green:"#52C41A",
  volcano:"#FA541C",
  orange:"#FA8C16",
  pink:" #EB2F96 ",
  cyan:"#13C2C2",
  gold:"#FAAD14  ",
  lime:" #A0D911",
  red:"#F5222D ",
  magenta:"#252525",
};

// let COLOR_DATA_LABEL:any[]= [];
export const COLOR_DATA_LABEL: any[] = [
  {
    colorCode: "red text-white",
    isSet: false,
    showMore: false,
  },
  {
    colorCode: "green text-white",
    isSet: true,
    showMore: false,
  },
  {
    colorCode: "blue text-white",
    isSet: false,
    showMore: false,
  },
  {
    colorCode: "orange text-white",
    isSet: false,
    showMore: false,
  },
  {
    colorCode: "magenta text-white",
    isSet: false,
    showMore: false,
  },
  {
    colorCode: "teal text-white",
    isSet: false,
    showMore: false,
  },
  {
    colorCode: "maroon text-white",
    isSet: false,
    showMore: false,
  },
  {
    colorCode: "brown text-white ",
    isSet: false,
    showMore: false,
  },
  {
    colorCode: "navy text-white",
    isSet: false,
    showMore: false,
  },
  {
    colorCode: "grey text-white",
    isSet: false,
    showMore: false,
  },
  {
    colorCode: "black text-white",
    isSet: false,
    showMore: false,
  },
  {
    colorCode: "Pink text-black",
    isSet: false,
    showMore: false,
  },
  {
    colorCode: "Lavender text-black",
    isSet: false,
    showMore: false,
  },
  {
    colorCode: "Beige text-black",
    isSet: false,
    showMore: false,
  },
  {
    colorCode: "Mint text-black",
    isSet: false,
    showMore: false,
  },
  {
    colorCode: "Cyan text-black",
    isSet: false,
    showMore: false,
  },
  {
    colorCode: "Yellow text-black",
    isSet: false,
    showMore: false,
  },
];



//createList
export const ADD_LIST = "Add List";

//listNameInput
export const INPUT_PLACEHOLDER = "Enter List Title";


//CardPreviewSeideSection menu
export const ACTION = "ACTION";
export const SLACK = "slack";
export const SLACK_APP_CONNECTION_WARNING = "Please integrate your Slack app."

export const ADD_TO_CARD = "Add to card";
export const MEMBERS = "members";
export const LABELS = "labels";
export const ATTACHMENTS = "Attachments";

/******************************** Websocket topic **************************************************/
export const WS_CDB = "cdb";
export const WS_BILLING = "billing";
export const WS_REVIEW = "review";

/******************************** Sidenav menu  ***************************************/

export const SIDENAV_MENU_ITEMS = {
  UPDATES: "Updates",
  ANALYTICS: "Analytics",
  BOARDS: "Job posts",
  TALENT_POOL: "Talent pool",
  REVIEW: "Reviews",
  INBOX: "Mails",
  IMPORT: "Import",
  MEMBERS: "Members",
  JOB_DESCRIPTION: "Job description ",
  WORKSPACE: "Workspace",
  CALENDAR:"Calendar",
  MEETINGS:"Meetings",
};
export const titleMapping: { [key: string]: string } = {
  db: "Talent Pool",
  analytics: "Analytics",
  tmail: "Mails",
  reviews: "Review",
  ats: "Job Posts",
  updates: "Updates",
  o: "Organizations",
  "start-setup": "Organization setup",
  createOrg: "Create org"
};


export const CARD_PREVIEW_MENU = [
  {
    name: "Members",
    icon: <MembersSmallIcon />,
    active: true,
    popup: AddMember,
  },
  { name: "Labels", icon: <LabelsIcon />, active: true, popup: LabelPopup },
  {
    name: "Attachments",
    icon: <AttachmentIcon />,
    active: true,
    popup: AddAttachment,
  },
];


export const ACTION_MENU = {
  ul: [
    {
      name: "Move",
      icon: <MoveCardIcon />,
      active: true,
      popup: MoveCardPopup,
    },
    {
      name: "Copy",
      icon: <CardCopyIcon />,
      active: true,
      popup: CopyCardPopup,
    },
  ],
  ul2: [
    {
      name: "Archive",
      icon: <ArchiveIcon />,
      active: true,
    },
  ],
  ul3: [
    {
      name: "Send To Tracker",
      icon: <ArchiveIcon />,
      active: true,
      popup: AddMember,
    },
    { name: "Delete", icon: <ArchiveIcon />, active: true, popup: AddMember },
  ],
};

//card
export const NOTIFY_ARCHIVE = "This card is archived";

//CardPreviewDatesBadge
export const CDBMember = "CDBMember";
export const JDMember = "JDMember";

//BillingActionNames
export const billingMember = "billingMember";
export const companyMember = "companyMember";

//listPopup
export const ListMenuItem = {
  ul1: [
    // { name: "Add Menu" },
    // { name: "copy list", popup: CopyList },
    { name: "Move", popup: "MoveList" },
    { name: "Archive list", popup: "ArchiveList" },
  ],
  ul2: [
    { name: "Move all cards in this list", popup: "MoveAllCardsInList" },
    { name: "Archive all cards in this list", popup: "ArchiveAllCard" },
  ],
};


export const PlansName = {
  premium:"premium",
  starter:"starter",
  free:"free",
  free_trail : "free trial",
}

export const TotalTrialDays = 14
export const ADD_MEMBER_TITLE = "add member";
export const ADD_CANDIDATE = "add candidate";
export const ADD_REVIEWER = "add reviewer";

//search result
export const SEARCH_TO_ADD_MEMBER =
  "Looks like that person isn't a org member yet.";

export const SEARCH_IN_BOARD_MEMBER = "This person is not a member of board.";
export const SEARCH_IN_CDB_MEMBER =
  "This person is not a member of candidate database.";
// Comment
export const COMMENT_TAG = "comment";
export const ACTIVITY_TAG = "activity";
export const EMAIL_TAG = "email";
export const EMAIL_EMPTY = "Email is required.";
export const INVALID_EMAIL = "Invalid e-mail address. Please try again.";
export const ORG_EXIST = "Name already exist, try another name.";
export const MAX_CHAR_ALLOWED = "Max. characters limit is 50.";
export const MAX_ATTACHMENT_SIZE =
  "The size of the attachment exceeds the allowed limit of 10MB.";
export const MAX_CHAR_LIMIT_WARNING = "maximum character limit is 250";
export const NO_LIST_FOUND_IN_BOARD = "No List found";
export const FORM_SAVE = "Form saved successfully.";
//toast notification
export const SLACK_INTEGRATION = "Slack settings integrated successfully.";
export const UPDATE_SLACK_OPTIONS = "Slack settings updated successfully.";
export const SLACK_TOKEN_EXPIRE_WARNING = "Your token has expired, and notifications are now paused. Please reconnect the Tacitbase app to your Slack workspace."
export const COPY_CARD = "Card copied to the selected board.";
// Token
export const LOGIN_TOKEN = "login_token";

// Socket client ID
export const SOCKET_CLIENT_ID="socket_client_id";
//title
export const TACITBASE = "Tacitbase";

export const LOGIN_TITLE = `Login | ${TACITBASE}`;
export const REGISTRATION_TITLE = `Registration | ${TACITBASE}`;
export const VERIFICATION_TITLE = `Verification | ${TACITBASE}`;
export const ORGANISATION_TITLE = `Organisations | ${TACITBASE}`;

// roles
export const GUEST = "GUEST";
export const OWNER = "Owner";
export const ADMIN = "ADMIN";

export const roles = {
  COMMENTOR: "Commentor",
  MEMBER: "Member",
  ADMIN: "Admin",
};

export const DEACTIVATE_BUTTON = "Deactivate";
export const ACTIVATE_BUTTON = "Activate";

//sorting typrs
export const ASCENDING = "ASCENDING";
export const DESCENDING = "DESCENDING";
export const DATE = "DATE";

//formdata
export const TALENT_PARTNER = "TALENT PARTNER";
export const MEMBER_ONLY = "member only";
export const PUBLIC = "public";

//search box placehlder
export const SEARCH_LABEL_PLACEHOLDER = "Search label";
export const SEARCH_MEMBER_PLACEHOLDER = "Search member";

export const UPLOAD_FROM_COMPUTER = "Upload from computer";
export const JOB_POSTS = "job posts";


export const FILTER_OPERATORS: any = [
  { name: "string", operators: ["start with", "equals"] },
  { name: "number", operators: ["is less than", "is greator than"] },
  // { name: "date", operators: ["between"] },
  // { name: "paragraph", operators: ["between"] },
  { name: "url", operators: ["between"] },
  // { name: "file", operators: ["between"] },
  { name: "email", operators: ["between"] },
];
export const COLUMN = "Column";
export const OPERATOR = "Operator";

export const LESS_THAN = "less than";
export const GREATOR_THAN = "greater than";
export const LESS_THAN_EQUAL = "less than or equal to";
export const GREATOR_THAN_EQUAL = "greater than or equal to";
export const IS = "is";

export const operatorMap: { [key: string]: string } = {
  [IS]: "=",
  [LESS_THAN_EQUAL]: "<=",
  [LESS_THAN]: "<",
  [GREATOR_THAN_EQUAL]: ">=",
  [GREATOR_THAN]: ">",
  // Add more mappings as needed
};
type FilterOperator =
  | typeof IS
  | "<"
  | ">"
  | "="
  | "<="
  | ">="
  | "before"
  | "after"
  | typeof LESS_THAN
  | typeof GREATOR_THAN
  | typeof GREATOR_THAN_EQUAL
  | typeof LESS_THAN_EQUAL;

export type FilterOperatorObject = { name: FilterOperator };

type FilterOperators = Record<string, FilterOperatorObject[]>;

export const filterOperators: FilterOperators = {
  string: [{ name: IS }],
  number: [
    { name: LESS_THAN },
    { name: GREATOR_THAN },
    { name: IS },
    { name: LESS_THAN_EQUAL },
    { name: GREATOR_THAN_EQUAL },
  ],
  boolean: [{ name: IS }],
  date: [{ name: "before" }, { name: "after" }],
  url: [{ name: IS }],
  email: [{ name: IS }],
  radio: [{ name: IS }],
  paragraph: [{ name: IS }],
  phone: [{ name: IS }],
  // Add more types and operators as needed
};
export const FormBuilderTableHead = [
  "Title",
  "status",
  "created by",
  "updated on",
  // "linked",
  "quick actions",
];
export type customRoles = {
  id: string;
  name: string;
  description: string;
};

export const board_role: Role[] = [
  {
      "description": "Can view, edit all resources and settings",
      "id": "",
      "insertInstant": 0,
      "isDefault": false,
      "isSuperRole": false,
      "lastUpdateInstant": 0,
      "name": "Admin"
  },
  {
      "description": "Can only view,comments all public and member only resources",
      "id": "",
      "insertInstant": 0,
      "isDefault": false,
      "isSuperRole": false,
      "lastUpdateInstant": 0,
      "name": "Commentor"
  },
  {
      "description": "Can view, edit all public and member only resources",
      "id": "",
      "insertInstant": 0,
      "isDefault": false,
      "isSuperRole": false,
      "lastUpdateInstant": 0,
    "name": "Member"
  },
  {
      "description": "Can view, edit and delete all resources and settings",
      "id": "",
      "insertInstant": 0,
      "isDefault": false,
      "isSuperRole": false,
      "lastUpdateInstant": 0,
      "name": "Owner"
  }
];
export const boardVisibilityActions: customRoles[] = [
  {
    id: "",
    name: PUBLIC,
    description: "All members of organisation can see this board",
  },
  {
    id: "",
    name: MEMBER_ONLY,
    description: "Only board members can see this board.",
  },
];
export const PREVIEW = "Preview";

//
export const CANDIDATES = "candidates";
export const REVIEWS = "reviews";

//domain tags
export const TACITMAIL_DOMAIN = "tacitmail.com";
export const TACITMAIL = "Tacitmail";

//activity api modalname
export const MODEL_BOARD = "boards";


export const jdInfo = {
  prePublish: `Before publishing the job description,
  ensure all fields are accurately filled and review for errors.
  Once published, the job will be live and you'll receive a shareable URL`,
  aiInfo: `The information you provide will only be used for this job
  description and will not impact future work.`,
  aiWarning: `The AI-generated content may be incorrect or inappropriate; review before use.`
};



export const advanceFormDesignComponent = [
  {
    name: "Heading",
    type: "heading",
    secret: false,
    label: "Heading",
    size: "default",
    subheading: "subheading",
  },
  {
    name: "Divider",
    type: "divider",
    secret: false,
    label: "Divider",
    style: "solid",
    height: 1,
  },
  {
    name: "EEO",
    type: "EEO",
    secret: false,
    label: "EEO",
  },
  {
    name: "File Upload",
    type: "file",
    secret: false,
    label: "Add attachment",
  },
  // {
  //   name: "Footer",
  //   type: "footer",
  //   secret: false,
  //   label: "Footer",
  // },
  {
    name: "Terms and conditions",
    type: "TermsAndConditions",
    secret: false,
    label: "Terms and conditions",
    isRequired: true,
    link: "#"
  },
];

export const REQUIRED_FIELD_OF_DATABASE = [
  "first_name",
  "last_name",
  "email",
  "button",
];

export const DIVIDER_STYLE: string[] = ["solid", "dotted", "dashed"];
export const HEADING_SIZE: string[] = ["default", "small"];
// export const LOGO_ALIGNMENT: string[] = [
//   "left", "center", "right"
// ]
export const UNSAVED_WARNING = "you have unsaved changes"
export const FORM_SETTING = "Form setting";
export const FIELD_SETTING = "Field setting";

export const BACK = "Back";

export const PUBLISH = "publish";
export const PUBLISH_JOB = "publish job"
export const UNPUBLISH_JOB = "unpublish job";

export const UNPUBLISH = "unpublish";

/****************************login page content  *******************************/
//search collection
export const COLLECTION_CDB_CANDIDATES = "candidates_candidates"; // global search in candidates services that includes all collections

//reset password link expire component heading
export const RESET_PWD_LINK_HEADING =
  "It seems like the password reset link you are trying to use has either expired or has already been used.";

export const RESET_PWD_LINK_ADDITIONAL_HEADING =
  "If you continue to experience issues or have any questions, please contact our support team for assistance.";

export const RESET_PASSWORD_HEADING = "Enter the email associated with your account,and we'll send an email with instructions to reset your password."

//Constant for date format used in antd datepicker

export const DateFormate="DD MM YYYY";

  // Define validation patterns
export const regexPatterns: { [key: string]: RegExp } =  {
  github: /^(https?:\/\/)?(www\.)?github\..*\/.*$/,
  figma: /^(https?:\/\/)?(www\.)?figma\..*\/.*$/,
  dribbble: /^(https?:\/\/)?(www\.)?dribbble\..*\/.*$/,
  leetcode: /^(https?:\/\/)?(www\.)?leetcode\..*\/.*$/,
  personal_blog: /^(https?:\/\/)?([a-zA-Z0-9_\-.]+\.)+[a-zA-Z]{2,6}(\/.*)?$/,
  behance: /^(https?:\/\/)?(www\.)?behance\..*\/.*$/,
  research_gate: /^(https?:\/\/)?(www\.)?researchgate\..*\/.*$/,
  stack_overflow: /^(https?:\/\/)?(www\.)?stackoverflow\..*\/.*$/,
  google_scholar:
    /^https:\/\/scholar\.google\.com\/citations\?hl=en&user=[A-Za-z0-9_-]+$/,
  email:
    /^[a-zA-Z0-9]+(?:[-._+][a-zA-Z0-9]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?(?:\.[a-zA-Z]{2,})+$/,
  linked_in: /^(https?:\/\/)?(www\.)?linkedin\.com\/.*$/,
  twitter: /^(https?:\/\/)?(www\.)?x\.com\/.*$/,
  facebook: /^(https?:\/\/)?(www\.)?facebook\.com\/.*$/,
  youtube: /^(https?:\/\/)?(www\.)?youtube\.com\/.*$/,
  insta: /^(https?:\/\/)?(www\.)?instagram\.com\/.*$/,
  website: /^(https?:\/\/)?(www\.)?[^/]+\/?.*$/

};
export const EEO_STATEMENT1 = "We are committed to providing equal employment opportunities to all applicants and employees. We are dedicated to fostering an inclusive environment where everyone feels valued and respected. We do not discriminate on the basis of race, color, religion, sex (including pregnancy, gender identity, and sexual orientation), national origin, age, disability, genetic information, veteran status, or any other legally protected characteristic."
export const EEO_STATEMENT2 = " All employment decisions are based on qualifications, merit, and business needs. We believe in creating a diverse workforce that reflects the communities we serve and promotes innovation and creativity."
export const CREATE_JOB_POST = "Create a job post"
// ATS tabs
export const PoolRoute="pool";
export const TrackerRoute="tracker";
export const CalendarRoute="calendar";
export const JobDescriptionRoute="job-description";
export const FormRoute="form";
export const SettingsRoute = "settings"

//JD warning msg
export const MAX_EXPERIENCE_ERROR = "Experience cannot be more than 100 years."


//form submission warning msg
export const FORM_SUBMISSION_WARNING_MSG = "Please provide a response for this field."
export const URL_FORMATE = "Invalid url format."
export const EMAIL_FORMATE = "Invalid email format."


//JD form input label
export const salary_range_from = "salary_range_from"
export const salary_range_to = "salary_range_to"
export const experience_level_from = "experience_level_from"
export const experience_level_to = "experience_level_to"
export const number_of_open_positions = "number_of_open_positions"

//JD input error msgs
export const compensation_error = "Compensation values should be in proper range."
export const experience_error = "Experience values should be in proper range."
export const memberTabsInSetting = {
  ACTIVE: { label: "All", key: "active" },
  MEMBERS: { label: "Members", key: "members" },
  GUEST: { label: "Guests", key: "guest" },
  AGENCIES: { label: "Agencies", key: "agency" },
  DEACTIVE: { label: "Deactive", key: "deactivate" },
  INVITED: { label: "Invited", key: "invited" }
};

export const UserType = {
  FULL: "full",
  AGENCY: "agency",
  GUEST: "guest",
  INTERVIEWER:"interviewer"
}
// cancel warning
export const CANCEL_WARNING_HEADING = "Are you sure you want to cancel? You'll lose your current job post and will need to recreate it.This action is irreversible."

// Save job post as draft
export const SAVE_DRAFT_SUCCESS = "You can edit or publish it from the Job Description tab inside the Job Tracker."

// job post publish
export const JOB_POST_PUBLISHED_SUCCESS = "Job posted on partner site will take at least 90 minutes to go live."

// job post archive
export const JOB_POST_ARCHIVED_SUCCESS = "Job post archived successfully."
// job post unarchive
export const JOB_POST_UNARCHIVED_SUCCESS = "Job post restored successfully.."


export const EVENT_CREATION_SUCCESS_MSG = "Event scheduled successfully."

//add candidate msg
export const CANDIDATE_ADDED_SUCCESS = "Candidate added successfully."

export const CANDIDATES_ADDED_TO_TRACKER_SUCCESS = "Candidates added successfully to tracker."

export const CANDIDATE_ADDED_TO_TRACKER_SUCCESS = "Candidate added successfully to tracker."

export const CANDIDATES_SEND_TO_REVIEW_SUCCESS = "Candidates sent for review."

export const CANDIDATE_SEND_TO_REVIEW_SUCCESS = "Candidate sent for review."
export const REFERRAL="referral"
export const THIRD_PARTY="third party"
export const OTHER="other"
export const AGENCIES="agencies"


//Sourcing mediums
export const RESUME_MEDIUM="tacitbase_resume"
export const TACITBASEQA_MEDIUM="tacitbase_qa"

//sourcing  private mode 
export const PRIVATE_MODE="pvt"

export const NOT_AVAILABLE="Not available"


// Columns required for validation in tables after parsing the resume
export const CANDIDATE_REQUIRED_FIELD = [
  "first_name",
  "last_name",
  "email",
];




//Background color of side navbar

export const BG_COLOR_SIDE_NAV = "#FBFAFD";
// "#EFEFEF"
// "#F0EFFE"


export const colors = {
  primary: '#4B48A5',
  secondary: '#F0EFFE',
  divider:'#ADB1B0',
};

export const DIVIDER_HEIGHT='16px';

export const SLACK_BUTTON = "Connect slack"


//custom values react quill text editor
export const customFormats = [
  "header",
  "bold",
  "italic",
  "underline",
  "list",
  "bullet",
];

//custom modules react quill text editor
export const customModules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, false] }],
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    // ['clean']
  ],
};

//Constant for social media icons
export const SOCIAL_LINKS_ICON: Record<string, JSX.Element>={
  facebook: <Facebook/>,
  twitter: <Twitter/>,
  linked_in: <Linkedin/>,
  // whatsapp: <Whatsapp/>,
  github: <Github/>,
  figma: <Figma />,
  dribbble: <Dribbble />,
  leetcode: <Leetcode />,
  personal_blog: <PersonalBlog />,
  behance: <Behance />,
  research_gate: <ResearchGate />,
  google_scholar: <GoogleScholar />,
  stack_overflow: <StackOverflow />,
}

export const File_SIZE_LIMIT_MB= 10;
export const FILE_SIZE_LIMIT = (1048576 * File_SIZE_LIMIT_MB); // 1 MB in bytes


export const predefinedFields = [
  { name: "linked_in", type: "url", view: "LinkedIn",custom:false },
  { name: "github", type: "url", view: "GitHub",custom:false  },
  { name: "figma", type: "url", view: "Figma" ,custom:false },
  { name: "dribbble", type: "url", view: "Dribbble",custom:false  },
  { name: "leetcode", type: "url", view: "LeetCode",custom:false  },
  { name: "personal_blog", type: "url", view: "Personal blog" ,custom:false },
  { name: "behance", type: "url", view: "Behance",custom:false  },
  { name: "research_gate", type: "url", view: "ResearchGate",custom:false  },
  { name: "google_scholar", type: "url", view: "Google Scholar" ,custom:false },
  { name: "twitter", type: "url", view: "Twitter",custom:false  },
  { name: "stack_overflow", type: "url", view: "Stack Overflow",custom:false  },
  
];
export const BETA_PRODUCT = "Our resume relevancy feature is now in beta — give it a try and share your feedback with us!";
export const PAID_RELEVANCY_FEATURES = "AI Relevancy is a paid feature. Upgrade your plan for exclusive access to evaluate candidate resumes.";
export const  PoolFilters = [
  { id: 1, label: "Relevant" },
  { id: 2, label: "Irrelevant" },
];


export const SET_VIEW_TYPE = "SET_VIEW_TYPE";
export const timezoneSelectCustomStyle=(width: string) =>({
  control: (base: any, state: any) => ({
    ...base,
    height: '32px', // Fixed height
    minHeight: '32px', // Ensuring minimum height
    width: width,
    border: state.isFocused ? '1px solid #4b48a5' : '1px solid #d9d9d9',
    boxShadow: state.isFocused ? '0 0 0 2px rgba(75, 72, 165, 0.2)' : 'none',
    '&:hover': {
      borderColor: '#4b48a5',
    },
    padding: '0 4px', // Adjust padding to ensure content fits well
    fontSize: '14px',
    backgroundColor: state.isDisabled ? '#f5f5f5' : '#fff', 
    cursor: state.isDisabled ? 'not-allowed' : 'default',
   }),
  //   valueContainer: (base: any) => ({
  //   ...base,
  //   padding: '0 8px', // Padding for inner content
  //   height: '32px', // Ensure height matches
  //   display: 'flex',
  //   alignItems: 'center', // Vertically center content (like placeholder and selected value)
  // }),
  placeholder: (base: any) => ({
    ...base,
    color: '#bfbfbf',
    display: 'flex',
    alignItems: 'center', // Vertically center the placeholder text
    height: '100%', // Ensure placeholder takes up full height of the container
  }),
  singleValue: (base: any) => ({
    ...base,
    color: '#333',
    display: 'flex',
    alignItems: 'center', // Vertically center the selected value
    height: '100%', // Take up full height to ensure proper centering
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    padding: '0', // Remove extra padding around the icon
    display: 'flex',
    alignItems: 'center', // Vertically center the dropdown icon
    height: '32px', // Match the height of the control
    color: '#bfbfbf',
    '&:hover': {
      color: '#4b48a5',
    },
  }),
  indicatorsContainer: (base: any) => ({
    ...base,
    height: '32px', // Ensure indicator container matches the control height
    display: 'flex',
    alignItems: 'center', // Vertically center the entire indicator container
  }),
  menu: (base: any) => ({
    ...base,
    borderRadius: '4px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  }),
  option: (base: any, state: any) => ({
    ...base,
    backgroundColor: state.isFocused ? '#f5f5f5' : '#fff',
    color: '#333',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
  }),
})

// Mapping providers to their properties
export const CALENDER_PROVIDER = {
  google: {
    icon: <GoogleCalendarIcon  />,
    name: 'Google calendar',
  },
  outlook: {
    icon: <OutlookIcon />,
    name: 'Outlook calendar',
  },
  // Add more providers here as needed
};
export const customMenuStyle = {
  backgroundColor: "white",
  border: "1px solid white",
  padding: 0, // Remove padding
  margin: 0, // Remove margin
};

export const customMenuItemStyle = {
  paddingTop: 0, // Remove padding
  marginTop: 0, // Remove margin
  marginBottom: 0,
  paddingBottom: 0,
};

export const SEND_TO_REVIEW_LABEL_WARNING_MESSAGE = "Candidate is being sent for review without labels. Consider adding labels for context."


// e-sign available integrations
export const availableIntegrations = [
  {
    key: "1",
    type: "signeasy",
    logo: "https://cdn.prod.website-files.com/5f83f30d99580b736213bc4e/655c819768a43e11f13de3aa_Signeasy_Shortform.png",
    description:
      "Integrate SignEasy to send, track, and receive signed documents directly within the platform.",
  },
  {
    key: "2",
    type: "docusign",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfbwTHpyBJIYdm0V3VXQ0m7ckwU6oiqp0uow&s",
    description:
      "Integrate Docusign to send, track, and receive signed documents directly within the platform.",
  },
  {
    key: "3",
    type: "zohosign",
    logo: "https://ascentbusiness.co.uk/wp-content/uploads/2024/07/sign-512.png",
    description:
      "Integrate ZohoSign to send, track, and receive signed documents directly within the platform.",
  },
]
export const jobPostFilters = [
  { value: 'name', label: 'Name' },
  // { value: 'labels', label: 'Labels' },
  { value: 'description', label: 'Description' },
  // { value: 'comment', label: 'Comments' },
//   { value: 'location', label: 'Location' },
//   { value: 'experience', label: 'Experience' },
//   { value: 'skills', label: 'Skills' },
//   { value: 'Job_post_name', label: 'Job Post Name' }
];

export const talentPoolFilters = [
  { value: 'first_name', label: 'First Name' },
  { value: 'last_name', label: 'Last Name' },
  // { value: 'labels', label: 'Labels' },
  { value: 'description', label: 'Description' },
  // { value: 'comment', label: 'Comments' },
  { value: 'location', label: 'Location' },
  { value: 'experience', label: 'Experience' },
  { value: 'skills', label: 'Skills' },
  {value: 'linked_in', label: 'Linkedin'},
  {value: 'github', label: 'Github'},

];

export const mailFilters = [
  { value: 'name', label: 'Name' },
  { value: 'subject', label: 'Subject' },
  { value: 'to', label: 'To' },
  { value: 'from', label: 'From' },
  { value: 'body', label: 'Body' }
];

export const reviewFilters = [
  { value: 'name', label: 'Review Name' },
  { value: 'labels', label: 'Labels' },
  { value: 'comment', label: 'Comments' }
];




// Calendar sharing permissions

export const permissionOptionsGoogle = [
  { value: "freeBusyReader", label: "See only free/busy (hide details)" },
  { value: "reader", label: "See all event details" },
  { value: "writer", label: "Make changes to events" },
  { value: "owner", label: "Make changes and manage sharing" },
];
export const permissionOptionsOutlook = [
  { value: 'writer', label: "Can edit" }, {
    value:"reader",label:"Can view all details"
  }
]

export const deleteEsignIntegration = "Deleting this integration will remove all documents. Re-integrate to access them again.";
