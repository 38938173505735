import React, { useRef } from "react";
import Input from "antd/es/input";
import Button from "antd/es/button";
import Form from "antd/es/form";
import Divider from "antd/es/divider";


type EditProfilePopupProps = {
  click: (e: any, flag: boolean) => void;
  attachment: any;
  updatedAttchmentName(attachment: string): void;
  updateAttachmentClick: (e: any) => void;
};

const EditAttachmentPopup = (props: EditProfilePopupProps) => {
  const attachmentRef = useRef<any>(null);

  const validateCommentLength = () => {
    if (attachmentRef.current) {
      const currentValue = attachmentRef.current.input?.value || "";
      props.updatedAttchmentName(currentValue);
    }
  };

  return (
    // <Popover
    //   className="flex flex-col h-auto z-10 w-52 rounded"
    //   title="Edit attachment"
    //   content={
    <>
    <Divider className="my-0"/>
        <Form layout="vertical" className="mt-2" onFinish={props.updateAttachmentClick}>
          <Form.Item
            label="Attach file name :"
            name="attachmentName"
            initialValue={props.attachment.name}
          >
            <Input
              className="w-full rounded"
              placeholder="Enter label name"
              ref={attachmentRef}
              data-testid="input"
              onClick={(e: any) => e.stopPropagation()}
              onKeyDown={(e: any) => {
                if (e.key === "Enter") {
                  props.updateAttachmentClick(e);
                }
              }}
              onChange={validateCommentLength}
            />
          </Form.Item>
          <Form.Item className="justify-end flex">
            <Button type="primary" className="mt-1" onClick={props.updateAttachmentClick}>
              Update
            </Button>
          </Form.Item>
        </Form>
        </>
    //   }
    // />
  );
};

export default EditAttachmentPopup;
