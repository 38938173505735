import React, { useState } from "react";
import Button from "antd/es/button";
import Dropdown from "antd/es/dropdown";
import Menu from "antd/es/menu";
import CaretDownOutlined from "@ant-design/icons/lib/icons/CaretDownOutlined";
import ZoomSVG from "../../../assets/ZoomSVG";
import { connectZoom, getIntegratedZoom } from "../../../lib/api";
import { useSelector } from "react-redux";
import { loginUser } from "../../../type/type";
import { throwWarning } from "../../../service/throwError";
import SyncOutlined from "@ant-design/icons/SyncOutlined"
const ConferenceDropdown = ({ onZoomClick,loading }: { onZoomClick: () => void ,loading:boolean}) => {
  const loginUserData: loginUser = useSelector((state: any) => state.opening.loginUser);
  const [isZoomIntegrated, setIsZoomIntegrated] = useState<boolean>(false);
  // const [loading, setLoading] = useState<boolean>(false);

  const fetchZoomIntegration = async () => {
    // setLoading(true);
    try {
      const res = await getIntegratedZoom();
      if (Array.isArray(res?.tools) && res.tools.some((tool: any) => tool.client === "zoom")) {
        setIsZoomIntegrated(true);
      } else {
        setIsZoomIntegrated(false);
      }
    } catch (error) {
      console.log(error);
    } 
  };

  const handleZoomClick = async () => {
    try {
      const authData = await connectZoom("zoom", loginUserData.user.id); // API call to get Zoom OAuth URL

      if (authData) {
        const oauthWindow = window.open(authData.url, "oauthWindow", "width=600,height=600");

        const handleMessage = (event: MessageEvent) => {
          if (event.origin === window.location.origin) {
            if (event.data.status === "connected") {
              setIsZoomIntegrated(true);
              onZoomClick();
            } else if (event.data.status === "error") {
              throwWarning("Please try again later.");
            }
          }
        };

        window.addEventListener("message", handleMessage, false);

        // Close event listener when OAuth window closes
        const checkWindowClosed = setInterval(() => {
          if (oauthWindow?.closed) {
            clearInterval(checkWindowClosed);
            window.removeEventListener("message", handleMessage);
          }
        }, 500);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const menu = (
    <Menu>
      <Menu.Item
        key="zoom"
        onClick={isZoomIntegrated ? onZoomClick : undefined}
        disabled={!isZoomIntegrated}
        icon={loading?
          <span className="h-5 w-5 flex items-center justify-center">
            <SyncOutlined />
          </span>:
          <span className="h-5 w-5 flex items-center justify-center">
            <ZoomSVG />
          </span>
        }
      >
        Zoom Meeting
      </Menu.Item>
      {!isZoomIntegrated && (
        <Button type="text" size="small" key="integrateZoom" onClick={handleZoomClick} className="text-red-600 w-full text-left">
          Log in
        </Button>
      )}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]} onOpenChange={(open) => open && fetchZoomIntegration()}>
      <Button className="p-2" icon={<CaretDownOutlined />} />
    </Dropdown>
  );
};

export default ConferenceDropdown;
