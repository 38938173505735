import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  addMemberOnCard,
  removeMemberFromCard,
} from "../../../../redux/actions/opening/action";
import {
  createApplicantMembership,
  removeApplicantMembership,
} from "../../../../lib/api";
import {
  roles,
  SOCKET_CLIENT_ID,
} from "../../../../constant";
import {
  currentApplicant,
  orgMember,
  Opening,
  OpeningMembers,
  Member,
} from "../../../../type/type.d";
import CheckOutlined from "@ant-design/icons/CheckOutlined";
import UserAddOutlined from "@ant-design/icons/UserAddOutlined";
import MemberAddPopover from "../../../common/MemberPopupAnt";
import { SecondaryButton } from "../../../../ant/Button";

type AddMembersProps = {
  currentOpening: Opening;
  members: orgMember;
  openingMembers: OpeningMembers;
  currentApplicant: currentApplicant;
  applicantMembers: any;
  applicationId: string;
  clientId: string;
  addMember(member: any, applicantID: string): void;
  removeMember(memberID: string, applicantID: string): void;
};

const AddMembers = ({
  currentOpening,
  members,
  openingMembers,
  currentApplicant,
  applicantMembers,
  applicationId,
  addMember,
  removeMember,
}: AddMembersProps) => {
  const [initialMembers, setInitialMembers] = useState<Member[]>([])
  const boardMembers = openingMembers[currentOpening.id] || [];

  useEffect(() => {
    if (boardMembers.length === 0) return;
    const memberIdList = boardMembers.map(member => member.id);
    const selectedMembers = Object.values(members).filter(member => memberIdList.includes(member.id));
    setInitialMembers(selectedMembers)
  }, [ boardMembers, members]);

  const determineIcon = (member:Member) => {
    return (
      <>
        {applicantMembers[currentApplicant.id]
          ? applicantMembers[currentApplicant.id].map(
              (applabelsID: any) =>
                members[member.id].id == applabelsID ? (
                  <span key={applabelsID}>
                    <CheckOutlined />
                  </span>
                ) : null
            )
          : null}
      </>
    );
  };
  const handleClick = (member:Member)=>{
          const applicantMemberList = applicantMembers[currentApplicant.id] || [];
          const isMemberAdded = applicantMemberList.includes(member.id);
          const isDisabled = currentApplicant.creator === member.id
          if(isDisabled) return;
          const req = {
            id: isMemberAdded ? member.id : currentApplicant.id,
            applicant_id: currentApplicant.id,
            application_id: applicationId,
            opening_id: currentOpening.id,
            socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
          };
    
          if (isMemberAdded) {
            removeApplicantMembership(req).then((res) => {
              if (res) removeMember(member.id, currentApplicant.id);
            });
          } else {
            const newMembership = {
              ...req,
              members: [
                { id: member.id, role: roles.MEMBER, is_secrete_visible: false },
              ],
              role: roles.MEMBER,
              is_secrete_visible: false,
            };
            createApplicantMembership(newMembership).then((applicantMembers) => {
              addMember(applicantMembers, currentApplicant.id);
            });
          }
        }
  return (
    <div>
      <MemberAddPopover determineIcon={determineIcon} onAddMember={handleClick} element={<SecondaryButton icon={<UserAddOutlined />} className="w-full truncate flex justify-start" onClick={() => { }} >Members</SecondaryButton>} defaultTitle="addmember" initialMembers={initialMembers} />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  members: state.opening.members,
  openingMembers: state.opening.openingMembers,
  currentApplicant: state.opening.currentApplicant,
  applicantMembers: state.opening.applicantMembers,
  applicationId: state.opening.applicationId,
  clientId: state.opening.clientId,
});

const mapDispatchToProps = (dispatch: any) => ({
  addMember: (members: any, applicantID: string) =>
    dispatch(addMemberOnCard(members, applicantID)),
  removeMember: (memberID: string, applicantID: string) =>
    dispatch(removeMemberFromCard(memberID, applicantID)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddMembers);
