import React, { useState } from "react";
import { SEARCH_LABEL_PLACEHOLDER } from "../../../../constant";
import { capitalizeFirstLetter } from "../../../../utils/capitalizeFirstLetter";
import LabelPreview from "../labelPreview/LabelPreview";
import TacitbaseInput from "../../../../ant/Input";
import Emoji from "../../../Emoji";
import { SecondaryButton } from "../../../../ant/Button";
import Typography from 'antd/es/typography';
const {Text} = Typography;
type LabelInputProps = {
  click: (searchInput: string) => void;
  change?: (e: any) => void;
  update: (labelID: any) => void;
  createLabel?: () => void;
  flag?: boolean;
  close: (e: boolean) => void;
  usePortal?: boolean;
};
const LabelInput = (props: LabelInputProps) => {
  const [searchedLabel, getSearchedLabel] = useState("");
  const searchKeyword = (e: any) => {
    getSearchedLabel(e.target.value);
  };

  const onEmojiSelect = (emoji: string) => {
    getSearchedLabel((prev) => prev + emoji);
  };
  return (
    <React.Fragment>
      <div className="my-2 mx-2">
        <TacitbaseInput
          placeholder={SEARCH_LABEL_PLACEHOLDER}
          onChange={(e: any) => searchKeyword(e)}
          value={searchedLabel}
          data-testid="label-search-input"
          suffix={
            <Emoji onEmojiSelect={onEmojiSelect} usePortal={props.usePortal} />
          }
        />
      </div>
      <div className="flex flex-col">
        <Text className="font-semibold">LABEL</Text>
        <LabelPreview
          flag={props.flag}
          update={(label) => props.update(label)}
          searchedLabel={searchedLabel}
        />
        <SecondaryButton
          onClick={() => props.click(searchedLabel)}
          className="mx-2"
        >
          {capitalizeFirstLetter("create a new label")}
        </SecondaryButton>
      </div>
    </React.Fragment>
  );
};
export default LabelInput;
