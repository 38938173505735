import React from "react";
import Input from "antd/es/input";
import Typography from "antd/es/typography";
import Form from "antd/es/form";
import CalendarOutlined from "@ant-design/icons/CalendarOutlined";
import CheckCircleOutlined from "@ant-design/icons/CheckCircleOutlined";
import CalendarSharing from "./CalendarSettingsModal";
import { CalendarItem } from "../../../lib/apiRes";

const { Text } = Typography;

interface CreateCalendarContentProps {
  showMemberShare: boolean;
  newlyCreatedCalendar: CalendarItem;
  config: {
    description: string;
  };
}

const CreateCalendarContent: React.FC<CreateCalendarContentProps> = ({
  showMemberShare,
  config,
  newlyCreatedCalendar,
}) => {
  if (showMemberShare) {
    return (
      <CalendarSharing
        isModal={false}
        selectedCalendarForEdit={newlyCreatedCalendar}
      />
    );
  }

  

  return (
    <div>
      <div className="flex flex-col pt-4 pb-6">
        <Text type="secondary">{config.description}</Text>
      </div>
      <div className="px-4">
        <div className="bg-tacitbase-secondary3 bg-opacity-10 rounded p-4 mb-6">
          <h3 className="font-medium mb-3">
            Your team members will be able to:
          </h3>
          <div className="space-y-3">
            {[
              "View and schedule recruitment events",
              "Coordinate interview timeslots",
              "Track events by job positions",
            ].map((capability, index) => (
              <div key={index} className="flex items-center">
                <CheckCircleOutlined className="text-green-500 mr-3" />
                <span>{capability}</span>
              </div>
            ))}
          </div>
        </div>

        <Form.Item
          label="Calendar name"
          name="calendarName"
          rules={[
            {
              required: true,
              message: "Please input calendar name",
            },
          ]}
        >
          <Input
            prefix={<CalendarOutlined />}
            placeholder="Calendar name"
          />
        </Form.Item>
      </div>
    </div>
  );
};

export default CreateCalendarContent;
