import React, { lazy, useEffect, useState } from "react";

import Button from "antd/es/button";
import Divider from "antd/es/divider";
import Pagination from "antd/es/pagination";
import Space from "antd/es/space";
import Typography from "antd/es/typography";
import LeftOutlined from "@ant-design/icons/LeftOutlined";
import RightOutlined from "@ant-design/icons/RightOutlined";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
const { Text } = Typography;

import { Dispatch } from "redux";
import { LabeledIconButton } from "../../ant/Button";
import { getCandidate, getCandidateLabel } from "../../lib/api";
const TacitBaseModal = lazy(() => import("../../ant/TacitbaseModal"));
import { Overlay } from "../hook/Overlay";
import CandidateInCDBDetails from "./CandidateInCDBDetails";
import CandidateInCDBTabs from "./tabs/CandidateInCDBTabs";
import SendCDBCandidateToReview from "./actions/SendCDBCandidateToReview";
import SendMailToCDBCandidate from "./actions/SendMailToCDBCandidate";
import AddAttachmentToCDBCandidate from "./actions/AddAttachmentToCandidateInCDB";
import AddLabelToCandidate from "./actions/AddNewLabelToCDBCandidate";
import SendCandidateToTracker from "./actions/SendCDBCandidateToTracker";
import SendCandidateToPool from "./actions/SendCDBCandidateToPool";
import {
  InitCurrentCandidateInDB,
  InitCurrentCandidateLabel,
} from "../../redux/actions/opening/action";
import { Candidate, Label, Scope, loginUser } from "../../type/type.d";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { renderCreatedAt } from "../../utils/renderCreatedAt";
import SuspenseLoader from "../SuspenseLoader";

const DetailedCDBApplicant = (props: {
  selectedRow: string;
  applicationId: string;
  candidateDB: any;
  loginUser: loginUser;
  InitCurrentCandidateLabel: (labels: Label[]) => void;
  InitCurrentCandidateInDB: (candidate: Candidate) => void;
  selectedRowKeyData: any;
  pageNumber: number;
  currentCandidateInEditMode: any;
  sources:{[key:string]:{name:string,profile:string}},
}) => {
  const history: any = useHistory();
  const [total, setTotal] = useState<number>(0);
  const params: { board_id: string; candidate_id: string; tabname: string } =
    useParams();
  const itemRender = (current: any, type: any) => {
    if (type === "prev") {
      return (
        <LabeledIconButton
          icon={<LeftOutlined />}
          onClick={() => {}}
          label=""
        />
      );
    }
    if (type === "next") {
      return (
        <LabeledIconButton
          icon={<RightOutlined />}
          onClick={() => {}}
          label=""
        />
      );
    }

    if (type === "page") {
      return null;
    }
    return null;
  };

  const backToPool = () => {
    history.push({
      pathname: `/o/${props.applicationId}/db`,
    });
  };
  useEffect(() => {
    params?.candidate_id &&
      (getCandidate(params.candidate_id).then((res) => {
        props.InitCurrentCandidateInDB(res);
      }),
        getCandidateLabel(params.candidate_id).then((res) => {
          props.InitCurrentCandidateLabel(res);
        }));
  }, [params]);

  const handleTableChange = (current: number) => {
    history.push({
      pathname: `/o/${props.applicationId}/db/${
        Object.keys(props.candidateDB[props.candidateDB.currentpage])[
        current - 1
        ]
        }`,
    });
  };

  useEffect(() => {
    // Calculate the total number of items
    let totalItems: number =
      props.candidateDB &&
      props.candidateDB[props.candidateDB.currentpage] &&
      Object.keys(props.candidateDB[props.candidateDB.currentpage]).length;
    setTotal(totalItems);
  }, [props.candidateDB]);

  return (
    <>
      <SuspenseLoader>
      <TacitBaseModal
        keyboard={false}
        maskClosable={false}
        visibility={params.candidate_id ? true : false}
        width={"100%"}
        title={
          <>
            <Overlay onClose={backToPool} scope={Scope.Dialog}>
              <div
                className={`w-full flex justify-between items-center py-3    
                 
                `}
                style={{ height: 52 }}
              >
                <div className="">
                  <Button
                    style={{
                      color: "#000000",
                      transition: "color 0.3s",
                    }}
                    className=""
                    icon={<LeftOutlined />}
                    type="link"
                    name="Back to pool"
                    onClick={backToPool}
                  >
                    {" "}
                    Back to Talent pool
                  </Button>
                </div>
                {/* Pagination component in the header */}
                <div className="flex flex-row">
                  <div className="flex items-center h-7 ">
                    <Text>
                      {props.currentCandidateInEditMode?.candidateId.source?.from &&
                        (props.sources[props.currentCandidateInEditMode?.candidateId.source?.from]
                          ? capitalizeFirstLetter(
                            props.sources[props.currentCandidateInEditMode?.candidateId.source?.from].name
                          )
                          : capitalizeFirstLetter(
                            props.currentCandidateInEditMode?.candidateId.source?.from
                          ))}
                    </Text>

                    {props.currentCandidateInEditMode?.candidateId.source?.medium && (
                      <Text className="font-normal">
                        {`, ${capitalizeFirstLetter(props.currentCandidateInEditMode.candidateId.source.medium)}`}
                      </Text>
                    )}
                    <div className="ml-4 font-normal">
                      <ul>
                        <li>
                          <Text>
                            {renderCreatedAt(props.currentCandidateInEditMode?.candidateId.created_at)}
                          </Text>
                        </li>
                      </ul>
                    </div>
                    <Divider type="vertical" />
                  </div>{" "}
                  <div className="flex items-center">
                    <Pagination
                      showTotal={(total, range) =>
                        `${range[0]} of ${total} applicants`
                      }
                      className=" pr-4"
                      pageSize={1}
                      showQuickJumper={false}
                      showSizeChanger={false}
                      current={
                        params.candidate_id &&
                          props.candidateDB[props.candidateDB.currentpage]
                          ? Object.keys(
                            props.candidateDB[props.candidateDB.currentpage]
                          )?.indexOf(params.candidate_id) + 1
                          : 1
                      }
                      // current={current}
                      onChange={handleTableChange}
                      total={total} // Total number of reviews
                      itemRender={(current: any, type: any) =>
                        itemRender(current, type)
                      }
                      style={{ alignItems: "center" }}
                    />
                  </div>
                </div>
              </div>

              {/* <Divider className="m-0 p-0" /> */}
            </Overlay>
          </>
        }
        closeIcon={null}
        content={
          <>
            <div
              style={{
                margin: 0,
                // height: modalContentHeight,
                // paddingRight: "2px",
                position: "relative",
                // borderRadius: '12px',
                overflow: "hidden",
                width: "calc(100vw - 150px )",
                maxWidth: "calc(100vw - 150px )",
                height: "calc(100vh - 161px )",
                maxHeight: "calc(100vh - 100px )",
                borderRadius: "4px",
              }}
            >
              <div style={{ display: "flex" }} className="h-full  ">
                {/* First Part */}
                <div
                  style={{ width: "48%" }}
                  className="flex flex-col h-full flex-shrink-0  border-l border-b border-t border-r"
                >
                  <CandidateInCDBDetails />{" "}
                </div>

                {/* Second Part */}
                <div
                  style={{
                    width: "35%",
                  }}
                  className=" pr-0.5 pb-2  flex flex-col h-full border-t border-r"
                >
                  <CandidateInCDBTabs />
                </div>
                <>
                  <>
                    <div
                      style={{ width: "17%" }}
                      className="px-5 py-3 border-t border-r border-b"
                    >
                      <Space direction="vertical" className="w-full space-y-1">
                        <AddLabelToCandidate />
                        <AddAttachmentToCDBCandidate />
                        <SendCandidateToTracker
                          isBulk={false}
                          candidateIds={[]}
                          isCollapsed={true}
                        />
                        <SendCandidateToPool
                          isBulk={false}
                          candidateIds={[]}
                          isCollapsed={true}
                        />
                        <SendCDBCandidateToReview
                          isBulk={false}
                          candidateIds={[]}
                        />
                        <SendMailToCDBCandidate
                          isBulk={false}
                          candidateIds={[]}
                          cadidatesData={[]}
                        />
                      </Space>
                    </div>
                  </>
                </>
              </div>
            </div>
          </>

          // </Overlay >
        }
        footer={<div className="m-0"></div>}
        style={{
          overflowY: "hidden",
          width: " 100vw",
          height: "100vh",
          position: "fixed",
          top: "50px",
          left: "10px",
          bottom: "10px",
          right: "0px",
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
          padding: "0px",
          borderRadius: "4px",
          // opacity: 1
        }}
        className="review-modal-content"
      />
        </SuspenseLoader>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  applicationId: state.opening.applicationId,
  loginUser: state.opening.loginUser,
  candidateDB: state.opening.candidateDB,
  currentCandidateInEditMode: state.opening.currentCandidateInEditMode,
  sources: state.opening.candidateSources,

});
const mapPropsToState = (dispatch: Dispatch) => ({
  InitCurrentCandidateInDB: (candidateId: any) =>
    dispatch(InitCurrentCandidateInDB(candidateId)),
  InitCurrentCandidateLabel: (labels: Label[]) =>
    dispatch(InitCurrentCandidateLabel(labels)),
});

export default connect(mapStateToProps, mapPropsToState)(DetailedCDBApplicant);
