import { useCallback, useEffect } from 'react';
import { notification } from 'antd';

export const useNetworkStatus = () => {
  const handleOffline = useCallback(() => {
    notification.destroy();
    setTimeout(() => {
      notification.open({
        type: "error",
        message: "You are offline",
        className: "text-sm font-medium",
        description: "Please check your internet connection",
        duration: 0,
        placement: "bottomLeft",
      });
    }, 500);
  }, []);

  const handleOnline = useCallback(() => {
    notification.destroy();
    setTimeout(() => {
      notification.open({
        type: "success",
        message: "Network is back online",
        className: "text-sm font-medium",
        description: "",
        duration: 4,
        placement: "bottomLeft",
      });
    }, 500);
  }, []);

  useEffect(() => {
    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);
    
    return () => {
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOnline);
    };
  }, []);
}; 