import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Spin from 'antd/es/spin';
import { exchangeAuthCode } from '../lib/api';
// import { getAccessToken } from '../lib/api';

interface OAuthLoginProps {}

const OAuthLogin: React.FC<OAuthLoginProps> = () => {
  const location = useLocation();

  useEffect(() => {
    const handleOAuthLogin = async () => {
      const urlParams = new URLSearchParams(location.search);      
      const code = urlParams.get('code');
      const state = urlParams.get('state');

      if (!code || !state) {        
        notifyOpenerAndClose({
          type: 'auth_error_message',
          error: 'Missing required OAuth parameters'
        });
        return;
      }

      try {
        const tokenResponse = await exchangeAuthCode(code, state, Intl.DateTimeFormat().resolvedOptions().timeZone);
        // Set the token in a cookie
        document.cookie = `auth_token=${tokenResponse.user.token}; path=/; secure; samesite=strict`;

        notifyOpenerAndClose({
          type: 'auth_success_message',
          result: tokenResponse
        });
      } catch (error: any) {
        console.error('OAuth login error:', error);
        let errorMessage = 'Authentication failed';
        
        // Handle specific error cases
        if (error.response?.status === 422) {
          errorMessage = error.response?.data?.error?.message || 'Invalid authentication data';
        }

        notifyOpenerAndClose({
          type: 'auth_error_message',
          error: errorMessage,
          status: error.response?.status
        });
      }
    };

    handleOAuthLogin();
  }, [location]);

  const notifyOpenerAndClose = (message: { type: string; error?: string; result?: any; status?: number }) => {
    if (window.opener) {
      window.opener.postMessage(message, window.location.origin);
      window.close();
    }
  };

  return (
    <div className="h-screen flex items-center justify-center">
      <div className="text-center">
        <Spin size="large" />
        <p className="mt-4 text-gray-600">Completing authentication...</p>
      </div>
    </div>
  );
};

export default OAuthLogin; 