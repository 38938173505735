import React, { useEffect } from "react";
import Image from "../../components/common/image/Image";
import Grid from 'antd/es/grid';
import paymentMethodTemplate from "../../images/PaymentMethodTemplate.png";
import { connect } from "react-redux";
import { newOrganisation } from "../../lib/apiRes";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { loginUser } from "../../type/type";
import { OWNER, PlansName, TACITBASE } from "../../constant";
import Link from "antd/es/typography/Link";
import { useHistory } from "react-router-dom";
import { getOrgDetails } from "../../lib/api";

const { useBreakpoint } = Grid;
type paymentIndexComponentProps = {
  initCurrentOrg: newOrganisation;
  loginUser: loginUser;
  applicationId:string;
};
const SubscriptionExpired = (props: paymentIndexComponentProps) => {
  useEffect(()=>{
    if(props.applicationId){
      const user = Object.values(props.loginUser?.user?.registrations)
      const userRole = user?.filter((user)=>user.applicationId === props.applicationId)[0]?.roles[0]
      getOrgDetails(props.applicationId).then((res)=>{
        if (
          (res.customer?.subscriptions?.data[0]?.status === "active" ||
          res.customer?.subscriptions?.data[0]?.status === "trialing")||(  (res.customer.metadata.plan).toLowerCase() === PlansName.free.toLowerCase())
        ) {
          history.push(`/o`);
        } else if (userRole.toUpperCase() !== OWNER.toUpperCase()) {
          history.push("/contact-admin")
        }
      })
    }
  },[]);
  const screens = useBreakpoint();
  const history=useHistory()
  return (

      <div className="flex  pt-6    w-full h-full xl:overflow-y-scroll space-x-4 2xl:space-x-0 ">
        <div className="w-1/12"></div>
        {screens.xl && (
          <div className="w-8/12  2xl:w-7/12  flex  justify-start items-center">
            <Image src={paymentMethodTemplate} size="full" />
          </div>
        )}
        <div className="xl:w-5/12 2xl:3/12 flex xl:justify-end justify-center h-full items-start w-full">
          <div
            className="bg-white     xl:p-8 p-4 rounded xl:mt-10 mt-4   "
            style={{
              width: 463,
              boxShadow: "2px 4px 10px 0px #00000040",
              border: "1px solid #D9D9D9",
            }}
          >
            <div>
              <div className=" flex flex-col space-y-6">
                <div className="w-full flex items-center justify-center font-semibold md:text-lg text-base">
                  {capitalizeFirstLetter("Subscription Expired")}
                </div>
                <div className="flex flex-col space-y-4 lg:text-sm text-xs">
                  <div>
                    <div className="tracking-wide">
                      Hey{" "}
                      <span className="font-medium">
                        {props.loginUser.user.firstName}
                      </span>
                      ,
                    </div>
                   
                  </div>
                  <div>
                      It looks like your {TACITBASE} subscription has expired. We&apos;d love to keep supporting your hiring needs!
                    </div>
                    <div>
                    Renew now by clicking on Manage account to continue enjoying uninterrupted access to our inclusive features. Your diverse team-building journey awaits.
                    </div>
                    <div>Happy Hiring!</div>

                  <div className="flex flex-col my-4">
                    <div className="flex flex-col w-full space-x-0 space-y-0"></div>
                    <div className="flex justify-end w-full">
                      
                    <Link onClick={()=>history.push('/login')} className="">
                    Manage account
                    </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/12"></div>
      { }
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  initCurrentOrg: state.opening.currentOrg,
  loginUser: state.opening.loginUser,
  applicationId: state.opening.applicationId,
});
export default connect(mapStateToProps)(SubscriptionExpired);
