import Divider from "antd/es/divider"
import React, { ReactNode } from "react";
interface ContentHeaderProps {
  children: ReactNode;
}

const ContentHeader: React.FC<ContentHeaderProps> = ({ children }) => {
  return <><div className="bg-white " style={{ height: "48px" }}>{children}</div><Divider style={{ margin: 0 }} /></>
};

export default ContentHeader;
