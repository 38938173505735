import React, { useEffect, useState } from "react";
import { SecondaryButton } from "../../../../ant/Button";
import CheckOutlined from "@ant-design/icons/CheckOutlined";
import LoadingOutlined from "@ant-design/icons/LoadingOutlined";


import { CANDIDATES, SOCKET_CLIENT_ID } from "../../../../constant";
import {
  currentApplicant,
  GetOpening,
  InitLabel,
  KeyWithArray,
  Label,
} from "../../../../type/type";
import {
  createApplicantLabel,
  createOpeningLabel,
  deleteLabel,
  editLabel,
  getLabels,
} from "../../../../lib/api";

import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  addBoardLabelInCard,
  initCandidateLabel,
  removeLabelFromCard,
  saveCardLabel,
  updateLabelState,
} from "../../../../redux/actions/opening/action";
import AddLabelPopover from "../../../common/AddLabelPopover";
import { determineTextColor } from "../../../../utils/CalculateBrightnessOfColor";
import AddLabelIcon from "../../../Icons/AddLabelIcon";

// const { Text } = Typography;

export type CardLabelsProps = {
  currentOpening: GetOpening;
  currentApplicant: currentApplicant;
  saveApplicantLabel(label: any, id: string, listID: string): void;
  updateApplicantLabel(updatedLabel: any): void;
  clientId: any;
  candidateLabels: InitLabel;
  boardList: any;
  initCandidateLabels: (labels: Label[]) => void;
  labels: InitLabel;
  applicantLabels: KeyWithArray;
  removeLabel(labelID: string, applicantID: string, list_id: string): void;
  saveLabel: (label: any) => void;
};

const CardLabels = ({
  currentOpening,
  currentApplicant,
  saveApplicantLabel,
  boardList,
  initCandidateLabels,
  labels,
  applicantLabels,
  removeLabel,
  saveLabel,
  updateApplicantLabel,
}: CardLabelsProps) => {
  const [loader, showLoader] = useState(false);
  const [loadedLabel, setloadedLabel] = useState("");

  useEffect(() => {
    getLabels(CANDIDATES).then((res) => {
      initCandidateLabels(res);
    });
  }, []);

  const editCandidateLabel = (updatedLabel: {
    id: string;
    value: string;
    color: string;
  }) => {
    const req = {
      id: updatedLabel.id,
      color: updatedLabel.color,
      name: updatedLabel.value,
      opening_id: currentOpening.id,
      socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
      applicant_id: currentApplicant.id,
    };
    editLabel(req).then((applicant_label: any) => {
      updateApplicantLabel(applicant_label);
    });
  };
  const createLabel = (newLabel: { value: string; color: string }) => {
    // if label length is greater than 50
    const label = {
      name: newLabel.value,
      color: newLabel.color,
      opening_id: currentOpening.id,
      applicant_id: currentApplicant.id,
      socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
    };
    createOpeningLabel(label).then((label: any) => {
      const req = {
        name: newLabel.value,
        color: newLabel.color,
        applicant_id: currentApplicant.id,
        label_id: label.id,
        opening_id: currentOpening.id,
        socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
      };
      createApplicantLabel(req).then((applicant_label: any) => {
        saveLabel(applicant_label);
      });
    });
  };

  const addOrRemoveLabelFromCandidate = (labelData: any) => {
    setloadedLabel(labelData.id);
    let found: boolean = false;
    if (applicantLabels[currentApplicant.id]) {
      found = applicantLabels[currentApplicant.id].includes(labelData.id);
      if (found) {
        showLoader(true);
        const req = {
          opening_id: currentOpening.id,
          applicant_id: currentApplicant.id,
          label_id: labelData.id,
          socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
        };
        deleteLabel(req)
          .then((res: any) => {
            showLoader(false);
            if (res)
              removeLabel(
                labelData.id,
                currentApplicant.id,
                currentApplicant.list_id
              );
          })
          .catch(() => {
            showLoader(false);
          });
      }
    }
    if (!found) {
      const req = {
        name: labelData.id,
        color: labelData.color,
        applicant_id: currentApplicant.id,
        label_id: labelData.id,
        opening_id: currentOpening.id,
        socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
      };
      showLoader(true);
      createApplicantLabel(req)
        .then((applicant_label: any) => {
          const newLabel = {
            id: applicant_label.label_id,
            name: labelData.name,
            color: labelData.color,
          };
          showLoader(false);

          saveApplicantLabel(
            newLabel,
            currentApplicant.id,
            currentApplicant.list_id
          );
        })
        .catch(() => {
          showLoader(false);
        });
    }
  };
  const determineIcon = (label: Label): JSX.Element | null => {
    const isLabelExist = applicantLabels[currentApplicant.id]?.includes(
      label.id
    );
    const isLabelSame = label.id === loadedLabel;

    if (loader && isLabelSame) {
      return (
        <LoadingOutlined style={{ color: determineTextColor(label.color) }} />
      );
    }

    if (isLabelExist) {
      return (
        <CheckOutlined style={{ color: determineTextColor(label.color) }} />
      );
    }

    return null;
  };
  return (
    <div>
      <AddLabelPopover
        determineIcon={determineIcon}
        onAddLabel={addOrRemoveLabelFromCandidate}
        selectedLabels={boardList[currentApplicant.list_id]?.applicants
          ?.filter((applicant: any) => applicant.id === currentApplicant.id)
          .labels?.map((label: string) => label)}
        element={
          <SecondaryButton
            onClick={() => {}}
            icon={<AddLabelIcon />}
            className="left-align-button w-full truncate"
          >
            Add label
          </SecondaryButton>
        }
        onEditLabel={editCandidateLabel}
        onCreateLabel={createLabel}
        defaultTitle="Create Label"
        initialLabels={Object.values(labels)}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  currentApplicant: state.opening.currentApplicant,
  clientId: state.opening.clientId,
  candidateLabels: state.opening.candidateLabels,
  boardList: state.opening.boardList,
  labels: state.opening.labels,
  applicantLabels: state.opening.applicantLabels,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveApplicantLabel: (label: any, id: string, listID: string) =>
    dispatch(addBoardLabelInCard(label, id, listID)),
  updateApplicantLabel: (updatedLabel: any) =>
    dispatch(updateLabelState(updatedLabel)),
  initCandidateLabels: (labels: Label[]) =>
    dispatch(initCandidateLabel(labels)),
  removeLabel: (labelID: string, applicantID: string, list_id: string) =>
    dispatch(removeLabelFromCard(labelID, applicantID, list_id)),
  saveLabel: (label: any) => dispatch(saveCardLabel(label)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardLabels);
