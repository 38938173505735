import React, { lazy, useEffect, useState } from "react";

import Divider from "antd/es/divider";
import Pagination from "antd/es/pagination";
import ArrowLeftOutlined from "@ant-design/icons/ArrowLeftOutlined";
import LeftOutlined from "@ant-design/icons/LeftOutlined";
import RightOutlined from "@ant-design/icons/RightOutlined";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";

import { LabeledIconButton } from "../../ant/Button";
const TacitBaseModal = lazy(() => import("../../ant/TacitbaseModal"));
import { Overlay } from "../hook/Overlay";
import { Scope } from "../../type/type.d";
import { userMails } from "../../redux/reducers/initialState";
import MailThreadView from "./MailThreadView";
import SuspenseLoader from "../SuspenseLoader";

const DetailedMailSentView = (props: {
  applicationId: string;
  pageNumber: number;
  userMails: userMails | any;
}) => {
  const history: any = useHistory();
  const [total, setTotal] = useState<number>(0);
  const params: { org_id: string; candidate_id: string; tabname: string } =
    useParams();
  const itemRender = (current: any, type: any) => {
    if (type === "prev") {
      return (
        <LabeledIconButton
          icon={<LeftOutlined />}
          onClick={() => {}}
          label=""
        />
      );
    }
    if (type === "next") {
      return (
        <LabeledIconButton
          icon={<RightOutlined />}
          onClick={() => {}}
          label=""
        />
      );
    }

    if (type === "page") {
      return null;
    }
    return null;
  };

  const backToSent = () => {
    history.push({
      pathname: `/o/${props.applicationId}/tmail/sent`,
    });
  };

  const handleTableChange = (current: number) => {
    history.push({
      pathname: `/o/${props.applicationId}/tmail/sent/${
        Object.keys(props.userMails["sent"][props?.userMails?.sent?.current_page])[
          current - 1
        ]
      }`,
    });
  };

  useEffect(() => {
    // Calculate the total number of items
    let totalItems: number =
      props.userMails &&
      props.userMails["sent"] &&
      props.userMails["sent"][props?.userMails?.sent?.current_page] &&
      Object.keys(props.userMails["sent"][props?.userMails?.sent?.current_page])
        .length;
    setTotal(totalItems);
  }, [props.userMails]);

  return (
    <>
      <SuspenseLoader>
      <TacitBaseModal
        keyboard={false}
        maskClosable={false}
        visibility={params.candidate_id ? true : false}
        width={"80%"}
        title={
          <>
            <Overlay onClose={backToSent} scope={Scope.Dialog}>
              <div
                className={`w-full flex justify-between items-center py-3    
                 
                `}
                style={{ height: 52 }}
              >
                <div className="ml-2">
                  {/* <Button
                    style={{
                      color: "#000000",
                      transition: "color 0.3s",
                    }}
                    className=""
                    icon={<LeftOutlined />}
                    type="link"
                    name="Back to pool"
                    onClick={backToSent}
                  >
                    {" "}
                    Back to sent
                  </Button> */}
                  <LabeledIconButton
                    icon={<ArrowLeftOutlined />}
                    type="text"
                    label="Back to sent"
                    onClick={backToSent}
                    className="left-align-button"
                  />{" "}
                </div>
                {/* Pagination component in the header */}
                <div className="flex flex-row">
                  <div className="flex items-center">
                    <Pagination
                      showTotal={(total, range) =>
                        `${range[0]} of ${total} mails`
                      }
                      className=" pr-4"
                      pageSize={1}
                      current={
                        params.candidate_id && props?.userMails?.sent?.current_page
                          ? Object.keys(
                              props.userMails["sent"][
                                props?.userMails?.sent?.current_page
                              ]
                            )?.indexOf(params.candidate_id) + 1
                          : 1
                      }
                      // current={current}
                      onChange={handleTableChange}
                      total={total} // Total number of reviews
                      itemRender={(current: any, type: any) =>
                        itemRender(current, type)
                      }
                      style={{ alignItems: "center" }}
                    />
                  </div>
                </div>
              </div>

              <Divider className="m-0 p-0" />
            </Overlay>
          </>
        }
        closeIcon={null}
        content={
          <>
            <div
              style={{
                margin: 0,
                // height: modalContentHeight,
                // paddingRight: "2px",
                position: "relative",
                // borderRadius: '12px',
                overflow: "hidden",
                width: "calc(80vw - 150px )",
                maxWidth: "calc(80vw - 150px )",
                height: "calc(100vh - 161px )",
                maxHeight: "calc(100vh - 100px )",
                borderRadius: "4px",
              }}
            >
              <div
                style={{ display: "flex" }}
                className="h-full flex-col flex overflow-y-scroll pl-2"
              >
                <MailThreadView
                  selectedMailId={params.candidate_id}
                  tab={"sent"}
                  // refKey={props?.selectedRowKeyData?.key}
                  currentPage={props?.pageNumber?.toString()}
                />
              </div>
            </div>
          </>

          // </Overlay >
        }
        footer={<div className="m-0"></div>}
        style={{
          overflowY: "hidden",
          width: " 80vw",
          height: "100vh",
          position: "fixed",
          top: "50px",
          left: "90px",
          bottom: "10px",
          right: "0px",
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
          padding: "0px",
          borderRadius: "4px",
          // opacity: 1
        }}
        className="review-modal-content"
        />
        </SuspenseLoader>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  applicationId: state.opening.applicationId,
  userMails: state.opening.userMails,
});

export default connect(mapStateToProps)(DetailedMailSentView);
