import Checkbox from "antd/es/checkbox";
import Typography from "antd/es/typography";
import React, { useState } from "react";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";

const { Text } = Typography;
const CheckList = ({ checkLists, selectCheck }: any) => {
  const [selectedKeep, setSelectedKeep] = useState<string[]>([]);

  const selectKeep = (e: any) => {
    const value = e.target.value;

    if (e.target.checked) {
      // Add the value to the list immutably
      const updatedKeep = [...selectedKeep, value];
      setSelectedKeep(updatedKeep);
      selectCheck(updatedKeep);
    } else {
      // Remove the value immutably
      const updatedKeep = selectedKeep.filter((item) => item !== value);
      setSelectedKeep(updatedKeep);
      selectCheck(updatedKeep);
    }
  };
  const selectAll = (e: any) => {
    if (e.target.checked) {
      const allKeys = Object.keys(checkLists);
      setSelectedKeep(allKeys);
      selectCheck(allKeys);
    } else {
      setSelectedKeep([]);
      selectCheck([]);
    }
  };
  const keys = checkLists ? Object.keys(checkLists) : [];

  const isAllSelected = selectedKeep.length === keys.length;
  const isIndeterminate = selectedKeep.length > 0 && !isAllSelected;
  if (!checkLists) return null;

  return (
    <>
      <div className="mb-2">
        <Checkbox           
        checked={isAllSelected}
        onChange={selectAll}
        indeterminate={isIndeterminate}
        />
        <Text className="font-semibold ml-3">Keep...</Text>
      </div>
      {Object.keys(checkLists)?.map((key: string, i: number) => (
        <div key={i}>
          <Checkbox
            className="form-checkbox"
            value={key}
            onChange={selectKeep}
            checked={selectedKeep.includes(key)} 
          />
          <Text className="ml-3">
            {capitalizeFirstLetter(key)} ({checkLists[key]?.length})
          </Text>
        </div>
      ))}
    </>
  );
};

export default CheckList;
