import { tokenVerify } from "./tokenVerify";

export const authHeader = () => {
  if (tokenVerify()) {
    if (sessionStorage.getItem("current_tab_application_token")) {
      const token = sessionStorage.getItem("current_tab_application_token");
      return {
        Accept: "applizcation/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    } else {
      window.location.replace(`${import.meta.env.VITE_WEB_URL}/o`);
    }
  } else {
    window.location.replace(`${import.meta.env.VITE_WEB_URL}/login`);
  }
};

export const authHeader1 = () => {
  if (tokenVerify()) {
    const token = localStorage.getItem("login_token");
    return {
      Authorization: `Bearer ${token}`,
    };
  } else {
    window.location.replace(`${import.meta.env.VITE_WEB_URL}/login`);
  }
};

export const atsUploadHeader = () => {
  if (tokenVerify()) {
    const token = sessionStorage.getItem("current_tab_application_token");
    return {
      "Content-type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
  } else {
    window.location.replace(`${import.meta.env.VITE_WEB_URL}/login`);
  }
};
export const uploadHeader = () => {
  if (tokenVerify()) {
    const token = localStorage.getItem("login_token");
    return {
      "Content-type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
  } else {
    window.location.replace(`${import.meta.env.VITE_WEB_URL}/login`);
  }
};
