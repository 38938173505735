import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { addWsRequest } from "../../redux/actions/opening/action";
import Space from "antd/es/space";
import Popover from "antd/es/popover";
import Typography from "antd/es/typography";
import Divider from "antd/es/divider";
import Avatar from "antd/es/avatar";
import { loginUser, orgMember } from "../../type/type.d"
// import NavSearch from "../navSearch/NavSearch"
// import TacitbaseLogoWithLabel from "./TacitbaseLogoWithLabel"
import Logout from "../../pages/logout/Logout"
import TacitbaseAvtar from "../../ant/Avtar"

const { Text } = Typography;

type NavbarType = {
  loginUser: loginUser;
  applicationName: string;
  applicationId: string;
  addWsRequest(actionRequest: any): void,
  applicationImgUrl: string,
  members: orgMember
};

const Navbar = (props: NavbarType) => {
  const userRegistrations = props.loginUser.user.registrations || [];
        // Find the registration object with the matching applicationId
      const matchingRegistration = userRegistrations.find(
       (registration: any) => registration.applicationId === props.applicationId
      );
        const userRoles = matchingRegistration?.roles[0] || "";
  const content = (
    <>
      <div className="flex items-start justify-start" style={{ minWidth: 250 }}>
        <Space>
          {props.applicationImgUrl ? (
            <TacitbaseAvtar
              size="large"
              src={props.applicationImgUrl}
              shape="square"
            />
          ) : (
            <Avatar shape="square" size="default"
              className="bg-tacitbase-primary1 uppercase">{props.applicationName?.[0]}</Avatar>
          )}
          <div className="flex flex-col">
            <strong>
              <Text
                style={{ maxWidth: 200, minWidth: 100, margin: 0 }}
                ellipsis={{ tooltip: props.applicationName }}
              >
                {props.applicationName}
              </Text>
            </strong>
            <div
              key={props.members[props.loginUser.user.id]?.id}
              className="text-xs font-normal leading-none bg-tacitbase-secondary3 bg-opacity-20 px-2 py-1 rounded-default text-tacitbase-secondary3 w-max"
            >
              {/* {props.members[props.loginUser.user.id]?.registrations[0].roles.length > 0 &&
                props.members[props.loginUser.user.id].registrations[0].roles[0]} */}
                {/* {props.loginUser.user?.registrations[0]?.roles.length>0&&props.loginUser.user.registrations[0].roles[0]} */}
                {userRoles}
            </div>          </div>
        </Space>
      </div>
      <Divider style={{ margin: "10px" }} />
      <Logout />
    </>
  );

  return (
    <>
      {/* <TacitbaseLogoWithLabel />
      <div style={{ flex: 1, padding: "0 8px" }}>
        <NavSearch />
      </div> */}
      <Popover placement="bottomRight" content={content}>

      </Popover>
      <Popover placement="bottomRight"
        content={content}>
        {props.applicationImgUrl ? (
          <TacitbaseAvtar
            size="default"
            src={props.applicationImgUrl}
            shape="square"
          />
        ) : (
          <Avatar shape="square" size="default"
            className="bg-tacitbase-primary1 uppercase">{props.applicationName?.[0]}</Avatar>
        )}        </Popover>
    </>
  );
};

const mapPropsToState = (dispatch: Dispatch) => ({
  addWsRequest: (actionRequest: any) => dispatch(addWsRequest(actionRequest)),
});

const mapStateToProps = (state: any) => ({
  currentBoard: state.opening.currentBoard,
  applicationName: state.opening.applicationName,
  applicationImgUrl: state.opening.applicationImgUrl,
  applicationId: state.opening.applicationId,
  loginUser: state.opening.loginUser,
  members: state.opening.members,
});

export default connect(mapStateToProps, mapPropsToState)(Navbar);
