import React from "react";
import Breadcrumb from "antd/es/breadcrumb";
import ContentHeader from "../analyticsVersion1/ContentHeader";
import Navbar from "../navbar/Navbar";
import MeetingTable from "./MeetingsTable";



const MeetingHeader = () => {
  return (
    <>
      <div className="h-full w-full flex flex-col overflow-hidden">
        <ContentHeader>
          <div className="flex h-12 px-4 py-3 justify-between items-center">
            <div className="flex items-center">
              <Breadcrumb
                items={[
                  {
                    title: "Meetings",
                  },
                ]}
              />
            </div>
            <div className="flex flex-row items-center">
              <Navbar />
            </div>
          </div>
        </ContentHeader>
        <div className="flex-grow bg-white rounded overflow-hidden">
          <MeetingTable/>
        </div>
      </div>
    </>
  );
};

export default MeetingHeader;