// GenericSelect.tsx
import React, { ReactNode, useState } from "react";
import Select from "antd/es/select";


interface OptionType<T> {
  label: string |any;
  value: T;
  searchText?: string; // searchText is optional
}

interface GenericSelectProps<T> {
  options: Array<OptionType<T>>;
  onChange?: (value: T[], selectedOptions: any) => void;
  dropdownStyle?: React.CSSProperties; // Define dropdownStyle in props
  tagRender?:ReactNode|any;
  style?:any,
  maxTagCount?:any;
  maxTagPlaceholder?:any;
  valueRender?:any;
  Placeholder?:string;
}

function GenericSelect<T>({
  options,
  onChange,
  dropdownStyle,
  style,
  maxTagCount,
  maxTagPlaceholder,
  Placeholder,
  ...rest
}: GenericSelectProps<T>) {
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (value: T[], selectedOptions: any) => {
    setIsOpen(false);
    if (onChange) {
      onChange(value, selectedOptions);
    }
  };

  const handleDropdownVisibleChange = (open: boolean) => {
    setIsOpen(open);
  };


  const filterOption = (input: string, option: OptionType<T> | undefined) => {
    if (!option) return false;

    // Use `searchText` if available, otherwise fall back to checking if `label` is a string
    const searchValue = option.searchText || (typeof option.label === 'string' ? option.label : '');

    return searchValue.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };


  return (
    <Select
      mode="multiple"
      allowClear
      style={{ width: "100%",...style }}
      placeholder={Placeholder}
      showSearch  
      filterOption={filterOption} 
      onChange={handleChange}
      options={options}
      popupMatchSelectWidth={false}
      dropdownStyle={dropdownStyle}
      onDropdownVisibleChange={handleDropdownVisibleChange}
      open={isOpen}
      maxTagCount={maxTagCount}
      maxTagPlaceholder={maxTagPlaceholder}
      {...rest}
    />
  );
}

export default GenericSelect;
