import React, { useEffect, useState } from "react"
import Badge from "antd/es/badge";
import Menu from "antd/es/menu";
import Space from "antd/es/space";
import NotificationOutlined from "@ant-design/icons/NotificationOutlined"
import SettingOutlined from "@ant-design/icons/SettingOutlined"
import TacitbaseAvtar from "../../ant/Avtar"
import { connect } from "react-redux"
import { loginUser, Opening, orgMember } from "../../type/type"
import { useHistory, useLocation } from "react-router-dom";
import { capitalizeFirstLetter, generateTooltipForMember } from "../../utils/capitalizeFirstLetter"
import { SIDENAV_MENU_ITEMS } from "../../constant"
type MenuItem = {
    key: string,
    icon: React.ReactNode,
    label: React.ReactNode,
    showBadge?: boolean
}
const SideMenu = (props: { members: orgMember; loginUser: loginUser; isCollaps: boolean; applicationId: string,showDot: boolean,currentOpening:Opening }) => {
    const avtarSize = props.isCollaps ? "large" : "default"
    const [selectedKey, setSelectedKey] = useState("/")
    const history = useHistory()
    const location = useLocation()

    useEffect(() => {
        const pathName = location.pathname
        setSelectedKey(pathName)
    }, [location.pathname]);

    const menuItems: MenuItem[] = [
        {
            label: SIDENAV_MENU_ITEMS.UPDATES,
            icon: <NotificationOutlined   />,
            key: `/o/${props.applicationId}/updates`,
            showBadge: props.showDot,
        },
        {
            key: `/o/${props.applicationId}/settings/profile`,
            icon: <SettingOutlined   />,
            label: "Settings",
        },
    ]

    const userRegistrations = props.loginUser.user.registrations || [];
    // Find the registration object with the matching applicationId
   const matchingRegistration = userRegistrations.find(
   (registration: any) => registration.applicationId === props.applicationId
   );
    const userScopes = matchingRegistration?.data.scopes || [];
    const hasMeetingsScope =userScopes?.[0] === "meetings";
  

    const profileMenu: MenuItem[] = [
        {
            key: "2",
            icon: <Space>
                {props.loginUser.user.imageUrl ? <TacitbaseAvtar size={avtarSize} src={props.loginUser.user.imageUrl} />
                    : <TacitbaseAvtar size={avtarSize} content={props.loginUser.user.firstName[0] + props.loginUser.user.lastName[0]} />
                }
            </Space>,
            label: (
                <>
                    <strong>
                        {capitalizeFirstLetter(generateTooltipForMember({
                            first_name: props.loginUser.user.firstName,
                            last_name: props.loginUser.user.lastName
                        }))}
                    </strong>
                </>
            ),
        },
    ];


    return (
        <>
         {!hasMeetingsScope && (
            <Menu
                theme="light"
                mode="inline"
                style={ props.currentOpening.color || props.currentOpening.background_image
                    ?{
                        borderBottom: "1px solid rgba(255, 255, 255, 0.25)" 
                    }:
                    
                    {
                        borderBottomWidth: "1px",
                        borderBottomStyle: "solid",
                }}
                onClick={(item) => {
                    history.push({
                        pathname: item.key,
                    })
                }}
                selectedKeys={[selectedKey]}
                // items={menuItems}
            >
                {menuItems.map((menuItem: any) => (

                    <Menu.Item
                        key={menuItem.key}
                        icon={
                            props.isCollaps ? menuItem.showBadge && menuItem.label === SIDENAV_MENU_ITEMS.UPDATES ? (
                                <Badge dot offset={[0, 10]} status="processing" color="red">
                                    {menuItem.icon}
                                </Badge>
                            ) : (
                                menuItem.icon
                            ) : menuItem.icon
                        }
                        title={
                            props.isCollaps ? <span>{menuItem.label}</span> : undefined
                        } // Set title for tooltip
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                placeItems: "center",
                                alignItems: "center",
                            }}
                        >
                            {menuItem.label}
                            {menuItem.showBadge &&
                                menuItem.label === SIDENAV_MENU_ITEMS.UPDATES && (
                                    <Badge dot status="processing" color="red" />
                                )}
                        </div>
                    </Menu.Item>
                ))}
            </Menu>)}

            <Menu theme="light" mode="inline" selectedKeys={[]}>
                {profileMenu.map((menuItem: MenuItem) => (
                    <Menu.Item
                        style={{ padding: "0px 10px 0px 16px", paddingLeft: "0px", paddingRight: "0px", height: "43px", }}
                        key={menuItem.key}
                        icon={menuItem.icon}
                    >
                        {menuItem.label}

                    </Menu.Item>
                ))}
            </Menu>
        </>
    )
};

const mapStateToProps = (state: any) => ({
    loginUser: state.opening.loginUser,
    members: state.opening.members,
    openings: state.opening.openings,
    applicationId: state.opening.applicationId,
    currentOpening: state.opening.currentOpening,
    showDot: state.opening.notificationCenter.showDotOnUpdates,
});

export default connect(mapStateToProps)(SideMenu)
