import React, {  lazy, useState } from "react";
import Button from 'antd/es/button';
import CloseIcon from "../../assets/Close";
import "../../../src/components/candidateDB/ImportResumeModal.css";
import ResumeCoversionPool from "./ResumeCoversionPool";
import { connect } from "react-redux";
const TacitBaseModal = lazy(() => import("../../ant/TacitbaseModal"));
import { LabeledIconButton, UpgradeButton } from "../../ant/Button";
import UpgradePlanModal from "../setting/billingSetting/UpgradePlanModal";
import { newOrganisation } from "../../lib/apiRes";
import { useOrgMetadata } from "../setting/billingSetting/OrgMetadata";
import SuspenseLoader from "../../components/SuspenseLoader";

interface ImportResumePoolProps {
  currentTrackerJd: any;
  currentOrg:newOrganisation;
}

function ImportResumePool(props: ImportResumePoolProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const orgMetadata = useOrgMetadata(props.currentOrg);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleBillingModal = ()=>{
    setIsModalVisible(true);
  }
  const handleBillingModalCancel = () => {
    setIsModalVisible(false);
};
  return (
    <>
      {props?.currentTrackerJd?.id?.trim().length > 0 && (
          orgMetadata?.ai_feature === 'true' ? (
           <LabeledIconButton
             onClick={showModal}
             label="Resume parser"
             className="h-7"
           />
          ) : (
            <UpgradeButton
              label="Resume parser"
              onClick={handleBillingModal}
            />
        )
        )}
      <SuspenseLoader>
      <TacitBaseModal
        title={null}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        className="custom-modal"
        closable={false}
        width={1300}
        visibility={isModalOpen}
        destroyOnClose={true}
        content={
          <>
            <div className="custom-modal-header px-4">
              <div className="custom-modal-title">Add candidate</div>
              <Button onClick={handleCancel} type="text">
                <CloseIcon border={false} />
              </Button>
            </div>
            <div className="custom-modal-divider" />
            <div className="custom-modal-content">
              <ResumeCoversionPool handleClose={handleCancel} />
            </div>
            <div className=" flex text-sm  items-center justify-center px-4 pb-2">
              The resume parser may contain errors; please double-check
              candidate details.
            </div>
          </>
        }
      />
      </SuspenseLoader>
       <UpgradePlanModal isModalVisible = {isModalVisible} handleCancel={handleBillingModalCancel}/>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  // currentTrackerForm: state.opening.currentTrackerForm,
  currentTrackerJd: state.opening.currentTrackerJd,
  currentOrg:state.opening.currentOrg,
});
export default connect(mapStateToProps)(ImportResumePool);
