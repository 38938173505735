import Divider from "antd/es/divider";
import Typography from "antd/es/typography";
import Tooltip from "antd/es/tooltip";
import Checkbox from "antd/es/checkbox";
import Input from "antd/es/input";
import Popconfirm from "antd/es/popconfirm";
import Title from "antd/es/typography/Title";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Jd } from "../../type/type"
import { LabeledIconButton } from "../../ant/Button"
import ExportOutlined from "@ant-design/icons/ExportOutlined"
import LinkedinFilled from "@ant-design/icons/LinkedinFilled"
import LinkOutlined from "@ant-design/icons/LinkOutlined"
import LoadingOutlined from "@ant-design/icons/LoadingOutlined"
import LockOutlined from "@ant-design/icons/LockOutlined"
import { PUBLISH, UNPUBLISH } from "../../constant"
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter"
// import Paragraph from "antd/es/typography/Paragraph"
import { getIntegrationsOfJD, getLinkedinSourceEmail, updateIntegrationStatus, updateJD } from "../../lib/api"
import { Dispatch } from "redux"
import { updateCurrentJd } from "../../redux/actions/opening/action"
import { Integrations } from "../JdManagement/Types"
import FormItem from "../../ant/FormItem"
import Read from "../../Read"
import UpgradePlanModal from "../../pages/setting/billingSetting/UpgradePlanModal"
import { newOrganisation } from "../../lib/apiRes"
import { useOrgMetadata } from "../../pages/setting/billingSetting/OrgMetadata"
import MailIcon from "../Icons/MailIcon"
import CopyIcon from "../Icons/CopyIcon"
import SocialShare from "../JdManagement/JdSocialMedia/SocialShare";
const { Text } = Typography

const JDIntegrations = (props: { currentTrackerJD: Jd, updateCurrentJd: (JD: Jd) => void,currentOrg:newOrganisation }) => {
    const [tooltipVisible, setTooltipVisible] = useState(false)
    const [emailTooltipVisible, setEmailTooltipVisible] = useState(false)
    const [jdIntegrations, setJdIntegrations] = useState<Integrations[]>([])
    const [selectAllLoading, setSelectAllLoading] = useState(false)
    const [isPublished, setIsPublished] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false)
    const [loading, setLoading] = useState(false);
    const [hideLoading, setHideLoading] = useState(false);
    const [openHidePopup, setOpenHidePopup] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [linkedinEmail, setLinkedinEmail] = useState("");
    const [emailLoading, setEmailLoading] = useState(false);
    const orgMetadata = useOrgMetadata(props.currentOrg);
    const [handleSelectAllIntermediate, setHandleSelectAllIntermediate] = useState(false);

    function openRoute() {
        const route = props.currentTrackerJD.jd_public_url
        window.open(route, '_blank')
    }
    useEffect(() => {
        setEmailLoading(true);
        getLinkedinSourceEmail(props.currentTrackerJD.id).then((res)=>{
            setLinkedinEmail(res.value)
            setEmailLoading(false);
        }).catch((err)=>{
            console.log("error when getting linkedin source email",err)
            setEmailLoading(false);
        })
        getIntegrationsOfJD(props.currentTrackerJD.id).then((res) => {
            setJdIntegrations(res.integrations)
            const allPublished = res.integrations.every((integration: Integrations) => integration.status === PUBLISH);
            setIsPublished(allPublished);
        })
    }, [])

    const handleJDStatusUpdate = () => {
        let formData = new FormData()
        let customObj: { [key: string]: string } = {}
        customObj["status"] = props.currentTrackerJD.status === "draft" || props.currentTrackerJD.status === UNPUBLISH ? PUBLISH : UNPUBLISH
        formData.append("jd_data", JSON.stringify(customObj))
        setLoading(true);
        updateJD(props.currentTrackerJD.id, formData)
            .then((res) => {
                props.updateCurrentJd(res)
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error:", error)
            }).finally(()=>{
                setLoading(false);
            })
    }

    const handleCopy = () => {
        const textToCopy = props.currentTrackerJD.jd_public_url ? props.currentTrackerJD.jd_public_url : "Not available"
        navigator.clipboard.writeText(textToCopy).then(() => {
            setTooltipVisible(true)
            setTimeout(() => setTooltipVisible(false), 1000)
        })
    }

    const handleEmailCopy = ()=>{
        const textToCopy = linkedinEmail;
        navigator.clipboard.writeText(textToCopy).then(() => {
            setEmailTooltipVisible(true)
            setTimeout(() => setEmailTooltipVisible(false), 1000)
        })
    }

    const handleCheckboxChange = (id: string) => {
        let newIntegrations = jdIntegrations.map((integration) =>
            integration.integration_id === id
                ? { ...integration, status: integration.status === "publish" ? "unpublish" : "publish" }
                : integration
        )
        updateIntegrationStatus(props.currentTrackerJD.id, newIntegrations).then((res: { jd_id: string, integrations: Integrations[] }) => {
            const allPublished = jdIntegrations.every((integration: Integrations) => integration.status === PUBLISH);
            setIsPublished(allPublished);
            setJdIntegrations(res.integrations)
        })
      
    }

    const saveNewJd = () => {
        let formData = new FormData()
        const jdDataJSON = JSON.stringify({ "hide": !props.currentTrackerJD.hide })
        formData.append("jd_data", jdDataJSON)
        saveJD(formData)
    }
    const saveJD = (formData: FormData) => {
        setHideLoading(true);
        updateJD(props.currentTrackerJD.id, formData)
            .then((res) => {
                if (res) {
                    props.updateCurrentJd(res)
                }
                setHideLoading(false);
                setOpenHidePopup(false);
            }).finally(()=>{
                setHideLoading(false);
                setOpenHidePopup(false);
            })


    };
    const handleSelectAllCheck= () =>{
        setSelectAllLoading(true)
        const newIntegrations = jdIntegrations.map((integration) =>
          ({...integration, status: isPublished?PUBLISH:UNPUBLISH}));
        updateIntegrationStatus(props.currentTrackerJD.id, newIntegrations).then((res: { jd_id: string, integrations: Integrations[] }) => {
            setJdIntegrations(res.integrations)
            setSelectAllLoading(false)
            setOpenConfirm(false);
        })
      }
    const handleSelectAll= (e:CheckboxChangeEvent) =>{
        setIsPublished(e.target.checked)
        setOpenConfirm(true);
    }

    const handleBillingModal = ()=>{
        setIsModalVisible(true);
      }
      const handleBillingModalCancel = () => {
        setIsModalVisible(false);
    };
    useEffect(() => {
        const selectedCount = jdIntegrations.filter(integration => integration.status === PUBLISH).length;
        const isIntermediate = selectedCount > 0 && selectedCount <jdIntegrations.length;
        setHandleSelectAllIntermediate(isIntermediate);
      }, [jdIntegrations]);
    return (
        <>
            <div className="overflow-y-scroll h-full flex flex-col">
                <Title level={5} className="pt-2 sticky top-0">
                    {"Job integrations"}
                </Title>
                <Divider style={{ margin: 0 }} className="pb-3" />
                <div className="mt-4">
                    <FormItem label=" Internal/confidential job posting?">
                        <div className="flex gap-2 mt-1">
                            <Popconfirm
                                title={`Are you sure you want to ${props.currentTrackerJD.hide ? "unhide" : "hide"} this job?`}
                                open={openHidePopup}
                                onConfirm={() => saveNewJd()}
                                okText="Yes"
                                cancelText="No"
                                okButtonProps={{loading:hideLoading}}
                            >
                                <Checkbox
                                    checked={props.currentTrackerJD.hide}
                                    onClick={()=>setOpenHidePopup(true)}
                                />
                            </Popconfirm>
                            <div>Hide this job from career page.</div>
                        </div>
                    </FormItem>
                </div>

                <Text strong>{capitalizeFirstLetter("direct link")}</Text>
                <Text className="mb-3">
                    {"Update, publish or unpublish your job here"}
                </Text>

                <div className="space-x-2 justify-start items-center w-full flex  2xl:w-1/2 xl:w-3/4  xs:w-full  pr-2">
                    <Input readOnly disabled addonBefore={<LinkOutlined />} addonAfter={<Tooltip title="Copied to clipboard!" visible={tooltipVisible}>
                        <CopyIcon onClick={handleCopy} />
                    </Tooltip>} defaultValue={props.currentTrackerJD.jd_public_url ? props.currentTrackerJD.jd_public_url : "Not available"} />
                    <LabeledIconButton onClick={openRoute} label={""} icon={<ExportOutlined />} />
                  <Read>
                    <LabeledIconButton onClick={handleJDStatusUpdate} loading={loading}  label={capitalizeFirstLetter(props.currentTrackerJD.status === "draft" || props.currentTrackerJD.status === UNPUBLISH ? PUBLISH : UNPUBLISH)} />
                    </Read>

                </div>
                <Text type="secondary" className="my-2">{"The candidate who applies using this link will be considered in your sourcing."}
                </Text>
               
             {props.currentTrackerJD.status !== "draft" && (   
                <div>
                <Divider />
                <div className="mb-4 flex  gap-2 flex-col 2xl:w-1/2 xl:w-3/4  xs:w-full  pr-2">
                <Text strong className="">Social share</Text>
                <Text type="secondary" className="mb-3">
                    Share your job posts on social media or communication channels.
                </Text>
                <div className="flex  justify-start">
                <SocialShare jobPostUrl={props.currentTrackerJD.jd_public_url} jobTitle={props.currentTrackerJD.title}/>
                    </div>
                </div>
                </div>
            )}
                <Divider />
                <div className="mb-4 flex  gap-2 flex-col 2xl:w-1/2 xl:w-3/4  xs:w-full  pr-2">
                <div className="pr-4 flex gap-2">
                    <LinkedinFilled
                      style={{
                        fontSize: "22px",
                        color: "#0A66C2",
                      }}
                    />
                 <Text strong>Linkedin job post email</Text>
                </div>
                    <Text type="secondary">
                    Add this job post email to LinkedIn, and candidates will be auto-added to your job pool.
                    </Text>
                <div className="my-2">
                    <Input  disabled readOnly addonBefore={emailLoading?<LoadingOutlined />: <MailIcon />} addonAfter={<Tooltip title="Copied to clipboard!" visible={emailTooltipVisible}>
                        <CopyIcon onClick={handleEmailCopy} />
                    </Tooltip>} value={linkedinEmail} />
                    </div>
                </div>
                <div className="pr-4 flex gap-3">
                <Popconfirm
                        title={`Are you sure you want to ${isPublished ? "publish" : "unpublish"} all this integration?`}
                        onConfirm={handleSelectAllCheck}
                            okButtonProps={{ loading: selectAllLoading }}
                        open={openConfirm}
                        okText="Yes"
                        cancelText="No"
                        onCancel={() => setOpenConfirm(false)}
                            >
                        {orgMetadata?.job_posting === 'true' ? (
                            <Checkbox 
                            indeterminate = {handleSelectAllIntermediate}
                             checked={jdIntegrations.every((integration: Integrations) => integration.status === PUBLISH)} 
                              onChange={handleSelectAll} 
                            />
                        ) : (
                            <div className="">
                                <Checkbox onClick={handleBillingModal} disabled={true} />
                            </div>
                            )}
                        </Popconfirm>
                    <Text strong> 
                        {orgMetadata?.job_posting === 'false' &&          
                    <LockOutlined />}{" "}
                    {" Publish jobs"}</Text>
                </div>
                <Text className="mb-3" type="secondary">                    
                    {
                        "Job posted on partner sites will take at least 90-180 min."}
                </Text>
                <div className="flex flex-wrap gap-3 mb-3 rounded">
                    {jdIntegrations
                        .map((integration: Integrations) => integration && (
                        <div
                            className="flex rounded items-center gap-1 p-2"
                            key={integration.integration_id}

                            style={{
                                border: "1px solid #D9D9D9",
                                padding: "8px",
                                height: "56px",
                                minWidth: "172px",
                                maxWidth:"auto"
                              }}
                        >  
                          {
                            orgMetadata?.job_posting === 'true' ? (
    <                           Popconfirm
                                title={`Are you sure you want to ${
                                    integration.status === "publish" ? "unpublish" : "publish"
                                } this integration?`}
                                onConfirm={() => handleCheckboxChange(integration.integration_id)}
                                okText="Yes"
                                cancelText="No"
                                >
                                <Checkbox checked={integration.status === "publish"} />
                                </Popconfirm>
                            ) : (
                                        <Checkbox checked={false} disabled />
                            )
                            }

                        
                            <img
                                src={integration.platform_image}
                                alt={integration.platform_name}
                                style={{
                                    width: "30px",
                                    height: "30px",
                                    marginLeft: "8px",
                                    marginRight: "8px",
                                  }}
                            />
                            <div>{capitalizeFirstLetter(integration.platform_name)}</div>
                        </div>
                    ))}

                </div>

            </div>
            <UpgradePlanModal isModalVisible = {isModalVisible} handleCancel={handleBillingModalCancel}/>
        </>
    )
}

const mapStateToProps = (state: any) => ({
    companyBoard: state.opening.companyBoards,
    applicationId: state.opening.applicationId,
    currentTrackerJD: state.opening.currentTrackerJd,
    currentOrg:state.opening.currentOrg,
})

const mapPropsToState = (dispatch: Dispatch) => ({
    updateCurrentJd: (JD: Jd) =>
        dispatch(updateCurrentJd(JD)),
})
export default connect(mapStateToProps, mapPropsToState)(JDIntegrations)
