import React from "react";
import WhatsAppOutlined from "@ant-design/icons/WhatsAppOutlined";
import MailFilled from "@ant-design/icons/MailFilled";
import RedditSquareFilled from "@ant-design/icons/RedditSquareFilled";
import SlackIcon from "../../../assets/SlackIcon";
import Linkedin from "../../../assets/Linkedin";
import Facebook from "../../../assets/Facebook";
import Twitter from "../../../assets/Twitter";

interface SocialShareProps {
  jobPostUrl: string;
  jobTitle?: string;
  companyName?: string;
}

const SocialShare: React.FC<SocialShareProps> = ({
  jobPostUrl,
  jobTitle = "Check out this job opportunity",
  companyName = "",
}) => {
  const encodedUrl = encodeURIComponent(jobPostUrl);
  const encodedTitle = encodeURIComponent(jobTitle);
  const emailSubject = `Job Opportunity: ${jobTitle}`;
  const emailBody = `Dear Colleague,

  I wanted to share a job opportunity that might interest you or someone in your network.
  
  Position: ${jobTitle}
  
  We're currently seeking talented professionals to join our team. This role offers competitive compensation, excellent benefits, and significant growth potential.
  
  For more information and to apply, please visit:`;
  const encodedEmailSubject = encodeURIComponent(emailSubject);
  const encodedEmailBody = encodeURIComponent(emailBody);

  const linkedinDescription = `🔥 Exciting opportunity: ${jobTitle} 🔥\n\nWe're looking for talented professionals to join our growing team! This position offers competitive compensation, excellent benefits, and significant growth potential. If you're passionate about making an impact and advancing your career, check out the full details below and apply today!\n\n#JobOpportunity #Hiring #Careers`;
  const twitterDescription = `🔥 Job Alert: ${jobTitle} 🔥 Great opportunity with competitive pay & benefits. #JobOpportunity #Hiring`;

  const encodedDescription = encodeURIComponent(linkedinDescription);
  const encodedTwitterDesc = encodeURIComponent(twitterDescription);

  const shareLinks = {
    linkedin: `https://www.linkedin.com/feed/?shareActive=true&text=${encodedDescription}%20${encodedUrl}`,
    whatsapp: `https://api.whatsapp.com/send?text=${encodedDescription}%20${encodedUrl}`,
    twitter: `https://twitter.com/intent/tweet?text=${encodedTwitterDesc}&url=${encodedUrl}`,
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`,
    email: `mailto:?subject=${encodedEmailSubject}&body=${encodedEmailBody}%20${encodedUrl}`,
    reddit: `https://www.reddit.com/submit?url=${encodedUrl}&title=${encodedTitle}`,
    slack: `slack://channel?team=&id=`,
  };
  const handleSlackShare = () => {
    navigator.clipboard
      .writeText(jobPostUrl)
      .then(() => {
        window.location.href = "slack://open";

        alert("Job details copied to clipboard! You can now paste in Slack.");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
        alert("Couldn't copy to clipboard. Please manually copy the job URL.");
      });
  };

  const socialIcons = [
    {
      name: "LinkedIn",
      url: shareLinks.linkedin,
      icon: <Linkedin />,
      onClick: null,
    },
    {
      name: "WhatsApp",
      url: shareLinks.whatsapp,
      icon: <WhatsAppOutlined style={{ color: "#25D366" }} />,
      onClick: null,
    },
    {
      name: "Twitter",
      url: shareLinks.twitter,
      icon: <Twitter />,
      onClick: null,
    },
    {
      name: "Facebook",
      url: shareLinks.facebook,
      icon: <Facebook/>,
      onClick: null,
    },
    {
      name: "Email",
      url: shareLinks.email,
      icon: <MailFilled style={{ color: "#4285F4" }} />,
      onClick: null,
    },
    {
      name: "Reddit",
      url: shareLinks.reddit,
      icon: <RedditSquareFilled style={{ color: "#FF4500" }} />,
      onClick: null,
    },
    {
      name: "Slack",
      url: "#",
      icon: <SlackIcon />,

      onClick: handleSlackShare,
    },
  ];

  return (
    <div className="flex flex-wrap gap-3 justify-center">
      {socialIcons.map((social) => (
        <a
          key={social.name}
          href={social.url}
          target="_blank"
          rel="noopener noreferrer"
          className={`flex items-center border justify-center w-10 h-10 rounded-md shadow-sm transition-transform transform hover:scale-110 hover:shadow-xl`}
          title={`Share on ${social.name}`}
          aria-label={`Share on ${social.name}`}
          onClick={(e) => {
            if (social.onClick) {
              e.preventDefault();
              social.onClick();
            }
          }}
        >
          <span className="text-2xl">{social.icon}</span>
        </a>
      ))}
    </div>
  );
};

export default SocialShare;
