import React, { useState, useEffect, useRef } from "react";
import Divider from "antd/es/divider";
import Input from "antd/es/input";
import Select from "antd/es/select";
import Spin from "antd/es/spin";
import Empty from "antd/es/empty";
import List from "antd/es/list";
import { searchDocument } from "../../lib/api";
import SearchBefore from "./SearchBefore";
import SearchTab from "./SearchTab";
import SearchedSideFilterComponent from "./SearchSideFilter";
import {
  jobPostFilters,
  mailFilters,
  reviewFilters,
  talentPoolFilters,
} from "../../constant";
import FileSearchOutlined from "@ant-design/icons/FileSearchOutlined";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import JobPostCardView from "./JobPostCardView";
import CDBCardView from "./CDBCardView";
import ReviewsCardView from "./ReviewsCardView";

type SearchPanalProps = {
  close?: () => void;
  refer: any;
  applicationId: string;
};

const { Option } = Select;

const SearchPanal = (props: SearchPanalProps) => {
  const [search, onSearch] = useState(false);
  const [applicantResult, setApplicantResult] = useState<any>([]);
  const [searchedData, getSearchedData] = useState("");
  const [activeKey, setActiveKey] = useState<string>("tacitbase");
  const [selectedFilter, setSelectedFilter] = useState<string>("");
  const [allowedFilters, setAllowedFilters] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [paginationLoading, setPaginationLoading] = useState<boolean>(false);
  const [sideFilters, setSideFilters] = useState<{
    filter_by?: string;
    sources: string[];
  }>({
    filter_by: "All time",
    sources: [],
  });
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const searchInputRef = useRef<HTMLInputElement | any>(null);
  const scrollTimeout = useRef<NodeJS.Timeout | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const resultsContainerRef = useRef<HTMLDivElement>(null);
  const history = useHistory();

  const filteredArray = applicantResult.filter(
    (item: any) =>
      item?.entity === "applicants" ||
      item?.entity === "candidates" ||
      item?.entity === "reviews"
  );

  const handleTabChange = (key: string) => {
    setActiveKey(key);
    if (key === "tacitbase") {
      setAllowedFilters([]);
    } else if (key === "ats") {
      setAllowedFilters(jobPostFilters);
    } else if (key === "candidates_candidates") {
      setAllowedFilters(talentPoolFilters);
    } else if (key === "mail") {
      setAllowedFilters(mailFilters);
    } else {
      setAllowedFilters(reviewFilters);
    }
    setSelectedFilter("");
    setPage(1);
    setSelectedIndex(0);
  };

  const handleFilterChange = (value: string) => {
    setSelectedFilter(value);
    setPage(1);
  };

  const handleSideFilterChange = (filters: {
    filter_by?: string;
    sources: string[];
  }) => {
    setSideFilters(filters);
    setPage(1);
  };

  const clearResults = () => {
    setApplicantResult([]);
    onSearch(false);
    setError(null);
    setPage(1);
    setHasMore(true);
  };

  useEffect(() => {
    if (searchedData.length > 3) {
      if (page === 1) {
        setApplicantResult([]);
        setLoading(true);
      } else {
        setPaginationLoading(true);
      }

      setError(null);
      let query = "";

      // Check if `filter_by` and `sources` are present and valid
      if (
        sideFilters.filter_by &&
        sideFilters.filter_by !== "All time" &&
        sideFilters.sources?.length > 0
      ) {
        query = `${sideFilters.filter_by} && source.from:=[${sideFilters.sources}]`;
      } else if (
        sideFilters.filter_by &&
        sideFilters.filter_by !== "All time"
      ) {
        query = `${sideFilters.filter_by}`;
      } else if (sideFilters.sources?.length > 0) {
        query = `source.from:=[${sideFilters.sources}]`;
      }

      const searchRequest = {
        q: searchedData,
        collection: activeKey,
        query_by: selectedFilter,
        filter_by: query,
        page: page,
        per_page: 10,
        sort_by: "updated_at:desc",
      };

      searchDocument(searchRequest)
        .then((res) => {
          setApplicantResult((prevResults:any) => {
            return [...prevResults, ...res.items];
          });

          searchInputRef.current.focus();
          setHasMore(res.items.length === 10);
          onSearch(true);
        })
        .catch(() => {
          setError("Unable to fetch data. Please try again.");
        })
        .finally(() => {
          setLoading(false);
          setPaginationLoading(false);
        });
    } else {
      // Clear search if input length is less than 4
      getSearchedData("");
      clearResults();
    }
  }, [searchedData, activeKey, selectedFilter, sideFilters, page]);

  // Ensure focus stays in the input after the search
  useEffect(() => {
    if (searchInputRef.current && searchedData.length > 3) {
      setTimeout(() => {
        searchInputRef.current.focus();
      }, 100); // Add delay to ensure the input is rendered
    }
  }, [searchedData, page]);

  useEffect(() => {
    // Initial focus
    if (searchInputRef.current) {
      // Add a slight delay to ensure the component is fully mounted
      setTimeout(() => {
        searchInputRef?.current?.focus();
        // Announce to screen readers that search is ready
        const announcement = document.createElement("div");
        announcement.setAttribute("role", "status");
        announcement.setAttribute("aria-live", "polite");
        announcement.textContent =
          "Search panel is ready. You can start typing to search.";
        document.body.appendChild(announcement);
        setTimeout(() => document.body.removeChild(announcement), 1000);
      }, 100);
    }

    // Cleanup function
    return () => {
      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current);
      }
    };
  }, []);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

    if (scrollTop + clientHeight >= scrollHeight - 100 && !loading && hasMore) {
      setPaginationLoading(true);

      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current);
      }
      scrollTimeout.current = setTimeout(() => {
        setPage((prevPage) => prevPage + 1);
      }, 300);
    }
  };

  // Handle keyboard navigation
  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Escape") {
      e.preventDefault();
      // If we're showing search results, clear them
      if (searchedData) {
        getSearchedData("");
        clearResults();
        // Maintain focus on the search input
        if (searchInputRef.current) {
          searchInputRef.current.focus();
        }
      } else {
        // If no search results, close the panel
        props.close?.();
      }
      return;
    }

    // Only handle navigation keys if we have results
    if (filteredArray.length > 0) {
      if (e.key === "ArrowDown" || e.key === "ArrowUp") {
        e.preventDefault(); // Prevent cursor movement in input

        if (e.key === "ArrowDown") {
          setSelectedIndex((prev) =>
            Math.min(prev + 1, filteredArray.length - 1)
          );
        } else if (e.key === "ArrowUp") {
          setSelectedIndex((prev) => Math.max(prev - 1, 0));
        }
      } else if (e.key === "Enter") {
        const selectedResult = filteredArray[selectedIndex];
        if (selectedResult) {
          handleItemClick(selectedResult);
        }
      }
    }
  };

  // Add the handleItemClick function from SearchAfter
  const handleItemClick = (searchResult: any) => {
    const { entity, document } = searchResult;
    let valueToSend = document.id;
    let additionalId = null;

    if (entity === "applicants") {
      valueToSend = document.document?.id;
      additionalId = document.document?.opening_id;
    }

    handleNavigation(entity, valueToSend, additionalId);
  };

  // Refactor navigation logic
  const handleNavigation = (entity: string, id: string, board_id?: string) => {
    let path = `/o/${props.applicationId}`;

    if (entity === "applicants") {
      path += `/b/${board_id}/tracker/${id}`;
    } else if (entity === "candidates") {
      path += `/db/${id}`;
    } else if (entity === "reviews") {
      path += `/reviews/${id}`;
    }

    history.push({ pathname: path });
  };

  // Update the scroll behavior useEffect
  useEffect(() => {
    if (selectedIndex >= 0 && resultsContainerRef.current) {
      const container = resultsContainerRef.current;
      const listItems = container.getElementsByClassName('ant-list-item');
      const selectedElement :any= listItems[selectedIndex];
      
      if (selectedElement) {
        const containerHeight = container.clientHeight;
        const elementHeight = selectedElement.clientHeight;
        const elementOffsetTop = selectedElement.offsetTop;
        const currentScrollTop = container.scrollTop;
        
        // Calculate the visible boundaries
        const visibleTop = currentScrollTop;
        const visibleBottom = currentScrollTop + containerHeight;
        
        // Add some padding for better visibility
        const padding = 20;

        // Calculate target scroll position
        let targetScrollTop = currentScrollTop;

        if (elementOffsetTop < visibleTop + padding) {
          // Element is above the visible area - scroll up
          targetScrollTop = Math.max(0, elementOffsetTop - padding);
        } else if (elementOffsetTop + elementHeight > visibleBottom - padding) {
          // Element is below the visible area - scroll down
          targetScrollTop = elementOffsetTop - containerHeight + elementHeight + padding;
        }

        // Only scroll if necessary
        if (targetScrollTop !== currentScrollTop) {
          container.scrollTo({
            top: targetScrollTop,
            behavior: 'smooth'
          });
        }
      }
    }
  }, [selectedIndex]);

  // Handle mouse hover logic
  const handleMouseEnter = (index: number) => {
    setSelectedIndex(index);
  };

  const renderItem = (searchResult: any, index: number) => (
    <List.Item
      key={index}
      onClick={() => handleItemClick(searchResult)}
      onMouseEnter={() => handleMouseEnter(index)}
      className="cursor-pointer mb-2"
      style={{
        borderRadius: "4px",
        padding: selectedIndex === index ? "0px" : "1px",
        border: selectedIndex === index ? "1px solid #8E8CE0" : "none",
        backgroundColor: selectedIndex === index ? "#F0EFFE" : "",
      }}
    >
      {searchResult?.entity === "applicants" && (
        <JobPostCardView searchResult={searchResult.document} />
      )}
      {searchResult?.entity === "candidates" && (
        <CDBCardView searchResult={searchResult} />
      )}
      {searchResult?.entity === "reviews" && (
        <ReviewsCardView searchResult={searchResult} />
      )}
    </List.Item>
  );

  // Modified render method for the results section
  const renderSearchResults = () => (
    <div
      ref={resultsContainerRef}
      className="w-full p-4 pr-2"
      style={{
        height: "calc(650px - 120px)",
        overflowY: "scroll",
        position: "relative",
      }}
      onScroll={handleScroll}
      role="listbox"
    >
      <List dataSource={filteredArray} renderItem={renderItem} />
      {paginationLoading && (
        <div className="flex justify-center mt-4 py-4">
          <Spin size="small" />
        </div>
      )}
    </div>
  );

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === "Escape" && searchInputRef.current) {
        e.preventDefault();
        searchInputRef.current.focus();
      }
    };

    window.addEventListener("keyup", handleEscape);
    return () => {
      window.removeEventListener("keyup", handleEscape);
    };
  }, []);

  useEffect(() => {
    // Listen for route changes
    const unlisten = history.listen(() => {
      // When route changes, ensure search input maintains focus
      if (searchInputRef.current) {
        setTimeout(() => {
          searchInputRef.current.focus();
        }, 100);
      }
    });

    return () => {
      unlisten(); // Cleanup listener when component unmounts
    };
  }, [history]);

  return (
    <div style={{ height: "633px" }}>
      <div className="flex flex-wrap w-full" ref={props.refer}>
        <div className="flex flex-row w-full p-3 items-center -mt-2">
          <div className="flex w-full">
            <Input.Group
              compact
              style={{ display: "flex", alignItems: "center", gap: "0px" }}
              className="global-search"
            >
              <Select
                placeholder="All"
                value={selectedFilter || undefined}
                onChange={handleFilterChange}
                style={{ borderRight: "none" }}
                dropdownStyle={{ width: "110px" }}
                allowClear
                disabled={activeKey === "tacitbase"}
              >
                {allowedFilters.map((filter: any) => (
                  <Option key={filter.value} value={filter.value}>
                    {filter.label}
                  </Option>
                ))}
              </Select>
              <Input.Search
                ref={searchInputRef}
                className="w-full"
                placeholder="Search... (Enter at least 4 characters)"
                onChange={(e) => {
                  getSearchedData(e.target.value.trim());
                }}
                onKeyDown={handleInputKeyDown}
                maxLength={200}
                allowClear
              />
            </Input.Group>
          </div>
        </div>
        <Divider className="m-0 p-0" />

        <SearchTab activeKey={activeKey} setQueryBy={handleTabChange} />
        <div className="w-full flex flex-row">
          <div className="w-8/12">
            {loading && page === 1 ? (
              <div className="flex justify-center items-center h-full">
                <Spin size="small" tip="Loading..." />
              </div>
            ) : error ? (
              <div className="flex justify-center items-center h-full">
                <Empty description="Unable to fetch data." />
              </div>
            ) : search && applicantResult.length === 0 ? (
              // Render a blank screen
              <div style={{ height: "calc(650px - 120px)" }}>
                <div
                  className="flex items-center justify-center w-full"
                  style={{ height: "500px" }}
                >

                  <Empty
                    image={
                      <FileSearchOutlined
                        style={{
                          fontSize: 40,
                          color: "#D3D3D3",
                          marginBottom:'-20px'
                        }}
                      />
                    }
                    description={<span style={{color:'grey'}}>No search result found</span>}
                    imageStyle={{height:'40px',marginTop:'30px'}}
                  />
                </div>
              </div>
            ) : search && applicantResult.length > 0 ? (
              renderSearchResults()
            ) : (
              <SearchBefore />
            )}
          </div>
          <div className="w-4/12 flex border-l">
            <SearchedSideFilterComponent
              onFilterChange={handleSideFilterChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// Redux mapStateToProps
const mapStateToProps = (state: any) => ({
  applicationId: state.opening.applicationId,
});

export default connect(mapStateToProps)(SearchPanal);
