import React from "react";
import ReactDOM from "react-dom";
import configureAppStore from "./redux/index";
import { configureAPI } from "./lib/api";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ErrorBoundary } from "react-error-boundary";
import App from "./App";
import "./styles/ats.css";
import FallBackErrorUI from "./components/pageNotFound/FallBackErrorUI";
//import App from "../src/components/avtar/index";
import CustomThemeProvider from "./CustomThemeProvider"

function ErrorFallback(error: any) {
  return (
    <div className={error}>
      <FallBackErrorUI />
    </div>
  );
}
export const con = configureAppStore();
const onBeforeLift = () => {
  configureAPI(import.meta.env.VITE_BASE_URL);
};
if ('serviceWorker' in navigator) {
  
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('../service-worker.js')
      .then((registration) => {
        console.log('Service Worker registered with scope:', registration.scope);
      })
      .catch((error) => {
        console.error('Service Worker registration failed:', error);
      });
  });
}

// Your other React code...

ReactDOM.render(
  <Provider store={con.store}>
    <PersistGate
      loading={null}
      onBeforeLift={onBeforeLift}
      persistor={con.persistor}
    >
      <>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <CustomThemeProvider >
            <App />
          </CustomThemeProvider>
        </ErrorBoundary>
      </>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
