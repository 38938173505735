import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Input from "antd/es/input";
import Tag from "antd/es/tag";
import Typography from "antd/es/typography";
import CheckOutlined from "@ant-design/icons/CheckOutlined";
import EditOutlined from "@ant-design/icons/EditOutlined";
import { determineTextColor } from "../../../../../utils/CalculateBrightnessOfColor";
import { LabeledIconButton } from "../../../../../ant/Button";
import { SOCKET_CLIENT_ID, colorDictionary } from "../../../../../constant";
import { GetOpening, InitLabel, Label, currentApplicant } from "../../../../../type/type";
import { labelReq } from "../../../../../lib/apiReq";
import { createOpeningLabel, editLabel } from "../../../../../lib/api";
import { Dispatch } from "redux";
import { saveBoardLabel, updateLabelState } from "../../../../../redux/actions/opening/action";
import TacitbaseInput from "../../../../../ant/Input";
import Emoji from "../../../../Emoji";

const { Text } = Typography;

export type LabelPreviewProps = {
  currentApplicant: currentApplicant;
  currentOpening: GetOpening;
  labels: InitLabel;
  clientId: string;
  updateApplicantLabel(updatedLabel: any): void;
  saveOpeningLabel(label: any, openingId: string): void;
};

const TrackerLabel = ({ labels, saveOpeningLabel, currentOpening, updateApplicantLabel }: LabelPreviewProps) => {
  const [searchValue, setSearchValue] = useState("");
  const [visible, setVisible] = useState(false);
  const [isCreatingLabel, setIsCreatingLabel] = useState(false);
  const [editedLabelId, setEditedLabelId] = useState<string>("");
  const [labelName, setLabelName] = useState("");
  const [selectedColor, setSelectedColor] = useState<string | null>(colorDictionary.red);
  const [searchResults, setSearchResults] = useState<Label[]>([]);
  const [isDisable, setIsDisable] = useState(true);

  useEffect(() => {
    if (!visible) resetLabelForm();
  }, [visible]);

  // Handle label search
  const handleSearch = (value: string) => {
    setSearchValue(value);
    if (value.length > 0 && value.length < 50) setLabelName(value);
    setSearchResults(Object.values(labels).filter((label) => label.name.toLowerCase().includes(value.toLowerCase())));
  };

  // Hide label form and reset fields
  const hide = () => {
    setVisible(false);
    resetLabelForm();
  };

  // Toggle label creation mode
  const createNewLabel = (isCreating: boolean) => {
    setEditedLabelId("");
    setIsCreatingLabel(isCreating);
  };

  // Handle color selection
  const handleColorSelect = (color: string) => {
    setSelectedColor(color);
  };

  // Save edited label
  const saveEditedLabel = () => {
    if (labelName && selectedColor) {
      const req = {
        id: editedLabelId,
        color: selectedColor,
        name: labelName,
        opening_id: currentOpening.id,
        socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
      };
      editLabel(req).then((applicant_label: any) => {
        resetLabelForm();
        updateApplicantLabel(applicant_label);
      });
    }
  };

  // Create a new label
  const handleCreateLabel = () => {
    if (labelName && selectedColor) {
      const label: labelReq = {
        name: labelName,
        color: selectedColor,
        opening_id: currentOpening.id,
        socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
      };
      createOpeningLabel(label).then((newLabel: Label) => {
        setLabelName("");
        saveOpeningLabel(newLabel, currentOpening.id);
      });
      resetLabelForm();
    }
  };

  // Set disable state based on label name length
  useEffect(() => {
    setIsDisable(labelName.length <= 0 || labelName.length >= 50);
  }, [labelName]);

  // Select emoji for label name
  const onEmojiSelect = (emoji: string): void => {
    setLabelName((prevLabelName) => prevLabelName + emoji);
  };

  // Edit an existing label
  const handleEditLabel = (label: Label) => {
    setEditedLabelId(label.id);
    setLabelName(label.name);
    setIsCreatingLabel(true);
    setSelectedColor(label.color);
    setSearchValue("");
  };

  // Reset label form fields
  const resetLabelForm = () => {
    setLabelName("");
    setSearchValue("");
    setEditedLabelId("");
    setIsCreatingLabel(false);
  };

  // Render color selection buttons
  const renderColorButton = (colorValue: string) => (
    <Tag
      key={colorValue}
      bordered
      style={{ backgroundColor: colorValue, borderColor: colorValue }}
      className="h-8 w-8 flex justify-center items-center mb-2 cursor-pointer"
      onClick={() => handleColorSelect(colorValue)}
      icon={selectedColor === colorValue && <CheckOutlined style={{ color: determineTextColor(colorValue) }} />}
    />
  );

  // Render labels
  const renderLabels = (labels: Label[]) => (
    <div className="max-h-full space-y-1.5">
      {labels.map((label) => (
        <div key={label.id} className="label-btn flex w-full items-center justify-center cursor-pointer">
          <Tag
            color={label.color}
            className="w-full flex items-center h-8 justify-between"
          >
            <div className="flex justify-center items-center">
             
              <Text ellipsis style={{ color: determineTextColor(label.color), maxWidth: 200, minWidth: 100 }}>
                {label.name}
              </Text>
            </div>
            <LabeledIconButton
              className="label-edit-icon"
              type="text"
              size="small"
              label=""
              onClick={(e) => {
                e.stopPropagation();
                handleEditLabel(label);
              }}
              icon={<EditOutlined style={{ color: determineTextColor(label.color) }} />}
            />
          </Tag>
        </div>
      ))}
    </div>
  );

  // Sort labels alphabetically
  const sortedLabels = Object.values(labels).sort((a, b) => a.name.localeCompare(b.name));
  const contentDefault = searchValue ? renderLabels(searchResults) : renderLabels(sortedLabels);

  const content = isCreatingLabel ? (
    <div className="w-72 h-full">
      <TacitbaseInput
        type="text"
        size="middle"
        placeholder="Enter label name"
        value={labelName}
        onChange={(e) => setLabelName(e.target.value)}
        suffix={<Emoji onEmojiSelect={onEmojiSelect} usePortal={true} />}
        validator={(value) => {
            if (value.length >= 50 ) {
                return 'Maximum 50 characters allowed';
            }
        }}
      />
      <div className="flex flex-wrap">
        {Object.values(colorDictionary).map(renderColorButton)}
      </div>
      <div className="flex justify-between mt-2">
        <LabeledIconButton label="Cancel" type="default" onClick={hide} />
        <LabeledIconButton label={editedLabelId ? "Save" : "Create"} type="primary" disabled={isDisable} onClick={editedLabelId ? saveEditedLabel : handleCreateLabel} />
      </div>
    </div>
  ) : (
    <div className="h-full flex flex-col space-y-4">
      <div className="w-72 sticky top-0">
        <Input placeholder="Search label" value={searchValue} onChange={(e) => handleSearch(e.target.value)} />
      </div>
      <div className="w-full h-full" style={{ height: "calc(100vh - 280px)" }}>
        <div className="max-h-full overflow-y-scroll w-72">{contentDefault}</div>
        <div className="mt-2">
          <LabeledIconButton className="w-72" label="Create a new label" onClick={() => createNewLabel(true)} />
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className="flex items-center px-4 border-b" style={{ height: 44 }}>
        <Text>Labels</Text>
      </div>
      <div className="h-full w-full" style={{ height: "calc(100vh - 350px)" }}>
        <div className="flex flex-col h-full">
          <div className="py-4 px-4 h-full">{content}</div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  labels: state.opening.labels,
  currentApplicant: state.opening.currentApplicant,
  currentOpening: state.opening.currentOpening,
  clientId: state.opening.clientId,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveOpeningLabel: (label: any, openingId: string) => dispatch(saveBoardLabel(label, openingId)),
  updateApplicantLabel: (updatedLabel: any) => dispatch(updateLabelState(updatedLabel)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TrackerLabel);
