// import UserOutlined from "@ant-design/icons/UserOutlined";
import React from "react";
import { RouteComponentProps } from "react-router";
import { Dispatch } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { loginUserAc } from "../../redux/actions/opening/action";
import BrandingPanel from "../signUp/BrandingPanel";
import Login from "./Login";
import TB_ORANGE_LOGO from "../../images/TB full orange.png";
import Typography from "antd/es/typography";
const {Text} = Typography;
type SignUpFormProps = {
  loginUserAc(token: string, data: any): void;
} & RouteComponentProps;

class SignInPage extends React.Component<SignUpFormProps> {
  render() {
    return (
      <div className="flex min-h-screen max-h-screen overflow-hidden ">
        {/* Left Panel */}
        <BrandingPanel />

        {/* Right Panel - Sign Up Form */}
        <div className="w-full lg:w-1/2 flex lg:items-center lg:justify-center p-8 ">
          <div className="w-full sm:w-full md:w-full lg:w-10/12 xl:9/12 2xl:w-8/12 space-y-8">
            <div className="lg:hidden flex  mb-8">
              <img
                src={TB_ORANGE_LOGO}
                alt="Tacitbase Logo"
                className="w-48"
                style={{ backgroundColor: "transparent" }}
              />
            </div>
            <div className="text-left flex flex-col gap-2">
              <Text className="lg:text-2xl text-xl  font-bold bg-gradient-to-r text-tacitbase-secondary3">
                Log in to your account{" "}
              </Text>
              <Text className="text-gray-600 text-xs md:text-sm">
                Transform your recruitment process today
              </Text>
            </div>

            <Login />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loginUserAc: (token: string, data: any) => dispatch(loginUserAc(token, data)),
});

export default connect(null, mapDispatchToProps)(withRouter(SignInPage));
