import React from 'react';
import Button from 'antd/es/button';
import GoogleIcon from '../../assets/GoogleIcon';

interface OAuthButtonProps {
  mode: 'signin' | 'signup';
  onLogin: () => void;
}

export const OAuthButton: React.FC<OAuthButtonProps> = ({ mode, onLogin }) => {
  const buttonText = mode === 'signin' ? 'Sign in with Google' : 'Continue with Google';
  
  return (
    <Button
      size="large"
      onClick={onLogin}
      className="w-full flex items-center justify-center gap-2"
      icon={<GoogleIcon />}
    >
      {buttonText}
    </Button>
  );
}; 