import React from 'react';
import Space from "antd/es/space";
import Button from "antd/es/button";
import Divider from "antd/es/divider";
import Modal from "antd/es/modal";
import Typography from "antd/es/typography";
import ReviewIcon from '../Icons/ReviewIcon';
import CheckCircleOutlined from "@ant-design/icons/CheckCircleOutlined";
import  ArrowRightOutlined  from '@ant-design/icons/ArrowRightOutlined';

const { Title, Text, Paragraph } = Typography;

interface SetupCompleteModalProps {
  visible: boolean;
  onSetupCareerPage: () => void;
  onSkip: () => void;
}

const SetupCompleteModal: React.FC<SetupCompleteModalProps> = ({
  visible,
  onSetupCareerPage,
  onSkip
}) => {
  return (
    <Modal
      open={visible}
      footer={null}
      closable={false}
      centered
      width={480}
      bodyStyle={{ padding: 0 }}
    >
      <div className='border-b flex items-center border-tacitbase-tertiary2 p-4'>
        <CheckCircleOutlined style={{ fontSize: 24, color: '#52c41a', marginRight: 12 }} />
        <div>
          <Title level={4} style={{ margin: 0, color: '#389e0d' }}>
            Organization Setup Complete!
          </Title>
          <Text type="secondary">
            Your hiring workspace is ready to use
          </Text>
        </div>
      </div>

      <div style={{ padding: '20px 24px' }}>
        <Title level={5}>Recommended Next Step</Title>
        
        <div style={{ 
          border: '1px solid #d9d9d9', 
          borderRadius: 8, 
          padding: 16,
          marginBottom: 16,
          backgroundColor: '#fafafa',
          display: 'flex',
          alignItems: 'center'
        }}>
          <Space align="start">
            <div style={{ 
              width: 40, 
              height: 40, 
              borderRadius: '50%', 
              backgroundColor: '#6752e7',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexShrink: 0
            }}>
              <ReviewIcon style={{ fontSize: 20, color: 'white' }} />
            </div>
            <div>
              <Text strong>Set up your career page</Text>
              <Paragraph style={{ color: 'rgba(0, 0, 0, 0.65)', margin: '4px 0 0' }}>
                Your career page is where candidates first meet your company. 
                Adding your branding and company details significantly improves candidate quality.
              </Paragraph>
            </div>
          </Space>
        </div>
        
        <Divider style={{ margin: '16px 0' }} />
        
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={onSkip}>
            Skip for now
          </Button>
          <Button 
            type="primary" 
            onClick={onSetupCareerPage}
            icon={<ArrowRightOutlined />}
          >
            Set up career page
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SetupCompleteModal;
