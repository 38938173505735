import React, { lazy, Suspense, useEffect, useState } from "react";

import { getAttachments } from "../../lib/api";
import {
  Attachment,
  Candidate,
} from "../../type/type";
import Divider from "antd/es/divider";
import Space from "antd/es/space";
import Tag from "antd/es/tag";
import Typography from "antd/es/typography";
import PhoneOutlined from "@ant-design/icons/PhoneOutlined";
import { CANDIDATES } from "../../constant";
import MailIcon from "../Icons/MailIcon";
import FilePdfOutlined from "@ant-design/icons/FilePdfOutlined";
const PDFRenderer = lazy(() => import('../card/PDFViewer'))
import Dropdown from "antd/lib/dropdown";
import EllipsisOutlined from "@ant-design/icons/EllipsisOutlined";
import Button from "antd/lib/button";
import Menu from "antd/lib/menu";
import DownloadFile from "../CDB/AttachmentAction/DownloadAttachment";
import OpenFileInNewTab from "../CDB/AttachmentAction/OpenFileInNewTab";
const { Title, Text } = Typography;
const DetailedReviewContent = (props: {
  currentCandidateInVideo: Candidate;
}) => {
  const [selectedAttachments, setSelectedAttachments] = useState<Attachment[]>(
    []
  );
  const [selectedCandidateAttachement, setSelectedAttachment] =
    useState<Attachment>({
      id: "",
      name: "",
      objectKey: "",
      modelName: "",
      recordID: "",
      presignedLink: "",
      created_at: "",
      updated_at: "",
      opening_id: "",
      converted_presigned_link: "",
      converted_object_key: "",
      parent: "",
    });

  useEffect(() => {
    props.currentCandidateInVideo.id &&
      props.currentCandidateInVideo.id.length > 0 &&
      getAttachments(CANDIDATES, props.currentCandidateInVideo.id).then(
        (res) => {
          setSelectedAttachments(res);
        }
      );
  }, [props.currentCandidateInVideo.id]);

  const handleSelectedAttachment = (attachment: Attachment) => {
    setSelectedAttachment(attachment);
  };

  const onCloseViewer = () => {
    setSelectedAttachment({
      id: "",
      name: "",
      objectKey: "",
      modelName: "",
      recordID: "",
      presignedLink: "",
      created_at: "",
      updated_at: "",
      opening_id: "",
      converted_presigned_link: "",
      converted_object_key: "",
      parent: "",
    });
  };

  const renderMenu = (attachment: Attachment) => (
    <Menu
      onClick={(e) => {
        e.domEvent.stopPropagation();
        e.domEvent.preventDefault(); // Additional prevention of default behavior
      }}
    >
      <div
        className="flex flex-col items-start space-y-2 p-2"
        role="button"
        tabIndex={0}
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            e.stopPropagation();
          }
        }}
      >
        {" "}
        <div>
          <DownloadFile attachment={attachment} />
        </div>
        <div>
          <OpenFileInNewTab attachment={attachment} />
        </div>
      </div>
    </Menu>
  );

  return (
    <>
      <div className="flex">
        <Title level={4} className="space-x-2 ">
          <span>{props.currentCandidateInVideo.first_name}</span>
          <span>{props.currentCandidateInVideo.last_name}</span>
        </Title>
      </div>
      <Divider className="my-2" />
      <Space direction="vertical" style={{ width: "100%" }}>
        <div>
          {" "}
          {/* <Text strong>Candidate details</Text> */}
          <div style={{ width: "100%" }} className="space-x-2 flex">
            <div style={{ width: "100%" }} className="space-x-2 flex">
              <MailIcon />
              <Text
                style={{
                  width: "100%",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
                copyable={{ tooltips: false }}
                ellipsis={{
                  tooltip: props.currentCandidateInVideo.email,
                }}
              >
                {props.currentCandidateInVideo.email
                  ? props.currentCandidateInVideo.email
                  : "Not available"}
              </Text>
            </div>
            <Text
              style={{
                width: "80%",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
              ellipsis={{
                tooltip: props.currentCandidateInVideo.phone,
              }}
              className="space-x-2"
            >
              <PhoneOutlined />
              <Text
                disabled={props.currentCandidateInVideo.phone ? false : true}
              >
                {props.currentCandidateInVideo.phone
                  ? props.currentCandidateInVideo.phone
                  : "Not available"}
              </Text>
            </Text>
          </div>
        </div>
      </Space>

      <Divider className="my-2" />
      <>
        {selectedAttachments?.length > 0 && (
          <Space direction="vertical" className="w-full">
            <Text strong>Attachments</Text>
            <div className="flex flex-wrap gap-4 pt-2 w-full">
              {selectedAttachments.map((attachment: Attachment) => (
                <Tag
                  className="cursor-pointer flex items-center border border-black border-opacity-5 p-2 h-10"
                  style={{ width: "46%" }}
                  bordered={false}
                  key={attachment.id}
                  onClick={() => handleSelectedAttachment(attachment)}
                >
                  <FilePdfOutlined style={{ fontSize: "22px" }} />
                  <Text
                    ellipsis={{ tooltip: attachment.name }}
                    style={{ width: "100%" }}
                  >
                    {attachment.name}
                  </Text>
                  <Dropdown
                    overlay={renderMenu(attachment)}
                    trigger={["click"]}
                  >
                    <Button
                      type="text"
                      tabIndex={-1}
                      icon={<EllipsisOutlined />}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                    />
                  </Dropdown>
                </Tag>
              ))}
            </div>
          </Space>
        )}
        {selectedCandidateAttachement.id.length > 0 && (
          <Suspense fallback={null}>
            <PDFRenderer
              title={selectedCandidateAttachement.name}
              attachmentOfwhom={props.currentCandidateInVideo.first_name+" " +props.currentCandidateInVideo.last_name}
              onCloseViewer={onCloseViewer}
              url={selectedCandidateAttachement.converted_presigned_link}
            ></PDFRenderer>
          </Suspense>
        )}
      </>
    </>
  );
};

export default (DetailedReviewContent);
