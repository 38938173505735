import { authenticateWithOAuth } from './authenticateWithOAuth';
import type { OAuthProvider, InitialOAuthResponse } from './authenticateWithOAuth';
import { con } from "../index";
import { setTokenInLocalStorage } from '../lib/api';
import { loginUserAc } from '../redux/actions/opening/action';

// Make the provider type more extensible
type AuthProvider = OAuthProvider; // Use the type from authenticateWithOAuth

type AuthResponse = {
  token: string;
  user: any;
};

/**
 * CentralAuth Component
 * Manages authentication flow for multiple providers
 * Handles redirects based on user state
 */
export const CentralAuth = () => {
  const handleAuthSuccess = async (authResponse: AuthResponse) => {
    // Dispatch login action with token and user data
    setTokenInLocalStorage('login_token', authResponse.token).then(() => {
      con?.store?.dispatch(loginUserAc(authResponse.token, authResponse.user));
    });
  };

  /**
   * Handles the login process for different providers
   * @param provider - The OAuth provider to use
   * @returns Promise<InitialOAuthResponse>
   */
  const handleLogin = async (provider: AuthProvider): Promise<InitialOAuthResponse> => {
    try {
      const authRes = await authenticateWithOAuth({ provider });

      if (!authRes) {
        throw new Error(`Authentication failed for provider: ${provider}`);
      }

      // Open authentication window with a specific name to maintain reference
      const authWindow = window.open(
        authRes.url,
        'OAuthWindow',  // Changed back to named window instead of _blank
        'width=600,height=600,resizable=yes,scrollbars=yes,status=yes,location=yes,toolbar=no,menubar=no'
      );

      if (!authWindow) {
        throw new Error('Popup window was blocked. Please allow popups for this site.');
      }

      // Focus the window to ensure it's visible
      authWindow.focus();

      // Create a promise that resolves when authentication is complete
      return new Promise((resolve, reject) => {
        const handleMessage = async (event: MessageEvent) => {
          
          if (event.origin !== window.location.origin) return;

          if (event.data.type === 'auth_success_message') {
                        
            window.removeEventListener('message', handleMessage);

            if (authWindow && !authWindow.closed) {
              authWindow.close();
            }
            
            await handleAuthSuccess(event.data.result);

            resolve(event.data.result);
          } 
          else if (event.data.type === 'auth_error_message') {            
            window.removeEventListener('message', handleMessage);
            if (authWindow && !authWindow.closed) {
              authWindow.close();
            }
            reject(new Error(event.data.error));
          }
        };

        window.addEventListener('message', handleMessage);
      });

    } catch (error) {
      console.error(`Login failed for ${provider}:`, error);
      throw error;
    }
  };
  // handleEmail = () => {};
  return { handleLogin, handleAuthSuccess };
};
