import React, { useState } from "react";
import Popover from "antd/es/popover";
import { connect } from "react-redux";
import Read from "../../../Read";
import { ListActionButtonProps } from "../../../type/boardPanal";
import ListPopOver from "../../listPopup/listPopover/ListPopOver";
import Button from "antd/es/button";
import EllipsisOutlined from "@ant-design/icons/EllipsisOutlined";

const ListActionButton = (props: ListActionButtonProps) => {
  const [modal, showModal] = useState(false);
  return (
    <Read access={props.currentOpening.access}>
      <div
        className={ `mr-1 ${
          modal
            ? "bg-opacity-25  rounded"
            : "hover:bg-gray-300 hover:bg-opacity-50 rounded"
        }`}
      >
        <Popover
          content={
            <ListPopOver
              listID={props.listID}
              close={() => showModal(false)}
            />
          }
          trigger="click"
          open={modal}
          onOpenChange={showModal}
          placement="topLeft"
        >

        <Button
          className={` ${
            modal ? " bg-gray-300 bg-opacity-50 rounded" : ""
          }    list-action-button hover:bg-gray-300 hover:bg-opacity-50 `}
          data-testid="testBtn"
          onClick={() => showModal(!modal)}
          icon={<EllipsisOutlined size={24} style={{color:'white'}}/>}
          size="small"
          type="text"
        />
        </Popover>
      </div>
    </Read>
  );
};
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
});

export default connect(mapStateToProps)(ListActionButton);
