import React, { ReactNode, useEffect, useRef, useState } from "react"
import Popover from "antd/es/popover";
import Input from "antd/es/input";
import Tag from "antd/es/tag";
import Typography from "antd/es/typography";
import Divider from "antd/es/divider";
import Space from "antd/es/space";
import Spin from "antd/es/spin";

import { colorDictionary } from "../../constant"
import CheckOutlined from "@ant-design/icons/CheckOutlined"
import CloseOutlined from "@ant-design/icons/CloseOutlined"
import EditOutlined from "@ant-design/icons/EditOutlined"
import LeftOutlined from "@ant-design/icons/LeftOutlined"
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter"
import { LabeledIconButton } from "../../ant/Button"
import { Label, Scope } from "../../type/type.d"
import { determineTextColor } from "../../utils/CalculateBrightnessOfColor"
// import { Overlay } from "../hook/Overlay"
import { hexToRGBA } from "../../utils/hexToRgb"
import { Overlay } from "../hook/Overlay"
import type { InputRef } from "antd/es/input"; // Import InputRef correctly
import EmojiPicker from "../EmojiPicker";

const { Text } = Typography;

interface Props {
    onCreateLabel: (label: { value: string, color: string }) => void,
    defaultTitle: string,
    initialLabels?: Label[],
    onEditLabel: (label: { id: string, value: string, color: string }) => void,
    element: ReactNode,
    selectedLabels?: string[],
    onAddLabel: (label: Label) => void,
    determineIcon: (label: Label) => ReactNode // Function to determine icon based on label presence in items
}

const AddLabelPopover: React.FC<Props> = ({ onCreateLabel, initialLabels = [], onEditLabel, element, onAddLabel, determineIcon }) => {
    const [visible, setVisible] = useState(false)
    const [labelName, setLabelName] = useState("")
    const [labelColor, setLabelColor] = useState("")
    const [searchValue, setSearchValue] = useState("")
    const [searchResults, setSearchResults] = useState<Label[]>([])
    const [isCreatingLabel, setIsCreatingLabel] = useState(false)
    const [selectedColor, setSelectedColor] = useState<string | null>(colorDictionary.red)
    const [editedLabelId, setEditedLabelId] = useState<string>("")

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1)
    const [paginatedLabels, setPaginatedLabels] = useState<Label[]>([])
    const [loading, setLoading] = useState(false)
    const [searchPage, setSearchPage] = useState(1)
    const [paginatedSearchResults, setPaginatedSearchResults] = useState<Label[]>([])
    const inputRef = useRef<InputRef | null>(null);

    const labelsPerPage = 10

    useEffect(() => {
        setPaginatedLabels(initialLabels.slice(0, labelsPerPage * currentPage))
    }, [initialLabels, currentPage])

    useEffect(() => {
        setPaginatedSearchResults(searchResults.slice(0, labelsPerPage * searchPage))
    }, [searchResults, searchPage])

    const handleScroll = async (e: React.UIEvent<HTMLDivElement>) => {
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget
        if (scrollTop + clientHeight >= scrollHeight && !loading) {
            if (searchValue) {
                if (searchPage * labelsPerPage < searchResults.length) {
                    setLoading(true)
                    await new Promise(resolve => setTimeout(resolve, 1000)) // Simulate network request
                    setSearchPage(prevPage => prevPage + 1)
                    setLoading(false)
                }
            } else {
                if (currentPage * labelsPerPage < initialLabels.length) {
                    setLoading(true)
                    await new Promise(resolve => setTimeout(resolve, 1000)) // Simulate network request
                    setCurrentPage(prevPage => prevPage + 1)
                    setLoading(false)
                }
            }
        }
    }

    const handleEditLabel = (label: Label) => {

        setEditedLabelId(label.id)
        setLabelName(label.name)
        setLabelColor(label.color)
        setIsCreatingLabel(true)
        setSelectedColor(label.color)
        setSearchValue("")

    }


    const handleAddLabel = (label: Label) => {
        if (!label) {
            return
        }
        onAddLabel(label)
        setLabelName("")
        setLabelColor("")
        setEditedLabelId("")
        setIsCreatingLabel(false)
    }


    const handleCreateLabel = () => {
        if (!labelName || !selectedColor) {
            return
        }
        onCreateLabel({ value: labelName.trim(), color: selectedColor })
        setLabelName("")
        setLabelColor("")
        setSearchValue("")
        // setVisible(false)
        setEditedLabelId("")
        setIsCreatingLabel(false)
    }

    const SaveEditedLabel = () => {
        if (!labelName || !labelColor) {
            return
        }
        onEditLabel({ id: editedLabelId, value: labelName.trim(), color: labelColor })
        setLabelName("")
        setLabelColor("")
        // setVisible(false)
        setEditedLabelId("")
        setIsCreatingLabel(false)
    }

    const handleSearch = (value: any) => {
        setSearchValue(value)

        const results = initialLabels.filter((label) =>
            label.name.toLowerCase().includes(value.toLowerCase())
        )
        setLabelName(value)
        setSearchResults(results)
        setSearchPage(1)
    };

    const createNewLabel = (isCreatingLabel: boolean) => {
        setEditedLabelId("")
        setIsCreatingLabel(isCreatingLabel)
    }
    const handleBackEvent = (isCreatingLabel: boolean) => {
        setSearchValue("")
        setLabelName("")
        setIsCreatingLabel(isCreatingLabel)
    }

    const handleColorSelect = (color: string) => {
        setSelectedColor(color)
        setLabelColor(color)
    }

    const handleLabelName = (value: any) => {
        setSearchValue("")
        setLabelName(value)
    }
    const renderLabels = (labels: Label[]) => {
        return (
            <div className="h-auto max-h-72 overflow-y-scroll overflow-hidden" onScroll={handleScroll}>
                <div className="space-y-1.5">
                    {labels.map((label) => {
                        const icon = determineIcon(label) // Call the function to determine the icon
                        return (
                            <div className="label-btn flex w-full items-center justify-center cursor-pointer" key={label.id}>
                                <Tag
                                    key={label.id}
                                    color={label.color}
                                    className="w-full flex items-center h-8 justify-between"
                                    onClick={() => handleAddLabel(label)}
                                >
                                    <div className="flex justify-center items-center">
                                        <Tag
                                            className="w-5 h-5 flex items-center justify-center outline-none border-0"
                                            style={{ backgroundColor: hexToRGBA('#ffffff', 0.2) }}
                                        >
                                            {icon}
                                        </Tag>
                                        <Text
                                            ellipsis
                                            style={{ color: determineTextColor(label.color), maxWidth: 200, minWidth: 100 }}
                                        >
                                            {label.name}
                                        </Text>
                                    </div>
                                    <LabeledIconButton
                                        className="label-edit-icon sticky right-0 w-full"
                                        type="text"
                                        size="small"
                                        label=""
                                        onClick={(event) => {
                                            event.stopPropagation()
                                            event.preventDefault()
                                            handleEditLabel(label)
                                        }}
                                        icon={<EditOutlined style={{ color: determineTextColor(label.color) }} />}
                                    />
                                </Tag>
                            </div>
                        )
                    })}
                    {loading && (
                        <div className="flex justify-center items-center py-3">
                            <Spin />
                        </div>
                    )}
                </div>
            </div>
        )
    };


    const renderColorButton = (colorName: string, colorValue: string) => {
        const isSelected = selectedColor === colorValue
        return (
            <>
                {/* <Flex gap="12px 12px" wrap="wrap"> */}
                <Tag
                    bordered
                    style={{ backgroundColor: colorValue, borderColor: colorValue }}
                    className="h-8 w-8 flex  justify-center items-center mb-2 cursor-pointer"
                    onClick={() => handleColorSelect(colorValue)}
                    icon={isSelected && <CheckOutlined style={{ color: determineTextColor(colorValue), border: `1px solid ${colorValue}  50%` }} />}

                />
                {/* </Flex> */}
            </>
        )
    }

    const hide = () => {
        setVisible(false)
        setIsCreatingLabel(false)
        setSearchValue("")
        setLabelName("")
        setCurrentPage(1)
        setSearchPage(1)
    };

    const titleContent = (
        <div className="flex w-full bg-white justify-between items-center">
            <LabeledIconButton
                type="text"
                label=""
                onClick={() => handleBackEvent(false)}
                icon={<LeftOutlined />}
            />
            <Text>{capitalizeFirstLetter(`${isCreatingLabel ? editedLabelId ? "Edit label" : "Create Label" : "Create label"}`)}</Text>
            <LabeledIconButton
                type="text"
                label=""
                onClick={hide}
                icon={<CloseOutlined />}
            />
        </div>
    )

    const titleContentDefault = (
        <div className="flex w-full bg-white items-center">
            <Text className="w-full flex justify-center">
                {capitalizeFirstLetter("Search label")}
            </Text>
            <LabeledIconButton
                type="text"
                label=""
                onClick={hide}
                icon={<CloseOutlined />}
            />
        </div>
    )
    useEffect(() => {
        if (!visible) {
            setIsCreatingLabel(false)
            setSearchValue("")
            setLabelName("")
            setCurrentPage(1)
            setSearchPage(1)
        }
    }, [visible])

    useEffect(() => {
        if(isCreatingLabel){
        inputRef.current?.focus(); // Properly calls focus on Ant Design's Input
      }}, [isCreatingLabel]);

    const content = isCreatingLabel ? (
        <>
            <Divider className="my-2" />
            <div className="w-72 ">
                <Input
                    value={labelName}
                    onChange={(e) => handleLabelName(e.target.value)}
                    style={{ marginBottom: 16 }}
                    ref={inputRef}
                    suffix={
                    <EmojiPicker onEmojiSelect={(emoji) => handleLabelName(labelName + emoji)} />
                    }
                />
                <div className="h-full overflow-y-hidden">
                    <div className="flex flex-wrap">
                        {Object.entries(colorDictionary).map(([colorName, colorValue]) =>
                            renderColorButton(colorName, colorValue)
                        )}
                    </div>
                </div>

                {editedLabelId ?
                    <div className="flex justify-end mt-2" >
                        <LabeledIconButton
                            label="Save"
                            type="primary"
                            onClick={() => SaveEditedLabel()}
                        ></LabeledIconButton>
                    </div> : <div className="flex justify-end">
                        <LabeledIconButton
                            disabled={labelName.trim() ? false : true}
                            label="Create"
                            type="primary"
                            onClick={handleCreateLabel}
                        ></LabeledIconButton>
                    </div>}

            </div>
        </>
    ) : (
            <>

                <Divider className="my-2" />
                <div className="w-72 overflow-hidden">
                    <Space direction="vertical" className="w-full">
                        <Input
                            placeholder="Search label"
                            value={searchValue}
                                // suffix={<EmojiPicker onEmojiSelect={(emoji) => handleSearch(emoji)} usePortal={true} />}
                            onChange={(e) => handleSearch(e.target.value)}
                        // onSearch={handleSearch}
                        />
                        <Text className="">{capitalizeFirstLetter("Labels")}</Text>
                    </Space>
                    {searchValue ? renderLabels(paginatedSearchResults) : renderLabels(paginatedLabels)}
                </div>
                <div className="w-full sticky bottom-0 mt-2">
                        <LabeledIconButton
                        className="w-full"
                        label="Create a new label"
                        onClick={() => createNewLabel(true)}
                    ></LabeledIconButton>
                </div>
                <div>
                </div>
        </>
    )

    return (

        <Popover
            title={isCreatingLabel ? titleContent : titleContentDefault}
            content={<Overlay onClose={hide} scope={Scope.Modal}>
                {content}</Overlay>}
            trigger="click"
            open={visible}
            onOpenChange={(isVisible) => setVisible(isVisible)}
            className="w-full"

        >

            {element}
        </Popover >
    )
}

export default AddLabelPopover
