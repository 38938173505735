import React, { lazy } from "react";
import Breadcrumb from "antd/es/breadcrumb";
import ContentHeader from "../analyticsVersion1/ContentHeader";
import Navbar from "../navbar/Navbar";
import SuspenseLoader from "../SuspenseLoader";
// import MailSubHeader from "../mail/MailSubHeader";
const MailSideBarPannel = lazy(() => import("../mail/MailSideBarPannel"));

const CandidateReviewBaseHeader = () => {
  return (
    <>
    
    <ContentHeader>
        <div
          className="flex h-12 px-4 py-3  justify-between items-center" >
          <div
            className="flex items-center"          >
            <Breadcrumb
    items={[
      {
        title: 'Mails',
      }
    ]}
  />
          </div>
          <div className="flex flex-row items-center">
            <Navbar/>
          </div>
        </div>
      </ContentHeader>
      {/* <div className="bg-white">
      <MailSubHeader/>
      </div> */}

      
      <div className="w-full flex h-full flex-col overflow-hidden bg-white">
      <SuspenseLoader>
      <MailSideBarPannel/>
          </SuspenseLoader>
      </div>
    </>
  );
};


export default (CandidateReviewBaseHeader);
