import React, { useEffect, useState } from "react";
import PoolTable from "./PoolTable";
import { getLabels } from "../../lib/api";
import { CANDIDATES } from "../../constant";
import { Dispatch } from "redux";
import { initCandidateLabel } from "../../redux/actions/opening/action";
import { connect } from "react-redux";
import { Jd, Label } from "../../type/type"
import Tabs from "antd/es/tabs";
import RecommendationsTable from "./RecommendationsTable";
import { useLocation } from "react-router-dom";

type poolProps = {
  currentTrackerJd: Jd,
  initCandidateLabels: (labels: Label[]) => void;


};

const Pool = (props:poolProps) => {  
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("pool");

  useEffect(() => {
    // Get tab from URL query parameter
    const params = new URLSearchParams(location.search);
    const tabFromUrl = params.get('tab');
    if (tabFromUrl === 'recommendation') {
      setActiveTab('recommendation');
    }
  }, [location]);

  useEffect(() => {
    getLabels(CANDIDATES).then((res) => {
      props.initCandidateLabels(res);
    })
  }, []);

  return (
    <div className=" mx-4 mb-4 rounded  h-full flex flex-col">
 <Tabs activeKey={activeTab} onChange={setActiveTab} className="w-full px-4 bg-white rounded">
        <Tabs.TabPane tab="Applicants" key="pool">
          <PoolTable />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Recommendation" key="recommendation">
          <RecommendationsTable/>
        </Tabs.TabPane>
      </Tabs>    </div>
  );
};


const mapPropsToState = (dispatch: Dispatch) => ({
  initCandidateLabels: (labels: Label[]) =>
    dispatch(initCandidateLabel(labels)),
 

  
});
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  candidatePool: state.opening.candidatePool,
  currentTrackerJd: state.opening.currentTrackerJd,

  applicationId: state.opening.applicationId,
});
export default connect(mapStateToProps,mapPropsToState)(Pool);

