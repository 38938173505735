import Skeleton from "antd/es/skeleton";
import Tag from "antd/es/tag";

import React from "react";

const AttachmentLoader = () => {
  return (
    <div className="flex flex-wrap gap-4 pt-2 w-full">
      <Tag
        style={{ width: "47%" }}
        className="cursor-pointer w-full flex items-center border border-black border-opacity-5 p-2 h-10"
        bordered={false}
      >
        <Skeleton.Avatar active shape="square" size="small" />
        <Skeleton.Input
          active
          size="default"
          style={{ width: "100%",marginLeft:'4px' }}
        />
      </Tag>
  </div>
  );
};

export default AttachmentLoader;
