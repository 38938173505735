import React from "react";
import Avatar from 'antd/es/avatar';
import Card from 'antd/es/card';
import List from 'antd/es/list';
import Spin from 'antd/es/spin';
import Typography from 'antd/es/typography';
import ClockCircleOutlined from "@ant-design/icons/ClockCircleOutlined";
import EditOutlined from "@ant-design/icons/EditOutlined";
import dayjs from "dayjs";
import { Attendee, EventData } from "../../../type/type";
import TacitbaseAvtar from "../../../ant/Avtar";
import { IconButton, SecondaryButton } from "../../../ant/Button";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers/root";
import { useHistory } from "react-router-dom";
import { calendarListState } from "../../../redux/reducers/calendarSlice";
import { CalendarItem, Calendars } from "../../../lib/apiRes";

const { Text } = Typography;
type eventProps={loading:boolean}

const TrackerCardEvents = ({
    summary,
    dateTimeRange,
    attendees,
    conferenceData,
    id,
    event_color
}: EventData) => {
    const history = useHistory();
    const Urllocation = useLocation();
    // Format the start and end date
    const startDate = dateTimeRange ? dayjs(dateTimeRange[0]) : null;
    const endDate = dateTimeRange ? dayjs(dateTimeRange[1]) : null;

    // Get formatted month, day, start and end time
    const month = startDate ? startDate.format("MMM") : ""; // First three letters of the month
    const day = startDate ? startDate.format("D") : ""; // Day of the month
    const startTime = startDate ? startDate.format("h:mm A") : ""; // Formatted start time
    const endTime = endDate ? endDate.format("h:mm A") : ""; // Formatted end time
    let calendarList: calendarListState = useSelector((state: RootState) => state.calendar.calendarList);
    const teamCalendar =Object.values(calendarList).find((calendar: CalendarItem) => calendar.is_team_calendar) ||Object.values(calendarList).find((calendar: Calendars) => calendar.primary);

    const handleEditClick = (id:string) => {
        history.push(`${Urllocation.pathname}/${teamCalendar.id}/${id}`);
    };
    return (
        <>
            <Card
                bordered={true}
                className="w-full ml-2"
                style={{ borderRadius: "8px", backgroundColor: "#fafafa" }}
                bodyStyle={{
                    display: "flex",
                    alignItems: "center",
                    padding: "2px 17px",
                }}
            >
                {dateTimeRange && (
                    <div className="w-16 h-14 rounded overflow-hidden shadow-md border border-gray-200">
                        {/* Month header */}
                        <div
                            className=" text-white text-center py-0.5"
                            style={{ backgroundColor: event_color }} // Use foreground color from Redux
                        >
                            <span className="text-xs font-semibold">{month}</span>
                        </div>

                        {/* Day number */}
                        <div className="bg-white flex flex-col items-center justify-center">
                            <span className="text-base font-extrabold text-gray-800">
                                {day}
                            </span>
                        </div>
                    </div>
                )}

                <div className="leading-4 flex flex-col w-full px-2 py-2">
                    <div className="flex justify-between items-center ">
                        <Text strong>{summary}</Text>
                        <div>
                            <IconButton
                                type="text"
                                icon={<EditOutlined />}
                                onClick={() => {
                                    handleEditClick(id);
                                }}
                            ></IconButton>
                        </div>
                    </div>
                    {dateTimeRange && (
                        <Text type="secondary" className="text-xs">
                            <ClockCircleOutlined /> {startTime} - {endTime}
                        </Text>
                    )}

                    {/* Bottom Row with Attendees and Join Link */}
                    <div className="flex justify-between items-center ">
                        {/* Attendees */}
                        <div className="flex gap-1">
                            <Avatar.Group size={"small"} maxCount={4}>
                                {attendees && attendees.length>0 &&
                                    attendees.map((attendee: Attendee) => (
                                        <TacitbaseAvtar
                                            toolTip={attendee.email}
                                            key={attendee.email}
                                            content={attendee.email[0]}
                                        />
                                    ))}
                            </Avatar.Group>
                        </div>

                        {/* Join Link */}
                        { conferenceData && conferenceData.entry_points &&conferenceData?.entry_points[0].uri && (
                            <SecondaryButton
                                title="Join"
                                size="small"
                                onClick={() => window.open(conferenceData.entry_points[0].uri)}
                            >
                                Join
                            </SecondaryButton>
                        )}
                    </div>
                </div>
                {/* {conferenceData?.entry_points[0].uri} */}
            </Card>
           
        </>
    );
};

const TrackerEvents = (props:eventProps) => {
 
    const events = useSelector((state: RootState) => state.opening.calendar);
    return (
        <div style={{ maxWidth: "700px", margin: "0 auto", padding: "4px 0px" }} className="h-full">
            {props.loading ? ( // Conditional rendering based on loading state
                <div
                    className="flex h-full  justify-center items-center w-full"
                    // style={{ width: 600, height: 500 }}
                >
                    <Spin />
                </div>) : (
                <List
                    dataSource={Object.values(events.currentCardEvents) ? Object.values(events.currentCardEvents) : []}
                    renderItem={(booking: any) => (
                        <List.Item>
                            <TrackerCardEvents {...booking} />
                        </List.Item>
                    )}
                />
            )}
        </div>
    )
};

export default TrackerEvents;
