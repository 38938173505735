import React from 'react';
import TB_ORANGE_LOGO from "./images/TB full orange.png";



const BrowserWarning = () => {

    const switchBrowser = () => {
        const userAgent = navigator.userAgent.toLowerCase();
    
        // Detect which browser the user is using and open the appropriate upgrade page
        if (/chrome/.test(userAgent) && !/edge|opr/.test(userAgent)) {
          window.open("https://www.google.com/chrome/", "_blank");
        } else if (/firefox/.test(userAgent)) {
          window.open("https://www.mozilla.org/en-US/firefox/new/", "_blank");
        } else if (/edg/.test(userAgent)) {
          window.open("https://www.microsoft.com/en-us/edge", "_blank");
        } else if (/safari/.test(userAgent)) {
          window.open("https://support.apple.com/en-us/HT201541", "_blank");
        }
      };

  return (
    <div className="relative w-full h-screen flex flex-col items-center justify-center text-center bg-cover bg-center">
    <div className="bg-white shadow-xl rounded-xl p-8 max-w-md mx-auto">
      <img
        src={TB_ORANGE_LOGO}
        alt="Browser Update"
        className="h-12 mx-auto mb-6"
      />

      <h1 className="text-2xl font-medium text-gray-900 mb-4" style={{lineHeight:"40px"}}>
        This browser is no longer supported
      </h1>

      <p className="text-gray-600 mb-8">
        Just like using the right tools helps in hiring top talent, having an
        updated browser ensures the best performance and security.
      </p>
      <p className="text-gray-600 mb-8">
        To continue switch to a supported browser.
      </p>

      <button className="w-full px-6 py-3 text-white font-semibold rounded-lg " onClick={switchBrowser}
      style={{
        backgroundColor: '#4B48A5', // Normal state background color
        transition: 'background-color 0.3s ease',
      }}
      onMouseEnter={(e:any) => e.target.style.backgroundColor = '#7F7CC4'} 
      onMouseLeave={(e:any) => e.target.style.backgroundColor = '#4B48A5'} 
    >
        Upgrade your browser
      </button>

      <div className="mt-6 text-sm text-gray-500">
        Need help?{" "}
        <a href="https://support.tacitbase.com/"className="text-blue-600 hover:underline">
          Contact our support team
        </a>
      </div>
    </div>
  </div>

  );
};

export default BrowserWarning;