import React, { useEffect, useState } from "react";
import { getRecordMailPreview } from "../../../lib/api";
import {
  MailPreview,
  Opening,
  OrganizedBoard,
  orgMember,
} from "../../../type/type";
import { connect } from "react-redux";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import Empty from "antd/es/empty";
import Spin from "antd/es/spin";
import Typography from "antd/es/typography";
import Divider from "antd/es/divider";

import dayjs from "dayjs";
import { DATE_TIME_FORMATE } from "../../../constant";
import TacitbaseAvtar from "../../../ant/Avtar";
import parse from "html-react-parser";
import AttachmentIcon from "../../Icons/AttachmentIcon";

const { Text } = Typography;

type CandidateInCDBMailProps = {
  openings: OrganizedBoard;
  members: orgMember;
  currentCandidate: any;
  currentOpening: Opening;
  applicationId: string;
  currentCandidateInEditMode: any,
  activeTab:string,
};

/**
 * Component to display candidate mail in ATS pool.
 * Fetches and displays mail previews for the current candidate.
 */
const CandidateInCDBMail = ({
  currentCandidateInEditMode,activeTab
}: CandidateInCDBMailProps) => {
  const [candidateMail, setCandidateMail] = useState<MailPreview[]>([]);
  const [showLoading, setShowLoading] = useState<boolean>(false);


  useEffect(() => {
    /**
     * Fetches mail preview for the current candidate.
     */
    if (activeTab !== "Mail") return;
    setCandidateMail([]);
    setShowLoading(true);

    getRecordMailPreview(currentCandidateInEditMode.candidateId.id)
      .then((res) => {
        setCandidateMail(res.items);
        setShowLoading(false);
      })
      .catch((error) => {
        setShowLoading(false);
        console.error("Error fetching mail preview:", error);
      });
  }, [currentCandidateInEditMode.candidateId.id, activeTab]);

  return (
    <div className="h-full py-2 pl-2 w-full" key={currentCandidateInEditMode.candidateId.id}>
      <div
        style={{ height: "calc(100vh - 245px)" }}
        className="overflow-y-scroll w-full "
      >
        <div className="w-full h-full gap-3.5 flex flex-col px-1">
          {
          candidateMail?.map((mail: MailPreview) => (
            mail && (
              <div
                role="button"
                tabIndex={0}
                onKeyDown={() => {}}
                style={{
                  boxShadow:
                    "var(--ds-shadow-raised,0 1px 1px #091e4240,0 0 1px #091e424f)",
                }}                className="flex flex-col rounded shadow-lg gap-1 w-full p-3 cursor-pointer"
                key={mail.source.id}
              >
                <div className="w-full">
                  <div className="flex justify-between items-center w-full">
                    <div className="flex space-x-2 flex-shrink-0">
                      <TacitbaseAvtar content={mail.message.to[0][0]} />
                      <div
                        className={`${
                          mail.message.read ? "font-normal text-gray-600 bg-opacity-5" : "font-medium"
                        } text-sm w-32 truncate`}
                      >
                        {mail.message.to[0]}
                      </div>
                      <div className="font-light text-xs">{mail.msg_count}</div>
                    </div>
                    <div className="flex font-light text-xs flex-shrink-0 text-gray-600 space-x-2">
                      <div>
                        <AttachmentIcon />
                      </div>
                      <div>
                        {dayjs(mail.message.created_at).format(DATE_TIME_FORMATE)}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col pl-8 w-full">
                    <Text
                      className={`${
                        mail.message.read ? "font-light text-gray-600" : "font-medium"
                      } ${mail.message.subject.length > 50 ? "w-full" : ""} text-sm leading-relaxed flex-shrink-0 truncate`}
                    >
                      {mail.message.subject}
                    </Text>
                    <Text
                      className={`${
                        mail.message.read ? "font-normal text-gray-600" : "font-medium"
                      } text-xs truncate w-full`}
                    >
                      {parse(mail.message.body)}
                    </Text>
                  </div>
                  <Divider style={{ height: "0.1px", margin: "6px" }} />
                  <div className="flex space-x-1 pl-8 w-full text-xs justify-between">
                    <div className="w-full">
                      Destination: {capitalizeFirstLetter(mail.source.name)}
                    </div>
                  </div>
                </div>
              </div>
            )
          ))}
          {candidateMail.length === 0 && showLoading && (
            <span className="flex flex-col space-y-2 items-center justify-center h-full">
              <Spin tip="" size="default" />
              <span>Fetching mail history</span>
            </span>
          )}
          {candidateMail.length === 0&& !showLoading &&(
             <Empty
             description="No mail found"
             className="flex flex-col h-full justify-center items-center"
             image={Empty.PRESENTED_IMAGE_SIMPLE}
           />)}
        </div>
      </div>
    </div>
  );
};

/**
 * Maps the Redux state to component props.
 */
const mapStateToProps = (state: any) => ({
  currentCandidateInEditMode: state.opening.currentCandidateInEditMode,
  members: state.opening.members,
  currentOpening: state.opening.currentOpening,
  currentCandidate: state.opening.currentCandidateInEditMode,
  openings: state.opening.openings,
  applicationId: state.opening.applicationId,
});

export default connect(mapStateToProps)(CandidateInCDBMail);
