import Avatar from "antd/es/avatar";
import Button from "antd/es/button";
import ConfigProvider from "antd/es/config-provider";
import Divider from "antd/es/divider";
import Empty from "antd/es/empty";
import Row from "antd/es/row";
import React, { useEffect } from "react";
import {
  archieveUnArchiveApplicant,
  getArchivedApplicants,
} from "../../../../../lib/api";
import { Dispatch } from "redux";
import {
  initArchivedItems,
  sendBackArchivedItem,
} from "../../../../../redux/actions/opening/action";
import { connect } from "react-redux";
import {
  GetOpening,
  currentApplicant,
  orgMember,
} from "../../../../../type/type";
import  RollbackOutlined from "@ant-design/icons/RollbackOutlined";
import Label from "../../../../boardPanel/label/Label";
import TacitbaseAvatar from "../../../../../ant/Avtar";
import { SOCKET_CLIENT_ID } from "../../../../../constant";

type ArchiveItemsProps = {
  prev: () => void;
  close: (e: boolean) => void;
  currentOpening: GetOpening;
  members: orgMember;
  initArchivedApplicants(archivedItems: any, openingID: string): void;
  archivedApplicants: any;
  sendBackArchivedApplicant(archivedApplicant: any): void;
  clientId: any;
};

const ArchivedCard = (props: ArchiveItemsProps) => {
  useEffect(() => {
    // Fetch archived applicants when component mounts
    getArchivedApplicants(props.currentOpening.id).then((applicants: any) => {
      props.initArchivedApplicants(applicants, props.currentOpening.id);
    });
  }, []);

  const sendBackToBoard = (archivedItem: any) => {
    // Send archived applicant back to board
    const req = {
      id: archivedItem.id,
      archive: false,
      socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
    };
    archieveUnArchiveApplicant(req).then((res: any) => {
      props.sendBackArchivedApplicant(res);
    });
  };

  return (
    <div className="h-full w-full" style={{ height: "calc(-195px + 100vh)" }}>
      <div
        className="flex-1 overflow-y-auto pl-4 pr-2"
        style={{ height: "calc(-195px + 100vh)" }}
      >
        <div className="flex flex-col  space-y-4">
          {props.archivedApplicants[props.currentOpening.id] ? (
            // Render archived applicants if available
            props.archivedApplicants[props.currentOpening.id].length>0?
            props.archivedApplicants[props.currentOpening.id].map(
              (card: currentApplicant, i: number) => (
                <Row className="w-72" key={i}>
                  <ConfigProvider
                    theme={{
                      token: {
                        paddingLG: 16,
                      },
                    }}
                  >
                    {/* Render each archived applicant */}
                    <div
                      role="button"
                      tabIndex={0}
                      onKeyDown={() => {}}
                      style={{
                        boxShadow: "0px 1px 4px 0px #0000001A",
                      }}
                      className="flex items-center rounded  gap-1 w-full  p-3 cursor-pointer truncate"
                      key={card.id}
                      id={card.id}
                    >
                      <span className="text-base font-medium w-full truncate">
                        {card.name}
                      </span>
                      <div className="flex flex-wrap">
                        {card.labels?.length > 0 &&
                          card.labels?.map((label: any, index: any) => (
                            // Render labels for the applicant
                            <Label
                              key={index}
                              color={label.color}
                              flag={true}
                              name={label.name}
                              click={() => {}}
                            />
                          ))}
                      </div>
                      <div className="flex items-center">
                        <div className="flex items-center">
                          {/* Render avatars for applicant creator and members */}
                          {props.members[card.creator] &&
                            props.members[card.creator].image_url && (
                              <Avatar.Group size={"small"} maxCount={4}>
                                <TacitbaseAvatar
                                  src={
                                    props.members[card.creator].image_url
                                  }
                                  key={props.members[card.creator].id}
                                  size="small"
                                  toolTip={`${
                                    props.members[card.creator].first_name
                                  } ${
                                    props.members[card.creator].last_name
                                  } (Creator)`}
                                />
                              </Avatar.Group>
                            )}
                          {card.members?.filter(
                            (member) => member !== card.creator
                          ).length > 0 && (
                            <Divider
                              type="vertical"
                              style={{ borderColor: "#666666" }}
                            />
                          )}
                          <Avatar.Group maxCount={4} size={"small"}>
                            {card.members?.map((member: string) =>
                              member !== card.creator ? (
                                props.members[member].image_url ? (
                                  <TacitbaseAvatar
                                    src={props.members[member].image_url}
                                    key={props.members[member].id}
                                    size="small"
                                    toolTip={`${props.members[member].first_name} ${props.members[member].last_name}`}
                                  />
                                ) : (
                                  <TacitbaseAvatar
                                    content={`${props.members[member].first_name[0]}${props.members[member].last_name[0]}`}
                                    key={props.members[member].id}
                                    size="small"
                                    toolTip={`${props.members[member].first_name} ${props.members[member].last_name}`}
                                  />
                                )
                              ) : null
                            )}
                          </Avatar.Group>
                        </div>
                      </div>
                      <div className="flex items-center gap-4 text-lg justify-between">
                     
                        {/* Button to send archived applicant back to board */}
                        <Button
                          onClick={() => sendBackToBoard(card)}
                          icon={<RollbackOutlined />}
                          type="link"
                          className=" text-xs font-normal"
                        >
                          Send to tracker
                        </Button>
                      </div>
                    </div>
                  </ConfigProvider>
                </Row>
              )
            )
           :  // Render empty component if no archived applicants available
           (<div className="flex "><Empty description="No archived cards" /></div>)): (
            
                // Render empty component if no archived applicants available
                <Empty description="No archived cards" />
              
      
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  archivedApplicants: state.opening.archivedApplicants,
  clientId: state.opening.clientId,
  members: state.opening.members,
});

const mapPropsToState = (dispatch: Dispatch) => ({
  initArchivedApplicants: (archivedApplicants: any, openingID: string) =>
    dispatch(initArchivedItems(archivedApplicants, openingID)),
  sendBackArchivedApplicant: (archivedApplicant: any) =>
    dispatch(sendBackArchivedItem(archivedApplicant)),
});

export default connect(mapStateToProps, mapPropsToState)(ArchivedCard);
