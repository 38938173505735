import React, { useState, useEffect } from 'react';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Typography from 'antd/es/typography';
import Alert from 'antd/es/alert';
import WarningOutlined from '@ant-design/icons/WarningOutlined';
import { useHistory, useLocation } from 'react-router-dom';
import TB_ORANGE_LOGO from "../../images/TB full orange.png";
import throwError from '../../service/throwError';
import { verifyPasswordlessLogin, passwordlessLogin } from '../../lib/api';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { initWs, loginUserAc } from '../../redux/actions/opening/action';
import GmailIcon from '../../assets/GmailIcon';
import { OutlookIcon } from '../../assets/OutlookIcon';
import Spin from 'antd/es/spin';

const { Text, Title } = Typography;
interface EmailVerificationProps {
  email: string;
  onVerify: (code: string) => void;
  onResend: () => void;
  loading?: boolean;
  error?: string;
}
interface loginUserAcProps {
  loginUserAc: (token: any, userData: any) => void;
}

const EmailVerification: React.FC<EmailVerificationProps> = ({
  email,
  onVerify,
  onResend,
  error,
  loading,
}) => {
  const [form] = Form.useForm();
  const [coolDown, setCoolDown] = useState(30);

  useEffect(() => {
    if (coolDown > 0) {
      const timer = setInterval(() => {
        setCoolDown(prev => Math.max(prev - 1, 0));
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [coolDown]);

  const handleResend = () => {
    if (coolDown === 0) {
      onResend();
      setCoolDown(30);
    }
  };

  const onFinish = (values: { otp: string }) => {
    onVerify(values.otp);
  };

  return (
    <div className="flex flex-col items-center max-w-lg mx-auto px-4">
      {/* Tacitbase Logo */}
      <div className="mb-8">
        <img src={TB_ORANGE_LOGO} alt="Tacitbase" className="h-12" />
      </div>

      <div className="text-center mb-6">
        <Title level={2} className="font-bold mb-4 text-primary">Check your email for a code</Title>
        <Text className="text-primary-dark">
          We've sent a 6-character code to <span className="font-medium">{email}</span>. The code expires
          shortly, so please enter it soon.
        </Text>
      </div>

      <Form
        form={form}
        onFinish={onFinish}
        className="w-full"
        layout="vertical"
      >
        <Form.Item
          className='w-full flex justify-center items-center mb-6 relative'
          name="otp"
          rules={[
            { required: true, message: 'Please enter the verification code' },
            { len: 6, message: 'Please enter all 6 digits' }
          ]}
        >
          <Input.OTP
            autoFocus
            size="large"
            length={6}
            className="flex justify-center items-center mx-auto"
            style={{ gap: '8px', borderColor: '#d9d9d9' }}
            formatter={(str) => str.toUpperCase()}
            onChange={(code: any) => {
              form.setFieldsValue({ otp: code });
              form.submit();
            }}
            disabled={loading}
          />
          {loading && (
            <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-80">
              <div className="flex flex-col items-center gap-2">
                <Spin size="small" />
                <Text className="text-primary-dark">Verifying code...</Text>
              </div>
            </div>
          )}
        </Form.Item>

        {error && (
          <div className="mb-6 text-center w-full">
            <Alert
              message={
                <div className="flex items-center justify-center">
                  <WarningOutlined className="mr-2 text-red-600" />
                  <span>That code wasn't valid. Have another go!</span>
                </div>
              }
              type="error"
              className="bg-red-50 border-red-100 text-red-600"
              showIcon={false}
            />
          </div>
        )}

        <div className="flex justify-center space-x-4 mb-6">
          <Button
            type="default"
            icon={<GmailIcon />}
            onClick={() => window.open('https://mail.google.com', '_blank')}
            className="flex items-center bg-white hover:bg-gray-50 text-primary border-primary"
          >
            Open Gmail
          </Button>
          <Button
            type="default"
            icon={<OutlookIcon />}
            onClick={() => window.open('https://outlook.com', '_blank')}
            className="flex items-center bg-white hover:bg-gray-50 text-primary border-primary"
          >
            Open Outlook
          </Button>
        </div>

        <div className="text-center mb-4">
          <Text className="text-primary-dark">
            Can't find your code? Check your spam folder!
          </Text>
        </div>

        <div className="text-center mb-4">
          <Button
            type="link"
            onClick={handleResend}
            disabled={coolDown > 0}
            className="text-primary hover:text-primary-dark"
          >
            {coolDown > 0 ? `Resend in ${coolDown}s` : 'Resend code'}
          </Button>
        </div>
      </Form>

      <div className="mt-auto pt-8 text-center">
        <div className="flex justify-center space-x-4 text-sm text-primary-light">
          <a
            href="https://www.tacitbase.com/terms-of-use"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:underline hover:text-primary"
            style={{ color: "#009D79" }}
          >
            Terms of use
          </a>
          <a
            href="https://www.tacitbase.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:underline hover:text-primary"
            style={{ color: "#009D79" }}
          >
            Privacy policy
          </a>
        </div>
      </div>
    </div>
  );
};

const EmailVerificationContainer: React.FC<loginUserAcProps> = ({ loginUserAc }) => {
  const history = useHistory();
  const location = useLocation<{ email: string }>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    // Redirect to login if no email in state
    if (!location.state?.email) {
      history.push('/login');
    }
  }, [location.state, history]);

  const handleVerification = async (code: string) => {
    setLoading(true);
    setError('');

    try {
      const response = await verifyPasswordlessLogin(
        code
      );

      if (response.token) {
        localStorage.setItem('login_token', response.token);
        loginUserAc(response.token, response.user);
        history.push('/o');
      } else {
        throw new Error('Verification failed');
      }
    } catch (err: any) {
      setError(err.message || 'Invalid verification code');
      throwError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleResend = async () => {
    setLoading(true);

    try {
      await passwordlessLogin(location.state?.email);
    } catch (err: any) {
      throwError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex min-h-screen justify-center items-center bg-white">
      <div className="w-full max-w-xl py-8 px-4">
        <EmailVerification
          email={location.state?.email}
          onVerify={handleVerification}
          onResend={handleResend}
          loading={loading}
          error={error}
        />
      </div>
    </div>
  );
};

const mapPropsTostate = (dispatch: Dispatch) => ({
  loginUserAc: (token: any, userData: any) =>
    dispatch(loginUserAc(token, userData)),
  initWs: (ws: WebSocket) => dispatch(initWs(ws)),
});
export default connect(null, mapPropsTostate)(EmailVerificationContainer)


