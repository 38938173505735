import React, { ReactNode } from 'react';
import AntTooltip from "antd/es/tooltip";
import type { TooltipProps as AntTooltipProps } from "antd/es/tooltip";
interface CustomTooltipProps extends Omit<AntTooltipProps, 'title'> {
  content: ReactNode;
  children: ReactNode;
  className?: string;
  tooltipClassName?: string;
  disabled?: boolean;
}

const Tooltip: React.FC<CustomTooltipProps> = ({
  content,
  children,
  className = '',
  tooltipClassName = '',
  disabled = false,
  placement = 'top',
  color = '',
  mouseEnterDelay = 0.1,
  mouseLeaveDelay = 0.1,
  ...rest
}) => {
  if (disabled) {
    return <>{children}</>;
  }

  return (
    <AntTooltip arrow={false}
      title={content}
      placement={placement}
      color={color}
      mouseEnterDelay={mouseEnterDelay}
      mouseLeaveDelay={mouseLeaveDelay}
      overlayClassName={tooltipClassName}
      overlayInnerStyle={{
        backgroundColor: '#1a1a1a',
        color: 'rgba(255,255,255,0.8)',
        padding: '3px 5px',
        borderRadius: '4px',
        fontSize: '12px',
        minHeight: '25px',
        display: 'flex',
        alignItems: 'center',
        boxShadow: '0 3px 6px -4px rgba(0,0,0,.12), 0 6px 16px 0 rgba(0,0,0,.08)',
      }}
      {...rest}
    >
      <span className={className}>{children}</span>
    </AntTooltip>
  );
};

export default Tooltip;