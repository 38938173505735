import React from 'react'
import GenericSelect from '../../analyticsVersion1/GenericSelect';
import Tag from "antd/es/tag";
import Avatar from "antd/es/avatar";

import getOrgMembers from '../../../utils/getOrgMember';

interface MemberPopupProps{
    handleMemberChange: (selectedMemberIds: string[]) => void; // Receive callback as prop
}

function MemberPopup(props:MemberPopupProps){

    const FilterMemberArray = getOrgMembers();

    // Custom rendering of selected tags in the input field
const tagRender = (props: any) => {
    const { value, closable, onClose } = props;
    const option = FilterMemberArray.find((member) => member.id === value); // Get the corresponding option by value
  
    return (
      <Tag
        closable={closable}
        onClose={onClose}
        style={{ display: 'flex', alignItems: 'center', padding: '0px 2px',margin:'2px 4px',backgroundColor:'#F0EFFE',border:'none' }}
      >
        <Avatar src={option?.image_url} size={19} style={{ marginRight: 4 }} />
        {option?.first_name} {option?.last_name}
      </Tag>
    );
  };
  
    return(
        <GenericSelect
        options={FilterMemberArray.map((option) => ({
         label: (
           <div style={{ display: "flex", alignItems: "center" }}>
             <Avatar src={option.image_url} style={{ marginRight: 8 }}/>
             <div>
               <div>{option.first_name + " " + option.last_name}</div>
               <div style={{ fontSize: "12px", color: "#888" }}>{option.email}</div>
             </div>
           </div>
         ),
         value: option.id,
         searchText: `${option.first_name} ${option.last_name} ${option.email}`, // Adding searchText field
       }))}
       dropdownStyle={{ maxWidth: "100%" }}
       onChange={(selectedIds) => props.handleMemberChange(selectedIds)} // Trigger the callback with selected member IDs
       tagRender={tagRender} // Use the custom tagRender for selected item display
       style={{border:'none',boxShadow:'none',activeBorderColor:'none',borderColor:'white'}}
     />
    );
}
export default MemberPopup;