import React, { lazy } from "react";
import Descriptions from 'antd/es/descriptions';
import Divider from 'antd/es/divider';
import Image from 'antd/es/image';
import Typography from 'antd/es/typography';
import { connect } from "react-redux";
const TacitBaseModal = lazy(() => import("../../../ant/TacitbaseModal"));
import sideImageForBillingModal from "../../../images/image61.png";
import Title from "antd/es/typography/Title";
import { loginUser, orgMember } from "../../../type/type";
import { OWNER } from "../../../constant";
import SuspenseLoader from "../../../components/SuspenseLoader";

const { Text } = Typography;
type UpgradePlanModalProps = {
  isModalVisible: boolean;
  handleCancel: () => void;
  external_id: string;
  stripeCustomerId: string;
  loginUser: loginUser,
  members: orgMember;
};

const items = [
  "Access to premium AI features, resume parsing, candidate ranking, job post creation.",
  "Free job posting on 9+ job boards.",
  "Priority customer support."
];
const UpgradePlanModal: React.FC<UpgradePlanModalProps> = (
  props: UpgradePlanModalProps
) => {
  return (
    <>
    <SuspenseLoader>
      <TacitBaseModal
        closable={false} maskClosable={true} onCancel={props.handleCancel}
        visibility={props.isModalVisible}
        content={
          <>
            <div className="flex">
              {/* Left Section with Illustration */}
              <div
                className="
              
              lg:w-1/3 hidden lg:flex  bg-tacitbase-primary2 bg-opacity-70 rounded-l items-center justify-center pl-5
              "
              >

                <Image
                  width={"100%"}
                  preview={false}
                  src={sideImageForBillingModal}
                />
              </div>

              {/* Right Section with Content */}
              <div className="lg:w-2/3 w-full bg-white rounded-l lg:rounded-l-none  rounded-r p-8">
                <Title level={5}>Upgrade your plan</Title>

                <Text style={{ display: "block", marginBottom: "24px" }}>
                  Upgrading your plan will unlock enhanced features, increased
                  limits, and advanced capabilities.
                </Text>
                <div className="mb-6">
                  <strong>Benefits of Upgrading:</strong>
                  <Descriptions
                    column={1} // Display each item in a single column
                    size="small" // Adjust size as needed
                    style={{ textAlign: "left", marginBottom: "24px" }} // Apply your custom styles
                  >
                    {items.map((item, index) => (
                      <Descriptions.Item key={index}>
                        • {item}
                      </Descriptions.Item>
                    ))}
                  </Descriptions>
                </div>
                <Divider />
                {props.members[props.loginUser.user.id]?.registrations[0]?.roles[0] !==OWNER &&
                  <Text style={{
                    color:"#009D79" ,
                  }}>{"Please contact your admin."}</Text>
                }
              </div>
            </div>
          </>
        }
        visible={true}
        footer={null}
        centered
        styles={{ body: { padding: 0 }, content: { padding: 0 } }}
        className="subscription-modal"
        width={700}
      ></TacitBaseModal>
      </SuspenseLoader>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  external_id: state.opening.applicationId,
  stripeCustomerId: state.opening.currentOrg?.customer?.id,
  loginUser: state.opening.loginUser,
  members: state.opening.members,
});
export default connect(mapStateToProps)(UpgradePlanModal);
