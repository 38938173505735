import React from "react";
import Button1 from "../common/button/Button1";
import ProductName from "../product/ProductName";
import BottomBubble from "./BottomBubble";
import TopBubble from "./TopBubble";
import Typography from "antd/es/typography";
const { Title } = Typography;

const FallBackErrorUI = () => {
  const reloadPage = () => {
    location.reload();
  };

  return (
    <div className="flex flex-col justify-center items-center h-screen space-y-8 bg-gray-50">
      <TopBubble />
      <div className="flex flex-col justify-center items-center space-y-4 text-center z-50 px-6 md:px-12">
        <ProductName />
        <Title level={1} className="tracking-widest text-4xl md:text-5xl text-gray-800 mb-2">
          O-o-oh!
        </Title>
        <Title level={2} className="tracking-wider text-xl text-gray-600 mb-2">
          Don&apos;t worry
        </Title>
        <Title level={3} className="tracking-wider text-lg text-gray-500 mb-4">
          Everything is all right.
        </Title>
        <Button1 buttonName="Try this" click={reloadPage} />
      </div>
      <BottomBubble />
    </div>
  );
};

export default FallBackErrorUI;
