import React from "react"
import EditListInput from "../editListInput/EditListInput";
//Api's
// Redux stuff
import { connect } from "react-redux";
import { useRef } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import ListActionButton from "../listActionButton/ListActionButton";
import ShowCardPreview from "../showCardPreview/ShowCardPreview";
import { ShowListsProps } from "../../../type/boardPanal";
import Badge from "antd/es/badge";

const ShowLists = (props: ShowListsProps) => {
  let cardreferenceElement = useRef();
  return (
    <div className="flex h-auto max-h-full" key={props.key}>
      <Draggable draggableId={props.listID} index={props.index}>
        {(provided) => (
          <div
            className="list-content"
            {...provided.draggableProps}
            ref={provided.innerRef}
          >
            <div
              className="list-header ml-2  flex flex-col  "
              {...provided.dragHandleProps}
              style={{marginTop:'1px',marginBottom:'1px'}}
            >
              <div className="flex justify-between w-full items-start " >
                <div className="flex flex-row items-start ml-1">
                {props.applicants ? (
              <Badge className="site-badge-count-109" count={props.applicants.length} style={{ backgroundColor: '#ffffff',color:'black',paddingLeft:'4px',paddingRight:'4px',borderRadius:'4px',marginRight:'8px',marginTop:'8px'}} size="default" />
            ) : null}
                
                <EditListInput
                  listName={props.listName}
                  listID={props.listID}
                  isLock={props.isListLock}
                />
                </div>
                <div className="items-center flex justify-end mt-1.5">
                              <ListActionButton listID={props.listID} />
                              </div>

              </div>
            </div>           
            <Droppable droppableId={props.listID}>
              {(provided) => (
                <div
                  className="list-card mb-0.5"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  <div className="ml-2.5 mr-1 ">
                    <ShowCardPreview
                      applicants={props.applicants}
                      listID={props.listID}
                      cardreferenceElement={cardreferenceElement}
                    />
                    {provided.placeholder}
                  </div>
                </div>
              )}
            </Droppable>

          </div>
        )}
      </Draggable>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  lists: state.opening.lists,
});

export default connect(mapStateToProps)(ShowLists);
