import React, { useState } from "react";

import { Dispatch } from "redux";
import { connect } from "react-redux";
import { currentApplicant, loginUser } from "../../../type/type.d";
import { deleteAttachment } from "../../../redux/actions/opening/action";
import { deleteApplicantAttachment } from "../../../lib/api";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import Button from "antd/es/button";
import Popconfirm from "antd/es/popconfirm";
import Typography from "antd/es/typography";

const { Text } = Typography;


type DeleteAttachmentType = {
  loginUser: loginUser,
  attachment: any,
  clientId: string,
  currentApplicant: currentApplicant,
  removeAttachment(attachment: any, listID: string): void,
}

const DeleteAttachment = (props: DeleteAttachmentType) => {
  const [open, setOpen] = useState(false);

  const deleteAttachmentHandler = (e: any) => {
    return new Promise<void>((resolve, reject) => {
      e.stopPropagation();
      const req = {
        id: props.attachment.id,
        socket_client_id: props.clientId,
        list_id: props.currentApplicant.list_id,
        opening_id: props.currentApplicant.opening_id,
        recordID: props.currentApplicant.id,
      };
  
      deleteApplicantAttachment(req).then((attachment: any) => {
        props.removeAttachment(attachment, props.currentApplicant.list_id);
        resolve();
      }).catch((err) => {
        reject(err);
      });
    })
  };

  const handlePopoverChange = (visible: boolean) => {
    setOpen(visible);
  };

  return (
    <>
      <Popconfirm
        placement="bottom"
        open={open}
        onOpenChange={handlePopoverChange}
        title="Delete Attachment"
        okText="Delete"
        onConfirm={deleteAttachmentHandler} // Call deleteAttachmentHandler here
        onCancel={() => setOpen(false)}
        description={
          <div className="w-64">
            <Text>
              <span>Are you sure you want to delete this attachment?</span>
              <br />
              This action is irreversible.
            </Text>
          </div>
        }
      >
        <Button
          size="small"
          type="text"
          icon={<DeleteOutlined />}
          onClick={() => setOpen(!open)}
        >
          Delete
        </Button>
      </Popconfirm>
    </>
  );
};







const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  applicationId: state.opening.applicationId,
  currentApplicant: state.opening.currentApplicant,
  attachmentLoader: state.opening.attachmentLoader,
  clientId: state.opening.clientId,
})
const mapPropsTostate = (dispatch: Dispatch) => ({
  removeAttachment: (attachment: any, listID: string) => {
    dispatch(deleteAttachment(attachment, listID))
  },
})
export default connect(mapStateToProps, mapPropsTostate)(DeleteAttachment)

