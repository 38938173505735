import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loginUser } from "../../type/type";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  checkEmailVerify,
  getInvitations,
  getOrgDetails,
  getUser,
} from "../../lib/api";
import OrgCreationStatus from "../../pages/signUp/OrgCreationStatus";
import Spin from "antd/es/spin";
import { Dispatch } from "redux";
import { updateUser } from "../../redux/actions/opening/action";
import EmailVerifiedPage from "./verification/EmailIsVerified";
import OrgCreation from "./OrgCreation";
import OrgPreviewSection from "./OrgPreviewSection";

type OnboardingProps = {
  loginUser: loginUser;
  applicationId: string;
  updateUser(user: any): void;
};

const Onboarding = (props: OnboardingProps) => {
  const { verificationId } = useParams<{ verificationId: string }>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("userId")||"";
  const [content, setContent] = useState<JSX.Element | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();

  useEffect(() => {
    const handleOnboarding = async () => {
      // if user is logged out and try to access onboarding route
      if (!props.loginUser && !verificationId) return history.push("/login");

      // if user is logged out but user have  a verification code then verifying user and logout it
      if (
        !props?.loginUser ||
        !props.loginUser?.user?.id ||
        props.loginUser?.user?.email === ""
      ) {
        if (verificationId) {
          try {
            const res = await checkEmailVerify(verificationId,userId);
            if (res) {
              setIsLoading(false);
              setContent(<EmailVerifiedPage/>)
              return;
            }
          } catch (err) {
            console.error("Email verification failed:", err);
          }
        }
        return history.push("/login");
      }
      let newUser = localStorage.getItem('orgCreationStatus');
      // if user is logged in
      if (props.loginUser?.user?.verified && newUser !== 'started' ) {
        try {
          const invitations: any = await getInvitations(
            props.loginUser.user.email
          );
          if (
            invitations?.length > 0 ||
            props.loginUser.user?.registrations?.length > 0
          ) {
            return history.push("/o");
          }
        } catch (err) {
          console.error("Error fetching invitations:", err);
        }
      }
      try {
        const userRes = await getUser(props.loginUser.user.id);
        props.updateUser(userRes);
        if(!userRes?.verified && verificationId){
          const res = await checkEmailVerify(verificationId,userId);
          if (res) {
            let updatedUser = {
              ...userRes,
              verified:true
            }
            props.updateUser(updatedUser);
            history.push("/register");
            return;
          }
          return;
        }
        if(!userRes?.verified){
          history.push("/register");
          return;
        }
        if (
          userRes?.verified &&
          (!userRes?.registrations || userRes.registrations?.length === 0)
        ) {
          setContent(<OrgCreation />);
        } else if (props.applicationId) {
          const appRes = await getOrgDetails(props.applicationId);
          const isDone = appRes.setup_stages.every(
            (stage: any) => stage.stage === "done"
          );
          if (!isDone) {
            setContent(<OrgPreviewSection renderComponent={OrgCreationStatus}/>);
          } 
        }
        else {
          history.push("/o");
        }
      } catch (err) {
        console.error("Error fetching user or organization details:", err);
      }
      setIsLoading(false);
    };
    handleOnboarding();
  }, [props.loginUser.user.verified,props.applicationId]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spin />
      </div>
    );
  }

  return <>{content}</>;
};

const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  applicationId: state.opening.applicationId,
});

const mapPropsToState = (dispatch: Dispatch) => ({
  updateUser: (user: any) => dispatch(updateUser(user)),
});
export default connect(mapStateToProps,mapPropsToState)(Onboarding);
