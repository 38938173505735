import React from "react";
import { connect } from "react-redux";
import { Attachment } from "../../../type/type.d";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import Button from 'antd/es/button';


type DeleteAttachmentType = {
    attachment: any,
    removeAttachment(attachment: Attachment): void,
}

const DeleteAttachment = (props: DeleteAttachmentType) => {

    const deleteAttachmentHandler = () => {
        props.removeAttachment(props.attachment);
    };

   

    return (
        <>
                <Button
                    size="small"
                    type="text"
                    icon={<DeleteOutlined />}
                    onClick={deleteAttachmentHandler}
                >
                    Remove
                </Button>
        </>
    );
};







const mapStateToProps = (state: any) => ({
    loginUser: state.opening.loginUser,
    attachmentLoader: state.opening.attachmentLoader,
    clientId: state.opening.clientId,
})

export default connect(mapStateToProps)(DeleteAttachment)

