import React, { useEffect, useRef, useState } from "react";
import OrgPreviewSection from "./OrgPreviewSection";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { loginUser } from "../../type/type";
import { getInvitations, getUser } from "../../lib/api";
import SetupOrgName from "./SetupOrgName";
import useDesktopWarning from "../../utils/useDesktopWarning";
import DesktopWarning from "./DesktopWarning";
 

type OrgCreationProps = {
  loginUser: loginUser;
};
const OrgCreation = (props:OrgCreationProps) => {
  const [isPageActive, setIsPageActive] = useState(true);
  const { showWarning, setShowWarning } = useDesktopWarning();
  const location = useLocation();
  const isOnboarding = location.pathname === "/onboarding";

  const pollingRef = useRef<number>();
  const activeTimerRef = useRef<number>();
  const pollingIntervalRef = useRef(3000);
  const startTimeRef = useRef(Date.now());
  const isTimeoutRef = useRef(false);
  const maxPollingInterval = 30000;

  const stopPolling = () => {
    if (pollingRef.current) {
      window.clearInterval(pollingRef.current);
      pollingRef.current = undefined;
    }
  };
  const pollServer = async () => {
    let orgCreationStatus = localStorage.getItem('orgCreationStatus');
    if(document.hidden || !isOnboarding || orgCreationStatus === 'started' ) return;
    try {
      const invitations: any = await getInvitations(props.loginUser.user.email);
      const userRes = await getUser(props?.loginUser?.user?.id);
      if (
        invitations?.length > 0 ||
        userRes?.registrations?.length > 0
      ) {
        stopPolling();
        window.location.href = "/o";
      } else {
        pollingIntervalRef.current = Math.min(pollingIntervalRef.current * 2, maxPollingInterval);
        stopPolling();
        startPolling();
      }
    } catch (error) {
      console.error("Error checking email verification:", error);
      stopPolling();
    }
  };

  const startPolling = () => {
    if (isTimeoutRef.current || document.hidden || !isOnboarding) return;
    stopPolling();
    pollingRef.current = window.setInterval(pollServer, pollingIntervalRef.current);
  };
  const resetAll = () => {
    stopPolling();
     if (activeTimerRef.current) {
      window.clearInterval(activeTimerRef.current);
      activeTimerRef.current = undefined;
    }
    pollingIntervalRef.current = 3000;
    startTimeRef.current = Date.now();
    isTimeoutRef.current = false;
  };
  const trackActiveTime = () => {
    if (activeTimerRef.current) {
      window.clearInterval(activeTimerRef.current);
    }

    activeTimerRef.current = window.setInterval(() => {
      const currentTime = Date.now();
      const elapsedSeconds = Math.floor((currentTime - startTimeRef.current) / 1000);

      if (elapsedSeconds >= 200) {
        stopPolling();
        isTimeoutRef.current = true;
        if (activeTimerRef.current) {
          window.clearInterval(activeTimerRef.current);
          activeTimerRef.current = undefined;
        }
      }
    }, 1000);
  }; 

  const handleVisibilityChange = () => {
    let orgCreationStatus = localStorage.getItem('orgCreationStatus');
    if (document.hidden || orgCreationStatus === 'started') {
      setIsPageActive(false);
      stopPolling();
      resetAll();
    } else {
      if (!isTimeoutRef.current && isOnboarding) {
        setIsPageActive(true);
        pollServer();
        startTimeRef.current = Date.now();
        pollingIntervalRef.current = 3000; 
        startPolling();
        trackActiveTime();
      }
    }
  };
  useEffect(()=>{
    if (isOnboarding) {   
      document.addEventListener('visibilitychange', handleVisibilityChange);
      if (document.visibilityState === "visible") {
        startTimeRef.current = Date.now();
        startPolling();
        trackActiveTime();
      }
      return () => {
        resetAll();
        stopPolling();
        document.removeEventListener("visibilitychange", handleVisibilityChange);
      };
    }
  },[isPageActive]);
  return (
      <div className="flex flex-col lg:flex-row h-screen w-full bg-white">
        <div className="lg:block w-full h-full lg:rounded ">
          <div className=" flex justify-center items-center h-full w-full">
            <OrgPreviewSection renderComponent={SetupOrgName} />
          </div>
        </div>
        {showWarning && <DesktopWarning onClose={() => setShowWarning(false)} />}
      </div>
  );
};

const mapStateToProps= (state:any)=>({
  loginUser: state.opening.loginUser,
})
export default connect(mapStateToProps) (OrgCreation);
