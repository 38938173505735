import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SOCKET_CLIENT_ID } from '../constant';
import { addWsRequest } from '../redux/actions/opening/action';

export const useHeartbeat = () => {
  const dispatch = useDispatch();
  
  const wsConnectionObject = useSelector((state: any) => state.opening.wsConnection);
  const loginUser = useSelector((state: any) => state.opening.loginUser);
  const applicationId = useSelector((state: any) => state.opening.applicationId);

  useEffect(() => {
    const heartbeatInterval = setInterval(() => {
      if (wsConnectionObject?.readyState === WebSocket.OPEN) {
        dispatch(addWsRequest({
          type: "CHECK_CONNECTION",
          model_type: "heartbeat",
          model_id: "",
          user_id: loginUser.user.id,
          org_id: applicationId,
          socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
          time: Math.floor(new Date().getTime() / 1000)
        }));
      }
    }, 5000);

    return () => clearInterval(heartbeatInterval);
  }, [wsConnectionObject]);
}; 