import React, { useEffect, useState } from "react";
import Modal from "antd/es/modal";
import InfoCircleOutlined from "@ant-design/icons/InfoCircleOutlined";
import CheckBox from "../../ant/Checkbox";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

interface DesktopNotificationCheckboxProps {
  allowDesktopNotifications: boolean;
  setAllowDesktopNotifications: (value: boolean) => void;
}

const DesktopNotificationCheckbox: React.FC<DesktopNotificationCheckboxProps> = ({ allowDesktopNotifications, setAllowDesktopNotifications }) => {
  const [showPermissionPrompt, setShowPermissionPrompt] = useState(false);

  useEffect(() => {
    if (showPermissionPrompt) {
      Notification.requestPermission().then((permission) => {
        setShowPermissionPrompt(false);

        if (permission === "granted") {
          setAllowDesktopNotifications(true);
        } else {
          setAllowDesktopNotifications(false);
        }
      });
    }
  }, [showPermissionPrompt]);

  const onChange = (e: CheckboxChangeEvent) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      if (Notification.permission === "denied") {
        changePermission("Hey there! It seems like you've blocked notifications for this app. Notifications can keep you updated on important information.", true);
      } else {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            setAllowDesktopNotifications(true);
          } else {
            setAllowDesktopNotifications(false);
          }
        });
      }
    } else {
      if (Notification.permission === "granted") {
        changePermission("Hello there! If you no longer wish to receive notifications from this app, you can adjust your settings easily.", false);
      }
      setAllowDesktopNotifications(false);
      setShowPermissionPrompt(true);
    }
  };

  const showNotificationModal = (message: string, isAllow: boolean) => {
    Modal.info({
      title: "Enable or disable desktop notifications",
      okText: "Ok",
      icon: <InfoCircleOutlined />,
      content: (
        <div className="max-w-md mx-auto rounded">
          <p className="mb-4">{message}</p>
          <p className="mb-4">
            To {isAllow ? "enable" : "disable"} notifications, please follow these steps:
          </p>
          <ol className="list-decimal ml-6 mb-4">
            <li>Click on <strong>[Settings]</strong> in the top-right corner of your screen.</li>
            <li>Navigate to <strong>[Site Settings]</strong> or <strong>[Privacy & Security]</strong>, depending on your browser.</li>
            <li>Look for <strong>[Notifications]</strong> and find our app in the list.</li>
            {isAllow ? (
              <li>Set it to <strong>[Allow]</strong> or <strong>[Ask (default)]</strong> to start receiving updates.</li>
            ) : (
              <li>Set it to <strong>[Block]</strong> or <strong>[Deny]</strong> to stop receiving updates.</li>
            )}
          </ol>
          <p>
            Thanks for {isAllow ? "staying connected with us!" : "using our app!"} If you have any questions, feel free to reach out{" "}
            <a href="https://support.tacitbase.com/" style={{ color: "#009D79" }}>here</a>
            &nbsp;or&nbsp;
            <a rel="noreferrer" target="_blank" style={{ color: "#009D79" }} href="mailto:support@tacitbase.com">Contact us.</a>
          </p>
        </div>
      ),
      onOk() {},
      okButtonProps: { type: "default" },
    });
  };

  const changePermission = (message: string, isAllow: boolean) => {
    showNotificationModal(message, isAllow);
  };

  useEffect(() => {
    if (Notification.permission === "granted") {
      setAllowDesktopNotifications(true);
    } else if (Notification.permission === "denied") {
      setAllowDesktopNotifications(false);
    } else {
      setAllowDesktopNotifications(false);
    }
  }, []);

  return (
    <div className="flex items-center space-x-2 py-6">
      <CheckBox checked={allowDesktopNotifications} boxName="Desktop Notifications" onChange={onChange} value="Desktop Notifications" />
      <div>Desktop Notifications</div>
    </div>
  );
};

export default DesktopNotificationCheckbox;
