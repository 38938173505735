

// hooks/useContextMenu.ts
import { useState, useEffect } from 'react';

interface ContextMenuState<T> {
  position: { x: number; y: number } | null;
  activeItem: T | null;
}

interface UseContextMenuProps {
  onMenuClose?: () => void;
}

export function useContextMenu<T>({ onMenuClose }: UseContextMenuProps = {}) {
  const [menuState, setMenuState] = useState<ContextMenuState<T>>({
    position: null,
    activeItem: null,
  });

  useEffect(() => {
    const handleClickOutside = () => {
      if (menuState.position || menuState.activeItem) {
        setMenuState({ position: null, activeItem: null });
        onMenuClose?.();
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [menuState, onMenuClose]);

  const handleContextMenu = (e: React.MouseEvent, item: T) => {
    e.preventDefault();
    e.stopPropagation();
    const rect = e.currentTarget.getBoundingClientRect();
    setMenuState({
      position: {
        x: e.clientX - rect.left,
        y: e.clientY - rect.top,
      },
      activeItem: item,
    });
  };

  const handleButtonClick = (e: React.MouseEvent, item: T) => {
    e.stopPropagation();
    e.preventDefault();
    setMenuState(prev => ({
      ...prev,
      activeItem: prev.activeItem === item ? null : item,
    }));
  };

  const closeMenu = () => {
    setMenuState({ position: null, activeItem: null });
    onMenuClose?.();
  };

  return {
    menuPosition: menuState.position,
    activeItem: menuState.activeItem,
    handleContextMenu,
    handleButtonClick,
    closeMenu,
    isMenuOpen: Boolean(menuState.activeItem),
  };
}