import React, { useRef, useState } from "react";
import Popover from "antd/es/popover";
import { connect } from "react-redux";
import data from "@emoji-mart/data";
import  SmileTwoTone  from "@ant-design/icons/SmileTwoTone";
import Picker from "@emoji-mart/react";
// import Portal from "./portal/Portal";

type EmojiProps = {
  onEmojiSelect: (emoji: string) => void;
  usePortal?: boolean; 
};

const Emoji = (props: EmojiProps) => {
  const refer = useRef(null);
  const [open, setOpen] = useState(false);
  const [firstClick , setFirstClick] = useState(false);
  const handleEmojiPicker = ()=>{
    setOpen(!open)
  }
  const handleEmojiSelect = (emoji: string): void => {
    props.onEmojiSelect(emoji);
    setOpen(!open)
  };
  const handleOutsideClick = () => {
    // setIsEmoji(false);
    // console.log('handleOutsideClick')
    if(open && firstClick){
        setOpen(false);
        setFirstClick(false);
    }else{
        setFirstClick(true);
    }
}
const emojiPickerContent = (
  <div ref={refer} className="mt-4" data-testid="picker">
    <Picker
      data={data}
      onEmojiSelect={(emoji: any) => handleEmojiSelect(emoji.native)}
      onClickOutside={handleOutsideClick}
    />
  </div>
);
  return (
    <Popover
      content={emojiPickerContent}
      trigger="click"
      open={open}
      onOpenChange={setOpen}
      placement="bottom"
    >
      <button data-testid="testBtn" onClick={handleEmojiPicker}>
        <SmileTwoTone />
      </button>
    </Popover>
  );
};

const mapStateToProps = (state: any) => ({
  currentApplicant: state.opening.currentApplicant,
});

export default connect(mapStateToProps)(Emoji);