import React, { useRef, useState } from "react"
import { connect } from "react-redux";
import AddMemberToDbPopup from "./AddMemberToDbPopup";
import Popover from "antd/es/popover";
import Typography from "antd/es/typography";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter"
import { IconButton, LabeledIconButton } from "../../ant/Button"
import CloseOutlined from "@ant-design/icons/CloseOutlined"
import { ADD_MEMBER_TITLE } from "../../constant"
import AddIcon from "../Icons/AddIcon";
const { Text } = Typography
const AddBoardMember = () => {
  const [open, setOpen] = useState(false);
  let refer = useRef(null);


  return (
    <>
      <Popover destroyTooltipOnHide title={<div className="flex w-full bg-white items-center">
        <Text className="w-full flex justify-center">
          <Text>{capitalizeFirstLetter(ADD_MEMBER_TITLE)}</Text>
        </Text>
        <LabeledIconButton
          type="text"
          label=""
          onClick={() => setOpen(false)}
          icon={<CloseOutlined />}
        />
      </div>} open={open} onOpenChange={(open) => setOpen(open)} trigger={["click"]}
        content={
          <AddMemberToDbPopup click={() => setOpen(!open)} refer={refer} />
        }>
        <div className="" >
          <IconButton
            icon={<AddIcon />}
            onClick={() => setOpen(!open)}
            data-testid="openMemberPopup"
            tooltipTitle="Add member"  
          />
        </div>
      </Popover >

    </>
  );
};
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
});
export default connect(mapStateToProps)(AddBoardMember);
