import React, { useEffect } from "react"
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter"
import {
    DIVIDER_STYLE,
    HEADING_SIZE,
    REQUIRED_FIELD_OF_DATABASE,
} from "../../constant"

import { ExtendedTableColumn } from "./FormBuilder"
import Form from "antd/es/form";
import Input from "antd/es/input";
import Typography from "antd/es/typography";
import Checkbox from "antd/es/checkbox";
import Divider from "antd/es/divider";
import Radio from "antd/es/radio";
import Space from "antd/es/space";
import FormItem from "../../ant/FormItem"
import TalentPoolIcon from "../Icons/TalentPoolIcon"
const { Text } = Typography;

type FormFieldSettingsType = {
    selectedComponent: ExtendedTableColumn,
    handleChangeRequest: (value: string) => void,
    handleChangeLabel: (changeValue: { [key: string]: string }) => void;
    makeFieldRequired: (e: any) => void
};

const allowedDataType = ["string", "number", "email", "phone", "file", "url"];

const FormFieldSettings = (props: FormFieldSettingsType) => {
    if (props.selectedComponent.name.trim().length === 0)
        return <>{capitalizeFirstLetter("please select element")}</>;

    const isAllowedDataType = allowedDataType.includes(
        props.selectedComponent.type
    );
    const handleFormChange = (changedValues: any) => {
        props.handleChangeLabel(changedValues)
    }
    const [form] = Form.useForm()

    useEffect(() => {
        form.setFieldsValue(props.selectedComponent)
    }, [props.selectedComponent])
    return (
        <Form form={form} layout="vertical"
            initialValues={{
                ...props.selectedComponent,
            }}
            onValuesChange={(changedValues) => handleFormChange(changedValues)}
        >
            <FormItem
                name={"label"}
                label={capitalizeFirstLetter("Field label")}
            >
                <Input

                // value={props.selectedComponent.view}
                // defaultValue={props.selectedComponent.view}
                />
            </FormItem>

            {isAllowedDataType && (
                <Form.Item
                    name={"isRequired"}
                    label={<Text className="font-medium">{capitalizeFirstLetter("required")}</Text>}
                    valuePropName="checked"
                >

                    <Checkbox
                        value={props.selectedComponent.isRequired}
                        disabled={REQUIRED_FIELD_OF_DATABASE.includes(
                            props.selectedComponent.name
                        )}
                        style={{ margin: 0 }}
                        defaultChecked={props.selectedComponent.isRequired}
                    >
                        {capitalizeFirstLetter("Prevent submission if this field is empty")}
                    </Checkbox>
                </Form.Item>
            )}
            {isAllowedDataType && props.selectedComponent.view && (
                <Space direction="vertical">
                    <Text className="font-medium">{capitalizeFirstLetter("Connected field of talent pool")}</Text>
                    <Text><TalentPoolIcon /> {capitalizeFirstLetter(props.selectedComponent.view)}</Text>
                </Space>)}
            {props.selectedComponent.type === "divider" &&
                <>

                    <FormItem
                        name={"style"}
                        // className="flex  w-full justify-around space-x-3"
                        label={capitalizeFirstLetter("Divider style")}
                    >
                        <Radio.Group
                            value={props.selectedComponent.style}
                        >
                            {DIVIDER_STYLE.map((style) => (
                                <Radio.Button key={style} value={style} >
                                    <Divider style={{ width: "50px", margin: "14px 0px", border: `2px ${style}` }} />
                                </Radio.Button>
                            ))}
                        </Radio.Group>
                    </FormItem>
                    <FormItem
                        name={"height"}
                        // className="flex  w-full justify-around space-x-3"
                        label={capitalizeFirstLetter("height")}
                    >
                        <Input suffix="px" type="number" defaultValue="1" />
                    </FormItem>
                </>
            }

            {props.selectedComponent.type === "TermsAndConditions" && (
                <>
                    <Form.Item name={"link"} label="Term Link">
                        <Input
                        />
                    </Form.Item>
                    <Form.Item name={"isRequired"}
                        label={<Text className="font-medium">{capitalizeFirstLetter("required")}</Text>}
                        valuePropName="checked"

                    >
                        <Checkbox
                            disabled
                    >
                        Prevent submission if this field is empty
                        </Checkbox>
                    </Form.Item>
                </>
            )}
            {props.selectedComponent.type === "logo" && (
                <>
                    <Form.Item label="Field label">
                        <Input
                            name="label"
                            value={props.selectedComponent.label}
                            onChange={(e: any) => props.handleChangeLabel(e)}
                        />
                    </Form.Item>
                    <div className="text-xs">
                        In order to create a link to your terms page, wrap the words where
                        you want your link to be in curly brackets. e.g. I agree to .
                    </div>
                    <Checkbox
                        checked={props.selectedComponent.isRequired}
                        onChange={(e: any) => props.handleChangeLabel(e)}
                    >
                        Prevent submission if this field is empty
                    </Checkbox>
                </>
            )}
            {props.selectedComponent.type === "heading" && (
                <>

                    <FormItem
                        name={"subheading"}
                        label={capitalizeFirstLetter("subheading")}
                    >
                        <Input
                            value={props.selectedComponent.view}
                        // defaultValue={props.JDFormData.title}
                        />
                    </FormItem>
                    <FormItem
                        name={"size"}
                        label={capitalizeFirstLetter("Heading Size")}

                    >
                        <Radio.Group
                            // onChange={(e) => props.handleChangeLabel(e)}
                            value={props.selectedComponent.size}
                        >
                            {HEADING_SIZE.map((size) => (
                                <Radio.Button key={size} value={size}>
                                    {capitalizeFirstLetter(size)}
                                </Radio.Button>
                            ))}
                        </Radio.Group>
                    </FormItem>


                </>
            )}
        </Form >
    )
};

export default FormFieldSettings
