import React from 'react';
import Typography from "antd/es/typography";
import Button from "antd/es/button";
import  RollbackOutlined  from '@ant-design/icons/RollbackOutlined';
import BottomBubble from './BottomBubble';
import TopBubble from './TopBubble';

const { Title, Text } = Typography;

const PageNotFound = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center px-4">
      <TopBubble />
      <div className="text-center flex flex-col items-center space-y-6">
        <Title 
        level={1}
          className="text-transparent font-extrabold leading-none"
        >
          404
        </Title>
        <Title level={2} className="text-2xl font-semibold">
          Oops! Page Not Found
        </Title>
        <Text type="secondary" className="block text-lg">
          The page you&apos;re looking for is not here.
        </Text>
        <Button 
          type="primary" 
          icon={<RollbackOutlined />}
          onClick={() => window.history.back()}
          className="flex items-center px-6 py-3"
        >
          Go Back
        </Button>
      </div>
      <BottomBubble />
    </div>
  );
};

export default PageNotFound;
