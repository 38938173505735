import React from 'react';

const Linkedin = ({size}:{size:number}) => {
    return (
        <div>
            <svg width={size?size:"26"} height={size?size:"26"} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_6189_16497)">
<path d="M23.5625 0.609469H2.4375C1.95819 0.604591 1.49652 0.790056 1.15379 1.12517C0.81106 1.46028 0.615268 1.91767 0.609375 2.39697V23.6073C0.616334 24.0859 0.812594 24.5422 1.15521 24.8765C1.49782 25.2107 1.95888 25.3956 2.4375 25.3907H23.5625C24.0418 25.3945 24.5032 25.2084 24.8458 24.8731C25.1883 24.5378 25.3842 24.0805 25.3906 23.6012V2.39088C25.3821 1.91298 25.1852 1.45777 24.8429 1.1242C24.5006 0.790625 24.0405 0.605646 23.5625 0.609469Z" fill="#0076B2"/>
<path d="M4.27785 9.89844H7.95645V21.7345H4.27785V9.89844ZM6.11817 4.00781C6.54008 4.00781 6.95252 4.13295 7.3033 4.3674C7.65409 4.60184 7.92745 4.93506 8.08882 5.3249C8.25019 5.71474 8.29231 6.14368 8.20985 6.55746C8.12739 6.97124 7.92405 7.35127 7.62557 7.64947C7.32709 7.94767 6.94687 8.15064 6.53301 8.2327C6.11915 8.31477 5.69025 8.27224 5.30056 8.1105C4.91088 7.94876 4.57792 7.67508 4.34381 7.32407C4.1097 6.97307 3.98495 6.56051 3.98535 6.13859C3.98589 5.57329 4.21083 5.03132 4.61076 4.63178C5.01068 4.23224 5.55286 4.00781 6.11817 4.00781ZM10.2639 9.89844H13.7902V11.5234H13.8389C14.3305 10.5931 15.5289 9.61203 17.3185 9.61203C21.0438 9.60391 21.7344 12.0556 21.7344 15.2345V21.7345H18.0558V15.9759C18.0558 14.6048 18.0314 12.8397 16.1444 12.8397C14.2574 12.8397 13.9364 14.3347 13.9364 15.8866V21.7345H10.2639V9.89844Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_6189_16497">
<rect width="26" height="26" fill="white"/>
</clipPath>
</defs>
</svg>
        </div>
    );
};

export default Linkedin;

