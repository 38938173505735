import React from 'react';
import Modal from 'antd/es/modal';
import type { ModalFuncProps } from 'antd/lib/modal';

interface CustomModalProps extends ModalFuncProps {
  // Customize content of the modal
  content: React.ReactNode;
  // Text for the OK button
  okText?: string;
  // Text for the Cancel button
  cancelText?: string;
  // Callback function for the OK button
  handleOk?: () => void;
  // Callback function for the Cancel button
  handleCancel?: () => void;
  // Button element to be rendered
  ModalTriggerButtonName: React.ReactNode;
  // setModalState: (state: boolean) => void;
  visibility: boolean;
  loadingOkButton?: boolean; // New prop to indicate loading state of OK button
  disabledOkButton?:boolean;
  destroyOnClose?:boolean
}

const TacitBaseModal: React.FC<CustomModalProps> = ({
  content,
  okText = 'OK',
  visibility,
  // setModalState,
  cancelText = 'Cancel',
  handleOk, loadingOkButton = false,
  handleCancel,disabledOkButton=false,
  ModalTriggerButtonName,destroyOnClose,
  ...modalProps
}) => {
  return (
    <>
      {ModalTriggerButtonName}
      <Modal destroyOnClose={destroyOnClose}
        visible={visibility} 
        onOk={handleOk} 
        onCancel={handleCancel}
        okText={okText} okButtonProps={{loading:loadingOkButton,disabled:disabledOkButton}}
        cancelText={cancelText}
        {...modalProps}
      >
        {content}
      </Modal>
    </>
  );
};

export default TacitBaseModal;
