import React, { useEffect, useRef } from "react";

import Form from "antd/es/form";
import Input from "antd/es/input";

const SetSubject = (props: { onchange: (value: string) => void }) => {
  const inputRef = useRef<HTMLInputElement |any>(null);

  // Focus the input field when the component mounts
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus(); // Focus using the InputRef's methods
    }
  }, []);
  return (
    <>
      <Form.Item
  // label={<span className="text-xs w-12 text-left" style={{width:'54px'}}>Subject</span>}
  // name="subject"
        data-testid="subject-input"
        // rules={[{ required: true, message: "Please enter subject!" }]}
      >
        <Input
        ref={inputRef}
          onChange={(e) => {
            props.onchange(e.target.value);
          }}
          size="middle"
            />
      </Form.Item>
    </>
  );
};
export default SetSubject;
