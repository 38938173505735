import React, { useState } from "react";
import Modal from "antd/es/modal";
import Typography from "antd/es/typography";
import Space from "antd/es/space";
import Button from "antd/es/button";
import Divider from "antd/es/divider";
import message from "antd/es/message";
import CloseCircleFilled from "@ant-design/icons/CloseCircleFilled";
import CheckCircleFilled from "@ant-design/icons/CheckCircleFilled";
import FileTextOutlined from "@ant-design/icons/FileTextOutlined";
import CloseOutlined from "@ant-design/icons/CloseOutlined";
import CopyOutlined from "@ant-design/icons/CopyOutlined";
import EnvironmentOutlined from "@ant-design/icons/EnvironmentOutlined";
import UserOutlined from "@ant-design/icons/UserOutlined";
import CalendarOutlined from "@ant-design/icons/CalendarOutlined";
import LockOutlined from "@ant-design/icons/LockOutlined";
import EditOutlined from "@ant-design/icons/EditOutlined";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";

import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import dayjs from "dayjs";
import Tooltip from "../../../ant/Tooltip";
import Skype from "../../../assets/Skype";
import MeetSvg from "../../../assets/MeetSVG";
import { useSelector } from "react-redux";
import TacitbaseAvatar from "../../../ant/Avtar";
import QuestionCircleFilled from "@ant-design/icons/QuestionCircleFilled";

const { Text, Title } = Typography;

interface EventModalProps {
  visible: boolean;
  event: any;
  onClose: () => void;
  onEdit: (eventId: string) => void;
  onDelete?: () => void;
}

const EventDetailsModal: React.FC<EventModalProps> = ({
  visible,
  event,
  onClose,
  onEdit,
  onDelete,
}) => {
  const connectedClient: "outlook" | "google" = useSelector(
    (state: any) => state.calendar.connectedClient
  );
  const [copied, setCopied] = useState(false);

  const handleJoinMeeting = (meetingLink: string) => {
    // Open Skype meeting link in a new window
    window.open(meetingLink, "_blank", "noopener,noreferrer");
  };

  const handleCopyLink = (meetingLink: string) => {
    // Copy meeting link to clipboard
    navigator.clipboard
      .writeText(meetingLink)
      .then(() => {
        message.success("Meeting link copied!");
        setCopied(true);

        // Reset copied state after 2 seconds
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
        message.error("Failed to copy meeting link");
      });
  };
  const totalAttendees = event?.attendees?.length || 0;
  const accepted =
    event?.attendees?.filter((a: any) => a.responseStatus === "accepted")
      .length || 0;
  const awaiting =
    event?.attendees?.filter((a: any) => a.responseStatus === "needsAction")
      .length || 0;

  const formatDateRange = () => {
    const startDate = new Date(event.start);
    const endDate = new Date(event.end);

    if (event.allDay) {
      return (
        dayjs(startDate).format("dddd, MMMM D") +
        (dayjs(startDate).isSame(endDate, "day")
          ? ""
          : ` - ${dayjs(endDate).format("dddd, MMMM D")}`)
      );
    } else {
      return `${dayjs(startDate).format("dddd, MMMM D, h:mma")} - ${dayjs(
        endDate
      ).format("dddd, MMMM D, h:mma")}`;
    }
  };

  const AvatarWithStatus = ({ attendee }: { attendee: any }) => (
    <div className="relative inline-block">
      <TacitbaseAvatar
                  content={`${attendee?.email?.[0]}`}
                  key={attendee?.email}
                  size="small"
                  toolTip={`${attendee?.email}`}
                />
      <span className="absolute right-0 bottom-1 flex items-center justify-center rounded-full">
        <span className="w-2 h-2">
          {attendee.responseStatus === "accepted" ? (
            <CheckCircleFilled className="text-green-500 text-xs" />
          ) : attendee.responseStatus === "declined" ? (
            <CloseCircleFilled className="text-red-500 text-xs" />
          ) : attendee.responseStatus === "tentative" ? (
            <QuestionCircleFilled className="text-gray-500 text-xs" />
          ) : null}
        </span>
      </span>
    </div>
  );

  return (
    <Modal
      centered={true}
      style={{
        maxWidth: 448,
        width: 448,
      }}
      open={visible}
      onCancel={onClose}
      footer={null}
      title={null}
      closeIcon={null}
      styles={{
        content: { padding: 0 },
      }}
    >
      <Space direction="vertical" size={0} className="w-full">
        <Space
          className="flex justify-end"
          style={{ padding: "10px 10px 0 10px", border: "none", minHeight: 30 }}
        >
          <Tooltip arrow={false} content={"Edit"}>
            <Button
              type="text"
              icon={<EditOutlined />}
              onClick={() => onEdit(event.id)}
            />
          </Tooltip>
          <Tooltip arrow={false} content={"Delete"}>
            <Button type="text" icon={<DeleteOutlined />} onClick={onDelete} />
          </Tooltip>
          <Tooltip arrow={false} content={"Close"}>
            <Button type="text" icon={<CloseOutlined />} onClick={onClose} />
          </Tooltip>
        </Space>
        <Divider className="my-2 m-0 p-0" />

        <Space
          style={{
            padding: "0px 16px",
            marginBottom: 15,
          }}
          size={16}
          align="center"
        >
          <div
            className="w-4 h-4 rounded"
            style={{ background: event.color }}
          />
          <Space.Compact direction="vertical">
            <Title level={5} style={{ margin: 0 }}>
              {event.title}
            </Title>
            <Text>{formatDateRange()}</Text>
          </Space.Compact>
        </Space>

        <Space
          direction="vertical"
          size={16}
          className="w-full"
          style={{
            maxHeight: "calc(100vh - 270px)",
            overflowY: "auto",
            scrollbarWidth: "thin",
            padding: "0px 16px",
            marginBottom: 16,
          }}
        >
          {event?.conference_data.entry_points?.[0] && (
            <Space
              align="center"
              size="middle"
              className="w-full break-words flex "
            >
              {connectedClient == "google" ? (
                <span className=" h-5 w-5 flex items-center justify-center">
                  <MeetSvg />
                </span>
              ) : connectedClient == "outlook" ? (
                <span className="-ml-0.5 h-6 w-6 flex items-center justify-center">
                  <Skype />{" "}
                </span>
              ) : null}
              {/* <div className="flex  w-full"> */}
              <Space.Compact direction="vertical">
                <Space.Compact block>
                  <Button
                    onClick={() =>
                      handleJoinMeeting(
                        event?.conference_data.entry_points?.[0]?.uri
                      )
                    }
                  >
                    {connectedClient == "google"
                      ? "Join with google meet"
                      : connectedClient == "outlook"
                      ? "Join skype meeting"
                      : "join"}
                  </Button>
                  <Button
                    icon={<CopyOutlined />}
                    onClick={() =>
                      handleCopyLink(
                        event?.conference_data.entry_points?.[0]?.uri
                      )
                    }
                    type={copied ? "primary" : "default"}
                    className="flex items-center justify-center"
                  >
                  </Button>
                </Space.Compact>

                <Text style={{ fontSize: 12 }} type="secondary">
                  {event?.conference_data.entry_points?.[0]?.uri}
                </Text>
              </Space.Compact>

             
            </Space>
          )}
          {/* Location */}
          {event.location && (
            <Space align="center" size="middle">
              <EnvironmentOutlined />
              <Text>{event.location}</Text>
            </Space>
          )}

          {/* Visibility */}
          {event.visibility === "private" && (
            <Space align="center" size="middle">
              <LockOutlined />
              <Text>{capitalizeFirstLetter(event.visibility)}</Text>
            </Space>
          )}

          {/* Attendees */}
          {event.attendees?.length > 0 && (
            <div className="space-y-4">
              <Space direction="vertical" className="w-full" size={2}>
                <Space align="center" size="middle">
                  <UserOutlined />
                  <Space.Compact direction="vertical">
                    <Text>{totalAttendees} guests</Text>
                    <Text style={{ fontSize: 12 }} type="secondary">
                      {accepted} yes, {awaiting} awaiting
                    </Text>
                  </Space.Compact>
                </Space>
              </Space>

              <div className="pl-6 space-y-2">
                {event.attendees?.map((attendee: any, index: number) => (
                  <Space
                    key={index}
                    align="center"
                    className="w-full justify-between"
                    size="middle"
                  >
                    <Space align="center" size="small">
                      <AvatarWithStatus attendee={attendee} />
                      <Space.Compact direction="vertical">
                        <Text>{attendee?.email}</Text>
                        {attendee?.organizer && (
                          <Text style={{ fontSize: 12 }} type="secondary">
                            Organizer
                          </Text>
                        )}
                      </Space.Compact>
                    </Space>
                  </Space>
                ))}
              </div>
            </div>
          )}

          {/* Description */}
          {event?.description && (
            <Space align="start" size="middle" className="w-full break-words">
              <FileTextOutlined />
              <div
                dangerouslySetInnerHTML={{ __html: event.description }}
                className="break-words overflow-wrap-break-word max-w-full"
                style={{
                  wordBreak: "break-word",
                  overflowWrap: "break-word",
                  width: "100%",
                }}
              />
            </Space>
          )}

          {/* Organizer */}
          <Space align="center" size="middle">
            <CalendarOutlined />
            <Space.Compact direction="vertical">
              {event.organizer?.display_name && (
                <Text>{event.organizer.display_name}</Text>
              )}
              {event.creator?.email && (
                <Text style={{ fontSize: 12 }} type="secondary">
                  Created by: {event.creator.email}
                </Text>
              )}
            </Space.Compact>
          </Space>
        </Space>
      </Space>
    </Modal>
  );
};

export default EventDetailsModal;
