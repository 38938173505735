import { useEffect, useState } from "react";
type DynamicBrowserVersionMap = {
    [key: string]: number;
  };
  type BrowserInfo = {
    [key: string]: boolean;
  };

const BrowserCompatibility = ({ onIncompatible }: any) => {
  const [isCompatible, setIsCompatible] = useState(true);

  const browserVersionMap :DynamicBrowserVersionMap= {
  chrome: 88,
  safari: 15.4,
  firefox: 78,
  edge: 88,
  };

  const checkCompatibility = () => {
    const userAgent = navigator.userAgent.toLowerCase();

    const isChromeIOS = /crios\/([\d.]+)/i.test(userAgent); // Detect Chrome on iOS
    const isChrome = /chrome/.test(userAgent) && !/edge|opr/.test(userAgent) && !isChromeIOS;
    const isFirefox = /firefox/.test(userAgent);
    const isEdge = /edg/.test(userAgent);
    const isSafari = /safari/.test(userAgent) && !isChrome && !isEdge || isChromeIOS; // Treat iOS Chrome as Safari

    const browserInfo: BrowserInfo = {
      chrome: isChrome ||isChromeIOS,
      firefox: isFirefox,
      edge: isEdge,
      safari: isSafari,
    };
    const isSupportedBrowser = Object.keys(browserInfo).some((browser) => {
      const browserMatch = browserInfo[browser];
      if (browserMatch) {
        let version = 0;
        if (browser === 'safari') {
          // For Safari, use "version/" to get the version number
          const versionMatch = userAgent.match(/version\/([\d.]+)/);
          if (versionMatch) {
            version = parseFloat(versionMatch[1]);
          }
        }else if (browser === "chrome" && isChromeIOS) {
            // For Chrome on iOS, extract from CriOS
            const versionMatch = userAgent.match(/crios\/([\d.]+)/i);
            if (versionMatch) {
              version = parseFloat(versionMatch[1]);
            }
        } else {
          // For other browsers, use the browser's name directly (e.g., chrome/, firefox/)
          const versionMatch = userAgent.match(new RegExp(`${browser}/([\\d.]+)`));
          if (versionMatch) {
            version = parseFloat(versionMatch[1]);
          }
        }
        return version >= (browserVersionMap[browser] || 0);
      }
      return false;
    });

    setIsCompatible(isSupportedBrowser);
  };

  useEffect(() => {
    checkCompatibility();
  }, []);

  useEffect(() => {
    if (!isCompatible) {
      onIncompatible();
    }
  }, [isCompatible, onIncompatible]);

  return null;
};

export default BrowserCompatibility;




