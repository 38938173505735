import React, { useState } from "react";
import { customRoles } from "../../../constant";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import DownOutlined from "@ant-design/icons/DownOutlined";
import Button from "antd/es/button";
import Dropdown from "antd/es/dropdown";
import Menu from "antd/es/menu";
type DropdownWithDescProp = {
  selectedRole: string;
  selectedItem: (item: string) => void;
  options: customRoles[];
  width?: string;
  refer?: any;
};
const DropdownWithDesc = (props: DropdownWithDescProp) => {
  const [isRoleOpen, openRolePopup] = useState(false);
  const handleSelect = (item: string) => {
    openRolePopup(false);
    props.selectedItem(item);
  };
  const menu = (
    <Menu style={{ maxHeight: "250px", overflowY: "auto" }}>
      {props.options.map((option, index) => (
        <Menu.Item key={index} onClick={() => handleSelect(option.name)}>
          <div className="text-xs font-medium">{capitalizeFirstLetter(option.name)}</div>
          <div className="text-xs font-light text-gray-500">{option.description}</div>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      open={isRoleOpen}
      onOpenChange={openRolePopup}
      placement="bottomLeft"
      overlayStyle={{
        maxHeight: "250px",
        overflowY: "auto",
      }}
    >
      <Button
        onClick={() => openRolePopup(!isRoleOpen)}
        className={`cursor-pointer flex p-1 justify-between text-xs items-center capitalize outline-none rounded ${
          props.width ? props.width : "w-32"
        } bg-opacity-20`}
        data-testid="selectBoard"
      >
        {capitalizeFirstLetter(props.selectedRole)}
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};
export default DropdownWithDesc;
