import React, { forwardRef, lazy, Suspense, useImperativeHandle, useRef } from 'react'
import { ReactQuillProps, UnprivilegedEditor,Quill } from 'react-quill'
const ReactQuill = lazy(() => import('react-quill')); 
import 'react-quill/dist/quill.snow.css'
import { DeltaStatic, Sources } from 'quill';
import 'react-quill/dist/quill.snow.css';

const Block = Quill.import('blots/block');
Block.tagName = 'DIV';
Quill.register(Block, true);

interface EditorProps extends  Omit<ReactQuillProps, 'ref'> {
    initialValue?: string;
    onChange(content: string, delta: any, source: string, editor: any): void;
    modules?: Record<string, any>;
    formats?: string[];
    className?: string;
    // value: string

}

const Editor = forwardRef((props:EditorProps, ref) => {
    const quillRef = useRef<any>(null);
    useImperativeHandle(ref, () => quillRef.current, []);
    const {
        initialValue = '',
        onChange,
        modules,
        formats,
        className,
        ...rest
    } = props;
    const handleChange = (content: string, delta: DeltaStatic, source: Sources, editor: UnprivilegedEditor) => {
        if (onChange) {
            onChange(content, delta, source, editor)
        }
    }
    return (
        <Suspense fallback={<div>Loading editor...</div>}>
        <ReactQuill
        ref={quillRef}
            theme="snow"
            value={initialValue}
            onChange={handleChange}
            {...rest}
            className={className}
            modules={modules}
            formats={formats}
        />
  </Suspense>
    )
});
Editor.displayName = 'Editor';
export default Editor;
