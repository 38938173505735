import React, { lazy, useEffect, useState } from "react"
const TacitBaseModal = lazy(() => import("../../ant/TacitbaseModal"));
import Alert from "antd/es/alert";
import Divider from "antd/es/divider";
import Pagination from "antd/es/pagination";
import Space from "antd/es/space";
import Spin from "antd/es/spin";
import { LabeledIconButton } from "../../ant/Button"
import ArrowLeftOutlined from "@ant-design/icons/ArrowLeftOutlined"
import LeftOutlined from "@ant-design/icons/LeftOutlined"
import RightOutlined from "@ant-design/icons/RightOutlined"
import { useHistory, useParams } from "react-router-dom"
import { connect } from "react-redux"
import DetailReviewContent from "./DetailReviewContent"
import AddLabelToReview from "./AddLabelToReview"
import AddMemberToReview from "./AddMemberToReview"
import DetailReviewCommentsSection from "./DetailReviewCommentsSection"
import {
    CandidateReview,
    CandidatesInReview,
    loginUser,
    notificationServiceEntityDotRemovePayloadType,
    Scope,
} from "../../type/type.d"
import ClosedReview from "./ClosedReview"
import { Overlay } from "../hook/Overlay"
import SendReviewToBoard from "./SendReviewToBoard"
import { readNotification } from "../../lib/api"
import {
    changeServiceNotificationsCountValue,
    unread_dot_remover,
} from "../../redux/actions/opening/action"
import { Dispatch } from "redux";
import SuspenseLoader from "../SuspenseLoader"

const DetailedReview = (props: {
    selectedRow: number | string,
    applicationId: string,
    currentCandidateInReview: CandidateReview,
    candidatesInReview: CandidatesInReview,
    loginUser: loginUser,
    removeReviewNotificationDotOfReview(
        payload: notificationServiceEntityDotRemovePayloadType
    ): void,
    notificationCenter: any,
    changeServiceNotificationsCount(payload: {}): void
}) => {
    const params: { review_id: string } = useParams()
    const history = useHistory()
    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = React.useState<boolean>(false);

    const itemRender = (current: any, type: any) => {
        if (type === "prev") {
            return (
                <LabeledIconButton
                    icon={<LeftOutlined />}
                    onClick={() => { }}
                    label=""
                />
            )
        }
        if (type === "next") {
            return (
                <LabeledIconButton
                    icon={<RightOutlined />}
                    onClick={() => { }}
                    label=""
                />
            )
        }

        if (type === "page") {
            return null
        }
        return null;
    };

    const backToReviews = () => {
      history.push({
        pathname: `/o/${props.applicationId}/reviews`,
      });
    };
  const handleTableChange = (current: number) => {
      history.push({
        pathname: `/o/${props.applicationId}/reviews/${
          Object.keys(props.candidatesInReview.reviews[props.candidatesInReview.currentPage])[current - 1]
        }`,
      });
    };

    useEffect(() => {
      props.notificationCenter.unreadDotsofServices?.review?.reviews[
        params.review_id
      ] &&
        readNotification(params.review_id, "review").then(() => {
          props.removeReviewNotificationDotOfReview({
            service: "review",
            model_name: "review",
            remove_id: params.review_id,
          });
        });
    }, []);

    useEffect(() => {
      // Calculate the total number of items
      let totalItems: number =
        props.candidatesInReview.reviews &&
        props.candidatesInReview.reviews[props.candidatesInReview.currentPage] &&
        Object.keys( props.candidatesInReview.reviews[props.candidatesInReview.currentPage]).length;
      setTotal(totalItems);
    }, [ props.candidatesInReview.reviews]);

    return (
      <>
        <SuspenseLoader>
        <TacitBaseModal
          keyboard={false}
          maskClosable={false}
          // onCancel={backToReviews}
          // onOk={backToReviews}
          visibility={params.review_id ? true : false}
          width={"100%"}
          title={
            <>
              <Overlay onClose={backToReviews} scope={Scope.Dialog}>
                <div
                  className={`w-full flex justify-between py-2.5 px-3 ${
                    props.currentCandidateInReview.reviewed && "bg-yellow-50"
                  }`}
                >
                  <LabeledIconButton
                    icon={<ArrowLeftOutlined />}
                    type="text"
                    label="Back to reviews"
                    onClick={backToReviews}
                  />{" "}
                  {props.currentCandidateInReview.reviewed && (
                    <Alert
                      style={{}}
                      className="m-0 px-2 py-0"
                      message="This review is now closed"
                      type="warning"
                    />
                  )}
                  {/* Pagination component in the header */}
                  <Pagination
                    showTotal={(total, range) =>
                      `${range[0]} of ${total} reviews`
                    }
                    showQuickJumper={false}
                    showSizeChanger={false}
                    pageSize={1}
                    current={
                      params.review_id &&
                      props.candidatesInReview.reviews[props.candidatesInReview.currentPage]
                        ? Object.keys(props.candidatesInReview.reviews[props.candidatesInReview.currentPage]).indexOf(
                            params.review_id
                          ) + 1
                        : 1
                    }
                    onChange={handleTableChange}
                    total={total} // Total number of reviews
                    itemRender={(current: any, type: any) =>
                      itemRender(current, type)
                    }
                  />
                </div>{" "}
                {/* <Divider className="m-0 p-0" /> */}
              </Overlay>
            </>
          }
          closeIcon={null}
          content={
            // <Overlay scope={Scope.Modal} onClose={backToReviews}>
            <Spin spinning={loading} delay={500}>
              <Divider className="m-0 p-0" />

              <div
                style={{
                  margin: 0,
                  // height: modalContentHeight,
                  // paddingRight: "2px",
                  position: "relative",
                  // borderRadius: '12px',
                  overflow: "hidden",
                  width: "calc(100vw - 150px )",
                  maxWidth: "calc(100vw - 150px )",
                  height: "calc(100vh - 150px )",
                  maxHeight: "calc(100vh - 100px )",
                }}
              >
                <div style={{ display: "flex" }} className="h-full py-3">
                  {/* First Part */}
                  <div
                    style={{ width: "40%" }}
                    className="flex flex-col h-full flex-shrink-0 pl-5 overflow-y-scroll"
                  >
                    <DetailReviewContent />{" "}
                  </div>
                  <Divider
                    type="vertical"
                    className="h-full mx-0.5"
                    style={{
                      marginTop: "-8px",
                    }}
                  ></Divider>
                  {/* Second Part */}
                  <div
                    style={{
                      width: "80%",
                    }}
                    className="px-2 flex flex-col overflow-y-hidden"
                  >
                    <DetailReviewCommentsSection />
                  </div>
                  <>
                    {!props.currentCandidateInReview.reviewed && (
                      <>
                        <Divider
                          type="vertical"
                          className="h-full mx-0"
                        ></Divider>
                        <div style={{ width: "25%" }} className="px-5 py-3">
                          <Space
                            direction="vertical"
                            className="w-full space-y-1"
                          >
                            <AddLabelToReview />
                            <AddMemberToReview />
                            <SendReviewToBoard />

                            {!props.currentCandidateInReview.reviewed &&
                              props.currentCandidateInReview.created_by ==
                                props.loginUser.user.id && (
                                <ClosedReview
                                  setLoading={(event: boolean) =>
                                    setLoading(event)
                                  }
                                />
                              )}
                          </Space>
                        </div>
                      </>
                    )}
                  </>
                </div>
              </div>
            </Spin>

            // </Overlay >
          }
          footer={<div className="m-0"></div>}
          style={{
            overflowY: "hidden",
            width: " 100vw",
            height: "100vh",
            position: "fixed",
            top: "50px",
            left: "10px",
            bottom: "10px",
            right: "0px",
            display: "flex",
            alignItems: "start",
            justifyContent: "center",
            padding: "0px",
            // opacity: 1
          }}
          className="review-modal-content"
        /></SuspenseLoader>
      </>
    );
}
const mapStateToProps = (state: any) => ({
    applicationId: state.opening.applicationId,
    candidatesInReview: state.opening.candidatesInReview,
    currentCandidateInReview: state.opening.currentCandidateInReview,
    loginUser: state.opening.loginUser,
    notificationCenter: state.opening.notificationCenter,
})
const mapPropsToState = (dispatch: Dispatch) => ({
    removeReviewNotificationDotOfReview: (
        payload: notificationServiceEntityDotRemovePayloadType
    ) => dispatch(unread_dot_remover(payload)),
    changeServiceNotificationsCount: (payload: {}) =>
        dispatch(changeServiceNotificationsCountValue(payload)),
});

export default connect(mapStateToProps, mapPropsToState)(DetailedReview)
