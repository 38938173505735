import React from 'react';
import Button from 'antd/es/button';

interface EmailContinueButtonProps {
  loading?: boolean;
  mode: 'signin' | 'signup';
}

export const EmailContinueButton: React.FC<EmailContinueButtonProps> = ({ 
  loading = false,
}) => {
  return (
    <Button
      size="large"
      loading={loading}
      type="primary"
      htmlType="submit"
      className="w-full my-3"
    >
      Continue with email
    </Button>
  );
}; 