import React from "react"

const DragHandle = () => {
    return (
        <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="2" cy="2" r="2" fill="#78787A" />
            <circle cx="2" cy="9" r="2" fill="#78787A" />
            <circle cx="2" cy="16" r="2" fill="#78787A" />
            <circle cx="9" cy="2" r="2" fill="#78787A" />
            <circle cx="9" cy="9" r="2" fill="#78787A" />
            <circle cx="9" cy="16" r="2" fill="#78787A" />
        </svg>

    )
}
export default DragHandle
