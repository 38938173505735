import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import Divider from "antd/es/divider";
import Popover from "antd/es/popover";
import Select from "antd/es/select";
import Space from "antd/es/space";
import Typography from "antd/es/typography";
import {
  JobBoardSummary,
  List,
  Opening,
  OrganizedBoard,
  companyBoards,
} from "../../../type/type";
import {
  listOfBoards,
  storedJdData,
} from "../../../redux/actions/opening/action";
import {
  IconButton,
  LabeledIconButton,
  SecondaryButton,
} from "../../../ant/Button";
import {
  CreateCandidateInPool,
  getJDDataBoardWise,
  getOpeningLists,
  getOpenings,
} from "../../../lib/api";
import { throwSuccess } from "../../../service/throwError";
import CloseOutlined from "@ant-design/icons/CloseOutlined";
import Paragraph from "antd/es/typography/Paragraph";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import TalentPoolIcon from "../../Icons/TalentPoolIcon";
const { Text } = Typography;

const SendCandidateToPool = (props: {
  isBulk: any;
  candidateIds: any;
  openings: OrganizedBoard;
  currentCandidateInEditMode: any;
  clientId: string;
  companyBoard: companyBoards[];
  listOfBoards(boardData: Opening[]): void;
  isCollapsed: any;
  setJdData(jdData: JobBoardSummary[]): void;
  jdData: JobBoardSummary[];
}) => {
  const { isBulk, currentCandidateInEditMode } = props;
  const [selectedWorkspace, setSelectedWorkspace] = useState<
    string | undefined
  >(undefined);
  const [selectedBoard, setSelectedBoard] = useState<string>("");
  const [boardLists, setBoardLists] = useState<List[]>([]);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [loader, showLoader] = useState<boolean>(false);

  useEffect(() => {
    if (open)
      Promise.all([getOpenings(), getJDDataBoardWise()]).then(
        ([openings, jdData]) => {
          props.listOfBoards(openings);
          props.setJdData(jdData);
        }
      );
    else {
      setSelectedBoard("");
      setSelectedWorkspace("");
    }
  }, [open]);

  useEffect(() => {
    if (selectedBoard) {
      getOpeningLists(selectedBoard).then((res: List[]) => {
        if (res.length == 0) {
          setError(true);
        } else {
          setError(false);

          setBoardLists(res);
        }
      });
    }
  }, [selectedBoard]);

  const handleWorkspaceChange = (value: string) => {
    setSelectedWorkspace(value);
  };

  const handleBoardChange = (value: string) => {
    setSelectedBoard(value);
  };
  const createReviews = () => {
    showLoader(true);
    const foundJD: any = props.jdData.find(
      (item: any) => item.board_id === selectedBoard
    );
    const req = {
    //   candidates: isBulk
    //     ? candidateIds
    //     : [currentCandidateInEditMode.candidateId.id],
    //   opening_id: selectedBoard,
      jd: foundJD.jd_id || "",
    };

    CreateCandidateInPool(req, currentCandidateInEditMode.candidateId.id)
      .then((res) => {
        if (res) {
          showLoader(false);
        //   if (req.candidates.length > 1) {
        //     throwSuccess(
        //       `Candidates have been forwarded to the tracker ${props.openings.pageSection.openings[selectedBoard].name}.`
        //     );
        //   } else {
            throwSuccess(
              `Candidate have been forwarded to the ${props.openings.pageSection.openings[selectedBoard].name} pool.`
            );
        //   }
          hide();
        }
      })
      .catch(() => {
        showLoader(false);
      });
  };
  const hide = () => {
    setOpen(false);
  };
  return (
    <Popover
      open={open}
      title={
        <div className="flex w-full bg-white justify-between items-center">
          <div></div>
          <Text>{"Send to pool"}</Text>
          <LabeledIconButton
            type="text"
            label=""
            onClick={hide}
            icon={<CloseOutlined />}
          />
        </div>
      }
      content={
        <div className="w-72">
          <Divider className="my-2" />
          <Space direction="vertical">
            <div>
              <Text>{"Select workspace"}</Text>
              <div className="w-full">
                <Select
                  showSearch
                  placeholder="Select workspace"
                  value={selectedWorkspace}
                  onChange={handleWorkspaceChange}
                  className="w-full"
                  filterOption={(input, option: any) =>
                    option?.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {Object.values(props.companyBoard).map((workspace) => (
                    <Select.Option
                      key={workspace.company_id}
                      value={workspace.company_id}
                    >
                      {workspace.company_name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="w-full">
              <Text>{"Select job post"}</Text>
              <div>
                <Select
                  showSearch
                  placeholder="Select job post"
                  value={selectedBoard}
                  onChange={handleBoardChange}
                  className="w-72"
                  filterOption={(input, option: any) =>
                    option?.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {/* Render board options based on selected workspace */}
                  {selectedWorkspace &&
                    Object.values(props.companyBoard)
                      .find(
                        (workspace) =>
                          workspace.company_id === selectedWorkspace
                      )
                      ?.boards.map((board) => {
                        const foundJD: any = props.jdData.find(
                          (item: any) => item.board_id === board
                        );
                        return (
                          <Select.Option
                            key={props.openings.pageSection.openings[board].id}
                            value={
                              props.openings.pageSection.openings[board].id
                            }
                            disabled={!foundJD} // Disable if JD ID is not found
                          >
                            {props.openings.pageSection.openings[board].name}
                          </Select.Option>
                        );
                      })}
                </Select>
              </div>
            </div>
            {selectedBoard && boardLists ? (
              <>
                <Paragraph style={{ fontSize: "13px" }}>
                  {capitalizeFirstLetter(
                    `candidate will be added in the job post pool`
                  )}{" "}
                  <Text strong style={{ fontSize: "13px" }}>
                    {props.openings.pageSection.openings[selectedBoard].name}
                  </Text>
                  .
                  <br />
                  <Text style={{ fontSize: "13px" }}>
                    {capitalizeFirstLetter(
                      "with all their details, attachments and labels automatically"
                    )}
                  </Text>
                </Paragraph>
              </>
            ) : null}
            <div>
              {error && <div style={{ color: "red" }}>List is empty</div>}
            </div>
            <div className="justify-end flex">
              <LabeledIconButton
                loading={loader}
                label="Send"
                type="primary"
                onClick={createReviews}
                disabled={!selectedWorkspace || !selectedBoard}
              />
            </div>
          </Space>
        </div>
      }
      onOpenChange={(open) => setOpen(open)}
      trigger={"click"}
      placement="bottom"
      className="w-full"
    >
      {!isBulk ? (
        <SecondaryButton
          onClick={() => {}}
          className="w-full truncate left-align-button"
          icon={<TalentPoolIcon />}
        >
          Send to pool
        </SecondaryButton>
      ) : (
        <IconButton
          tooltipTitle="Send to pool"
          icon={<TalentPoolIcon />}
          className="h-7 w-7"
          onClick={(e: {
            preventDefault: () => void;
            stopPropagation: () => void;
          }) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        />
      )}
    </Popover>
  );
};

const mapStateToProps = (state: {
  opening: {
    currentCandidateInPool: any;
    currentOpening: any;
    openings: any;
    currentCandidateInEditMode: any;
    clientId: any;
    companyBoards: any;
    companies: any;
    jdData: any;
  };
}) => ({
  openings: state.opening.openings,
  currentCandidateInEditMode: state.opening.currentCandidateInEditMode,
  clientId: state.opening.clientId,
  companyBoard: state.opening.companyBoards,
  jdData: state.opening.jdData,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  listOfBoards: (boardData: Opening[]) => dispatch(listOfBoards(boardData)),
  setJdData: (jdData: JobBoardSummary[]) => dispatch(storedJdData(jdData)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SendCandidateToPool);
