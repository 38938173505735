import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { OWNER, SOCKET_CLIENT_ID, roles } from "../../../constant"
import { createOpeningMembership } from "../../../lib/api";
import { AddMemberReq } from "../../../lib/apiReq";
import { Role } from "../../../lib/apiRes";
import { addMemberOnBoard } from "../../../redux/actions/opening/action";
import {
  currentApplicant,
  Member,
  KeyWithArray,
  orgMember,
  Opening,
  OpeningMembers,
  Scope,
} from "../../../type/type.d";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import DropdownWithDesc from "../../common/customDropdowm/DropdownWithDesc"
import SearchedResultPopup from "./SearchedResultPopup";
import ShowMeberInInput from "./ShowMeberInInput";
import { Overlay } from "../../hook/Overlay";
import Checkbox from 'antd/es/checkbox';
import Divider from 'antd/es/divider';
import Tooltip from 'antd/es/tooltip';
import { orgMemberRoleVisibility } from "../../JdManagement/Types";
import { LabeledIconButton } from "../../../ant/Button";
export type AddBoardMemberPopupProps = {
  currentOpening: Opening;
  members: orgMember;
  openingMembers: OpeningMembers;
  currentApplicant: currentApplicant;
  addMember(members: Member[], OpeningID: string): void;
  applicantMembers: KeyWithArray;
  applicationId: string;
  clientId: string;
  // refer: any;
  click: () => void;
  orgRoles: Role[];
};
const AddBoardMemberPopup = (props: AddBoardMemberPopupProps) => {
  const [isSecretVisible, setSecretVisible] = useState<boolean>(false);
  const [isRoleOpen, openRolePopup] = useState(false);
  const refer=useRef(null);
  const [selectedRole, selectRole] = useState<string>("member");
  const inputRef = useRef<HTMLInputElement>(null);
  const [checked, setChecked] = useState<any[]>([]);
  const [memberwithVisibility,setMemberwithVisibility] =useState<orgMemberRoleVisibility[]>([]);
  const [searchedMember, getSearchedMember] = useState("");
  const [loading, setLoading] = useState(false);
  let orgMembers = Object.values(props.members).filter(
    (member: Member) =>
      member.registrations[0].data.activated &&
      (member.first_name.toUpperCase().includes(searchedMember.toUpperCase()) ||
        member.last_name.toUpperCase().includes(searchedMember.toUpperCase()) ||
        member.email.toUpperCase().includes(searchedMember.toUpperCase()) ||
        member.username.toUpperCase().includes(searchedMember.toUpperCase()))
  );
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = "";
      inputRef.current.focus();
      getSearchedMember("");
    }
  }, [checked]);
  const setRole = (role: string) => {
    if (role == roles.ADMIN) { setSecretVisible(true) }
    else setSecretVisible(false)
    selectRole(role);
    openRolePopup(!isRoleOpen);
  };

  const searchKeyword = (e: any) => {
    getSearchedMember(e.target.value);
  };
  const handleInputChange = (member: Member) => {

    var updatedList = [...checked];
    if (!checked.includes(member.id)) {
      updatedList = [...checked, member.id];
    }
    setMemberwithVisibility([...memberwithVisibility, {
      id: member.id,
      role: member.role,
      is_secrete_visible: isSecretVisible,
    }]);
    setChecked(updatedList);
  };
  const removeMemberFromSelectedList = (index: number) => {
    setChecked((a: Member[]) => {
      a.splice(index, 1);
      return [...a];
    });
  };

  const AddMemberOnBoard = () => {
    let clientId=sessionStorage.getItem(SOCKET_CLIENT_ID)
    if (checked.length > 0 ) {
      setLoading(true)
      const req: AddMemberReq = {
        id: props.currentOpening.id,
        members: memberwithVisibility,
        role: selectedRole,
        is_secrete_visible: isSecretVisible,
        socket_client_id: clientId?clientId:"",
      };
      createOpeningMembership(req)
        .then((res: Member[]) => {
          props.addMember(res, props.currentOpening.id);
          setLoading(false)
          props.click();
          setChecked([]);
          selectRole("member");
        })
    
    } else {
      inputRef.current && inputRef.current.focus();
    }
  };
  useEffect(() => {
    if (inputRef.current && inputRef.current.value.trim() == "") {
      inputRef.current.focus();
    }
  }, [inputRef.current]);
  const handleCheckboxChange = (e: any) => {
    // Update the isSecretVisible state based on the checkbox's checked state
    setSecretVisible(e.target.checked)
  };
  if (!props.members) return null;
  return (
    <React.Fragment>
      <Divider className="my-2" />

      <div
        className="w-96"
        // ref={props.refer}
      >

        <div className="justify-center items-center flex">
          <div className="w-full relative m-2">
            <div
              className={`rounded inputCSSOnlyForFocus ${
                inputRef.current
                  ? "border border-tacitbase-secondary3"
                  : "border border-tacitbase-tertiary1 border-opacity-25"
              }  flex flex-wrap items-center w-full `}
            >
              <div
                className={`${
                  checked.length == 0 ? "w-full" : ""
                } flex px-2 pb-1 flex-wrap`}
              >
                {checked
                  ? checked.map(
                      (memberID: string, index: number) =>
                        memberID && (
                          <ShowMeberInInput
                            key={index}
                            index={index}
                            memberId={memberID}
                            removeMemberFromSelectedList={(idx: number) =>
                              removeMemberFromSelectedList(idx)
                            }
                          />
                        )
                    )
                  : null}
                <input
                  type="text"
                  className={`${
                    checked.length == 0 ? "w-full" : ""
                  } placeholder-italic outline-none text-sm mt-1`}
                  placeholder={"Add email or name"}
                  onChange={(e: any) => searchKeyword(e)}
                  ref={inputRef}
                  onBlur={() => inputRef.current?.blur()}
                />
              </div>
            </div>

            {searchedMember.trim() ? (
               <Overlay onClose={() => getSearchedMember("")
              } scope={Scope.Popover} reference={refer} >
              <SearchedResultPopup
              refer={refer}
                click={(member: Member) => handleInputChange(member)}
                selectedMember={checked}
                searchResult={orgMembers}
                searchInObject={props.openingMembers}
                searchInObjectKey={props.currentOpening.id}
              /> </Overlay>
            ) : null}
          </div>
        </div>

        <div className="flex-col flex space-y-1 mx-2">
          <div className=" flex text-xs text-black space-x-1 items-center font-normal">
            <span>{capitalizeFirstLetter("role")}</span>
            <Tooltip title="You can define the level of access and responsibility for board members." data-testid="your-tooltip1-test-id">
              <i className="fa fa-info-circle" aria-hidden="true" data-testid="your-icon1-test-id"></i>
             </Tooltip>
          </div>
          <div className="flex justify-between items-center">
            <DropdownWithDesc
              selectedItem={(item: string) => setRole(item)}
              options={
                props.orgRoles
                  ? props.orgRoles.filter(
                      (role: Role) => role.name !== capitalizeFirstLetter(OWNER)
                    )
                  : []
              }
              selectedRole={selectedRole}
            />

            <Checkbox
              onChange={handleCheckboxChange}
              disabled={selectedRole === roles.ADMIN}
              checked={isSecretVisible}
            >
              {capitalizeFirstLetter("secrets visible")} &nbsp;
              <Tooltip title="You can decide on the visibility, determining whether secret fields are visible."  data-testid="your-tooltip-test-id">
                <i className="fa fa-info-circle" aria-hidden="true" data-testid="your-icon-test-id">
              </i></Tooltip>
            </Checkbox>
          </div>
        </div>
        <div className="justify-start flex  mx-1 my-2">
          <LabeledIconButton type="primary" label="Add" className="submit-btn text-xs " onClick={AddMemberOnBoard} loading={loading}/>
        </div>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  members: state.opening.members,
  openingMembers: state.opening.openingMembers,
  currentApplicant: state.opening.currentApplicant,
  applicantMembers: state.opening.applicantMembers,
  applicationId: state.opening.applicationId,
  clientId: state.opening.clientId,
  orgRoles: state.opening.orgRoles,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  addMember: (members: Member[], OpeningID: string) =>
    dispatch(addMemberOnBoard(members, OpeningID)),
});
export default connect(mapStateToProps, mapPropsToState)(AddBoardMemberPopup);
