import React from "react";
import { connect } from "react-redux"
import { initMembership } from "../../../redux/actions/opening/action";
import { Dispatch } from "redux";
import TacitbaseAvtar from "../../../ant/Avtar"
import { CardMemberProps } from "../../../type/card";
import Avatar from 'antd/es/avatar';
import Divider from 'antd/es/divider';
import { generateTooltipForMember } from "../../../utils/capitalizeFirstLetter"

const CardMember = (props: CardMemberProps) => {
  if (!props.applicantMembers[props.currentApplicant.id]) return null;
  const creatorId = props.currentOpening.member_id
  const isCreatorActivated = creatorId && props.members[creatorId] && props.members[creatorId].registrations[0].data.activated

  const membersWithoutCreator = props.applicantMembers[props.currentApplicant.id]
    .filter((memberId: string) => props.members[memberId] && memberId !== creatorId)
    .map((memberId: string) => (
      <TacitbaseAvtar
        key={props.members[memberId].id}
        toolTip={generateTooltipForMember(props.members[memberId])}
        src={props.members[memberId].image_url}
        size="default"
        content={props.members[memberId].first_name[0] + props.members[memberId].last_name[0]}
      />
    ))

  return (
    <React.Fragment>
      <div className="flex items-center mb-0 px-4 pt-2">
        {isCreatorActivated && (
          <Avatar.Group>
            <TacitbaseAvtar
              toolTip={generateTooltipForMember(props.members[creatorId])}
              src={props.members[creatorId].image_url}
              size="default"
            />
          </Avatar.Group>
        )}
        {membersWithoutCreator.length > 0 && (
          <>
            <Divider type="vertical" style={{ borderColor: "black" }} />
            <Avatar.Group maxCount={4} size={"default"}>{membersWithoutCreator}</Avatar.Group>
          </>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  currentApplicant: state.opening.currentApplicant,
  members: state.opening.members,
  applicantMembers: state.opening.applicantMembers,
  applicationId: state.opening.applicationId,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  initMembers: (members: any) => dispatch(initMembership(members)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardMember)
