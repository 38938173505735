import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { EMAIL_TAG } from "../../../constant";
import { addMailOnCard } from "../../../redux/actions/opening/action";
import {
  currentApplicant,
  loginUser,
  orgMember,
  SendMail,
} from "../../../type/type.d";
import ComposeMail from "../../common/composeMail/NewComposeMail";
import { OrgDomain } from "../../../redux/reducers/initialState";
import DomainModel from "../../../pages/setting/billingSetting/DomainModel";
import { IconButton } from "../../../ant/Button";
import Modal from "antd/es/modal";
import MailIcon from "../../Icons/MailIcon";

type CardEmailAndCommentType = {
  loginUser: loginUser;
  currentApplicant: currentApplicant | any;
  addMailOnCard: (data: SendMail, id: string, tag: string) => void;
  members: orgMember;
  defaultDomain: OrgDomain;
};
const CardEmailAndComment = (props: CardEmailAndCommentType) => {
  const [isEmailSet, setEmailPanal] = useState<boolean>(false);
  const [openDomainPopup, setOpenDomainPopup] = useState(false);
  const refer = useRef(null);
  const showEmail = () => {
    const domainID = Object.keys(props.defaultDomain)[0];
    if (domainID && !props.defaultDomain[domainID].custom) {
      setOpenDomainPopup(true);
      return;
    }
    setEmailPanal(true);
  };
  const closeAll = () => {
    setEmailPanal(false);
  };
  const addMail = (res: SendMail) => {
    props.addMailOnCard(res, props.currentApplicant.id, EMAIL_TAG);
  };
  const reqObj = {
    ...props.currentApplicant.source,
    ["id"]: props.currentApplicant.id,
  };

  return (
    <>
      <div className="w-full bg-transparent">
        <IconButton
          icon={<MailIcon />}
          onClick={(e) => {
            e.stopPropagation();
            showEmail();
          }}
          type="text"
          tooltipTitle="Send mail"
          size="small"
        />
      </div>
      {props.currentApplicant.email && (
        <>
          <Modal
            visible={isEmailSet}
            onCancel={() => {
              setEmailPanal(false);
            }}
            footer={null}
            closable={false}
            width={900} // Adjust the width as per your requirement
            getContainer={false}
            className="mailBox"
            centered
          >
            <ComposeMail
              refer={refer}
              service="ats"
              entity="applicants"
              close={closeAll}
              record_id={[reqObj]}
              toAddress={[props.currentApplicant.email]}
              reduxActionCall={(res: SendMail) => addMail(res)}
              getJobDescription={() => {}}
              getDisableState={() => {}}
            />
          </Modal>
        </>
      )}
      <DomainModel
        isModalVisible={openDomainPopup}
        handleCancel={() => setOpenDomainPopup(false)}
      />
    </>
  );
};
const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  members: state.opening.members,
  defaultDomain: state.opening.defaultDomain,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  addMailOnCard: (data: SendMail, id: string, tag: string) =>
    dispatch(addMailOnCard(data, id, tag)),
});
export default connect(mapStateToProps, mapPropsToState)(CardEmailAndComment);
