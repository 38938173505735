import React from "react";

const ZoomSvg: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="30"
      height="30"
      viewBox="0 0 48 48"
    >
      <circle cx="24" cy="24" r="20" fill="#1565C0" />
      <path
        fill="#fff"
        d="M18,16h6c2.21,0,4,1.79,4,4v8c0,2.21-1.79,4-4,4h-6c-2.21,0-4-1.79-4-4v-8C14,17.79,15.79,16,18,16z"
      />
      <polygon fill="#fff" points="30,20 30,28 38,32 38,16" />
    </svg>
  );
};

export default ZoomSvg;
