import   FilterOutlined  from "@ant-design/icons/FilterOutlined";
import Badge from "antd/es/badge";
import Button from "antd/es/button";
import Divider from "antd/es/divider";
import Form from "antd/es/form";
import Popover from "antd/es/popover";
import Select from "antd/es/select";
import Tooltip from "antd/es/tooltip";
import React, { useEffect, useState } from "react";
import GenericSelect from "./GenericSelect";
import { Filter } from "./AnalyticsMainPage";
import { connect } from "react-redux";
import { getOpenings } from "../../lib/api";
import getOrgMembers from "../../utils/getOrgMember";
import { loginUser } from "../../type/type";
const { Option } = Select;

interface AnalyticsFilterPopupProps {
  onFilterChange: (value: Filter) => void;
  resetFilter: boolean;
  loginUser:loginUser;
}

 function AnalyticsFilterPopup(props: AnalyticsFilterPopupProps) {
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedWorkspace, setSelectedWorkspace] = useState([]);
  const [selectedBoard, setSelectedBoard] = useState([]);
  const [selectedMember, setSelectedMember] = useState([]);
  const [filterCount, setFilterCount] = useState(0);
  const [open, setOpen] = useState(false);
  const[openingData,setopeningData]=useState<any[]>([]);

  useEffect(() => {
    // Calculate total filter count whenever any filter changes
    setFilterCount(
      selectedWorkspace.length + selectedBoard.length + selectedMember.length
    );
  }, [selectedWorkspace, selectedBoard, selectedMember]);

  useEffect(() => {
    if (props.resetFilter) {
      setSelectedFilter(null);
      setSelectedWorkspace([]);
      setSelectedBoard([]);
      setSelectedMember([]);
      props.onFilterChange({
        entity_type: "",
        entity_names: [],
      });
    }
  }, [props.resetFilter]);

  const handleOpenChange = (
    newOpen: boolean | ((prevState: boolean) => boolean)
  ) => {
    setOpen(newOpen);
  };

  const handleFilterChange =(value: any) => {
    setSelectedFilter(value); 
  };

  

  useEffect(() => {
    
    async function fetchData() {
      try{
        if (open==true) {

      const OpeningData = await getOpenings();
      setopeningData(OpeningData);
        }
      }
      catch{
      console.log("workspace not fetched");
      }
    }
    fetchData();
    
  }, [open]);

  const handleWorkspaceChange = (value: any) => {
    setSelectedWorkspace(value);
    // If selected filter is workspace, immediately send the value to onFilterChange
    if (selectedFilter === "workspace_id") {
      setSelectedBoard([]);
      setSelectedMember([]);
      const filterData = {
        entity_type: selectedFilter,
        entity_names: value,
      };
      if (filterData.entity_names.length > 0) {
        props.onFilterChange(filterData);
      } else {
        props.onFilterChange({
          entity_type: "",
          entity_names: [],
        });
      }
      
    }
  };

  const handleBoardChange = (value: any) => {
    setSelectedBoard(value);
    // If selected filter is board, immediately send the value to onFilterChange
    if (selectedFilter === "board_id") {
      setSelectedWorkspace([]);
      setSelectedMember([]);

      const filterData = {
        entity_type: selectedFilter,
        entity_names: value,
      };
      if (filterData.entity_names.length > 0) {
        props.onFilterChange(filterData);
      } else {
        props.onFilterChange({
          entity_type: "",
          entity_names: [],
        });
      }
    }
  };

  const handleMemberChange = (value: any) => {
    setSelectedMember(value);
    // If selected filter is board, immediately send the value to onFilterChange
    if (selectedFilter === "creator_id") {
      setSelectedWorkspace([]);
      setSelectedBoard([]);
      const filterData = {
        entity_type: selectedFilter,
        entity_names: value,
      };
      if (filterData.entity_names.length > 0) {
        props.onFilterChange(filterData);
      } else {
        props.onFilterChange({
          entity_type: "",
          entity_names: [],
        });
      }
    }
  };

  const handleClearAll = () => {
    setSelectedFilter(null);
    setSelectedWorkspace([]);
    setSelectedBoard([]);
    setSelectedMember([]);

    // Clear all filters
    props.onFilterChange({
      entity_type: "",
      entity_names: [],
    });
    setOpen(false);
  };


const FilterMemberArray = getOrgMembers();
const loginUser ={first_name:props.loginUser.user.firstName,last_name:props.loginUser.user.lastName,...props.loginUser.user}

const FilterWorkspaceArray = [];

// Iterate over each object in the data and extract first_name and id
for (const key in openingData) {
    const { company_id, company_name } = openingData[key];
    FilterWorkspaceArray.push({ company_id, company_name });
}


const FilterBoardArray: { id: string, name: string }[] = [];

openingData.forEach(company => {
  const { boards } = company;
  
  boards.forEach((board: { id: string; name: string; }) => {
    const { id, name } = board;
    FilterBoardArray.push({ id, name });
  });
});
const FilterMemberArrayWithLoginUser = [loginUser, ...FilterMemberArray];

  return (
    <Popover
      title={
        <div className="h-full w-full">
          <div className="flex justify-between items-center pl-3">
            <div>Filter</div>
            <Button type="link" onClick={handleClearAll} style={{color:'#4B48A5'}}>
              Clear All
            </Button>
          </div>
          <Divider style={{ margin: "8px 0" }} />
        </div>
      }
      content={
        <div style={{ width: 300, minHeight: 100, overflowY: "auto" }}>
          <Form layout="vertical">
            <Form.Item
              name="filter_name"
              label="Select Filter"
              style={{ marginTop: 16 }}
            >
              <Select
                value={selectedFilter}
                onChange={handleFilterChange}
                showSearch
                style={{ width: 300, height: 30 }}
                popupClassName="filter-dropdown" // Apply custom class to the dropdown container
                placeholder="Select Filter"
              >
                <Option value="workspace_id">Workspace</Option>
                <Option value="board_id">Job post</Option>
                <Option value="creator_id">Member</Option>
              </Select>
            </Form.Item>
            {selectedFilter && (
              <div>
                {selectedFilter === "workspace_id" && (
                  <div>
                    <Form.Item
                      name="workspace_id"
                      label="Select workspace"
                      style={{ marginTop: 16 }}
                    >
                      <GenericSelect
                        options={FilterWorkspaceArray.map((option) => ({
                          label: option.company_name,
                          value: option.company_id,
                        }))}
                        dropdownStyle={{ maxWidth: "100%" }}
                        onChange={handleWorkspaceChange}
                        Placeholder="Please select"
                      />
                    </Form.Item>
                  </div>
                )}
                {selectedFilter === "board_id" && (
                  <div>
                    <Form.Item
                      name="board_name"
                      label="Select job post"
                      style={{ marginTop: 16 }}
                    >
                      <GenericSelect
                        options={FilterBoardArray.map((option) => ({
                          label: option.name,
                          value: option.id,
                        }))}
                        dropdownStyle={{ maxWidth: "100%" }}
                        onChange={handleBoardChange}
                        Placeholder="Please select"
                      />
                    </Form.Item>
                  </div>
                )}
                {selectedFilter === "creator_id" && (
                  <div>
                    <Form.Item
                      name="mamber_name"
                      label="Select Member"
                      style={{ marginTop: 16 }}
                    >
                      <GenericSelect
                        options={FilterMemberArrayWithLoginUser.map((option) => ({
                              label: option.first_name+" "+option.last_name,
                              value: option.id,
                            }))}
                        dropdownStyle={{ maxWidth: "100%" }}
                        onChange={handleMemberChange}
                        Placeholder="Please select"
                      />
                    </Form.Item>
                  </div>
                )}
              </div>
            )}
          </Form>
        </div>
      }
      trigger="click"
      placement="bottomRight"
      open={open}
      onOpenChange={handleOpenChange}
    >
      <Tooltip title="Apply filter">
      <Button
        type="default"
        icon={<FilterOutlined   />}
        size="small"
        style={{height:'28px',minWidth:'28px'}}
      >
         
        {filterCount > 0 && 

        <Badge   
         className="site-badge-count-109"
        count={filterCount}
        style={{ backgroundColor: '#4B48A5'}}
      />
    }
      </Button>    
      </Tooltip>
    </Popover>
  );
}



const mapStateToProps = (state: any) => ({
  loginUser:state.opening.loginUser,  
});

export default connect(mapStateToProps)(AnalyticsFilterPopup);
