import React, { useState } from "react";
import Dropdown from 'antd/es/dropdown';
import Menu from 'antd/es/menu';
import Popover from 'antd/es/popover';
import Typography from 'antd/es/typography';
import Tag from 'antd/es/tag';
import Tooltip from 'antd/es/tooltip';
import MoreOutlined from "@ant-design/icons/MoreOutlined"
import { renderCreatedAt } from "../../../utils/renderCreatedAt";
import { ESignDocument } from "../../../type/type";
import { GetDocumentStatus, RecipientStatus } from "./GetDocumentStatus";
import GetDocumentActionByTool from "./GetDocumentActionByTool";

const { Text } = Typography;

type GetDocumentByIdProps = {
  doc: ESignDocument;
  tracker_id?:string;
  applicant_id?:string;
  handleMessage?:(e:MessageEvent)=>void;
};

const GetDocumentById = (props: GetDocumentByIdProps) => {
  const [showAllRecipients, setShowAllRecipients] = useState(false);

  const recipientsContent = (
    <div className="max-w-sm p-2">
      <Text strong className="block mb-2">
        All Recipients
      </Text>
      <div className="space-y-2">
        {props.doc?.recipients?.map((recipient, index) => (
          <div key={index} className="flex items-center justify-between gap-4">
            <Text className="truncate" style={{ minWidth: 0 }}>
              {recipient.email}
            </Text>
            <RecipientStatus status={recipient.status} />
          </div>
        ))}
      </div>
    </div>
  );

  const renderMenu = (doc:any) => (
    <Menu>
      <div
        className="flex flex-col items-start space-y-2 p-2"
        role="button"
        tabIndex={0}
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => {
          if (e.keyCode === 13 || e.metaKey) {
            e.stopPropagation();
          }
        }}
      >
        <GetDocumentActionByTool
         tracker_id={props.tracker_id} applicant_id={props.applicant_id}
          doc={doc} handleMessage = {props.handleMessage} />
        </div>
    </Menu>
  );

  return (
    <div className="w-full max-w-2xl mx-auto p-4 border rounded-lg space-y-3 bg-white">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <Text className="text-base font-medium m-0">
            {props.doc?.sources?.[0]?.name || "No source available"}
          </Text>
        </div>
        <div className="flex items-center justify-center gap-2">
          <GetDocumentStatus documentStatus={props.doc.status} />
          {(props.doc?.status !== "canceled" && props.doc?.status !== "voided" && props.doc?.status !== "recalled")  &&(
            <Dropdown
              overlay={renderMenu(props.doc)}
              placement="bottomRight"
              trigger={["click"]}
            >
              <MoreOutlined className="text-lg cursor-pointer hover:bg-gray-100 p-2 rounded-full" />
            </Dropdown>
          )}
        </div>
      </div>

      <div className="flex items-center gap-2 text-sm">
        <Text strong className="flex-shrink-0">
          Owner:
        </Text>
        <Tooltip title={`${props.doc?.owner_email}`}>
          <Text type="secondary" className="truncate">
            {props.doc?.owner_email}
          </Text>
        </Tooltip>
      </div>

      <div className="flex gap-2 text-sm">
        <Text strong className="flex-shrink-0">
          Recipients:{" "}
        </Text>
        <Popover
          content={recipientsContent}
          trigger="click"
          open={showAllRecipients}
          onOpenChange={setShowAllRecipients}
          placement="right"
        >
          <div className="cursor-pointer flex flex-col w-10/12 gap-1">
          {props.doc?.recipients?.slice(0, 2).map((recipient, index) => (
              <div key={index} className="grid w-full" style={{
                gridTemplateColumns: "60% max-content",
                alignItems: "center"
              }}
         >
                <Text type="secondary" className="truncate">
                  {recipient?.email}
                </Text>
                <RecipientStatus status={recipient.status} />
              </div>
            ))}
            {props.doc?.recipients?.length > 2 && (
              <Tag className="cursor-pointer hover:bg-blue-50 self-start">
                +{props.doc?.recipients.length - 2} more
              </Tag>
            )}
          </div>
        </Popover>
      </div>
      {props.doc?.status !== "draft" &&(<div className="flex flex-wrap gap-x-4 gap-y-2 text-sm text-gray-500">
        <div className="flex items-center gap-1">
          <Text strong>Created:</Text>
          <Text type="secondary">
            {renderCreatedAt(props.doc?.created_time)}
          </Text>
        </div>
        <div className="flex items-center gap-1">
          <Text strong>Modified:</Text>
          <Text type="secondary">
            {renderCreatedAt(props.doc?.last_modified_time)}
          </Text>
        </div>
        <div className="flex items-center gap-1">
          <Text strong>Expires:</Text>
          <Text type="secondary">
            {renderCreatedAt(props.doc?.expiry_time)}
          </Text>
        </div>
      </div>)}
    </div>
  );
};

export default GetDocumentById;
