import ConfigProvider from "antd/es/config-provider";
import Divider from "antd/es/divider";
import Input from "antd/es/input";
import Typography from "antd/es/typography";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react"
import FormItem from "../../ant/FormItem";
import { connect } from "react-redux";
import { Jd } from "../../type/type";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import Editor from "../../ant/Editor/Editor";
import {  jdInfo } from "../../constant"
import { LabeledIconButton, UpgradeButton } from "../../ant/Button";
import GPT from "../../assets/GPT";
import AIModal from "./GPTResponce";
import UpgradePlanModal from "../../pages/setting/billingSetting/UpgradePlanModal";
import Read from "../../Read";
import { newOrganisation } from "../../lib/apiRes";
import { useOrgMetadata } from "../../pages/setting/billingSetting/OrgMetadata";

type JDSummaryProps = {
  JDFormData: Jd;
  onChangeField: (job: { [key: string]: string }) => void;
  currentOrg:newOrganisation;
};
const { Text } = Typography;

const JDSummary = (props: JDSummaryProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [updatedJd, setAiJd] = useState("");
  const [description, setDescription] = useState<string>("")
  const orgMetadata = useOrgMetadata(props.currentOrg);
  useEffect(() => {
    setDescription(props.JDFormData.description)
  }, [props.JDFormData])
  const customModules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, false] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      // ['clean']
    ],
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const customFormats = [
    "header",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
  ];
  const showModal = () => {
    // setModalContent(props.JDFormData.description);
    setIsModalVisible(true);
  };

  const handleOk = (aiContent: string) => {
    setIsModalVisible(false);
    setAiJd(aiContent);
    setDescription(aiContent);
    props.onChangeField({ description: aiContent });
  };

  const onEditorChange = (content: string) => {
    setDescription(content)
    props.onChangeField({ description: content })
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleBillingModal = ()=>{
    setIsVisible(true);
  }
  const handleBillingModalCancel = () => {
    setIsVisible(false);
};
  return (
    <>
      <div className="mr-4">
        <Title level={5} className="pt-2">
          {"Job post"}
        </Title>
        <Divider style={{ margin: 0 }} className="pb-3" />
        <div className="flex w-full">
          <div className="w-full flex  2xl:w-2/5 xl:w-1/2 gap-8 xs:w-full  pr-2">
            <div className="w-full">
            <FormItem
              name={"title"}
              label={capitalizeFirstLetter("job post title")}
              rules={[
                {
                  required: true,
                  message: capitalizeFirstLetter("job title required"),
                },
              ]}
            >
              <Input
                onChange={() => {}}
                showCount
                maxLength={50}
                value={updatedJd}
                // defaultValue={props.JDFormData.title}
              />
              </FormItem>
            </div>
          </div>

        </div>
        <FormItem
          name="description"
          label={capitalizeFirstLetter("description")}
        >
          <div className="jd-editor-description border rounded mb-2">
            <Editor
              initialValue={description}
              defaultValue={description}
              value={description}
              onChange={(content: string) => onEditorChange(content)}
              modules={customModules}
              formats={customFormats}
              placeholder="Type your job description here..."
            />
            <div className="p-2 justify-end flex">
            <Read>
              <ConfigProvider
                theme={{
                  components: {
                    Button: {
                      colorPrimary: "#009D79",
                      colorPrimaryHover: "#009D79",
                      colorPrimaryActive: "#009D79"
                    },
                  },
                }}
              >
                {
                     
                  orgMetadata?.ai_feature  === 'true' ?(
                  <LabeledIconButton
                  label="Create with AI"
                  icon={<GPT />}
                  type="primary"
                  size="small"
                  onClick={showModal}
                />
                  ):(
                  <UpgradeButton label="Generate with AI" 
                  style={{ backgroundColor: "#009D79", color: "#fff" }}
                   onClick={handleBillingModal}
                    />
                    )
                   }
                
              </ConfigProvider>
              </Read>
            </div>
          </div>
        </FormItem>
        <Text className="text-sm ">
          <sup style={{ color: "red" }}>*</sup>{jdInfo.aiWarning}
        </Text>
      </div>
      {isModalVisible &&
        <AIModal
          visible={isModalVisible}
          title={props.JDFormData.title}
          onOk={(aiContent: string) => handleOk(aiContent)}
          onCancel={handleCancel}
        />
      }
      <UpgradePlanModal isModalVisible = {isVisible} handleCancel={handleBillingModalCancel}/>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  openingMembers: state.opening.currentOpening,
  loginUser: state.opening.loginUser,
  topic: state.opening.topicSubscription,
  applicationId: state.opening.applicationId,
  cal_id: state.opening.calendar.currentCalenderId,
  orgStatus: state.opening.orgStatus,
  notificationCenter: state.opening.notificationCenter,
  currentOrg:state.opening.currentOrg,
});

export default connect(mapStateToProps)(JDSummary)
