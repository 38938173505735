import { useState, useEffect } from "react";

const useDesktopWarning = () => {
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setShowWarning(true);
    }
  }, []);

  return { showWarning, setShowWarning };
};

export default useDesktopWarning;
