import React, { useEffect, useState } from "react"
import Select from "antd/es/select";
import Space from "antd/es/space";
import Typography from "antd/es/typography";
import { attachBoardToWorkspace, getCompanies } from "../../../lib/api"
import { Opening, company } from "../../../type/type.d"
import PopupTitle from "../../common/popupTitle/PopupTitle"
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter"
import { LabeledIconButton } from "../../../ant/Button"
import { connect } from "react-redux"
import { Dispatch } from "redux"
import { addBoardToWorkspace } from "../../../redux/actions/opening/action"
import { SOCKET_CLIENT_ID } from "../../../constant"
const { Option } = Select
const { Text } = Typography
type AttachCompanyProps = {
    click?: () => void,
    prev?: () => void,
    close?: (e: boolean) => void,
    currentOpening: Opening,
    addBoardToWorkspace: (company_id: string) => void
}
const AttachCompany = (props: AttachCompanyProps) => {

    const [companyData, setCompanyData] = useState<company[]>([])
    const [selectedCompany, setSelectedCompany] = useState<string>("")

    useEffect(() => {
        getCompanies().then((res) => {
            setCompanyData(res)
        })

    }, [])
    const attachBoard = () => {
        let req = {
            id: props.currentOpening.id,
            company_id: selectedCompany,
            socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),

        }
        attachBoardToWorkspace(req).then((res) => {
            props.addBoardToWorkspace(res.company_id)

        })
    }
    return (

        <React.Fragment>
            <div className="">
                <PopupTitle
                    PopupTitle="workspace"
                    font="text-base font-medium text-black"
                    close={props.close}
                    back={props.prev}
                />
                <div className="mx-4 my-3 space-y-2">

                    {!companyData.some((company) => company.id == props.currentOpening.company_id) ?
                        <>
                            <Space direction="vertical">
                                <Text strong>{capitalizeFirstLetter("This board is uncategorised")}</Text>
                                <Text>{capitalizeFirstLetter("Add workspace")}</Text>
                            </Space>
                            <Select
                                style={{
                                    width: "100%"
                                }}
                                popupMatchSelectWidth={false}
                                placeholder="Select workspace"
                                onChange={(e: string) => setSelectedCompany(e)}
                            >
                                {companyData.map((companyItem: company) => companyItem && (
                                    <Option key={companyItem.id} value={companyItem.id}>{companyItem.name} </Option>
                                ))}
                            </Select>
                            <LabeledIconButton type="primary" onClick={attachBoard} label="change" />
                        </> : <Space direction="vertical"><Text strong>{capitalizeFirstLetter("This board is part of...")}</Text>
                            {companyData.map((company) =>
                                company.id == props.currentOpening.company_id && (
                                    <Text key={company.id} className="bg-gray-200 p-2 rounded"> {company.name}</Text>)
                            )}</Space>
                    }
                </div>            </div>
        </React.Fragment >
    )
}
const mapStateToProps = (state: any) => ({
    currentOpening: state.opening.currentOpening,
    currentApplicant: state.opening.currentApplicant,
    clientId: state.opening.clientId,
})
const mapPropsTostate = (dispatch: Dispatch) => ({
    addBoardToWorkspace: (company_id: string) => dispatch(addBoardToWorkspace(company_id)),
})
export default connect(mapStateToProps, mapPropsTostate)(AttachCompany)
