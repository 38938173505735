import React from "react";
import CalendarList from "./CalendarList";
import SimpleCalendar from "./Minicalendar";
import Divider from "antd/es/divider";
import { Dayjs } from "dayjs";
import { CalendarItem } from "../../../lib/apiRes";

interface CalendarSidebarProps {
  currentDate: Dayjs;
  collapse: boolean;
  onDateChange: (date: Dayjs) => void;
  onCalendarToggle: (calendar: CalendarItem,value:boolean) => void;
  selectedCalendars: string[];
}

const CalendarSidebar: React.FC<CalendarSidebarProps> = ({
  currentDate,collapse,
  onDateChange,
  onCalendarToggle,
  selectedCalendars
}) => {
  const handleDateChange = (date: Dayjs) => {
    onDateChange(date);
  };

  return (
    <div>
      <div
        className=" overflow-y-auto overflow-x-hidden   border-t"
        style={{
          width: collapse ? '0' : 235,
          transition: 'width 0.3s ease',
          scrollbarWidth: 'thin',
          height: 'calc(100vh - 100px)'
        }}

       
      >
        <SimpleCalendar
          value={currentDate}
          onChange={handleDateChange}
          onNavigate={handleDateChange}
        />
        <Divider style={{ margin: "8px 0" }} />

        <CalendarList 
          onCalendarToggle={onCalendarToggle} 
          selectedCalendars={selectedCalendars} 
        />
      </div>
    </div>
  );
};

export default CalendarSidebar;