import React, { lazy } from "react"
const ReviewTable = lazy(() => import("./AntTable"))

import ContentHeader from "../analyticsVersion1/ContentHeader"
import Breadcrumb from "antd/es/breadcrumb";
import Navbar from "../navbar/Navbar"
import SuspenseLoader from "../SuspenseLoader"

const ReviewIndex = () => {
    return (
        <>
<div className="flex flex-col w-full  h-full overflow-hidden rounded" id="parentDiv"> 
      <ContentHeader>
        <div
          className="flex h-12 justify-between items-center px-4 py-3" >
          <div
            className="items-center flex  "          >
            <Breadcrumb
    items={[
      {
        title: 'Reviews',
      }
    ]}
  />
          </div>
          <div className="flex flex-row items-center">
              <Navbar/>

                </div> 
        </div>
      </ContentHeader>
      <div className=" flex flex-col h-full bg-white" >
            
      <SuspenseLoader>
                <ReviewTable />
      </SuspenseLoader>
                
                </div>
            </div>
        </>
    )
}

export default ReviewIndex;
