import React, { useState } from "react";
import { connect } from "react-redux";
import Divider from "antd/es/divider";
import Select from "antd/es/select";
import Typography from "antd/es/typography";
import Popover from "antd/es/popover";
import Space from "antd/es/space";
import Alert from "antd/es/alert";
import CloseOutlined from "@ant-design/icons/CloseOutlined";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import { IconButton, LabeledIconButton } from "../../../ant/Button";
import TextArea from "antd/es/input/TextArea";
import { createReviewReq } from "../../../lib/apiReq";
import {
  CANDIDATE_SEND_TO_REVIEW_SUCCESS,
  CANDIDATES_SEND_TO_REVIEW_SUCCESS,
  SEND_TO_REVIEW_LABEL_WARNING_MESSAGE,
  SOCKET_CLIENT_ID,
} from "../../../constant";
import { createReview } from "../../../lib/api";
import { throwSuccess } from "../../../service/throwError";
import { Member, Registration, loginUser, orgMember } from "../../../type/type";
import TacitbaseAvtar from "../../../ant/Avtar";
import SlackIcon from "../../../assets/SlackIcon";
import "../../../styles/component/style.css";
import getOrgMembers from "../../../utils/getOrgMember";
import ReviewIcon from "../../Icons/ReviewIcon";

const { Text } = Typography;

type SendPoolCandidateToReviewProps = {
  orgMembers: orgMember;
  loginUser: loginUser;
  candidate: any;
};

const SendCDBCandidateToReview = (props: SendPoolCandidateToReviewProps) => {
  const [popoverOpen, setPopoverOpen] = useState(false); // State to control the popover visibility
  const [loading, setLoading] = useState(false); // State to control the loading state
  const [selectedMembers, setSelectedMembers] = useState<string[]>([]); // State to store selected member emails
  const [comment, setComment] = useState(""); // State to store the comment
  const [filteredMembers, setFilteredMembers] = useState<Member[]>( // State to store filtered members based on search
    getOrgMembers()
  );
  const [selectedMemberIds, setSelectedMemberIds] = useState<string[]>([]); // State to store selected member IDs

  // Function to handle review creation
  const createReviews = () => {
    setLoading(true);
    const clientId = sessionStorage.getItem(SOCKET_CLIENT_ID) || "";
    if (selectedMembers.length > 0 && selectedMemberIds.length > 0) {
      const req: createReviewReq = {
        candidates: [props.candidate.id],
        reviewers: selectedMemberIds,
        socket_client_id: clientId,
        comments: comment.trim() ? [{ body: comment.trim() }] : [],
        labels: props.candidate.labels_id,
        // Assign labels array to req object
        //   props.currentCandidateInEditMode?.labels?.map((item: { id: any; }) => item.id)
      };
      createReview(req)
        .then((res) => {
          if (res) {
            setLoading(false);
            if (req.candidates.length > 1) {
              throwSuccess(CANDIDATES_SEND_TO_REVIEW_SUCCESS);
            } else {
              throwSuccess(CANDIDATE_SEND_TO_REVIEW_SUCCESS);
            }
            setSelectedMembers([]);
            setComment("");
            setPopoverOpen(false);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  // Function to close the popover
  const closePopover = () => {
    setPopoverOpen(false);
  };

  // Function to handle search input
  const handleSearch = (query: string) => {
    const searchQuery = query.toLowerCase();
    const filtered = query
      ? Object.values(props.orgMembers).filter(
          (member) =>
            (member?.first_name?.toLowerCase().includes(searchQuery) ||
              member?.last_name?.toLowerCase().includes(searchQuery) ||
              member?.email?.toLowerCase().includes(searchQuery)) &&
            member.registrations[0].data.activated &&
            member.id !== props.loginUser.user.id
        )
      : Object.values(props.orgMembers).filter(
          (member) => member.id !== props.loginUser.user.id
        );
    setFilteredMembers(filtered);
  };

  // Function to handle member selection
  const handleSelectMembers = (values: string[], options: any[]) => {
    const newMemberIds = options.map((option) => option.key);
    setSelectedMemberIds(newMemberIds);
    setSelectedMembers(values);
  };

  return (
    <Popover
      open={popoverOpen}
      title={
        <div className="flex w-full bg-white justify-between items-center">
          <Text>{capitalizeFirstLetter("Send to review")}</Text>
          <LabeledIconButton
            type="text"
            label=""
            onClick={closePopover}
            icon={<CloseOutlined />}
          />
        </div>
      }
      content={
        <div className="w-72">
          <Divider className="my-2" />
          <Space direction="vertical">
            <div>
              <Text>Add reviewer</Text>
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                placeholder="Select members"
                value={selectedMembers}
                virtual={false}
                showSearch
                onChange={handleSelectMembers}
                onSearch={handleSearch}
                filterOption={false}
                tagRender={({ value, closable, onClose }) => {
                  const handleClose = (e: any) => {
                    e.preventDefault();
                    onClose();
                    setSelectedMembers(
                      selectedMembers.filter((member) => member !== value)
                    );
                  };
                  return (
                    <div
                      className={`ant-select-selection-item ${
                        closable ? "" : ""
                      }`}
                    >
                      <div className="ant-select-selection-item-content">
                        {value}
                      </div>
                      {closable && (
                        <span
                          onClick={handleClose}
                          tabIndex={0}
                          role="button"
                          onKeyDown={() => {}}
                        >
                          <CloseOutlined />
                        </span>
                      )}
                    </div>
                  );
                }}
                dropdownRender={(menu) => (
                  <div style={{ maxHeight: 200 }}>{menu}</div>
                )}
              >
                {filteredMembers.map((member) => (
                  <Select.Option key={member.id} value={member.email}>
                    <div className="w-full flex items-center py-0.5 justify-center h-auto cursor-pointer">
                      <Space className="w-full">
                        <TacitbaseAvtar size="default" src={member.image_url} />
                        <div className="flex flex-col w-full justify-between">
                          <span className="space-x-1 flex justify-start items-center">
                            <Text strong>{member.first_name}</Text>
                            <Text strong>{member.last_name}</Text>
                            {member.registrations.some(
                              (registration: Registration) =>
                                registration.data.source === "slack"
                            ) && <SlackIcon size="14" />}
                          </span>
                          <Text className="text-xs font-light">
                            {member.email}
                          </Text>
                        </div>
                      </Space>
                    </div>
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className="w-full mb-4">
              <Text>Comment (optional)</Text>
              <TextArea
                className="w-72"
                onChange={(e) => setComment(e.target.value)}
                maxLength={250}
                showCount
                value={comment}
                placeholder=""
                autoSize={{ minRows: 2, maxRows: 6 }}
              />
            </div>
            <div className="justify-end flex">
              <LabeledIconButton
                loading={loading}
                label="Send"
                type="primary"
                size="small"
                onClick={createReviews}
                disabled={
                  selectedMembers.length <= 0 && selectedMemberIds.length <= 0
                }
              />
            </div>
          </Space>
          { (props?.candidate?.labels?.length <= 0)&&
          <div className="mt-2">
            <Alert message={SEND_TO_REVIEW_LABEL_WARNING_MESSAGE} type="warning" style={{fontSize:'12px'}} showIcon/>

          </div>}
        </div>
      }
      onOpenChange={setPopoverOpen}
      trigger="click"
      placement="bottom"
      className="w-full left-align-button truncate"
    >
      <div className="">
        <IconButton
          size="small"
          icon={<ReviewIcon />}
          tooltipTitle="Send to review"
          type="text"
          onClick={(e) => {
            e.preventDefault(), e.stopPropagation();
            setPopoverOpen(true);
          }}
        />
      </div>
    </Popover>
  );
};

const mapStateToProps = (state: any) => ({
  // currentCandidateInPool: state.opening.currentCandidateInPool,
  loginUser: state.opening.loginUser,
  orgMembers: state.opening.members,
});

export default connect(mapStateToProps)(SendCDBCandidateToReview);
