import React from "react";
import { SEARCH_MEMBER_PLACEHOLDER } from "../../constant"
import Divider from "antd/es/divider";
type MembersOfServicesPopupType = {
  searchKeyword: (e: string) => void;
  closePopup: (e: any) => void;
  // refer: any;
  children: any;
};
const MembersOfServicesPopup = (props: MembersOfServicesPopupType) => {
  return (
    <React.Fragment>
      <Divider className="my-2" />

      <div
        className="w-72 bg-white"
      >
        <div className="m-2">
          <input
            type="text"
            data-testid="searchMember"
            className="p-2 h-10 w-full text-sm rounded inputCSS "
            placeholder={SEARCH_MEMBER_PLACEHOLDER}
            onChange={(e: any) => props.searchKeyword(e)}
          />
        </div>

        <div className="member-panal">
          {props.children}
        </div>
      </div>
    </React.Fragment>
  );
};
export default MembersOfServicesPopup;
