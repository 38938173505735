import React from "react";
// import parse from "html-react-parser";

import { connect } from "react-redux";
import { activity, loginUser, Opening, orgMember } from "../../../../type/type";
import { ACTIVITY_TAG } from "../../../../constant";
type ActivityProps = {
  activity: activity;
  loginUser: loginUser;
  applicationId: string;
  currentOpening: Opening;
  member: orgMember;
};
const BoardActivityMsg = (props: ActivityProps) => {
  var openingActivity: activity = {
    id: "",
    trackable: "",
    trackable_id: "",
    trackable_name: "",
    sub_trackable: "",
    sub_trackable_id: "",
    sub_trackable_name: "",
    action: "",
    owner: "",
    owner_image: "",
    owner_id: "",
    recipient: "",
    attachment_object_key: "",
    old_val: "",
    new_val: "",
    topic_id: "",
    created_at: "",
    updated_at: "",
    tag: ACTIVITY_TAG,
  };
  openingActivity = props.activity;
  if (!openingActivity) return null;
  if (
    openingActivity.action == "added" &&
    openingActivity.sub_trackable == "card"
  ) {
    return (
      <>
        <span>{openingActivity.action}</span>
       
          <span className="">
            {openingActivity.sub_trackable_name}
          </span>
        <span>to</span>
        <span>the</span>
        <span>{openingActivity.trackable_name}</span>
      </>
    );
  } else if (openingActivity.action == "moved") {
    return (
      <>
        <span>{openingActivity.action}</span>
       
          <span className="">
            {openingActivity.sub_trackable_name}
          </span>
        <span>from</span>
        <span>the</span>
        <span>{openingActivity.old_val}</span>
        <span>to</span>
        <span>the</span>
        <span>{openingActivity.new_val}</span>
      </>
    );
  } else if (
    openingActivity.action == "added" &&
    openingActivity.sub_trackable == "attachment"
  ) {
    return (
      <>
        <span>{openingActivity.action}</span>
        <span>the</span>
        <span>{openingActivity.sub_trackable}</span>
        <span>{openingActivity.sub_trackable_name}</span>
        <span>to</span>
        <span>the</span>
       
          <span className="">{openingActivity.trackable_name}</span>
      </>
    );
  } else if (
    openingActivity.action == "unarchived" &&
    openingActivity.sub_trackable == "card"
  ) {
    return (
      <>
        <span>{openingActivity.action}</span>
        <span>the</span>
       
          <span className="">
            {openingActivity.sub_trackable_name}
          </span>
       
      </>
    );
  } else if (
    openingActivity.action == "archived" &&
    openingActivity.sub_trackable == "card"
  ) {
    return (
      <>
        <span>{openingActivity.action}</span>
        <span>the</span>
       
          <span className="">
            {openingActivity.sub_trackable_name}
          </span>
      </>
    );
  } else if (
    openingActivity.action == "archived" &&
    openingActivity.sub_trackable == "list"
  ) {
    return (
      <>
        <span>{openingActivity.action}</span>
        <span>{openingActivity.sub_trackable}</span>
        <span>{openingActivity.sub_trackable_name}</span>
      </>
    );
  } else if (
    openingActivity.action == "unarchived" &&
    openingActivity.sub_trackable == "list"
  ) {
    return (
      <>
        <span>{openingActivity.action}</span>
        <span>{openingActivity.sub_trackable}</span>
        <span>{openingActivity.sub_trackable_name}</span>
      </>
    );
  } else if (
    openingActivity.action == "added" &&
    openingActivity.sub_trackable == "label"
  ) {
    return (
      <>
        <span>{openingActivity.action}</span>
        <span>{openingActivity.sub_trackable}</span>
        <span>{openingActivity.sub_trackable_name}</span>
        <span>to</span>
        <span>the</span>
       
          <span className="">{openingActivity.trackable_name}</span>
      </>
    );
  } else if (
    openingActivity.action == "created" &&
    openingActivity.sub_trackable == "board"
  ) {
    return (
      <>
        <span>{openingActivity.action}</span>
        <span>the</span>
        <span>{openingActivity.sub_trackable}</span>
      </>
    );
  } else if (
    openingActivity.action == "added" &&
    openingActivity.sub_trackable == "list"
  ) {
    return (
      <>
        <span>{openingActivity.action}</span>
        <span>{openingActivity.sub_trackable}</span>
        <span>{openingActivity.sub_trackable_name}</span>
      </>
    );
  } else if (
    openingActivity.action == "added" &&
    openingActivity.sub_trackable == "member" &&
    openingActivity.trackable == "card"
  ) {
    if (openingActivity.sub_trackable_id === openingActivity.owner_id) {
      return (
        <>
          <span>joined</span>
        
            <span className="">{openingActivity.trackable_name}</span>
        </>
      );
    } else {
      return (
        <>
          <span>{openingActivity.action}</span>
          <span className="font-semibold">
            {openingActivity.sub_trackable_name}
          </span>
          <span>to</span>
          <span>the</span>
         
            <span className="">{openingActivity.trackable_name}</span>
        </>
      );
    }
  } else if (
    openingActivity.action == "deleted" &&
    openingActivity.sub_trackable == "attachment"
  ) {
    return (
      <>
        <span>{openingActivity.action}</span>
        <span>the</span>
        <span>{openingActivity.sub_trackable}</span>
        <span>{openingActivity.sub_trackable_name}</span>
        <span>from</span>
        <span>{openingActivity.trackable}</span>
        <span>{openingActivity.trackable_name}</span>
      </>
    );
  } else if (
    openingActivity.action == "updated" &&
    openingActivity.sub_trackable == "attachment"
  ) {
    return (
      <>
        <span>{openingActivity.action}</span>
        <span>the</span>
        <span>{openingActivity.sub_trackable}</span>
        <span>{openingActivity.old_val}</span>
        <span>to</span>
        <span>the</span>
        <span>{openingActivity.trackable_name}</span>
      </>
    );
  } else if (
    /*
     * e.g kanchan update description to hello on card software developer
     */
    openingActivity.action == "updated" &&
    openingActivity.sub_trackable == "description"
  ) {
    return (
      <>
        <span>{openingActivity.action}</span>
        <span>the</span>
        <span>{openingActivity.sub_trackable}</span>
        {/* <span>to</span>
        <span className="inline-block">{parse(openingActivity.new_val)}</span> */}
        <span>on</span>
        <span>the</span>
        <span>{openingActivity.trackable}</span>
       
          <span className="">{openingActivity.trackable_name}</span>
      </>
    );
  } else if (
    openingActivity.action == "removed" &&
    openingActivity.sub_trackable == "member"
  ) {
    if (openingActivity.sub_trackable_id === openingActivity.owner_id)
      return (
        <>
          <span>left</span>
         
            <span className="">{openingActivity.trackable_name}</span>
        </>
      );
    else {
      return (
        <>
          <span>{openingActivity.action}</span>
          <span className="font-semibold">
            {openingActivity.sub_trackable_name}
          </span>
          <span>from</span>
          <span>{openingActivity.trackable}</span>
         
            <span className="">{openingActivity.trackable_name}</span>
        </>
      );
    }
  } else if (
    openingActivity.trackable == "board" &&
    openingActivity.action == "added" &&
    openingActivity.sub_trackable == "member"
  ) {
    if (openingActivity.sub_trackable_id === openingActivity.owner_id)
      return <>joined this {openingActivity.trackable}</>;
    else
      return (
        <>
          <span>{openingActivity.action}</span>
          <span className="font-semibold inline-block space-x-1">
            {props.member[openingActivity.sub_trackable_id]?.first_name}&nbsp;
            {props.member[openingActivity.sub_trackable_id]?.last_name}
          </span>
          <span>to</span>
          <span>this</span>
          <span>{openingActivity.trackable}</span>
        </>
      );
  }
  return <></>;
};

const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  currentOpening: state.opening.currentOpening,
  applicationId: state.opening.applicationId,
  member: state.opening.members,
});

export default connect(mapStateToProps)(BoardActivityMsg);
