import React, { useEffect, useRef, useState } from 'react';
import TacitbaseAvtar from '../../ant/Avtar';

interface Word {
    text: string;
    start_timestamp:number;
    confidence:number;
    end_timestamp:number;
}

interface TranscriptEntry {
    words: Word[];
    speaker: string
}

interface VideoTranscriptProps {
    transcript: TranscriptEntry[];
    videoRef: React.RefObject<HTMLVideoElement>; // Video reference to track time
}

const VideoTranscript: React.FC<VideoTranscriptProps> = ({  transcript,videoRef }) => {
    const [currentTime, setCurrentTime] = useState(0); // Store video time
    const activeWordRef = useRef<HTMLSpanElement>(null);


    // Sync current time with video
    useEffect(() => {
        const video = videoRef.current;
        if (!video) return;

        const updateTime = () => setCurrentTime(video.currentTime);
        video.addEventListener('timeupdate', updateTime);

        return () => video.removeEventListener('timeupdate', updateTime);
    }, [videoRef]);

    useEffect(() => {
        const activeWord = activeWordRef.current;
        if (activeWord) {
            activeWord.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest'
            });
        }
    }, [currentTime]); // Re-run when currentTime changes

     // Function to check if a word is currently spoken
     const isWordActive = (word: Word) => {
        const buffer = 0.001; // 10ms buffer for smoother transitions
        return (
            currentTime >= word.start_timestamp - buffer && 
            currentTime <= word.end_timestamp + buffer
        );
    };


     // Preprocess the transcript to merge consecutive entries by the same speaker
     const mergeTranscriptEntries = (entries: TranscriptEntry[]): TranscriptEntry[] => {
        const mergedEntries: TranscriptEntry[] = [];
        const seenTimestamps = new Set();
    
        if (!Array.isArray(entries)) {
            console.error("Expected 'entries' to be an array");
            return mergedEntries;
        }
    
        for (const entry of entries) {
            if (!entry || !Array.isArray(entry.words) || typeof entry.speaker !== 'string') {
                console.warn("Invalid entry detected, skipping:", entry);
                continue;
            }
    
            // Filter out duplicate words based on timestamps
            const uniqueWords = entry.words.filter(word => {
                const timestampKey = `${word.start_timestamp}-${word.end_timestamp}`;
                if (seenTimestamps.has(timestampKey)) {
                    return false;
                }
                seenTimestamps.add(timestampKey);
                return true;
            });
    
            if (
                mergedEntries.length > 0 &&
                mergedEntries[mergedEntries.length - 1].speaker === entry.speaker
            ) {
                // Add only unique words to the existing entry
                mergedEntries[mergedEntries.length - 1].words.push(...uniqueWords);
                // Sort words by timestamp
                mergedEntries[mergedEntries.length - 1].words.sort((a, b) => 
                    a.start_timestamp - b.start_timestamp
                );
            } else if (uniqueWords.length > 0) {
                // Create new entry only if there are unique words
                mergedEntries.push({
                    ...entry,
                    words: uniqueWords
                });
            }
        }
    
        return mergedEntries;
    };

    const mergedTranscript = mergeTranscriptEntries(transcript);


    return (
        <div className='flex '>
            <div
            //  onMouseUp={handleTextSelection}
               role="button" tabIndex={-1} >
                 {mergedTranscript?.map((entry, index) => (
                    <div key={index} className='mb-6'>
                        <div className='flex gap-2 font-bold items-center mb-1'>                      
                              <TacitbaseAvtar content={entry?.speaker?.[0]} />
                        {entry.speaker}</div> {/* Display speaker name */}
                        <p className='mt-1'>
                            {entry.words.map((word, wordIndex) => (
                                 <span 
                                 key={`${word.start_timestamp}-${word.end_timestamp}-${wordIndex}`}
                                 style={{ 
                                     userSelect: 'text',
                                     transition: 'background-color 0.2s ease-in-out'
                                 }} 
                                 ref={isWordActive(word) ? activeWordRef : null}   
                                 className={`${
                                     isWordActive(word) 
                                         ? 'bg-yellow-100  p-0.5' 
                                             : 'text-black p-0.5 items-center'
                                 }`}
                             >
                                 {word.text}{' '}
                             </span>
                            ))}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default VideoTranscript;
