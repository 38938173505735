type ErrorResponse = {
    status?: number;
    data?: {
      error?: {
        message?: string;
      };
    };
  };
  
  type ErrorWithResponse = {
    response?: ErrorResponse;
    message?: string;
  };
  
  export const handleAuthError = (
    error: ErrorWithResponse,
    options: {
      onSetError: (message: string) => void;
      clearErrorAfterDelay?: boolean;
    }
  ) => {
    const { response } = error;
    let errorMessage = "Authentication failed";
  
    if (response?.status === 422) {
      errorMessage = response?.data?.error?.message || "Invalid request. Please check your input.";
    } else if (response?.status === 423) {
      errorMessage = "locked";
    }  else if (error.message) {
      errorMessage = error.message;
    }
  
    options.onSetError(errorMessage);
  
    if (options.clearErrorAfterDelay && errorMessage !== "locked") {
      setTimeout(() => options.onSetError(""), 8000);
    }
  };