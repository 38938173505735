import React from 'react';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Typography from 'antd/es/typography';

const { Text } = Typography;

interface EmailInputProps {
  label?: string;
  helpText?: string;
}

export const EmailInput: React.FC<EmailInputProps> = ({ 
  label = "Work email",
  helpText
}) => {
  return (
    <Form.Item
      name="email"
      label={label}
      rules={[
        { required: true, message: "Please input your work email!" },
        { type: 'email', message: 'Invalid email format' },
      ]}
    >
      <Input 
        size="large" 
        placeholder="johndoe@yourcompany.com" 
      />
      {helpText && (
        <Text className="text-gray-500 text-xs mb-4">
          {helpText}
        </Text>
      )}
    </Form.Item>
  );
}; 