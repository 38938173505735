import * as actionTypes from "../../actionType";
import {
  Opening,
  List,
  currentApplicant,
  Member,
  PermissionAccess,
  Applicant,
  Candidate,
  Attachment,
  ReviewComment,
  Label,
  CandidateShortReview,
  activity,
  MailPreview,
  RecordThreads,
  Domain,
  DomainIntegration,
  SendMail,
  Mail,
  Jd,
  company,
  notificationsPagination,
  FormBuilder,
  IntegratedMember,
  ThirdPartyIntegrations,
  Product,
  UserNotificationSettings,
  eSignDetails,
  JobBoardSummary,
  feedback,
  // Product,
} from "../../../type/type.d";
import { NotificatioRTU, Role, Source } from "../../../lib/apiRes"
import { debounce } from "../../../lib/debounce"

export const tokenExchange = (
  token: any,
  applicantionID: string,
  applicationName: string,
  applicationDesc: string,
  applicationImgURL: string,
  applicationRoles: Role[]
) => {
  return {
    type: actionTypes.ORG_TOKEN,
    payload: {
      token: token,
      applicationID: applicantionID,
      applicationName: applicationName,
      applicationDesc: applicationDesc,
      applicationImgURL: applicationImgURL,
      applicationRoles: applicationRoles,
    },
  };
};
export const initCurrentBoard = (currentBoardData: Opening) => {
  return {
    type: actionTypes.INIT_CURRUNT_BOARD,
    payload: currentBoardData,
  };
};
export const getActiveBoard = (job_board:any)=>{
  return{
    type: actionTypes.GET_ACTIVE_BOARD,
    payload: job_board,
  }
}

export const getActiveMeetings = (job_board:any)=>{
  return{
    type: actionTypes.GET_ACTIVE_MEETINGS,
    payload: job_board,
  }
}
export const initCurrentTrackerJd = (currenttrackerJd: Jd) => {
  return {
    type: actionTypes.INIT_CURRUNT_TRACKER_JD,
    payload: currenttrackerJd,
  }
}
export const initCurrentTrackerForm = (currenttrackerJdForm: FormBuilder) => {
  return {
    type: actionTypes.INIT_CURRUNT_TRACKER_JD_FORM,
    payload: currenttrackerJdForm,
  }
}
export const removeCurrentBoard = () => {
  return {
    type: actionTypes.REMOVE_CURRUNT_OPENING,
  };
};
export const clientId = (data: any) => {
  return {
    type: actionTypes.SET_CLIENT_ID,
    payload: data.id,
  };
};
export const setNewWsConnection = () => {
  return {
    type: actionTypes.SET_NEW_WS_CONNECTION,
  };
};
export const registerCurrentOrg = (applicant: any) => {
  return {
    type: actionTypes.REGISTER_CURRENT_ORG,
    data: applicant,
  };
};
export const updateOpeningName = (updatedOpeningname: Opening) => {
  return {
    type: actionTypes.UPDATE_OPENING_NAME,
    data: updatedOpeningname,
  };
};
export const updateOpeningStatus = (status: Opening) => {
  return {
    type: actionTypes.UPDATE_CURRUNT_OPENING,
    data: status,
  };
};
export const updateOpeningDescription = (opening: string) => {
  return {
    type: actionTypes.UPDATE_OPENING_DESCRIPTION,
    data: opening,
  };
};
export const listOfBoards = (boardData: Opening[]) => {
  return {
    type: actionTypes.INIT_BOARDS,
    payload: boardData,
  };
};

export const getBoardLists = (lists: List) => {
  return {
    type: actionTypes.ADD_BOARD_LISTS,
    data: lists,
  };
};
export const addNewOpening = (opening: Opening) => {
  return {
    type: actionTypes.ADD_OPENING,
    data: opening,
  };
};
export const addOpening = (opening: Opening) => {
  return {
    type: actionTypes.ADD_NEW_OPENINGS,
    data: opening,
  };
};
export const removeOpening = (opening: Opening) => {
  return {
    type: actionTypes.REMOVE_OPENING,
    data: opening,
  };
};

export const addNewOpeningFromWS = (opening: Opening) => {
  return {
    type: actionTypes.ADD_OPENING_FROM_WS,
    data: opening,
  };
};
export const addNewList = (list: any) => {
  return {
    type: actionTypes.ADD_NEW_LIST,
    data: list,
  };
};
export const addList = (list: any) => {
  return {
    type: actionTypes.ADD_LIST,
    data: list,
  };
};

export const initLists = (lists: any) => {
  return {
    type: actionTypes.INIT_LISTS,
    payload: lists,
  };
};

export const saveCardLabel = (labelData: any) => {
  return {
    type: actionTypes.SAVE_CARD_LABEL,
    data: labelData,
  };
};
export const saveBoardLabel = (label: any, openingId: string) => {
  return {
    type: actionTypes.SAVE_BOARD_LABEL,
    data: label,
    openingId: openingId,
  };
};

export const addApplicant = (applicant: any) => {
  return {
    type: actionTypes.ADD_APPLICANT,
    data: applicant,
  };
};

export const initApplicationLables = (labels: any) => {
  return {
    type: actionTypes.INIT_APPLICANT_LABEL,
    data: labels,
  };
};

export const initApplicantMembers = (members: any, applicantId: string) => {
  return {
    type: actionTypes.INIT_APPLICANT_MEMBERS,
    data: members,
    applicantId: applicantId,
  };
};

export const initMembership = (members: Member[]) => {
  return {
    type: actionTypes.INIT_MEMBERS,
    data: members,
  };
};

//analytics label store 

export const initAnalyticsLabel= (labels: any) => {
  return {
    type: actionTypes.INIT_ANALYTICS_LABELS,
    data: labels,
  };
};

export const initOpeningMembership = (members: any, openingId: string) => {
  return {
    type: actionTypes.INIT_OPENING_MEMBERS,
    data: members,
    currentOpeningId: openingId,
  };
};

export const initLabels = (labels: any, id: string) => {
  return {
    type: actionTypes.INIT_LABELS,
    data: labels,
    id: id,
  };
};
export const initBoardLabels = (labels: any, id: string) => {
  return {
    type: actionTypes.INIT_BOARD_LABELS,
    data: labels,
    id: id,
  };
};

export const initComments = (
  comments: any,
  tag: string,
  applicant_id: string
) => {
  return {
    type: actionTypes.INIT_COMMENTS,
    data: comments,
    tag: tag,
    applicantId: applicant_id,
  };
};

export const saveComment = (newComment: any, listID: string) => {
  return {
    type: actionTypes.SAVE_COMMENT,
    data: newComment,
    tag: listID,
  };
};

export const initActivity = (activity: any) => {
  return {
    type: actionTypes.INIT_APPLICANT_ACTIVITY,
    data: activity,
  };
};

export const initOpeningActivity = (activity: any, openingId: string,currentPage:number) => {
  return {
    type: actionTypes.INIT_OPENING_ACTIVITY,
    data: activity,
    openingID: openingId,
    currentPage:currentPage
  };
};
export const clearBoardActivity = () => {
  return {
    type: actionTypes.CLEAR_OPENING_ACTIVITY,
    
  };
};
// export const editDesc = (desc: string) => {
//   return {
//     type: actionTypes.EDIT_DESC,
//     data: desc,
//   };
// };

export const archiveList = (archiveList: any) => {
  return {
    type: actionTypes.ARCHIVE_LIST,
    data: archiveList,
  };
};
export const editName = (name: any) => {
  return {
    type: actionTypes.EDIT_APPLICANT_NAME,
    data: name,
  };
};

export const move = (
  req: any,
  currentListId: any,
  selectedApplicantPosition: any
) => {
  return {
    type: actionTypes.MOVE_APPLICANT,
    data: req,
    currentListId: currentListId,
    selectedApplicantPosition: selectedApplicantPosition,
  };
};

export const updateLabelState = (updatedLabel: any) => {
  return {
    type: actionTypes.UPDATE_LABEL,
    data: updatedLabel,
  };
};

export const updateListName = (req: any) => {
  return {
    type: actionTypes.EDIT_LIST_NAME,
    data: req,
  };
};

//Aanlytics update label object
export const updateAnalyticsListLabel = (req: any) => {
  return {
    type: actionTypes.UPDATE_ANALYTICS_LIST_LABEL_NAME,
    data: req,
  };
};

export const initAttachment = (req: any, applicantId: string) => {
  return {
    type: actionTypes.ATTACHMENTS,
    data: req,
    applicantID: applicantId,
  };
};

export const addAttachments = (attachment: any, listID: string) => {
  return {
    type: actionTypes.ADD_ATTACHMENTS,
    data: attachment,
    listID: listID,
  };
};
export const addMemberOnCard = (memberID: any, applicantID: string) => {
  return {
    type: actionTypes.ADD_MEMBER_ON_CARD,
    data: memberID,
    applicantID: applicantID,
  };
};
export const addCreatorOnCard = (memberID: any, applicantID: string) => {
  return {
    type: actionTypes.ADD_CREATOR_ON_CARD,
    data: memberID,
    applicantID: applicantID,
  };
};
export const addMemberOnBoard = (members: Member[], openingID: string) => {
  return {
    type: actionTypes.ADD_MEMBER_ON_BOARD,
    data: members,
    openingID: openingID,
  };
};
export const removeMemberFromCard = (memberID: string, applicantID: string) => {
  return {
    type: actionTypes.REMOVE_MEMBER_FROM_CARD,
    memberID: memberID,
    applicantID: applicantID,
  };
};
export const removeMemberFromOpening = (
  memberID: string,
  openingId: string
) => {
  return {
    type: actionTypes.REMOVE_MEMBER_FROM_OPENING,
    memberID: memberID,
    openingId: openingId,
  };
};
export const collapseLabel = (flag: boolean) => {
  return {
    type: actionTypes.COLLAPSE_LABEL,
    data: flag,
  };
};

export const updateApplicantArchiveStatus = (applicantData: any) => {
  return {
    type: actionTypes.UPDATE_ARCHIVE_STATUS,
    data: applicantData,
  };
};

export const removeArchiveApplicant = () => {
  return {
    type: actionTypes.REMOVE_ARCHIVED_APPLICANT,
  };
};

export const showApplicantFlag = (id: string, listId: string) => {
  return {
    type: actionTypes.SHOW_APPLICANT,
    applicantID: id,
    listID: listId,
  };
};

export const closeApplicantFlag = (id: string, listId: string) => {
  return {
    type: actionTypes.CLOSE_APPLICANT,
    applicantID: id,
    listID: listId,
  };
};

export const setBoardBackgroundColor = (opening: any) => {
  return {
    type: actionTypes.UPDATE_BOARD_BACKGROUND_COLOR,
    data: opening,
  };
};

export const initArchivedItems = (archivedItems: any, openingID: string) => {
  return {
    type: actionTypes.INIT_ARCHIVED_APPLICANT,
    data: archivedItems,
    openingID: openingID,
  };
};

export const removeTask = (taskID: string, applicantID: string) => {
  return {
    type: actionTypes.REMOVE_TASK,
    taskID: taskID,
    applicantID: applicantID,
  };
};

export const sendBackArchivedItem = (archivedItem: any) => {
  return {
    type: actionTypes.SEND_BACK_ARCHIVED_ITEM,
    data: archivedItem,
  };
};

export const addNewTask = (newTask: any) => {
  return {
    type: actionTypes.ADD_NEW_TASK,
    data: newTask,
  };
};

export const saveApplicantTasks = (tasks: any, applicantID: string) => {
  return {
    type: actionTypes.SAVE_APPLICANT_TASKS,
    data: tasks,
    applicantID: applicantID,
  };
};

export const updateTaskAction = (updatedTask: any) => {
  return {
    type: actionTypes.UPDATE_TASK,
    data: updatedTask,
  };
};

export const updateComment = (updateComment: any) => {
  return {
    type: actionTypes.UPDATE_COMMENT,
    data: updateComment,
  };
};
export const moveListAction = (req: string, selectedPosition: string) => {
  return {
    type: actionTypes.MOVE_LIST,
    data: req,
    destination: {
      index: selectedPosition,
    },
  };
};

export const moveAllApplicants = (payload: any) => {
  return {
    type: actionTypes.MOVE_APPLICANTS_OF_LIST,
    data: payload,
  };
};
export const draggableCard = (draggableCardDetails: any) => {
  return {
    type: actionTypes.DRAG_CARD,
    data: draggableCardDetails,
  };
};
export const draggableList = (draggableListDetails: any) => {
  return {
    type: actionTypes.DRAG_LIST,
    data: draggableListDetails,
  };
};

export const addBoardLabelInCard = (label: any, id: string, listID: string) => {
  return {
    type: actionTypes.SAVE_BOARD_LABEL_IN_CARD,
    data: label,
    applicantID: id,
    listID: listID,
  };
};
export const archivedAllListApplicants = (
  listID: string,
  openingID: string
) => {
  return {
    type: actionTypes.ARCHIVED_APPLICANTS_OF_LIST,
    listID: listID,
    openingID: openingID,
  };
};

export const removeLabelFromCard = (
  labelID: string,
  applicantID: string,
  listID: string
) => {
  return {
    type: actionTypes.REMOVE_LABEL_FROM_CARD,
    labelID: labelID,
    applicantID: applicantID,
    listID: listID,
  };
};

export const initCurrentApplicant = (applicant: currentApplicant) => {
  return {
    type: actionTypes.INIT_CURRENT_APPLICANT,
    data: applicant,
  };
};

export const editApplicantDesc = (desc: string) => {
  return {
    type: actionTypes.UPDATE_APPLICANT_DESC,
    data: desc,
  };
};

export const copyApplicantAction = (newApplicant: any, index: number) => {
  return {
    type: actionTypes.COPY_APPLICANT,
    data: newApplicant,
    index: index,
  };
};

export const starredOpening = (opening: any) => {
  return {
    type: actionTypes.STARRED_BOARD,
    data: opening,
  };
};
export const setStarredBoardPreview = (Id: string) => {
  return {
    type: actionTypes.STARRED_BOARD_PREVIEW,
    openingID: Id,
  };
};

//login
export const loginUserAc = (token: any, userData: any) => {
  return {
    type: actionTypes.LOGIN_USER,
    token: token,
    user: userData,
  };
};

//logout
export const logoutUserAc = () => {
  return {
    type: actionTypes.LOGOUT_USER,
  };
};

// ws

export const initWs = (ws: WebSocket) => {
  return {
    type: actionTypes.INIT_WS,
    payload: ws,
  };
};

//topicSubscription
export const topicSubscription = (topic: any) => {
  return {
    type: actionTypes.TOPIC_SUBSCRIPTION,
    payload: topic,
  };
};

//attachment loader

export const attachmentLoader = () => {
  return {
    type: actionTypes.ATTACHMENTS_LOADER,
  };
};

export const slackIntegration = (data: any) => {
  return {
    type: actionTypes.SLACK_INTEGRATION,
    payload: data,
  };
};
export const integratedSlackMember = (data: IntegratedMember[]) => {
  return {
    type: actionTypes.INTEGRATED_SLACK_MEMBER,
    payload: data,
  }
}
export const connectSlackOfMember = (data: IntegratedMember[]) => {
  return {
    type: actionTypes.INTEGRATE_SLACK_MEMBER,
    payload: data,
  }
};
export const disconnectSlackAccountOfMember = (data: string) => {
  return {
    type: actionTypes.DISCONNECT_SLACK_ACCOUNT_OF_MEMBER,
    payload: data,
  }
};

export const resetSlackIntegration = () => {
  return {
    type: actionTypes.RESET_SLACK_OPTIONS,
  };
};

export const storedJdData = (jdData: JobBoardSummary[]) => {
  return {
    type: actionTypes.STORE_JD_DATA,
    data: jdData,
  };
};

export const showOrg = (data: any) => {
  return {
    type: actionTypes.SHOW_ORG,
    payload: data,
  };
};

export const initOutgoingInvitations = (data: any) => {
  return {
    type: actionTypes.INIT_OUTGOING_INVIATIONS,
    payload: data,
  };
};

export const initIncomingInvitations = (data: any) => {
  return {
    type: actionTypes.INIT_INCOMING_INVIATIONS,
    payload: data,
  };
};
export const addIncomingInvitation = (data: any) => {
  return {
    type: actionTypes.ADD_INCOMING_INVITATION,
    payload: data,
  };
};

export const addInvitation = (data: any) => {
  return {
    type: actionTypes.ADD_INVITATION,
    payload: data,
  };
};
export const withdrawInvitation = (data: any) => {
  
  return {
    type: actionTypes.WITHDRAW_INVITATION,
    payload: data,
  };
};

export const clearData = () => {
  return {
    type: actionTypes.CLEAR_DATA,
  };
};

export const clearCardData = () => {
  return {
    type: actionTypes.CLEAR_CARD_DATA,
  };
};

export const updateUser = (user: any) => {
  return {
    type: actionTypes.UPDATE_USER,
    user: user,
  };
};

export const deleteAttachment = (attachment: any, listID: string) => {
  return {
    type: actionTypes.DELETE_ATTACHMENT,
    attachment: attachment,
    listID: listID,
  };
};

export const updateAttachment = (attachment: any) => {
  return {
    type: actionTypes.UPDATE_ATTACHMENT,
    attachment: attachment,
  };
};

export const setError = (showError: boolean, message: string) => {
  return {
    type: actionTypes.SET_ERROR,
    show: showError,
    message: message,
  };
};
export const showSearch = () => {
  return {
    type: actionTypes.SHOW_SEARCH,
  };
};
export const resetSearch = () => {
  return {
    type: actionTypes.RESET_SEARCH,
  };
};
export const updateSearchFlag = () => {
  return {
    type: actionTypes.UPDATE_SEARCH_FLAG,
  };
};
export const slackOptions = (slackOptions: any) => {
  return {
    type: actionTypes.SET_SLACK_OPTIONS,
    data: slackOptions,
  };
};

export const setBoardSlackOptions = (data: any) => {
  return {
    type: actionTypes.BOARD_SLACK_OPTIONS,
    payload: data,
  };
};

export const addActivity = (data: any, tag: string) => {
  return {
    type: actionTypes.ADD_ACTIVITY,
    data: data,
    tag: tag,
  };
};

export const moveListWS = (data: any, selectedPosition: string) => {
  return {
    type: actionTypes.MOVE_LIST_WS,
    data: data,
    destination: {
      index: selectedPosition,
    },
  };
};

export const moveApplicantWS = (data: any) => {
  return {
    type: actionTypes.MOVE_APPLICANT_TO_ANOTHER_OPENING,
    data: data,
  };
};

export const copyApplicantWS = (data: any) => {
  return {
    type: actionTypes.COPY_APPLICANT_TO_ANOTHER_OPENING,
    data: data,
  };
};
export const initArchiveLists = (data: any, openingID: string) => {
  return {
    type: actionTypes.INIT_ARCHIVE_LISTS,
    data: data,
    openingID: openingID,
  };
};

export const sendBackArchivedList = (data: any) => {
  return {
    type: actionTypes.SEND_BACK_ARCHIVED_LIST,
    data: data,
  };
};

export const resetWebsocket = () => {
  return {
    type: actionTypes.RESET_WS,
  };
};

export const dequeueWsRequest = (data: any) => {
  return {
    type: actionTypes.DEQUEUE_WS_REQUEST_ACTION,
    data: data,
  };
};

export const addWsRequest = (data: any) => {
  return {
    type: actionTypes.ADD_WS_REQUEST_ACTION,
    data: data,
  };
};
export const debouncedAddWsRequest = debounce((dispatch: any, data: any) => {
  dispatch(addWsRequest(data))
}, 1000)
export const reconnectWSRequestAction = () => {
  return {
    type: actionTypes.RECONNECTION_WS_REQUEST,
    // applicationId: applicationId
  };
};

export const updateWsRequestStatus = (data: any) => {
  return {
    type: actionTypes.UPDATE_WS_REQUEST_STATUS,
    data: data,
  };
};
export const emailVerify = () => {
  return {
    type: actionTypes.EMAIL_VERIFY,
  };
};
export const phoneVerify = () => {
  return {
    type: actionTypes.PHONE_VERIFY,
  };
};
export const updateActivity = (data: any) => {
  return {
    type: actionTypes.UPDATE_ACTIVITY,
    data: data,
  };
};

export const orgSettingUpdate = (data: any) => {
  return {
    type: actionTypes.ORG_SETTING_UPDATE,
    data: data,
  };
};
export const clearTabStates = () => {
  return {
    type: actionTypes.CLEAR_TAB_DATA,
  };
};

export const filter = (filerKey: any) => {
  return {
    type: actionTypes.FILTER,
    data: filerKey,
  };
};

export const removeFilters = () => {
  return {
    type: actionTypes.REMOVE_FILTERS,
  };
};

export const filterCount = (count: any) => {
  return {
    type: actionTypes.FILTER_COUNT,
    count: count,
  };
};

export const updateMember = (paylod: any) => {
  return {
    type: actionTypes.UPDATE_MEMBER,
    data: paylod,
  };
};

export const sortingBoard = (sortingType: string) => {
  return {
    type: actionTypes.SORTING_BOARD,
    data: sortingType,
  };
};
export const copyAttachmentsAction = (
  record_id: string,
  list_id: string,
  data: Attachment[]
) => {
  return {
    type: actionTypes.COPY_ATTACHMENTS,
    data: {
      record_id: record_id,
      list_id: list_id,
      attachments: data,
    },
  };
};
export const initCardMails = (data: MailPreview[], candidateId: string) => {
  return {
    type: actionTypes.INIT_CURRENT_APPLICANT_MAILS_PREVIEW,
    data: data,
    candidateId: candidateId,
  };
};
export const initCardMailThread = (data: RecordThreads, mailId: string) => {
  return {
    type: actionTypes.INIT_CURRENT_APPLICANT_MAIL_THREAD,
    data: data,
    mailID: mailId,
  };
};
export const addMailInCurrentThreadOfCurrentcard = (
  data: Mail,
  mailId: string
) => {
  return {
    type: actionTypes.ADD_MAIL_IN_CURRENT_THREAD_OF_CARD,
    data: data,
    mailId: mailId,
  };
};
export const addMailOnCard = (data: SendMail, mailId: string, tag: string) => {
  return {
    type: actionTypes.ADD_MAIL_ON_CURRENT_CARD,
    data: data,
    cardId: mailId,
    tag: tag,
  };
};


// billing
export const recentInvoice = (data: any) => {
  return {
    type: actionTypes.INIT_RECENT_INVOICE,
    data: data,
  };
};
export const init_billing_member = (data: any) => {
  return {
    type: actionTypes.INIT_BILLING_OPA_MEMBER,
    data: data,
  };
};

export const add_current_plan = (data: Product[]) => {
  return {
    type: actionTypes.ADD_CURRENT_PLAN,
    data: data,
  };
};
export const paymentMethods = (data: any) => {
  return {
    type: actionTypes.INIT_PAYMENT_METHODS,
    data: data,
  };
};

export const updateDefaultMethod = (data: any) => {
  return {
    type: actionTypes.UPDATE_DEFAULT_METHOD,
    data: data,
  };
};

export const addMemberInBilling = (memberID: any) => {
  return {
    type: actionTypes.ADD_MEMBER_IN_BILLING,
    data: memberID,
  };
};

export const editBillingMemberRole = (
  memberId: string,
  access: PermissionAccess
) => {
  return {
    type: actionTypes.UPDATE_BILLING_MEMBER_ROLE,
    memberId: memberId,
    access: access,
  };
};

export const removeBillingMember = (memberId: string) => {
  return {
    type: actionTypes.REMOVE_BILLING_MEMBER,
    memberId: memberId,
  };
};

export const addPaymentMethod = (data: any) => {
  return {
    type: actionTypes.ADD_PAYMENT_METHOD,
    data: data,
  };
};

export const storeCustomerSelectionOfPlan = (stripeStatusObj: any) => {
  return {
    type: actionTypes.STORE_CUSTOMER_SELECTION_OF_PLAN,
    data: stripeStatusObj,
  };
};
export const updateCustomerSetupStatus = (updateObj: any) => {
  return {
    type: actionTypes.UPDATE_CUSTOMER_STATUS,
    data: updateObj,
  };
};



export const updateOrgNameId = (data: any) => {
  return {
    type: actionTypes.UPDATE_ORG_INFO,
    data: data,
  };
};

export const calendarId = (data: any) => {
  return {
    type: actionTypes.SET_CALENDER_ID,
    data: data,
  };
};

export const editRoleOfMember = (
  memberId: string,
  access: PermissionAccess,
  r_id: string
) => {
  return {
    type: actionTypes.EDIT_BOARD_ROLE,
    memberId: memberId,
    access: access,
    r_id: r_id,
  };
};
export const updateBoardVisibility = (board: Opening) => {
  return {
    type: actionTypes.CHANGE_BOARD_VISIBILITY,
    data: board,
  };
};
export const updateCardVisibility = (card: Applicant, listID: string) => {
  return {
    type: actionTypes.CHANGE_CARD_VISIBILITY,
    data: card,
    listID: listID,
  };
};
export const addCardBlockStatus = (data: any) => {
  return {
    type: actionTypes.ADD_BLOCK_STATUS,
    data: data,
  };
};

/***************************** CANDIDATE DB *****************************/

export const addCandidateInDB = (data: Candidate) => {
  return {
    type: actionTypes.ADD_CANDIDATE_IN_DB,
    data: data,
  };
};
export const addCandidateInPool = (data: Candidate) => {
  return {
    type: actionTypes.ADD_CANDIDATE_IN_POOL,
    data: data,
  };
};
export const addCandidateComments = (data: any) => {
  return {
    type: actionTypes.ADD_CANDIDATE_COMMENTS,
    data: data,
  };
};
export const editCandidateComment = (data: any) => {
  return {
    type: actionTypes.EDIT_CANDIDATE_COMMENTS,
    data: data,
  };
};
export const initCandidateInReview = (
  data: CandidateShortReview[],
  reviewTag: boolean,
  currentPage:number,
  isSearchResult: boolean = false
) => {
  return {
    type: actionTypes.INIT_CANDIDATES_IN_REVIEW,
    data: data,
    reviewTag: reviewTag,
    currentPage:currentPage,
    isSearchResult: isSearchResult
  };
};
export const deleteCandidateInReview = (
  data: CandidateShortReview[],
  currentPage:number,
) => {
  return {
    type: actionTypes.DELETE_CANDIDATES_FROM_REVIEW,
    data: data,
    currentPage:currentPage,
  };
};
export const addCandidateInReview = (
  data: CandidateShortReview[],
  reviewTag: boolean
) => {
  return {
    type: actionTypes.ADD_CANDIDATE_IN_REVIEW,
    data: data,
    reviewTag: reviewTag,
  };
};
export const addCandidateDBTableHeader = (data: any) => {
  return {
    type: actionTypes.ADD_CDB_TABLE_HEADER,
    data: data,
  };
};

export const initCurrentCandidateInReview = (data: any) => {
  return {
    type: actionTypes.INIT_CURRENT_CANDIDATE_IN_REVIEW,
    data: data,
  };
};

export const initCandidateLabel = (data: any) => {
  return {
    type: actionTypes.INIT_CANDIDATE_LABEL,
    data: data,
  };
};
export const saveCandidateLabel = (labelData: any) => {
  return {
    type: actionTypes.ADD_CANDIDATE_LABEL,
    data: labelData,
  };
};
export const updateCandidateLabel = (labelData: Label) => {
  return {
    type: actionTypes.UPDATE_CANDIDATE_LABEL,
    data: labelData,
  };
};
export const updateReviewLabel = (labelData: Label) => {
  return {
    type: actionTypes.UPDATE_REVIEW_LABEL,
    data: labelData,
  };
};
export const candidateDBMember = (members: any) => {
  return {
    type: actionTypes.INIT_CDB_MEMBER,
    data: members,
  };
};
export const addMemberOnCDB = (memberID: any) => {
  return {
    type: actionTypes.ADD_MEMBER_ON_CDB,
    data: memberID,
  };
};

export const initCDBHead = (data: any) => {
  return {
    type: actionTypes.INIT_CDBTABLE_HEAD,
    data: data,
  };
};

export const initCandidates = (data: Candidate[], currentpage: string) => {
  return {
    type: actionTypes.INIT_CANDIDATES,
    data: data,
    currentpage: currentpage,
  };
};

export const editCandidateAction = (data: Candidate, currentPage: string) => {
  return {
    type: actionTypes.EDIT_CANDIDATE,
    data: data,
    currentPage: currentPage,
  };
};

export const initReviewAttachments = (data: Attachment[], reviewId: string) => {
  return {
    type: actionTypes.INIT_REVIEW_ATTACHMENTS,
    data: data,
    reviewId: reviewId,
  };
};
export const initCandidateAttachments = (data: Attachment[]) => {
  return {
    type: actionTypes.INIT_CANDIDATE_ATTACHMENTS,
    data: data,
  };
};

export const initReviewComments = (
  data: ReviewComment[] | activity[],
  reviewId: string,
  tag: string
) => {
  return {
    type: actionTypes.INIT_REVIEW_COMMENTS,
    data: data,
    tag: tag,
    reviewId: reviewId,
  };
};

export const addCommentReview = (data: ReviewComment, tag: string) => {
  return {
    type: actionTypes.ADD_REVIEW_COMMENT,
    data: data,
    tag: tag,
  };
};

export const addLabelToCandidateInReview = (reviewId: string, label: any) => {
  return {
    type: actionTypes.ADD_LABEL_TO_CANDIDATE_IN_REVIEW,
    data: {
      reviewId: reviewId,
      label: label,
    },
  };
};
export const addLabelInReview = (label: any) => {
  return {
    type: actionTypes.ADD_REVIEW_LABEL,
    data: label,
  };
};

export const addReviewAttachment = (
  reviewId: string,
  attachment: Attachment[]
) => {
  return {
    type: actionTypes.ADD_REVIEW_ATTACHMENT,
    data: attachment,
    reviewId: reviewId,
  };
};
export const addAttachmentToCurrentCandidateInDb = (
  attachment: Attachment[]
) => {
  return {
    type: actionTypes.ADD_CANDIDATE_ATTACHMENT,
    data: attachment,
  }
}

// calendar

export const setCardCalEvents = (data: any) => {
  return {
    type: actionTypes.SET_CARD_CALENDAR_EVENTS,
    data: data,
  };
};

export const updateCardEvents = (data: any) => {
  return {
    type: actionTypes.UPDATE_CARD_CALENDAR_EVENTS,
    data: data,
  };
};
export const setBoardCalEvents = (data: Event[]) => {
  return {
    type: actionTypes.SET_BOARD_CAL_EVENTS,
    data: data,
  };
};
export const addEventOnBoardCalender = (data: Event) => {
  return {
    type: actionTypes.ADD_EVENT_ON_BOARD_CALENDER,
    data: data,
  };
};
export const deleteBoardEvent = (data: string) => {
  return {
    type: actionTypes.DELETE_BOARD_EVENT,
    data: data,
  };
};

export const editBoardEvent = (payload: any) => {
  return {
    type: actionTypes.EDIT_BOARD_EVENT,
    data: payload,
  };
};

export const deleteCardEvent = (data: string) => {
  return {
    type: actionTypes.DELETE_CARD_EVENT,
    data: data,
  };
};

export const editCardEvent = (payload: any) => {
  return {
    type: actionTypes.EDIT_CARD_EVENT,
    data: payload,
  };
};
export const InitCurrentCandidateInDB = (candidateId: Candidate) => {
  return {
    type: actionTypes.INIT_CURRENT_CANDIDATE_IN_DB,
    candidateId: candidateId,
  };
};
export const addLabelToCurrentCandidateInCDB = (
  data: any,
  candidateId: string
) => {
  return {
    type: actionTypes.ADD_LABEL_TO_CUURENT_CANDIDATE_IN_CDB,
    data: data,
    candidateId: candidateId,
  };
};
export const InitCurrentCandidateLabel = (data: any) => {
  return {
    type: actionTypes.INIT_CURRENT_CANDIDATE_LABELS,
    data: data,
  };
};
export const updateAttachmentOfCandidate = (attachment: Attachment) => {
  return {
    type: actionTypes.UPDATE_ATTACHMENT_OF_CANDIDATE,
    data: attachment,
  };
};
export const deleteAttachmentOfCandidate = (attachmentID: string) => {
  return {
    type: actionTypes.DELETE_CANDIDATE_ATTACHMENT,
    data: attachmentID,
  };
};
export const initReviewLabels = (labels: any) => {
  return {
    type: actionTypes.INIT_REVIEW_LABELS,
    data: labels,
  };
};

export const setCurrentCal = (data: any) => {
  return {
    type: actionTypes.SET_CURRENT_CAL,
    data: data,
  };
};
export const UpdateCurrentCal = (data: any) => {
  return {
    type: actionTypes.UPDATE_CURRENT_CALENDAR,
    data: data,
  };
};
export const setCurrentUserZoomMeetAcc = (data: any) => {
  return {
    type: actionTypes.SET_CURRENT_USER_ZOOM_MEET_ACCOUNT,
    data: data,
  };
};
export const removeZoomAccessState = () => {
  return {
    type: actionTypes.REMOVE_ZOOM_ACCESS,
  };
};
export const removeMeetAccess = () => {
  return {
    type: actionTypes.REMOVE_MEET_ACCESS,
  };
};

export const change_state_filledfeedbackform = () => {
  return {
    type: actionTypes.CHANGE_FILLEDFEEDBACKFORM_STATE,
  };
};
export const addZoomAccess = (data: any) => {
  return {
    type: actionTypes.ADD_ZOOM_ACCESS,
    data: data,
  };
};
export const addMeetAccess = (data: any) => {
  return {
    type: actionTypes.ADD_MEET_ACCESS,
    data: data,
  };
};
export const setCurrentCalConferenceSettings = (data: any) => {
  return {
    type: actionTypes.SET_CURRENT_CALENDAR_CONFERENCE_SETTINGS,
    data: data,
  };
};
export const addMemberOnReview = (memberID: string, reviewId: string) => {
  return {
    type: actionTypes.ADD_MEMBER_ON_REVIEW,
    data: memberID,
    reviewId: reviewId,
  };
};
export const removeMemberOnReview = (memberID: Member[], reviewId: string) => {
  return {
    type: actionTypes.REMOVE_MEMBER_ON_REVIEW,
    data: memberID,
    reviewId: reviewId,
  };
};

export const editCDBRoleOfMember = (
  memberId: string,
  access: PermissionAccess
) => {
  return {
    type: actionTypes.UPDATE_CDB_MEMBER_ROLE,
    memberId: memberId,
    access: access,
  };
};

export const deleteCDBMember = (memberId: string) => {
  return {
    type: actionTypes.DELETE_CDB_MEMBER,
    memberId: memberId,
  };
};
export const addLabelsToMultipleReviews = (data: any) => {
  return {
    type: actionTypes.ADD_LABEL_ON_MULTIPLE_REVIEWS,
    data: data,
  };
};

export const addMembersToMultipleReviews = (data: any) => {
  return {
    type: actionTypes.ADD_MEMBERS_ON_MULTIPLE_REVIEWS,
    data: data,
  };
};

export const updateCommentOfReview = (updateComment: any) => {
  return {
    type: actionTypes.UPDATE_COMMENT_OF_REVIEW,
    data: updateComment,
  };
};

export const changeStateOfCloseReview = (reviewId: string) => {
  return {
    type: actionTypes.CHANGE_STATE_OF_CLOSE_REVIEW,
    reviewId: reviewId,
  };
};
export const currentCDBActivity = (
  review: CandidateShortReview,
  comments: any,
  tag: string
) => {
  return {
    type: actionTypes.CURRENT_CDB_ACTIVITY,
    review: review,
    comments: comments,
    tag: tag,
  };
};
export const currentCDBReviewActivity = (comments: any, tag: string) => {
  return {
    type: actionTypes.CDB_REVIEW_ACTIVITY,
    comments: comments,
    tag: tag,
  };
};
export const AtsActivityOfCandidate = (candidateApplicants: Applicant) => {
  return {
    type: actionTypes.ATS_ACTIVITY_OF_CANDIDATE,
    candidateApplicants: candidateApplicants,
  };
};
export const removeLabelFromReview = (reviewId: string, labels: string[]) => {
  return {
    type: actionTypes.REMOVE_LABEL_FROM_REVIEW,
    reviewId: reviewId,
    labels: labels,
  };
};
export const removeLabelFromCandidate = (
  reviewId: string,
  labels: string[]
) => {
  return {
    type: actionTypes.REMOVE_LABEL_FROM_CANDIDATE,
    reviewId: reviewId,
    labels: labels,
  };
};
export const removeLabelFromReviews = (
  reviewIds: string[],
  labels: string[]
) => {
  return {
    type: actionTypes.REMOVE_LABEL_FROM_REVIEWS,
    data: reviewIds,
    labels: labels,
  };
};

//Tacitmail
export const getAllMailsPagewise = (
  currentPage: string,
  mails: MailPreview[],
  tab: string,
  total_count:number,
) => {
  return {
    type: actionTypes.USER_MAILS,
    data: mails,
    currentPage: currentPage,
    tab: tab,
    total_count:total_count,
  };
};

export const getSelectedMailThread = (
  currentMailId: string,
  Threads: RecordThreads
) => {
  return {
    type: actionTypes.CURRENT_SELECTED_MAIL,
    data: Threads,
    currentMailId: currentMailId,
  };
};

export const addDomainOfOrg = (domainRes: Domain) => {
  return {
    type: actionTypes.ADD_DOMAIN_OF_ORG,
    domainRes: domainRes,
  };
};

export const addDomainIntegrations = (
  domainRes: DomainIntegration[],
  DomainId: string
) => {
  return {
    type: actionTypes.ADD_DOMAIN_INTEGRATION,
    domainRes: domainRes,
    id: DomainId,
  };
};
export const addComposeMailInInboxThread = (
  Threads: SendMail,
  selectedMailId: string
) => {
  return {
    type: actionTypes.ADD_MAIL_IN_INBOX_THREAD,
    data: Threads,
    selectedMailId: selectedMailId,
  };
};
export const markAsReadMail = (
  mailId: string,
  tab: string,
  currentPage: string
) => {
  return {
    type: actionTypes.MARK_AS_READ_MAIL,
    mailid: mailId,
    tab: tab,
    currentPage: currentPage,
  };
};
export const currentCandidateMails = (
  mails: MailPreview[],
  currentCandidateId: string
) => {
  return {
    type: actionTypes.CURRENT_CANDIDATE_MAILS,
    data: mails,
    currentCandidateId: currentCandidateId,
  };
};
export const currentCandidateMailThread = (
  mails: RecordThreads,
  currentMailId: string
) => {
  return {
    type: actionTypes.CURRENT_CANDIDATE_MAIL_THREAD,
    data: mails,
    currentMailId: currentMailId,
  };
};
export const addMailInCurrentcandidateMails = (
  mails: SendMail,
  currentCandidateId: string
) => {
  return {
    type: actionTypes.ADD_MAIL_IN_CANDIDATE_MAIL_THREAD,
    data: mails,
    currentCandidateId: currentCandidateId,
  };
};
export const changeDomainStatus = (id: string) => {
  return {
    type: actionTypes.CHANGE_DOMAIN_STATUS,
    data: id,
  };
};

// Plugin

export const initGetAllMappings = (mappings: any) => {
  return {
    type: actionTypes.INIT_MAPPINGS,
    data: mappings,
  };
};

export const updateMappingAction = (payload: any) => {
  return {
    type: actionTypes.UPDATE_MAPPING,
    data: payload,
  };
};
// notification

export const notificationOptions = (notificationOptions: any) => {
  return {
    type: actionTypes.SET_NOTIFICATION_OPTIONS,
    data: notificationOptions,
  };
};
export const userNotificationSettingIntegration = (data: any) => {
  return {
    type: actionTypes.USER_NOTIFICATION_SETTINGS_INTEGRATION,
    payload: data,
  };
};

export const initAllNotifications = (notification: any) => {
  return {
    type: actionTypes.INIT_ALL_NOTIFICATIONS,
    data: notification,
  };
};

export const initUnreadNotifications = (notification: any) => {
  return {
    type: actionTypes.INIT_UNREAD_NOTIFICATIONS,
    data: notification,
  };
};

export const initDashboardNotification = (notification: any) => {
  return {
    type: actionTypes.INIT_DASHBOARD_NOTIFICATIONS,
    data: notification,
  };
};

export const initEntityUnreadNotifications = (notification: any) => {
  return {
    type: actionTypes.INIT_ENTITY_UNREAD_NOTIFICATIONS,
    data: notification,
  };
};



export const initCurrentCardWatcher = (subscription: any) => {
  return {
    type: actionTypes.INIT_CURRENT_CARD_WATCHER,
    data: subscription,
  };
};

export const initCurrentBoardWatcher = (subscription: any) => {
  return {
    type: actionTypes.INIT_CURRENT_BOARD_WATCHER,
    data: subscription,
  };
};

export const initEntityUnreadNotificationsBoard = (notification: any) => {
  return {
    type: actionTypes.INIT_ENTITY_UNREAD_NOTIFICATIONS_BOARD,
    data: notification,
  };
};

export const removeServiceNotificationDot = (entity_id: string) => {
  return {
    type: actionTypes.REMOVE_SERVICE_NOTIFICATION_DOT,
    data: entity_id,
  };
};



export const changeServiceNotificationsCountValue = (payload:any) => {
  return {
    type: actionTypes.CHANGE_SERVICE_NOTIFICATIONS_COUNT,
    payload: payload,
  };
};



export const initNotificationsInitialState =()=>{
  return {
    type: actionTypes.INIT_NOTIFICATIONS_INITIAL_STATE,
   
  };
}
export const changeServiceDotOfEntities =(payload:any)=>{
  return {
    type:actionTypes.CHANGE_SERVICE_DOT_OF_ENTITIES,
  payload:payload
}
}
export const changeBoardDotOfEntities =(payload:any)=>{
  return {
    type:actionTypes.CHANGE_BOARD_ENTITIES_DOT,
  payload:payload
}
}
export const initServiceEntityNotificationsDot =(payload:any)=>{
  return {
    type:actionTypes.INIT_NOTIFICATIONS_DOT,
  payload:payload
}
}
export const removeNotificationDotOfEntity =(payload:any)=>{
  return {
    type:actionTypes.REMOVE_NOTIFICATION_DOT_OF_ENTITY,
  payload:payload
}
}


//addCurrentJob
export const initCurrentJd = (JD: Jd) => {
  return {
    type: actionTypes.INIT_CURRENT_JD,
    JD: JD
  }
}
export const updateCurrentJd = (JD: Jd) => {
  return {
    type: actionTypes.UPDATE_CURRENT_JD,
    JD: JD
  }
}
export const addJDReviewer = (reviewers: string[]) => {
  return {
    type: actionTypes.ADD_JD_REVIEWER,
    reviewer: reviewers
  }
}
/********************company */
// filter
export const initSearchQuery = (query: string) => {
  return {
    type: actionTypes.SEARCH_QUERY,
    query: query,
  }
}
export const initSearchFilter = (filterObj: any) => {
  return {
    type: actionTypes.SEARCH_FILTER_QUERY,
    filterObj: filterObj
  }
}

export const initJobPostPoolSearchFilter = (filterObj: any) => {
  return {
    type: actionTypes.JOB_POST_POOL_SEARCH_FILTER_QUERY,
    filterObj: filterObj
  }
}

//Mail search

export const initMailSearchQuery = (query: string) => {
  return {
    type: actionTypes.MAIL_SEARCH_QUERY,
    query: query,
  }
}



export const currentEditableCompany = (companyDetails: company) => {
  return {
    type: actionTypes.CURRENT_COMPANY,
    company: companyDetails
  }
}
export const companyMember = (members: any) => {
  return {
    type: actionTypes.INIT_COMPANY_MEMBER,
    data: members,
  }
}
export const addMemberOnCompany = (memberID: any) => {
  return {
    type: actionTypes.ADD_MEMBER_ON_COMPANY,
    data: memberID,
  }
}
export const removeCandidateMember = (memberId: string) => {
  return {
    type: actionTypes.REMOVE_COMPANY_MEMBER,
    memberId: memberId,
  }
};

export const initCompanies = (companies: company[]) => {
  return {
    type: actionTypes.INIT_COMPANIES,
    payload: companies,
  }
}

export const addCompanies = (companies: company[]) => {
  return {
    type: actionTypes.ADD_COMPANY,
    payload: companies,
  }
}

export const addAssigneeToCompany = (members: string[], id: string) => {
  return {
    type: actionTypes.ADD_ASSIGNEE_TO_COMPANY,
    members: members,
    company_id: id
  }
}
export const removeAssigneeToCompany = (member: string, id: string) => {
  return {
    type: actionTypes.REMOVE_COMPANY_MEMBER,
    memberID: member,
    company_id: id
  }
}
export const addBoardToWorkspace = (company_id: string) => {
  return {
    type: actionTypes.ADD_BOARD_TO_WORKSPACE,
    company_id: company_id
  }
}
export const editCompanyDetails = (company: company) => {
  return {
    type: actionTypes.EDIT_WORKSPACE_DETAILS,
    data: company
  }
}
export const deleteWorkspaceMember = (memberId: string) => {
  return {
    type: actionTypes.REMOVE_MEMBER_FROM_WORKSPACE,
    memberId: memberId,
  }
};

// RTU notification
export const initNotificationCenterUnread = (notification: NotificatioRTU[],pagination:notificationsPagination) => {
  return {
    type: actionTypes.INIT_NOTIFICATION_CENTER_UNREAD,
    data: notification,
    pagination:pagination
  }
}

export const addNotificationFromWs = (notification: NotificatioRTU) => {
  return {
    type: actionTypes.ADD_NOTIFICATION_FROM_WS,
    data: notification
  }
}


// Action to update dots for a service, board, or card
export const addDots = (payload:any) => ({
  type: actionTypes.UNREAD_NOTIFICATION_DOT_ADDER,
  payload: payload,
});
export const showDotOrNotOnUpdates = (showDot:boolean) => ({
  type: actionTypes.SHOW_DOT_OR_NOT_ON_UPDATES,
  data: showDot,
});
export const unread_dot_remover = (payload:{model_name:string,service:string,remove_id:string}) => ({
  type: actionTypes.UNREAD_NOTIFICATION_DOT_REMOVER,
  data: payload,
});

export const dot_initial_state=()=> ({
  type: actionTypes.DOT_INITIAL_STATE,
  
});
// ORG creation
export const current_org = (payload:any) => ({
  type: actionTypes.ADD_CURRENT_ORG,
  data: payload,
});
export const update_current_org_creation_stages = (payload:any) => ({
  type: actionTypes.UPDATE_CURRENT_ORG_CREATION_STAGES,
  data: payload,
});
export const initCurrentOrg = () => ({
  type: actionTypes.INIT_CURRENT_ORG,
});

export const changeLoaderStatus = (payload:any) => ({
  type: actionTypes.CHANGE_LOADER_STATUS,
  data:payload
});
export const addChatIntegration= (payload:any) => ({
  type: actionTypes.ADD_CHAT_INTEGRATION,
  data:payload
});

export const updateChatIntegration= (payload:any) => ({
  type: actionTypes.UPDATE_CHAT_INTEGRATION,
  data:payload
});
export const addOrgMember=(payload:any)=>({
  type: actionTypes.ADD_ORG_MEMBER,
  data:payload
})

export const updateUserServices=(payload:any)=>({
  type: actionTypes.UPDATE_USER_SERVICES,
  data:payload
})

// ATS pool
export const initCandidatesAtsPool = (data: Candidate[], currentpage: string) => {
  return {
    type: actionTypes.INIT_CANDIDATES_ATS_POOL,
    data: data,
    currentpage: currentpage,
  };
};

export const InitCurrentCandidateInAtsPool = (candidate: Candidate) => {
  return {
    type: actionTypes.INIT_CURRENT_CANDIDATE_IN_ATS_POOL,
    candidate: candidate,
  };
};

export const InitCurrentCandidateLabelsInAtsPool = (labels: Label[]) => {
  return {
    type: actionTypes.INIT_CURRENT_CANDIDATE_LABELS_IN_ATS_POOL,
    labels: labels,
  };
};
export const addLabelToPoolCandidateInATS = (
  data: any,
  candidateId: string
) => {
  return {
    type: actionTypes.ADD_LABEL_TO_POOL_CANDIDATE_IN_ATS,
    data: data,
    candidateId: candidateId,
  };
};
export const removeLabelFromPoolInATS = (
  reviewId: string,
  labels: string[]
) => {
  return {
    type: actionTypes.REMOVE_LABEL_FROM_POOL_CANDIDATE_IN_ATS,
    reviewId: reviewId,
    labels: labels,
  };
};

export const addAttachmentToCurrentCandidateInAtsPool = (
  attachment: Attachment[]
) => {
  return {
    type: actionTypes.ADD_POOL_CANDIDATE_ATTACHMENT,
    data: attachment,
  };
};

export const addAttachmentUploadStatus = (
  status: {}
) => {
  return {
    type: actionTypes.ADD_ATTACHMENT_UPLOAD_STATUS,
    data: status,
  };
};

export const addCDBAttachmentUploadStatus = (
  status: {}
) => {
  return {
    type: actionTypes.ADD_CDB_ATTACHMENT_UPLOAD_STATUS,
    data: status,
  };
};


export const addNewAttachmentToCurrentCandidateInAtsPool = (
  attachment: Attachment[]
) => {
  return {
    type: actionTypes.ADD_NEW_POOL_CANDIDATE_ATTACHMENT,
    data: attachment,
  };
};


export const removeLabelFromPoolCandidate = (
  candidateIds: string[],
  labels: string[]
) => {
  
  return {
    type: actionTypes.REMOVE_LABEL_FROM_POOL_CANDIDATE,
    data: candidateIds,
    labels: labels,
  };
};
export const removeLabelFromTalentPoolCandidate = (
  candidateIds: string[],
  labels: string[]
) => {
  
  return {
    type: actionTypes.REMOVE_LABEL_FROM_TALENT_POOL_CANDIDATE,
    data: candidateIds,
    labels: labels,
  };
};



export const addLabelsToMultipleAtsPoolCandidates = (data: any) => {
  return {
    type: actionTypes.ADD_LABELS_ON_MULTIPLE_ATS_POOL_CANDIDATES,
    data: data,
  };
};

export const addLabelsToMultipleCandidatesInTalentPool = (data: any) => {
  return {
    type: actionTypes.ADD_LABEL_ON_MULTIPLE_CANDIDATE_IN_CDB,
    data: data,
  };
};

export const updateCareerPageDetails = (details: { [key: string]: string })=>{
  return{
    type:actionTypes.UPDATE_ORG_SETTING_DETAILS,
    payload: details,
  };   
};




//init source
export const initSources = (sources: Source[]) => {
  return {
    type: actionTypes.INIT_SOURCES,
    payload: sources,
  }
}


//init job platform
export const initJobPlatform = (Integrations: ThirdPartyIntegrations[]) => {
  return {
    type: actionTypes.INIT_JOB_PLATFORM,
    payload: Integrations,
  }
}


// applicant status of tracker
export const applicantStatus = (applicants: { id: string, trackers: string[] }[]) => {
  return {
    type: actionTypes.APPLICANT_STATUS_FOR_TRACKER,
    payload: applicants,
  }
}

export const setResumeRelevancyFilterNameAndCount = (data: {name: string, count: number}) => {
  return {
    type: actionTypes.SET_RESUME_RELEVANCY_FILTER_NAME_AND_COUNT,
    payload: data,
  }
}

export const setViewType = (viewType: string) => ({
  type: actionTypes.SET_VIEW_TYPE,
  data: { viewType },
});
export const setUserNotificationTimezone = (data:UserNotificationSettings) => {
  return {
    type: actionTypes.SET_USER_NOTIFICATION_TIMEZONE,
    payload: data,
  }
}

//e-sign
export const setEsignDetails = (data:eSignDetails) => {
  return {
    type: actionTypes.SET_ESIGN_DETAILS,
    payload: data,
  }
}
export const updateEsignDetails = (data:Partial<eSignDetails>) => {
  return {
    type: actionTypes.UPDATE_ESIGN_DETAILS,
    payload: data,
  }
}
export const clearEsignDetails = () => {
  return {
    type: actionTypes.CLEAR_ESIGN_DETAILS,
  }
}

export const initFeedback = (
  data: feedback[] ,
  meetingId: string,
) => {
  return {
    type: actionTypes.INIT_FEEDBACK,
    data: data,
    meetingId: meetingId,
  };
};

export const addFeedback = (data: feedback) => {
  return {
    type: actionTypes.ADD_FEEDBACK,
    data: data,
  };
};

export const updateFeedback = (updateFeedback: any) => {
  return {
    type: actionTypes.UPDATE_FEEDBACK,
    data: updateFeedback,
  };
}
