import React, { useRef, useState } from "react"
import { Scope} from "../../type/type.d"
import { Overlay } from "../hook/Overlay";
import Popover from "antd/es/popover";
import Typography from "antd/es/typography";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter"
import { MEMBERS } from "../../constant"
import { IconButton, LabeledIconButton } from "../../ant/Button"
import CloseOutlined from "@ant-design/icons/CloseOutlined"
import TeamOutlined from "@ant-design/icons/TeamOutlined"
import ShowCDBMemberPopup from "./ShowCDBMemberPopup"
const { Text } = Typography


const AddBoardMember = () => {
  const [open, setOpen] = useState(false);
  let refer = useRef(null);
  return (
    <div className="z-0">
      <Popover
        open={open}
        onOpenChange={(open) => setOpen(open)}
        title={<div className="flex w-full bg-white items-center">
          <Text className="w-full flex justify-center">
            <Text>{capitalizeFirstLetter(MEMBERS)}</Text>
          </Text>
          <LabeledIconButton
            type="text"
            label=""
            onClick={() => setOpen(false)}
            icon={<CloseOutlined />}
          />
        </div>} trigger={["click"]} content={<Overlay onClose={() => setOpen(!open)} scope={Scope.Popover} reference={refer}>
          <ShowCDBMemberPopup click={() => setOpen(!open)} refer={refer} /></Overlay>
        }>
         
          <IconButton
            className="nav-btn"
            onClick={() => setOpen(true)}
            tooltipTitle="Members"
            icon={<TeamOutlined/>}
          />
      </Popover>
    </div>
  );
};

export default (AddBoardMember);
