import React, { useEffect, useRef, useState } from "react";
import Button from "antd/es/button";
import Input from "antd/es/input";
import Spin from "antd/es/spin";
import Popconfirm from "antd/es/popconfirm";
import { connect } from "react-redux";
import { generateFeedbackUsingGpt } from "../../lib/api";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import GPT from "../../assets/GPT";
import { useParams } from "react-router-dom";
const { TextArea } = Input;
import CopyOutlined from "@ant-design/icons/CopyOutlined";
import SendOutlined from "@ant-design/icons/SendOutlined";
import InsertRowBelowOutlined from "@ant-design/icons/InsertRowBelowOutlined";
import EditOutlined from "@ant-design/icons/EditOutlined";
import CloseOutlined from "@ant-design/icons/CloseOutlined";
import parse from "html-react-parser";
import LoadingOutlined from "@ant-design/icons/LoadingOutlined";
import Tooltip from "antd/lib/tooltip";

const parseHtmlToText = (html: string): string =>
  new DOMParser().parseFromString(html, "text/html").body.textContent?.trim() ||
  "";

// type of user input while creating job post with AI
interface Message {
  text: string;
  editable: boolean;
  edited: boolean;
  aiResponse?: string; // Add AI response field
  copied?: boolean; // Add copied state
}

type ChatboxProps = {
  handleChatBoxFromParent: (value: boolean) => void;
  getJobDescription: (description: string) => void;
  title: string;
  handleLoading: (value: boolean) => void;
  // getDisableState : (value: boolean) =>void;
  currentOrg?: any;
  companyDes?: string;
};

const Chatbox = (props: ChatboxProps) => {
  const [currentMessage, setCurrentMessage] = useState("");
  const [messages, setMessages] = useState<Message[]>([]);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [disableButton, setDisableButton] = useState(false);
  const [chatVisibility, setChatVisibility] = useState(true);
  const params: { org_id: string; meeting_id: string } = useParams();
  const [apiError, setApiError] = useState<string | null>(null);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const [showCloseConfirm, setShowCloseConfirm] = useState(false);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "auto" });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]); // Scroll when messages array changes

  //after click on edit message the function will call
  // and clicked message will be in input for update
  const handleUserInput = () => {
    if (currentMessage.trim() !== "") {
      if (editIndex !== null) {
        const updatedMessages = [...messages];
        updatedMessages[editIndex].text = currentMessage;
        updatedMessages[editIndex].editable = false;
        updatedMessages[editIndex].edited = true;
        setMessages(updatedMessages);
        callApi(currentMessage);
        setEditIndex(null);
      } else {
        // setMessages([...messages, { text: currentMessage, editable: false,edited:false }]);
        callApi(currentMessage);
      }
      setCurrentMessage("");
    }
  };

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter" && (e.metaKey || e.ctrlKey)) {
      e.preventDefault(); // Prevents adding a new line
      handleUserInput();
    }
  };

  // calling GPT api
  const callApi = (messageText: string) => {
    let JDReq = {
      meeting_id: params.meeting_id,
      Prompt: messageText,
    };

    setDisableButton(true);

    // Add new message first
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: messageText, editable: false, edited: false },
    ]);

    generateFeedbackUsingGpt(JDReq)
      .then((res: any) => {
        const response = res.gpt_response_data.data[0]?.content[0]?.text?.value;

        // Ensure we update the latest message with AI response
        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          updatedMessages[updatedMessages.length - 1] = {
            ...updatedMessages[updatedMessages.length - 1],
            aiResponse: response,
          };
          return updatedMessages;
        });

        setDisableButton(false);
      })
      .catch((error) => {
        console.log(error);
        setApiError("Failed to get response. Please try again.");
        setDisableButton(false);
      });
  };

  // set edit click message index
  const handleEdit = (index: number) => {
    setEditIndex(index);
    setCurrentMessage(messages[index].text);
  };

  // after click on close button hiding the chatBox
  const handleChatBoxVisibility = () => {
    setChatVisibility(false);
    props.handleChatBoxFromParent(false);
  };

  return (
    <>
      {/* {chatVisibility && ( */}
      <div
        className={` sm:w-full md:w-4/6  ai-chatbox ${
          chatVisibility
            ? "animate-error_prompt_slide_in"
            : " animate-error_prompt_slide_out hidden "
        }`}
        style={{
          position: "relative",
          zIndex: 1,
          background: "white",
          backdropFilter: "none",
          transition: "transform 270ms ease",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          borderBottom: "1px solid rgba(0, 0, 0, 0.10)",
          borderLeft: "1px solid rgba(0, 0, 0, 0.10)",
          borderBottomRightRadius: "4px",
          borderTopRightRadius: "4px",
          //   height: "calc(-68px + 85vh)"
          height: "100%",
          maxWidth: "521.2px",
        }}
      >
        <div
          className="text-black w-full  p-1 py-1.5 rounded-tr -mr-4"
          style={{
            backgroundColor: "#F0EFFE",
            boxShadow:
              "var(--ds-shadow-raised,0 1px 1px #091e4240,0 0 1px #091e424f)",
          }}
        >
          <div className="flex justify-between px-3">
            <div className="flex items-center space-x-2 text-tacitbase-secondary3 font-medium text-sm">
              {" "}
              <div>
                {" "}
                <GPT fill="#4B48A5" />
              </div>
              <div className="">Generate with AI</div>
            </div>
            <Popconfirm
              title="Close Chat"
              description={
                <div style={{ width: "350px" }}>
                  Are you sure you want to close the chat? Closing the chat window will delete all chat history.
                </div>
              }              open={showCloseConfirm && messages.length > 0}
              onConfirm={() => {
                handleChatBoxVisibility();
                setShowCloseConfirm(false);
              }}
              onCancel={() => setShowCloseConfirm(false)}
              okText="Yes"
              cancelText="No"
            >
              <CloseOutlined
                style={{ color: "#4B48A5" }}
                onClick={() => {
                  if (messages.length > 0) {
                    setShowCloseConfirm(true);
                  } else {
                    handleChatBoxVisibility();
                  }
                }}
              />
            </Popconfirm>
          </div>
        </div>
        <div
          className="p-4"
          style={{
            flex: 1,
            paddingLeft: "10px",
            paddingRight: "10px",
            marginTop: "2px",
            paddingBottom: "10px",
            overflowY: "auto",
            scrollBehavior: "smooth",
          }}
        >
          {messages.map((message: Message, index: number) => (
            <React.Fragment key={index}>
              {/* User message */}
              <div className="w-3/4 flex justify-end items-end pl-2 space-x-2 mb-4">
                <div
                  className="h-auto text-black flex-wrap"
                  style={{
                    borderRadius: "8px",
                    padding: "10px",
                    boxShadow: "var(--ds-shadow-raised,0 1px 1px #091e4240)",
                    backgroundColor: "#F0EFFE",
                    maxWidth: "400px",
                  }}
                >
                  {message.text}
                </div>
                <Button
                  type="text"
                  size="small"
                  icon={<EditOutlined onClick={() => handleEdit(index)} />}
                />
              </div>

              {/* AI Response or Loading/Error */}
              <div className="w-11/12 flex justify-start items-start pl-2 mb-4">
                {message.aiResponse ? (
                  <div
                    className="h-auto text-black flex-wrap relative"
                    style={{
                      borderRadius: "8px",
                      padding: "10px",
                      boxShadow: "var(--ds-shadow-raised,0 1px 1px #091e4240)",
                      backgroundColor: "#fff",
                      maxWidth: "400px",
                      width: "100%",
                    }}
                  >
                    {parse(message.aiResponse)}
                  </div>
                ) : apiError ? (
                  <div className="h-auto flex items-center justify-center p-4 text-red-500">
                    {apiError}
                  </div>
                ) : (
                  <div className="h-auto flex items-center justify-center p-4">
                    <Spin
                      indicator={
                        <LoadingOutlined style={{ fontSize: 24 }} spin />
                      }
                    />
                  </div>
                )}
              </div>

              {/* Action Buttons */}
              {message.aiResponse && (
                <div className="w-11/12 flex justify-end space-x-2 mb-4">
                  <Tooltip title="Copy text" mouseLeaveDelay={0}>
                    <Button
                      type="text"
                      size="small"
                      icon={
                        <CopyOutlined
                          style={{
                            color: message.copied ? "#4BB543" : undefined,
                          }}
                        />
                      }
                      onClick={() => {
                        navigator.clipboard.writeText(
                          parseHtmlToText(message.aiResponse || "")
                        );
                        // Update copied state
                        const updatedMessages = [...messages];
                        updatedMessages[index] = {
                          ...updatedMessages[index],
                          copied: true,
                        };
                        setMessages(updatedMessages);
                        // Reset copied state after 2 seconds
                        setTimeout(() => {
                          const resetMessages = [...messages];
                          resetMessages[index] = {
                            ...resetMessages[index],
                            copied: false,
                          };
                          setMessages(resetMessages);
                        }, 2000);
                      }}
                    />
                  </Tooltip>
                  <Tooltip title="Insert text" mouseLeaveDelay={0}>
                    <Button
                      type="text"
                      size="small"
                      icon={<InsertRowBelowOutlined />}
                      onClick={() => {
                        props.handleLoading(true);
                        props.getJobDescription(message.aiResponse || "");
                        props.handleLoading(false);
                      }}
                    />
                  </Tooltip>
                </div>
              )}

              {message.edited && (
                <div className="w-full max-w-full flex justify-end items-end mb-4 pr-8">
                  <div className="text-gray-500 text-xs">edited</div>
                </div>
              )}
            </React.Fragment>
          ))}
          <div ref={messagesEndRef} />
        </div>
        <label className="pl-4 pb-1">
          <span className="text-red-400">*</span>
          {capitalizeFirstLetter("instructions")}
        </label>
        <div
          className="px-4"
          style={{
            position: "relative",
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <TextArea
            className="w-full mt-1 rounded "
            rows={3}
            autoSize={{ minRows: 3, maxRows: 6 }}
            maxLength={250}
            value={currentMessage}
            style={{
              resize: "none",
              paddingRight: 55,
              marginBottom: "15px",
            }}
            onKeyDown={handleKeyPress}
            placeholder="Please provide key points for feedback."
            onChange={(e) => setCurrentMessage(e.target.value)}
          />
          <SendOutlined
            size={10}
            className="bg-green-600 rounded text-white p-2"
            style={{
              position: "absolute",
              right: "25px",
              fontSize: "10px",
              cursor: "pointer",
              bottom: 25,
            }}
            onClick={handleUserInput}
            disabled={disableButton}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  currentOrg: state.opening.currentOrg,
  companyDescription: state.opening.applicationDes,
});
export default connect(mapStateToProps)(Chatbox);
