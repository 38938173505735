import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { exchangeZoomOAuthCode } from '../../../lib/api';

interface ZoomOAuthParams {
  code: string;
  state: string;
}

const ZoomOAuthRedirect = () => {
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const zoomParams: ZoomOAuthParams = {
      state: queryParams.get('state') || '',
      code: queryParams.get('code') || '',
    };
    if (zoomParams.state && zoomParams.code) {
        exchangeZoomOAuthCode(zoomParams.code, zoomParams.state, "zoom")
        .then((response: any) => {
          if (response) notifyOpener('connected', response);
        })
        .catch((error) => {
          let errorMessage = 'Failed to connect to Zoom.';
          if (error.response) {
            errorMessage = error.response.data.message || errorMessage;
          } else if (error.request) {
            errorMessage = 'No response received from the server.';
          }
          notifyOpener('error', errorMessage);
        })
        .finally(() => {
          window.close(); // Close the popup window
        });
    } else {
      console.error('State or code is missing.');
      notifyOpener('error', 'State or code is missing. Please try again.');
    }
  }, [location]);

  const notifyOpener = (status: string, message: string): void => {
    if (window.opener) {
      window.opener.postMessage({ status, message }, window.location.origin);
    } else {
      alert(message);
    }
  };

  return <div>Connecting to Zoom... Please wait.</div>;
};

export default ZoomOAuthRedirect;
