import React, { ComponentType } from "react";
import { connect } from "react-redux";
import { loginUser } from "./type/type";
import { Redirect } from "react-router-dom";

interface WithEmailVerificationProps {
  loginUser: loginUser;
}

const withEmailVerification = (WrappedComponent: ComponentType<any>) => {
  const ComponentWithVerification: React.FC<WithEmailVerificationProps> = ({
    loginUser,
    ...restOfProps
  }) => {
    if (!loginUser?.user?.verified) {
      return <Redirect to={`/register`} />;
    }
    return <WrappedComponent {...restOfProps} />;
  };

  const mapStateToProps = (state: any) => ({
    loginUser: state.opening.loginUser,
  });

  return connect(mapStateToProps)(ComponentWithVerification);
};

export default withEmailVerification;
