import React, { memo, useState } from "react";
import { connect } from "react-redux";
import FilterPopup from "./FilterPopup";
import CloseIcon from "../../../assets/Close";
import { useHistory } from "react-router-dom";
import { Dispatch } from "redux";
import { removeFilters } from "../../../redux/actions/opening/action";
import { Opening, Scope } from "../../../type/type.d";
import { Overlay } from "../../hook/Overlay";
import FilterOutlined from "@ant-design/icons/FilterOutlined";
import Tooltip from 'antd/es/tooltip';
import { IconButton } from "../../../ant/Button";

export type FilterProps = {
  applicationId: string;
  currentOpening: Opening;
  removeFilters: () => void;
  filterCount: number;
};
const Filter = (props: FilterProps) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const removeFilter = (e: any) => {
    e.stopPropagation();
    props.removeFilters();
    history.push({
      pathname: `/o/${props.applicationId}/b/${props.currentOpening.id}/tracker`,
    });
  };
  return (
    <>
      <div className="mt-2">
        {props.filterCount != null ? (
          <span
            className="bg-white bg-opacity-50  text-black h-7 w-auto flex  justify-center items-center  hover:bg-opacity-50 rounded text-sm cursor-pointer focus:outline-none capitalize"
            onClick={() => setOpen(!open)}
            aria-hidden
          >
            <span className="p-2 flex justify-center items-center">
              <span className="mx-2 flex space-x-1 items-center ">
                {" "}
                {/* <i className="fa fa-filter" aria-hidden="true"></i> */}
                <FilterOutlined style={{color:'black'}}/>
                {/* <span>Filter</span> */}
              </span>{" "}
              <span className="rounded-full bg-white w-auto px-2">
                {props.filterCount}
              </span>
            </span>
            <Tooltip title="Remove filter">
            <button
              onClick={(e: any) => removeFilter(e)}
              className="bg-white h-7 w-7 flex justify-center items-center rounded bg-opacity-60"
            >
              <CloseIcon />
            </button>
            </Tooltip>
          </span>
        ) : (
          <IconButton
            tooltipTitle="Apply filter"
              onClick={() => setOpen(!open)}
              className="flex items-center justify-center"
              icon={<FilterOutlined/>}
            />

        )}
      </div>

      {open ? (
        <Overlay scope={Scope.Popover} onClose={() => setOpen(!open)}>
          <div>
            <FilterPopup click={() => setOpen(!open)} />
          </div>
        </Overlay>
      ) : null}
    </>
  );
};
const mapStateToProps = (state: any) => ({
  openingMembers: state.opening.openingMembers,
  currentOpening: state.opening.currentOpening,
  applicationId: state.opening.applicationId,
  filterCount: state.opening.filterCount,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  removeFilters: () => dispatch(removeFilters()),
});
export default memo(connect(mapStateToProps, mapPropsToState)(Filter));
