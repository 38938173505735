import React  from "react";

import WorkspaceSelector from "./OrgSwitcher1";
import OrgPreviewSection from "./OrgPreviewSection";
import useDesktopWarning from "../../utils/useDesktopWarning";
import DesktopWarning from "./DesktopWarning";
const UserLanding = () => {
  const { showWarning, setShowWarning } = useDesktopWarning();
  return (
      <div className="flex flex-col lg:flex-row h-screen w-full bg-white">
            <div className="lg:block w-full h-full lg:rounded ">
              <div className=" flex justify-center items-center h-full w-full">
              <OrgPreviewSection renderComponent={WorkspaceSelector}
              />
              </div>
            </div>
            {showWarning && <DesktopWarning onClose={() => setShowWarning(false)} />}
          </div>
  );
};

export default UserLanding;
