import React from "react";
import { Button } from "antd";

interface DesktopWarningProps {
  onClose: () => void;
}

const DesktopWarning: React.FC<DesktopWarningProps> = ({ onClose }) => {
  return (
    <>
      {/* Overlay to prevent interaction */}
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50"></div>

      {/* Warning message positioned at the bottom */}
      <div className="fixed bottom-0 left-0 w-full bg-white p-4 z-50 shadow-md">
        <p className="text-center text-gray-800 font-medium">
          For the best experience, please use this application on a desktop browser.
          Some features may not work properly on mobile.
        </p>
        <div className="mt-3 flex justify-center">
          <Button type="primary" onClick={onClose}>
            Continue Anyway
          </Button>
        </div>
      </div>
    </>
  );
};

export default DesktopWarning;
