// Imports
import React, { useEffect, useState, useMemo } from "react";
import Button from "antd/es/button";
import Select from "antd/es/select";
import Space from "antd/es/space";
import dayjs, { Dayjs } from "dayjs";
import { Views } from "react-big-calendar";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import CalendarMain from "./Calendar";
import { getJobPostEvents } from "../../../lib/api";
import { getDateRangeForView, getUserTimezone, processEvents } from "../global/calendarUtils";
import { RootState } from "../../../redux/reducers/root";
import { useParams } from "react-router-dom";
import { setBoardCalendarEvents } from "../../../redux/reducers/calendarSlice";
import { CalendarItem } from "../../../lib/apiRes";
import LeftOutlined from "@ant-design/icons/LeftOutlined";
import RightOutlined from "@ant-design/icons/RightOutlined";

// Redux Selectors
const selectEvents = (state: RootState) =>
  Object.values(state.calendar.boardEventsByCalendar).flatMap((calendar: any) =>
    Object.values(calendar)
  );
  const selectLoginUserData = (state: RootState) => state.opening.loginUser;
  const selectCalendarList = (state: RootState) => state.calendar.calendarList;


// Types
type ViewType = string;


const BoardCalendarView = () => {
  const [isLoading, setIsLoading] = useState(false);
  const params: { board_id: string } = useParams();
  const dispatch = useDispatch();
  const [currentCalendar, setCurrentCalendar] = useState<CalendarItem>();

  // State
  const [currentDate, setCurrentDate] = useState<Dayjs>(dayjs());
  const [view, setView] = useState<ViewType>(Views.MONTH);

  // Selectors
  const events = useSelector(selectEvents, shallowEqual);
  const loginUserData = useSelector(selectLoginUserData, shallowEqual);
  const calendarList = useSelector(selectCalendarList, shallowEqual);

  // Memoized Values
  const dateRange = useMemo(
    () => getDateRangeForView(currentDate, view),
    [currentDate.valueOf(), view]
  );
  const timeMin = dateRange.start.toISOString();
  const timeMax = dateRange.end.toISOString();

  // Event Handlers
  const handleToday = () => {
    setCurrentDate(dayjs());
  };

  useEffect(() => {
    // Determine primaryOrTeamId based on the conditions
    
    if (Object.values(calendarList)?.length>0) {

      const primaryOrTeamId: any=
        Object.values(calendarList)?.find((item:any) => item.is_team_calendar) ||
        Object.values(calendarList)?.find((item: any) => item.primary);

      // let primaryOrTeamId;
      if (primaryOrTeamId) {
        fetchEvents(primaryOrTeamId);
        setCurrentCalendar(primaryOrTeamId);
      }
    }
  }, [calendarList]); // Empty dependency array means this runs once when component mounts

  const handlePrev = () => {
    const newDate = currentDate.subtract(
      1,
      view === Views.MONTH ? "month" : view === Views.WEEK ? "week" : "day"
    );
    setCurrentDate(newDate);
  };

  const handleNext = () => {
    const newDate = currentDate.add(
      1,
      view === Views.MONTH ? "month" : view === Views.WEEK ? "week" : "day"
    );
    setCurrentDate(newDate);
  };

  // API Functions
  const fetchEvents = (calendar: CalendarItem) => {
    setIsLoading(true);
    getJobPostEvents(calendar.id, loginUserData.user.id, params.board_id,getUserTimezone().name, {
      timeMin,
      timeMax,
    })
      .then((eventsRes) => {
        if (eventsRes && eventsRes.items) {
          const processedEvents = processEvents(
            eventsRes.items,
            calendar.id
          );
          dispatch(
            setBoardCalendarEvents({
              calendarId: calendar.id,
              events: processedEvents,
            })
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching calendar events:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Effects
  useEffect(() => {
    currentCalendar && fetchEvents(currentCalendar);
  }, [dateRange]);

  // Render
  return (
    <div className="h-full flex flex-col">
      <div
        className="cal-panal rounded h-full px-4 pb-4"
        style={{ height: "calc(100vh - 95.4px)" }}
      >
        <div className="rounded bg-white w-full h-full z-0">
          <div className="flex py-2 px-4">
            <div className="flex justify-between w-full">
              <Space>
                <Button onClick={handleToday}>Today</Button>
                <Button onClick={handlePrev} icon={<LeftOutlined className="w-3"></LeftOutlined>}></Button>
                <Button onClick={handleNext} icon={<RightOutlined className="w-3" />}></Button>
               
              </Space>
              <Space className="">
                {currentDate.format("MMMM YYYY")}
              </Space>
              <Select value={view} style={{ width: 120 }} onChange={setView}>
                  <Select.Option value={Views.MONTH}>Month</Select.Option>
                  <Select.Option value={Views.WEEK}>Week</Select.Option>
                  <Select.Option value={Views.DAY}>Day</Select.Option>
                  <Select.Option value={Views.AGENDA}>Agenda</Select.Option>
                  {/* <Select.Option value={Views.WORK_WEEK}>
                    Work week
                  </Select.Option> */}
                </Select>
            </div>
          </div>

          <CalendarMain
            isLoading={isLoading}
            events={events}
            view={view}
            currentDate={currentDate}
            onViewChange={() => {}}
            onNavigate={() => {}}
          />
        </div>
      </div>
    </div>
  );
};

export default BoardCalendarView;
