import React, { useRef, useState } from "react"
import { connect } from "react-redux"
import { Candidate, loginUser, Scope } from "../../type/type.d"

import Divider from 'antd/es/divider';
import Popover from 'antd/es/popover';
import Typography from 'antd/es/typography';
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter"
import { LabeledIconButton } from "../../ant/Button"
import CloseOutlined from "@ant-design/icons/CloseOutlined"
import { ADD_CANDIDATE, CANDIDATE_ADDED_SUCCESS, CANDIDATES, PRIVATE_MODE, TACITBASEQA_MEDIUM } from "../../constant"
import { Overlay } from "../../components/hook/Overlay"
import QuickAddCandidatePopup from "../../components/candidateDB/QuickAddCandidatePopup"
import { createQuickCandidate, uploadAttachmentOfCandidate } from "../../lib/api"
import { Dispatch } from "redux"
import { addCandidateInPool } from "../../redux/actions/opening/action"
import { throwSuccess } from "../../service/throwError"
import { OrgInfo } from "../../lib/apiRes"
const { Text } = Typography
const AddBoardMember = (props: {
    addCandidateInPool: (candidate: any) => void, currentTrackerJd: any,loginUser:loginUser,org:OrgInfo
}

) => {
    const [open, setOpen] = useState(false)
    let refer = useRef(null)
    const [isLoading, setLoading] = useState(false)

    const onCreateCandidate = (req: any, file: any) => {
        setLoading(true)
        createQuickCandidate(PRIVATE_MODE,{
            ...req,
            source:{
                jd:props.currentTrackerJd.id,
                medium:TACITBASEQA_MEDIUM
            },
        }).then((res: Candidate) => {

            props.addCandidateInPool(res)
            if (file && file.getAll("file").length > 0) {
                uploadAttachmentOfCandidate(file, CANDIDATES, res.id,props.org.id,props.loginUser.user.id).then((res) => {
                    if (res) {
                        setOpen(false)
                        setLoading(false)

                        throwSuccess(CANDIDATE_ADDED_SUCCESS)
                    }
                }).catch(() => {
                    setOpen(false)
                    setLoading(false)

                })
            } else {
                setOpen(false)
                setLoading(false)

                throwSuccess(CANDIDATE_ADDED_SUCCESS)

            }

        }).catch(() => {
            setOpen(false)
            setLoading(false)

        })
    }

    return (
        <>
            <Popover placement="bottomLeft"  destroyTooltipOnHide title={<div className="flex w-full bg-white items-center" >
                <Text className="w-full flex justify-center">
                    <Text>{capitalizeFirstLetter(ADD_CANDIDATE)}</Text>
                </Text>
                <LabeledIconButton
                    type="text"
                    label=""
                    onClick={() => setOpen(false)}
                    icon={<CloseOutlined />}
                />
            </div>} open={open} onOpenChange={(open) => setOpen(open)} trigger={["click"]}
                content={<Overlay onClose={() => setOpen(false)} scope={Scope.Popover} >
                    <div className="w-143">
                        <Divider className="my-2" />


                        <QuickAddCandidatePopup loading={isLoading} click={() => setOpen(false)} refer={refer} onCreateCandidate={onCreateCandidate} />
                    </div>
                </Overlay >}>
                <div className="" >

                    {/* <LabeledIconButton
                        label="Quick add candidate" size="small"
                        onClick={() => setOpen(true)}
                        className="bg-black bg-opacity-30 text-white hover:bg-black hover:bg-opacity-50"

                    //   style={{backgroundColor:'black',color:'white'}}
                    >
                    </LabeledIconButton> */}
                                      <LabeledIconButton label="Create"  type="default" className="h-7 " ></LabeledIconButton>

                </div>
            </Popover >

        </>
    )
}
const mapPropsToState = (dispatch: Dispatch) => ({
    addCandidateInPool: (candidate: any) => dispatch(addCandidateInPool(candidate)),
})
const mapStateToProps = (state: any) => ({
    currentOpening: state.opening.currentOpening,
    currentTrackerJd: state.opening.currentTrackerJd,
    loginUser: state.opening.loginUser,
    org:state.opening.currentOrg,
})
export default connect(mapStateToProps, mapPropsToState)(AddBoardMember)
