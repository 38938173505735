import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { showCandidateDBMember } from "../../lib/api";
import { candidateDBMember } from "../../redux/actions/opening/action";
import { Opening, orgMember, openingMemberType, OpeningMembers, loginUser, Member } from "../../type/type.d"
import TacitbaseAvtar from "../../ant/Avtar"
import Avatar from "antd/es/avatar";
import Divider from "antd/es/divider";
import { generateTooltipForMember } from "../../utils/capitalizeFirstLetter"
import { servicesMembership } from "../../redux/reducers/initialState"

type BoardMemberProps = {
  loginUser: loginUser;
  currentOpening: Opening;
  openingMembers: OpeningMembers;
  member: orgMember;
  servicesMembership: servicesMembership;
  initCDBMembers: (members: Member[]) => void;
};
const BoardMember = (props: BoardMemberProps) => {
  useEffect(() => {
    showCandidateDBMember().then((res) => {
      props.initCDBMembers(res);
    });
    return () => {};
  }, []);

  if (props.servicesMembership?.CDBMember?.length === 0 || Object.keys(props.member)?.length === 0) {
    return null
  }
  const ownerMember = Object.values(props.member).filter((member: Member) => {
    return member && member.registrations[0].roles[0] === "Owner"
  })

  const isAdminCreator = ownerMember.length > 0 ? true : false


  const membersWithoutCreator = props.servicesMembership?.CDBMember
    ?.filter((memberId: openingMemberType) => props.member[memberId.id] && props.member[memberId.id]?.registrations[0].data.activated && memberId.id !== ownerMember[0].id)
    .map((memberId: openingMemberType) => (
      props.member[memberId.id].image_url ?
        <TacitbaseAvtar
          key={props.member[memberId.id].id}
          src={props.member[memberId.id].image_url}
          size="default"
          toolTip={generateTooltipForMember(props.member[memberId.id])}
        /> : <TacitbaseAvtar
          key={props.member[memberId.id].id}
          content={props.member[memberId.id].first_name[0] + props.member[memberId.id].last_name[0]}
          size="default"
          toolTip={generateTooltipForMember(props.member[memberId.id])}
        />
    ))

  return (
    <div className="flex flex-row items-center h-auto space-x-0.5 mr-1">
      {isAdminCreator && (
        <Avatar.Group maxCount={4} size={"default"}>
          <div className="isEditable">
            {ownerMember[0].image_url ?
              <TacitbaseAvtar
                src={ownerMember[0].image_url}
                key={ownerMember[0].id}
                size="default"
              /> : <TacitbaseAvtar
                content={ownerMember[0].first_name[0] + ownerMember[0].last_name[0]}
                key={ownerMember[0].id}
                size="default"
              />}
          </div>
        </Avatar.Group>
      )}
      {membersWithoutCreator?.length > 0 && (
        <>
          <Divider type="vertical" style={{ borderColor: "black" }} />
          <Avatar.Group maxCount={4} size={"default"}>{membersWithoutCreator}</Avatar.Group>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  openingMembers: state.opening.openingMembers,
  member: state.opening.members,
  currentOpening: state.opening.currentOpening,
  servicesMembership: state.opening.servicesMembership,
  loginUser: state.opening.loginUser,
});

const mapPropsToState = (dispatch: Dispatch) => ({
  initCDBMembers: (members: Member[]) => dispatch(candidateDBMember(members)),
});

export default connect(mapStateToProps, mapPropsToState)(BoardMember);
