import dayjs from "dayjs";
import React, { useRef} from "react";
import { connect } from "react-redux";
import { DATE_TIME_FORMATE } from "../../constant";
// import { seperateMailAddress } from "../../utils/seperateMailAddress";
import { Mail } from "../../type/type.d";

type ShowMailDetailsPopupProps = {
  mail: Mail;
};


const ShowMailDetailsPopup = (props: ShowMailDetailsPopupProps) => {
  
  const refer=useRef(null)
  
  return (  
              <div className="w-96 text-xs  bg-white " ref={refer}>
                <div className="w-full flex space-x-3 mb-0.5">
                  <div className="w-16 flex-shrink-0 justify-end flex items-end text-gray-500">
                    from:
                  </div>
                  <div className={"  truncate"}>
                    <span className=" font-semibold leading-none">
                      {props.mail.from}
                    </span>
                    {/* <span
                      style={{ fontSize: "11px" }}
                      className="font-light truncate overflow-hidden"
                    >
                      {address.email}
                    </span> */}
                  </div>{" "}
                </div>
                <div className="w-full flex space-x-3 mb-0.5">
                <div className="w-16 justify-end flex items-start text-gray-500">
                to:
                  </div>
                  <div className="flex flex-col truncate">
                    {props.mail.to.map((address: string) => address &&
                      <div className="truncate" key={address}>{address}</div>
                    )}
                  </div>
                </div>
                {props.mail.cc.length > 0 && (
                  <div className="w-full flex space-x-3 mb-0.5">
                    <div className="w-16 justify-end flex items-start text-gray-500">
                      cc:
                    </div>
                    <div className="truncate">
                      {props.mail.cc.map(
                        (cc: string) =>
                          cc && <div key={cc} className="truncate">{cc}</div>
                      )}
                    </div>
                  </div>
                )}

                <div className="w-full flex space-x-3 mb-0.5 space-x-1">
                  <div className="w-16 justify-end flex items-end text-gray-500">
                    date:
                  </div>
                  <div className="truncate">
                    {" "}
                    {dayjs(props.mail.created_at).format(DATE_TIME_FORMATE)}
                  </div>
                </div>
                <div className="w-full flex space-x-3 mb-0.5">
                  <div className="w-16 justify-end flex items-end text-gray-500">
                    signed-by:
                  </div>
                  <div className="truncate">{props.mail.domain}</div>
                </div>
              </div>
            
  );
};
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  loginUser: state.opening.loginUser,
});
export default connect(mapStateToProps)(ShowMailDetailsPopup);
