import React, { lazy } from "react";
import { colors, DIVIDER_HEIGHT } from "../../constant";
import { connect } from "react-redux";
import { loginUser} from "../../type/type.d";
import CDBMembers from "./CDBMembers";
import AddMemberToDB from "./AddMemberToDB";
import Read from "../../Read";
import { servicesMembership } from "../../redux/reducers/initialState";
import ShowCDBMember from "./ShowCDBMember";
import { getServiceAccessOfLoginUser } from "./hook/getLoginUserServiceAccess";
import Breadcrumb from "antd/es/breadcrumb";
import Divider from "antd/es/divider";
const CDBTable = lazy(() => import("../CDB/CDBTable"))
import Navbar from "../navbar/Navbar";
import ContentHeader from "../analyticsVersion1/ContentHeader";
import SuspenseLoader from "../SuspenseLoader";
type CandidateDBHeaderProps = {
  servicesMembership: servicesMembership;
  loginUser: loginUser;
};
const CandidateDBHeader = (props: CandidateDBHeaderProps) => {

  let accessForCDB = getServiceAccessOfLoginUser({
    serviceMembers: props.servicesMembership?.CDBMember,
    loginUserId: props.loginUser.user.id,
  });

  return (
    <>
      <div className="h-full w-full flex flex-col overflow-hidden">
       
            <ContentHeader>
            <div
              className="flex h-12 px-4 py-3 justify-between items-center " >
              <div
                className="flex items-center "          >
                <Breadcrumb
        items={[
          {
            title: 'Talent pool',
          }
        ]}
      />
              </div>
              <div className="flex flex-row items-center ">
                <div className="flex flex-row items-center space-x-2" style={{ paddingRight: "8px"}}>
                <SuspenseLoader>
              <CDBMembers />
              </SuspenseLoader>
                    <Read access={accessForCDB}>
                      <ShowCDBMember />
                    </Read>
                    <Read access={accessForCDB} type={true}>
                      <AddMemberToDB />
                    </Read>
                    <Divider type="vertical" style={{height:DIVIDER_HEIGHT,borderColor:`${colors.divider}`}} />
                    </div>
                <Navbar/>
              </div>
            </div>
          </ContentHeader>
           
        <div className=" bg-white rounded overflow-hidden h-full relative">
        <SuspenseLoader>
            <CDBTable />
            </SuspenseLoader>
          </div>
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  servicesMembership: state.opening.servicesMembership,
  loginUser: state.opening.loginUser,
  searchQuery: state.opening.searchQuery,
});

export default connect(mapStateToProps)(CandidateDBHeader);
