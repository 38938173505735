import React from "react";
import Popover from "antd/es/popover";
type EllipsButtonPopupType = {
  children: any;
  setOpen: () => void;
  openPopup: boolean;
  refer:any
};
const EllipsButtonPopup = (props: EllipsButtonPopupType) => {
  return (
    <>
     <Popover
      content={props.children}
      trigger="click"
      open={props.openPopup}
      onOpenChange={props.setOpen}
      placement="right"
    >
      <div
        role="button"
        tabIndex={0}
        data-testid="ellipsButton"
        className="flex h-full focus:outline-none"
        onKeyDown={() => {}}
        onClick={props.setOpen}
      >
        <div className="h-full">
          <i
            className={`fa fa-ellipsis-v  ${
              props.openPopup ? "bg-gray-300 rounded-default" : null
            } flex justify-center p-1`}
            aria-hidden="true"
          ></i>
        </div>
      </div>
    </Popover>
      
    </>
  );
};
export default EllipsButtonPopup;
