import React from 'react';
import  HolderOutlined from '@ant-design/icons/HolderOutlined';
import  CloseOutlined from '@ant-design/icons/CloseOutlined';
import  PlusOutlined from '@ant-design/icons/PlusOutlined';
import Input from 'antd/es/input';
import List from 'antd/es/list';
import Button from 'antd/es/button';
import Form from 'antd/es/form';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const AddRecipients = ({ recipients, setRecipients,form }: any) => {
  const addRecipient = () => {
    const newRecipient = {
      action_type: "SIGN",
      recipient_email: "",
      recipient_name: "",
      signing_order: recipients.length+ 1,
      verify_recipient: false,
    };
    setRecipients([...recipients, newRecipient]);
    const newIndex = recipients.length;
    form.setFieldsValue({
      [`recipient_email_${newIndex}`]: "",
      [`recipient_name_${newIndex}`]: ""
    });
  };
  const removeRecipient = (email: string) => {
    const removedIndex = recipients.findIndex((item: any) => item.recipient_email === email);

    const updatedRecipients = recipients.filter((item: any) => item.recipient_email !== email);
    const reorderedRecipients = updatedRecipients.map((item: any, index: number) => ({
      ...item,
      signing_order: index + 1
    }));
    setRecipients(reorderedRecipients);
    form.setFields([
      {
        name: `recipient_email_${removedIndex}`,
        value: undefined
      },
      {
        name: `recipient_name_${removedIndex}`,
        value: undefined
      }
    ]);
    reorderedRecipients.forEach((recipient: any, index: number) => {
      form.setFieldsValue({
        [`recipient_email_${index}`]: recipient.recipient_email,
        [`recipient_name_${index}`]: recipient.recipient_name
      });
    });
  };
  

  const handleOnDragEnd = (result: any) => {
    const { destination, source } = result;
    if (!destination) return;

    const items = Array.from(recipients);
    const [reorderedItem] = items.splice(source.index, 1);
    items.splice(destination.index, 0, reorderedItem);
    const updatedItems = items.map((item:any, index) => ({
      ...item,
      signing_order: index + 1
    }));
    setRecipients(updatedItems);
    items.forEach((recipient:any, index) => {
      form.setFieldsValue({
          [`recipient_email_${index}`]: recipient.recipient_email,
          [`recipient_name_${index}`]: recipient.recipient_name
      });
  });
  };

  return (
    <div>
      <div className="p-5 border rounded-sm">
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="recipients">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{ marginTop: '10px' }}
              >
                <List>
                  {recipients.map((recipient: any, index: number) => {
                    return (
                    <Draggable
                      key={index.toString()}
                      draggableId={index.toString()}
                      index={index}
                    >
                      {(provided) => (
                       <List.Item
                       ref={provided.innerRef}
                       {...provided.draggableProps}
                       {...provided.dragHandleProps}
                       style={{
                         display: 'flex',
                         alignItems: 'flex-start',
                         padding: '8px',
                         marginBottom: '8px',
                         borderRadius: '8px',
                         gap: '10px',
                         minHeight: '64px',
                         ...provided.draggableProps.style,
                       }}
                     >  
                       <div className='flex gap-4 items-center justify-center'>
                       <HolderOutlined  style={{ width: '24px'}} />
                       <div className=' border w-8 flex items-center justify-center rounded-sm h-8'>
                       <span >{index + 1}</span>
                       </div>
                       </div>
                       <Form.Item
                         name={`recipient_email_${index}`}
                         rules={[
                           { type: 'email', message: 'Enter a valid email' },
                           { required: true, message: 'Email is required' },
                         ]}
                         style={{ flex: '0 0 calc(50% - 67px)', margin: 0 }}
                       >
                         <Input
                           placeholder="Enter email"
                           
                           value={recipient?.recipient_email || ''}
                           onChange={(e) => {
                             const updatedRecipients = [...recipients];
                             updatedRecipients[index].recipient_email = e.target.value;
                             setRecipients(updatedRecipients);
                           }}
                         />
                       </Form.Item>
                       <Form.Item
                         name={`recipient_name_${index}`}
                         rules={[{ required: true, message: 'Name is required' }]}
                         style={{ flex: '0 0 calc(50% - 67px)', margin: 0 }}
                       >
                         <Input
                           placeholder="Enter name"
                           value={recipient.recipient_name || ''}
                           onChange={(e) => {
                             const updatedRecipients = [...recipients];
                             updatedRecipients[index].recipient_name = e.target.value;
                             setRecipients(updatedRecipients);
                           }}
                         />
                       </Form.Item>
                       <Button
                         className=' border w-8 flex items-center justify-center rounded-sm h-8'
                         onClick={() => removeRecipient(recipient.recipient_email)}
                         icon={<CloseOutlined />}
                         disabled={recipients.length ===1}
                       />
                     </List.Item>
                     
                      )}
                    </Draggable>
                  )})}
                </List>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <Button icon = {<PlusOutlined />} onClick={addRecipient} className="my-4">
        Add Participant
      </Button>
    </div>
  );
};

export default AddRecipients;
