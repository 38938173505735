import React, { ReactNode } from 'react'
import Button from "antd/es/button";
import Tooltip from "antd/es/tooltip";
import { ButtonProps } from 'antd/lib/button/button'
// import BillingUpgradeIcon from '../assets/BillingUpgradeIcon'
import LockOutlined from "@ant-design/icons/LockOutlined"


//button with icon and label
interface LabeledIconButtonProps extends ButtonProps {
    type?: 'default' | 'primary' | 'link' | "text", // Specify available button types
    onClick?: (event: any) => void, // Optional click event handler,
    label: string |ReactNode,
    htmlType?: "button" | "submit" | "reset" | undefined,
    icon?: ReactNode,       // Pass an icon component
    tooltipTitle?: string
}

export const LabeledIconButton: React.FC<LabeledIconButtonProps> = ({
    type = 'default',
    onClick,
    label,
    icon,
    htmlType = "submit",
    tooltipTitle,
    ...ButtonProps
}) => (
    <Tooltip title={tooltipTitle}>
        <Button

            htmlType={htmlType}
            {...ButtonProps}
            type={type} icon={icon} onClick={onClick}>
            {label}
        </Button>
    </Tooltip>
)


//icon button
interface IconButtonProps extends ButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  icon: React.ReactNode;
  type?: "default" | "primary" | "link" | "text";
  size?: "small" | "middle" | "large";
  tooltipTitle?: string;
}

export const IconButton: React.FC<IconButtonProps> = ({
    onClick,
    icon,
    type = 'default',
    size = 'small',
    tooltipTitle,
    shape
}) => (
    <Tooltip title={tooltipTitle}>
        <Button type={type} size={size} shape={shape} icon={icon} onClick={onClick} className='h-7' style={{width:'28px'}}/>
    </Tooltip>
)

interface SecondaryButtonProps extends ButtonProps {
    onClick: (e?:any) => void;
    width?:number;
    tooltipTitle?: string;
}
export const SecondaryButton: React.FC<SecondaryButtonProps> = ({ onClick,width, children,tooltipTitle, ...rest }) => {
    return (
        <Tooltip title={tooltipTitle}>
      <Button
        style={{ backgroundColor: "#F0EFFE", color: "#4B48A5",maxWidth:width, minWidth:width }}
        onClick={onClick}
        {...rest}
      >
        {children}
      </Button>
      </Tooltip>
    );
  };
  interface UpgradeButtonProps extends ButtonProps {
    label:React.ReactNode;
    onClick: (e?: any) => void;
    htmlType?: "button" | "submit" | "reset" | undefined;
    style?: React.CSSProperties;
  }
  export const UpgradeButton: React.FC<UpgradeButtonProps> = (
   { 
    type = 'default',
    onClick,
    label,
    style,
    ...ButtonProps
}
) => {  
  return (
    <Button
      {...ButtonProps}
      icon={<LockOutlined />}
      type={type}
      style={style}
      onClick={onClick}
     className="h-7 flex items-center justify-center" iconPosition="start"
      size="middle"
    >
      {label}
    </Button>
  );
};


