import React from "react";
import PopupTitle from "../../common/popupTitle/PopupTitle";
import { ListMenuItem } from "../../../constant";
import ListPopupMenuItem from "../listPopoverMenuItem/ListPopupMenuItem";
import { ListPopupMenuProps } from "../../../type/listPopup";
import { connect } from "react-redux"
import Button from "antd/lib/button";
import EditOutlined from "@ant-design/icons/EditOutlined";
import Tag from "antd/es/tag";
import Tooltip from "antd/es/tooltip";
import { lightenColor } from "../AnalyticsLabel/UpdateAnalyticsLabelPopup";

const ListPopupMenu = (props: ListPopupMenuProps) => {

  let listMenuItem1 = ListMenuItem.ul1.map((listMenu, index) =>
  (props.lists[props.listId].locked && listMenu.name == "Move" ? <></> :
      <ListPopupMenuItem
        key={index}
        menuItemName={listMenu.name}
        popup={listMenu.popup}
        click={(e: any) => props.click(e)}
      />
  ))
  let listMenuItem2 = ListMenuItem.ul2.map((listMenu, index) => {
    return (
      <ListPopupMenuItem
        key={index}
        menuItemName={listMenu.name}
        popup={listMenu.popup}
        click={(e: any) => props.click(e)}
      />
    );
  });
  let  listMenuItem3= 
  <ListPopupMenuItem
  menuItemName={<>
  <div >
    <span >
  {props.lists[props.listId]?.label?.name ? (
    <span >
      <Tag color={lightenColor(props.lists[props.listId].label.color)} bordered style={{border:`1px solid ${props.lists[props.listId].label.color}`,color:`${props.lists[props.listId].label.color}`,marginRight:'0px'}}>
                {props.lists[props.listId].label.name}
              </Tag>
    </span>
  ) : (
    <span>No label Attached</span>
  )}   </span> <Tooltip title="Updating the label affects all candidate analytics.Best to keep it for accurate analytics.">
         <Button type="link"  icon={<EditOutlined style={{color:'#7152f2'}} />} style={{height:"10px"}} >
        </Button>
        </Tooltip>
     </div></>}
  popup={"AnalyticLabel"}
  click={(e: any) => props.click(e)}
/>

  

  return (
    <React.Fragment>
      <div className="mt-2">
        <PopupTitle PopupTitle="List actions" close={props.close} />
      </div>
      <div className="my-2">
      <div className="analytics-header px-3 font-medium justify-center" style={{height:"25px"}}>Analytics label</div>
      <span className=" cursor-pointer">{listMenuItem3}</span>
      <div className="flex">
          <hr
            className=" w-full mx-2 my-1"
            style={{ borderColor: "#0F0A0D", opacity: "0.3" }}
          ></hr>
        </div>
        <div className="items-center cursor-pointer">{listMenuItem1}</div>
        <div className="flex">
          <hr
            className=" w-full mx-2 my-1"
            style={{ borderColor: "#0F0A0D", opacity: "0.3" }}
          ></hr>
        </div>
        <div className="items-centercursor-pointer">{listMenuItem2}</div>
      </div>
     
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  lists: state.opening.boardList,
  currentOpening: state.opening.currentOpening,
})


export default connect(mapStateToProps)(ListPopupMenu)


