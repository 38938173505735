import React, {  useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { SEARCH_MEMBER_PLACEHOLDER, SOCKET_CLIENT_ID, roles } from "../../../constant"
import {
  createApplicantMembership,
  removeApplicantMembership,
} from "../../../lib/api";
import {
  addMemberOnCard,
  removeMemberFromCard,
} from "../../../redux/actions/opening/action";
import {
  currentApplicant,
  Opening,
  orgMember,
  OpeningMembers,
  openingMemberType,
  Member,
  loginUser,
} from "../../../type/type"
import CheckedMember from "./CheckedMember";
import Avatar from "antd/es/avatar";
import Input from "antd/es/input";
import Popover from "antd/es/popover";
import Typography from "antd/es/typography";

import TacitbaseAvtar from "../../../ant/Avtar"
import { generateTooltipForMember } from "../../../utils/capitalizeFirstLetter"
import CloseIcon from "../../../assets/Close";

const {Text} = Typography;
export type AddMembersProps = {
  click: (e: boolean) => void;
  currentOpening: Opening;
  members: orgMember;
  openingMembers: OpeningMembers;
  currentApplicant: currentApplicant;
  addMember(member: any, applicantID: string): void;
  removeMember(memberID: string, applicantID: string): void;
  refer: any;
  applicantMembers: any;
  applicationId: string;
  clientId: string;
  name: string;
  loginUser: loginUser;
};
const AddMembers = (props: AddMembersProps) => {
  const [searchedMember, getSearchedMember] = useState("");
  let boardMembers: openingMemberType[] = [];
  if (props.openingMembers[props.currentOpening.id].length == 0) return null;

  boardMembers = Object.values(
    props.openingMembers[props.currentOpening.id]
  ).filter((member: openingMemberType) => props.members[member.id].registrations[0].data.activated);

  boardMembers = boardMembers.filter((member) => {
    return (
      props.members[member.id].first_name
        .toUpperCase()
        .includes(searchedMember.toUpperCase()) ||
      props.members[member.id].last_name
        .toUpperCase()
        .includes(searchedMember.toUpperCase()) ||
      props.members[member.id].email
        .toUpperCase()
        .includes(searchedMember.toUpperCase()) ||
      props.members[member.id].username
        .toUpperCase()
        .includes(searchedMember.toUpperCase())
    );
  });

  /* here check if key pressed is tab then close the popup and set reference to parent
if key pressed is enter then it  add member to card which is selected   */

  const setFocusToParent = (e: any, index: number, memberId: string) => {
    if (e.keyCode == 13) {
      AddMemberOnCard(memberId);
    } else {
      if (e.key === "Tab" && index === boardMembers.length - 1) {
        document.getElementById("card-input")?.focus();
        props.click(false);
      }
    }
  };


  const AddMemberOnCard = (member: string) => {
    let found: boolean = false;
    if (props.applicantMembers[props.currentApplicant.id]) {
      found =
        props.applicantMembers[props.currentApplicant.id].includes(member);

      if (found) {
        const req = {
          id: member,
          applicant_id: props.currentApplicant.id,
          application_id: props.applicationId,
          opening_id: props.currentOpening.id,
          socket_client_id:sessionStorage.getItem(SOCKET_CLIENT_ID),
        };
        removeApplicantMembership(req)
          .then((res) => {
            if (res) {
              props.removeMember(member, props.currentApplicant.id);
            }
          })
      }
    }

    if (!found) {

      const req = {
        id: props.currentApplicant.id,
        parent_resource_id: props.currentApplicant.list_id,
        members: [{
          id: member,
          role: roles.MEMBER,
          is_secrete_visible: false,
        }],
        role: roles.MEMBER,
        socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
        is_secrete_visible: false,
      };
     
      createApplicantMembership(req)
        .then((applicant_members: Member) => {
          props.addMember(applicant_members, props.currentApplicant.id);
        })
       
    }
  };
  const searchKeyword = (e: any) => {
    getSearchedMember(e.target.value);
  };
  if (!props.openingMembers[props.currentOpening.id]) return null;
  return (
    <React.Fragment>
      <Popover
        title={
          <div className="flex justify-between">
            <div>{props.name}</div>
        <CloseIcon className="hover:cursor-pointer" onClick={props.click} />
            </div>
        }
        open={true}
        content={
          <div className="w-72">
            <div className="p-1 m-2">
              <Input
                type="text"
                placeholder={SEARCH_MEMBER_PLACEHOLDER}
                onChange={(e: any) => searchKeyword(e)}
              />
            </div>
            <Text className = "m-1">BOARD MEMBERS</Text>
            <div className="member-panal ">
              {boardMembers.map((member, index: number) =>
                props.members[member.id] ? (
                  <div
                    key={index}
                    className={`${
                      props.currentApplicant.creator === member.id ||
                      member.id === props.loginUser.user.id
                        ? "cursor-not-allowed"
                        : "cursor-pointer"
                    } p-1.5 flex items-center justify-between bg-gray-300 bg-opacity-25 hover:bg-gray-300 hover:bg-opacity-40 mb-2 ml-2 mr-1`}
                    onClick={
                      props.currentApplicant.creator === member.id ||
                      member.id === props.loginUser.user.id
                        ? () => {}
                        : () => AddMemberOnCard(member.id)
                    }
                    onKeyDown={(e: any) =>
                      setFocusToParent(e, index, member.id)
                    }
                    tabIndex={0}
                    role="button"
                  >
                    <div className="flex flex-row items-center space-x-2">
                      <div className="flex flex-col">
                        <Avatar.Group>
                          {props.members[member.id].image_url ? (
                            <TacitbaseAvtar
                              size="default"
                              toolTip={generateTooltipForMember(
                                props.members[member.id]
                              )}
                              src={props.members[member.id].image_url}
                            />
                          ) : (
                            <TacitbaseAvtar
                              size="default"
                              toolTip={generateTooltipForMember(
                                props.members[member.id]
                              )}
                              content={
                                props.members[member.id].first_name[0] +
                                props.members[member.id].last_name[0]
                              }
                            />
                          )}
                        </Avatar.Group>
                      </div>
                      <div className="flex flex-col">
                        <div className="leading-snug font-bold text-sm text-gray-800 tracking-normal line-clamp-1">
                          {props.members[member.id].username}
                        </div>
                        <div className="text-xs leading-snug tracking-wide text-gray-800">
                          {props.members[member.id].email}
                        </div>
                      </div>
                    </div>
                    <CheckedMember selectedMemberId={member.id} />
                  </div>
                ) : null
              )}
            </div>
          </div>
        }
      ></Popover>
    </React.Fragment>
  );
};
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  members: state.opening.members,
  openingMembers: state.opening.openingMembers,
  currentApplicant: state.opening.currentApplicant,
  applicantMembers: state.opening.applicantMembers,
  applicationId: state.opening.applicationId,
  clientId: state.opening.clientId,
  loginUser: state.opening.loginUser,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  addMember: (members: any, applicantID: string) =>
    dispatch(addMemberOnCard(members, applicantID)),
  removeMember: (memberID: string, applicantID: string) =>
    dispatch(removeMemberFromCard(memberID, applicantID)),
});
export default connect(mapStateToProps, mapPropsToState)(AddMembers);
