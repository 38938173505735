import React, { useState, useCallback } from "react";
import Modal from "antd/es/modal";
import Button from "antd/es/button";
import Form from "antd/es/form";
import CalendarOutlined from "@ant-design/icons/CalendarOutlined";
import PlusCircleOutlined from "@ant-design/icons/PlusCircleOutlined";
import UserOutlined from "@ant-design/icons/UserOutlined";


import { useDispatch, useSelector } from "react-redux";
import { createCalendar, fetchUserCalendarList } from "../../../lib/api";
import {
  addCalendarList,
  setSelectedCalendar,
} from "../../../redux/reducers/calendarSlice";
import AccessContent from "./AccessContent";
import CreateCalendarContent from "./CreateCalendarContent";
import CloseIconWithTooltip from "../../../ant/CloseIconWithTooltip";
import { throwSuccess } from "../../../service/throwError";


// Types
interface TeamCalendarModalProps {
  isOpen: boolean;
  onClose: () => void;
  isOwner: boolean;
}

const TeamCalendarModal: React.FC<TeamCalendarModalProps> = React.memo(
  ({ isOpen, onClose, isOwner }) => {
    // State Management
    const [loading, setLoading] = useState(false);
    const [showMemberShare, setShowMemberShare] = useState(false);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const loginUserData = useSelector((state: any) => state.opening.loginUser);
    const [newlyCreatedCalendar, setNewlyCreatedCalendar] = useState<any>();
    // Handler for creating a calendar
    const handleAddCalendar = useCallback(
      (values: { calendarName: string }) => {
        if (!values.calendarName?.trim()) return;

        setLoading(true);
        createCalendar({
          name: values.calendarName,
          userId: loginUserData.user.id,
          isShared: true,
        })
          .then((createCalendarRes) => {
            setNewlyCreatedCalendar(createCalendarRes);
            fetchUserCalendarList(loginUserData.user.id).then(
              (calendarList) => {

                const newlySelectedCalendar = calendarList.items
                  .filter(
                    (cal) => cal.selected && cal.id === createCalendarRes.id
                  )
                  .map((cal) => cal.id);

                if (newlySelectedCalendar.length > 0) {
                  dispatch(setSelectedCalendar(newlySelectedCalendar));
                }
                setLoading(false);

                dispatch(addCalendarList(calendarList.items));
                setShowMemberShare(true);
                throwSuccess(`${createCalendarRes.summary} created successfully.`)
               
              }
            );
          })
          .catch((error) => {
            console.error("Calendar creation error:", error);
            setLoading(false);
          });
      },
      [loginUserData, dispatch]
    );

    // Close handler
    const handleClose = useCallback(() => {
      form.resetFields();
      setShowMemberShare(false);
      onClose();
    }, [form, onClose]);

    const modalConfig = {
      access: {
        headerTitle: "Team calendar access",
        icon: <UserOutlined className="text-2xl" />,
        title: "Need access to team calendar?",
        description:
          "Contact your team owner to get access to the shared calendar. Once added, you can collaborate on scheduling interviews and managing recruitment events.",
      },
      owner: {
        headerTitle: "Team calendar",
        icon: <PlusCircleOutlined className="text-2xl" />,
        title: "Create a calendar for your Team",
        description:
          "Set up a shared calendar to help your team coordinate interviews and manage recruitment events efficiently.",
      },
    };

    // Determine current modal configuration
    const currentConfig = isOwner ? modalConfig.owner : modalConfig.access;

    return (
      <Modal
        closeIcon={<CloseIconWithTooltip />}
        open={isOpen} onClose={handleClose}
        onCancel={handleClose}
        footer={
          isOwner  && !showMemberShare ? (
            <div className="flex justify-end space-x-3">
              <Button onClick={onClose}>Cancel</Button>
              <Button
                type="primary"
                onClick={() => form.submit()}
                loading={loading}
              >
                Create team calendar
              </Button>
            </div>
          ) : null
        }
        width={600}
        className="team-calendar-modal"
        closable
        centered
        title={
          <div className="flex space-x-2 items-center">
            <CalendarOutlined />
            <span className="font-medium">{currentConfig.headerTitle}</span>
          </div>
        }
      >
        <Form form={form} layout="vertical" onFinish={handleAddCalendar}>
          {!isOwner ? (
            <AccessContent config={currentConfig} />
          ) : (
            <CreateCalendarContent
              newlyCreatedCalendar={newlyCreatedCalendar}
              showMemberShare={showMemberShare}
              config={currentConfig}
            />
          )}
        </Form>
      </Modal>
    );
  }
);

TeamCalendarModal.displayName = "TeamCalendarModal";
export default TeamCalendarModal;
