import React, { lazy } from "react";
import { connect } from "react-redux";
import ContentHeader from "../analyticsVersion1/ContentHeader";
import { Dispatch } from "redux";
import {
  addChatIntegration,
} from "../../redux/actions/opening/action";
import { Integration } from "../../lib/apiRes";
const SideNavBar = lazy(() => import("../../setting_v1/SideNavBar"));
import Breadcrumb from "antd/es/breadcrumb";
import Navbar from "../navbar/Navbar";
import SuspenseLoader from "../SuspenseLoader";

const SettingHeader = () => {
  return (
    <>
      <ContentHeader>
        <div
          className="flex h-12 px-4 py-3 justify-between items-center " >
          <div
            className="flex items-center "          >
            <Breadcrumb
    items={[
      {
        title: 'Settings',
      }
    ]}
  />
          </div>
          <div className="flex flex-row items-center">
            <Navbar/>
          </div>
        </div>
      </ContentHeader>
      <div className="bg-white  h-full">
      <SuspenseLoader>
     <SideNavBar />
        </SuspenseLoader>
      </div>
    </>
  );
};

const mapPropsToState = (dispatch: Dispatch) => ({
  addIntegrations: (integration: Integration[]) =>
    dispatch(addChatIntegration(integration)),
});
export default connect(null, mapPropsToState)(SettingHeader);
