import React from "react"
import { connect } from "react-redux"
import { RouteComponentProps, withRouter } from "react-router"
import ConfigProvider from 'antd/es/config-provider';
import Menu from 'antd/es/menu';
import { logoutFromAllTabs } from "../../utils/logoutFromAllTabs"
import LogoutOutlined from "@ant-design/icons/LogoutOutlined"

type Props = {
  wsConnectionObject: WebSocket,
} & RouteComponentProps

const Logout: React.FC<Props> = ({ wsConnectionObject }) => {
  const logout = () => {
    localStorage.setItem('logout', String(Date.now()));
    logoutFromAllTabs()
    let ws = wsConnectionObject
    if (ws && ws.readyState !== WebSocket.CLOSED) {
      ws?.close()
    }
   
  }
  const menu = (
    <>
      <ConfigProvider
        theme={{
          components: {
            Menu: {
              colorText: "black",
            },
          },
        }}
      >
        <Menu>
          <Menu.Item icon={<LogoutOutlined   />} onClick={logout}>
            {"Sign out"}
          </Menu.Item>
        </Menu>
      </ConfigProvider>

    </>
  )

  return (
    <>{menu}
      {/* <Space>
        <LabeledIconButton label="Sign out" icon={<LogoutOutlined   />} onClick={logout} />
      </Space> */}
    </>
  )
}

const mapStateToProps = (state: any) => ({
  wsConnectionObject: state.opening.wsConnection,
})

export default connect(mapStateToProps)(withRouter(Logout))
