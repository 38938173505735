import React, { useEffect, useState } from "react";
import { getActivity } from "../../../lib/api";
import { activity } from "../../../type/type";
import { connect } from "react-redux";
import ShowCardActivity from "../../card/activity/ShowCardActivity";
import Empty from "antd/es/empty";
import Spin from "antd/es/spin";
import { useParams } from "react-router-dom";

type CandidateInCDBActivityProps = {
  currentCandidateInEditMode:any;
  activeTab:string
};

/**
 * Component to display the activity of a candidate in the pool.
 * Fetches and displays activity logs for the current candidate.
 */
const CandidateInCDBActivity = ({ currentCandidateInEditMode,activeTab }: CandidateInCDBActivityProps) => {
  const [candidateActivity, setCandidateActivity] = useState<activity[]>([]);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const params: { board_id: string; candidate_id: string; tabname: string } =
  useParams();

  useEffect(() => {
    /**
     * Fetches activity for the current candidate.
     */
    const fetchActivity = async () => {
      if (!params.candidate_id|| activeTab!=="Activity") return;

      setShowLoading(true);
      try {
        const res = await getActivity("candidates", params.candidate_id);
        setCandidateActivity(res);
      } catch (error) {
        console.error("Error fetching activity:", error);
      } finally {
        setShowLoading(false);
      }
    };

    fetchActivity();
  }, [params.candidate_id,activeTab]);

  // Sort activities by creation date in descending order
  const sortedActivities = [...candidateActivity].sort(
    (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  );

  return (
    <div className="h-full pt-2 pl-2 w-full pb-4" key={currentCandidateInEditMode.candidateId?.id}>
      <div style={{ height: "calc(100vh - 245px)" }} className="overflow-y-scroll w-full ">
        <div className="w-full h-full gap-2 flex flex-col p-2">
          {sortedActivities.map((activityCandidate: activity) => (
            <ShowCardActivity key={activityCandidate.id} activity={activityCandidate} />
          ))}
          {sortedActivities.length === 0 && showLoading && (
            <span className="flex flex-col space-y-2 items-center justify-center h-full">
              <Spin tip="" size="default" />
              <span>Fetching activity</span>
            </span>
          )}
          {sortedActivities.length === 0 && !showLoading && (
            <Empty
              description="No activity yet"
              className="flex flex-col h-full justify-center items-center"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )}
        </div>
      </div>
    </div>
  );
};

/**
 * Maps the Redux state to component props.
 */
const mapStateToProps = (state: any) => ({
  currentCandidateInEditMode: state.opening.currentCandidateInEditMode,

});

export default connect(mapStateToProps)(CandidateInCDBActivity);
