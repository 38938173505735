/* eslint-disable camelcase */
import { getAuthUrl } from '../lib/api';
import { InitialOAuthResponse, OAuthProvider } from './authenticateWithOAuth';

interface GetOAuthAccessTokenParams {
  provider: OAuthProvider;
}

export const getOAuthAccessToken = async ({
  provider,
}: GetOAuthAccessTokenParams): Promise<InitialOAuthResponse> => {
  const url = await getAuthUrl(provider);
  return { url };
};
