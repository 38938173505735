import React, { useEffect, useState } from "react";
import Table from "antd/es/table";
import Empty from "antd/es/empty";
import Tooltip from "antd/es/tooltip";
import Button from "antd/es/button";
import Skeleton from "antd/es/skeleton";
import { getAllMeetingsUserWise, getJDDataBoardWise } from "../../lib/api"; // Adjust the import path as necessary
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import MeetingParticipants from "./MeetingParticipants";
import { renderCreatedAt } from "../../utils/renderCreatedAt";
import { convertUnixToISO } from "../../utils/ConvertUnixToIso";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import TacitbaseAvtar from "../../ant/Avtar";
import { Dispatch } from "redux";
import { storedJdData } from "../../redux/actions/opening/action";
import { JobBoardSummary } from "../../type/type";
import ExportOutlined from "@ant-design/icons/ExportOutlined";
import dayjs from "dayjs";
import Tag from "antd/lib/tag";
// const { Text } = Typography;

interface Meeting {
  id: string;
  title: string;
  date: string;
  participants: string[];
  status: string;
}

interface MeetingTableProps {
  applicationId: string;
  setJdData(jdData: JobBoardSummary[]): void;
  jdData: JobBoardSummary[];
}
export const getMeetingStatus = (joinAt: string): 'past' | 'current' | 'upcoming' => {
  const now = dayjs();
  const meetingTime = dayjs(joinAt);
  const meetingEndTime = meetingTime.add(30, 'minutes'); // Assuming default duration is 30 minutes
  
  if (now.isAfter(meetingEndTime)) {
    return 'past';
  } else if (now.isBefore(meetingTime)) {
    return 'upcoming';
  } else {
    return 'current';
  }
};
export  const statusConfig :any = {
  past: { color: 'default', text: 'Completed' },
  current: { color: 'green', text: 'In progress' },
  upcoming: { color: 'blue', text: 'Upcoming' }
};

const MeetingTable: React.FC<MeetingTableProps> = (props) => {
  const [meetings, setMeetings] = useState<Meeting[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [jdDataLoading, setJdDataLoading] = useState<boolean>(false);
  const [hoveredRow, setHoveredRow] = useState(null);
  const history = useHistory();

  useEffect(() => {
    // Dynamically set the height of the table body
    const tableBody: any = document.querySelector(".ant-table-body");
    if (tableBody) {
      tableBody.style.height = "calc(100vh - 131.8px)";
    }
  }, []);

  useEffect(() => {
    const fetchMeetings = async () => {
      setLoading(true);
      try {
        const [meetingsResponse]: any = await Promise.all([
          getAllMeetingsUserWise(),
          // getOpenings()
        ]);
        setMeetings(meetingsResponse);
        setJdDataLoading(true)
        try {
          const jdData: JobBoardSummary[] = await getJDDataBoardWise();
          props.setJdData(jdData);
        } catch (error) {
          console.error("Error fetching JD data:", error);
          props.setJdData([]); // Set empty array on failure
        } finally {
          setJdDataLoading(false);
        }
      } catch (error) {
        console.error("Error fetching meetings:", error);
        setJdDataLoading(false);
        props.setJdData([]);
      } finally {
        setLoading(false);
      }
    };

    fetchMeetings();
  }, []);
  // Function to get JD name by board ID
  function getJdDetailsByBoardId(boardId: string) {
    const item =
      props.jdData &&
      props.jdData.find((entry: JobBoardSummary) => entry.board_id === boardId);
    return item
      ? {
          jdTitle: item.jd_title,
          jdLink: item.job_post_link,
          status: item.status,
        }
      : { jdTitle: "Job post not found", jdLink: null, status: "" };
  }

  // Generate data for the table from candidate pool
  const generateData = (meetings: Meeting[]) => {
    return meetings.map((meeting: any) => {
      const host = meeting?.meeting_participants?.find(
        (participant: any) => participant.is_host
      );
      return {
        key: meeting.id,
        id: meeting.id || "",
        title: meeting.meeting_metadata.title || "",
        created_at: meeting.created_at,
        participants: meeting.meeting_participants || [],
        host: host ? host.name : "", // Add host name or "No Host" if not available
        boardName: 
        getJdDetailsByBoardId(meeting?.metadata?.board_id),
        status: getMeetingStatus(meeting.join_at)
      };
    });
  };

  const data: any[] = generateData(meetings);
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      className: "pl-8",
      render: (record: string) => (
        <span className="block truncate pr-2">
          {capitalizeFirstLetter(record)}
        </span>
      ),
    },
    {
      title: "Job post name",
      dataIndex: "boardName",
      key: "boardName",
      width:'18%',
      render: (boardId: any) => {
        return (
          <div className="flex flex-row gap-2 pr-2">
          {!jdDataLoading ? (
            <>
              <span className="block truncate">
                {capitalizeFirstLetter(boardId.jdTitle)}
              </span>
              {boardId.jdLink && (
                <Tooltip
                  title={
                    boardId.status?.toLowerCase() !== "publish"
                      ? "Job post is not published."
                      : "Go to the job post."
                  }
                >
                  <span>
                    <Button
                      type="text"
                      size="small"
                      onClick={(e) => {
                        window.open(boardId.jdLink, "_blank"); // Opens the job post link in a new tab
                        e.stopPropagation(); // Prevents the row click event
                      }}
                      disabled={boardId.status.toLowerCase() !== "publish"}
                      icon={<ExportOutlined />}
                    />
                  </span>
                </Tooltip>
              )}
            </>
          ) : (
            <Skeleton.Button active={true} size="small" style={{ width: 160 }} />
          )}
        </div>
        
        );
      },
    },
    {
      title: "Host",
      dataIndex: "host",
      key: "host",
      width: "20%",
      render: (host: string) => (
        <span className="flex gap-2 truncate pr-2">
          {host.length > 0 ? (
            <>
              <TacitbaseAvtar content={host[0].toUpperCase()} />
              {host}
            </>
          ) : (
            <span>No host</span>
          )}
        </span>
      ),
    },
    {
      title: "Participants",
      dataIndex: "participants",
      key: "participants",
      width: "10%",
      render: (participants: any[]) => (
        <>
          {participants && (
            <MeetingParticipants meetingParticipants={participants} />
          )}
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "10%",
      render: (record: any) => (
        <Tag color={statusConfig[record].color}>
      {statusConfig[record].text}
    </Tag>
      ),
    },
    {
      title: "Created at",
      dataIndex: "created_at",
      key: "created_at",
      width: "15%",
      render: (record: any) => (
        <span>
          {typeof record === "number"
            ? renderCreatedAt(convertUnixToISO(record))
            : renderCreatedAt(record)}
        </span>
      ),
    },
  ];

  const onRowClick = (record: any) => {
    // setSelectedRowKey(record?.id);
    history.push(`/o/${props.applicationId}/meetings/${record?.id}`);
  };

  // Handle row hover event
  const handleRowHover = (record: any) => {
    setHoveredRow(record ? record.key : null);
  };

  return (
    <Table
      rowClassName={(record) =>
        record.id === hoveredRow ? "cursor-pointer" : ""
      }
      size="small"
      dataSource={data}
      columns={columns}
      loading={loading}
      pagination={{
        pageSize: 25,
        position: ["bottomLeft"],
        size: "default",
        showSizeChanger: false,
        style: {
          marginTop: "0px",
          borderTop: "1px solid rgba(5, 5, 5, 0.06)",
          paddingTop: "9px",
        },
      }}
      scroll={{ y: "calc(100vh - 130.8px)" }}
      onRow={(record) => ({
        onMouseEnter: () => handleRowHover(record),
        onMouseLeave: () => handleRowHover(null),
        onClick: () => onRowClick(record),
      })}
      locale={{
        emptyText: (
          <div
            className="flex h-full items-center justify-center"
            style={{ height: "calc(100vh - 134.8px)" }}
          >
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<span>No data available</span>}
            />
          </div>
        ),
      }}
    />
  );
};

const mapStateToProps = (state: any) => ({
  applicationId: state.opening.applicationId,
  Boards: state.opening.openings.pageSection.openings,
  jdData: state.opening.jdData,
});

const mapPropsTostate = (dispatch: Dispatch) => ({
  setJdData: (jdData: JobBoardSummary[]) => dispatch(storedJdData(jdData)),
});

export default connect(mapStateToProps, mapPropsTostate)(MeetingTable);
