import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { InitLabel, Label, customeFilterType, searchDynamicQuery } from "../../type/type.d"
import { IS, SEARCH_LABEL_PLACEHOLDER, operatorMap } from "../../constant"
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter"
import Input from "antd/es/input";
import Tag from "antd/es/tag";
import Typography from "antd/es/typography";
import Space from "antd/es/space";
import Spin from "antd/es/spin";
import { determineTextColor } from "../../utils/CalculateBrightnessOfColor"
import { hexToRGBA } from "../../utils/hexToRgb"
import CheckOutlined from "@ant-design/icons/CheckOutlined"

const { Text } = Typography
const { Search } = Input

const CandidateLabelFilter = (props: {
    candidateLabels: InitLabel,
    initSearchFilter: (filterObj: any) => void,
    searchQuery: searchDynamicQuery,
}) => {
    const [labels, setLabels] = useState<Label[]>([])
    const [addedFilterLabels, setFilterLabel] = useState<string[]>([])
    const [searchValue, setSearchValue] = useState("")
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const labelsPerPage = 10

    useEffect(() => {
        if (props.candidateLabels) {
            const allLabels = Object.values(props.candidateLabels)
            allLabels.sort((a: Label, b: Label) => a.name.localeCompare(b.name))
            setLabels(allLabels)
        }
    }, [props.candidateLabels])

    useEffect(() => {
        if (!props.searchQuery?.filters || props.searchQuery.filters.length === 0) {
            setFilterLabel([])
            return
        }
        
        try {
            const updatedFilters = props.searchQuery.filters
                ?.filter((filter: customeFilterType) => filter?.column === "label_ids" && filter?.value)
                ?.map((filter: customeFilterType) => filter.value) || []
            setFilterLabel(updatedFilters)
        } catch (error) {
            console.error("Error updating filters:", error)
            setFilterLabel([])
        }
    }, [props.searchQuery])

    const handleScroll = async (e: React.UIEvent<HTMLDivElement>) => {
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget
        if (scrollTop + clientHeight >= scrollHeight - 50 && !loading) {
            if (currentPage * labelsPerPage < labels.length) {
                setLoading(true)
                await new Promise(resolve => setTimeout(resolve, 500))
                setCurrentPage(prev => prev + 1)
                setLoading(false)
            }
        }
    }

    const handleSearch = (value: string) => {
        setSearchValue(value)
        setCurrentPage(1)
    }

    const addCardLabel = (label: Label) => {
        if (!label?.id) {
            console.error("Invalid label:", label)
            return
        }

        try {
            const found = addedFilterLabels?.includes(label.id)
            
            if (found) {
                const updatedFilterLabels = (addedFilterLabels || []).filter(id => id !== label.id)
                setFilterLabel(updatedFilterLabels)
                
                const updatedFilters = (props.searchQuery?.filters || []).filter(
                    (filter: customeFilterType) => 
                        !(filter.column === "label_ids" && filter.value === label.id)
                )
                props.initSearchFilter(updatedFilters)
            } else {
                const updatedFilterLabels = [...(addedFilterLabels || []), label.id]
                setFilterLabel(updatedFilterLabels)
                
                const filterObj = [
                    ...(props.searchQuery?.filters || []),
                    { 
                        column: "label_ids", 
                        operator: operatorMap[IS], 
                        value: label.id, 
                        view: "label", 
                        operatorName: IS, 
                        id: label.id 
                    }
                ]
                props.initSearchFilter(filterObj)
            }
        } catch (error) {
            console.error("Error handling label:", error)
        }
    }

    const getFilteredLabels = () => {
        let filteredLabels = labels
        if (searchValue) {
            filteredLabels = labels.filter(label => 
                label.name.toLowerCase().includes(searchValue.toLowerCase())
            )
        }
        return filteredLabels.slice(0, currentPage * labelsPerPage)
    }

    const renderLabels = () => {
        const filteredLabels = getFilteredLabels()
        
        return (
            <div className="space-y-1.5">
                {filteredLabels?.map((label) => label && (
                    <div key={label.id} className="label-btn flex w-full items-center justify-center cursor-pointer">
                        <Tag
                            color={label.color}
                            className="w-full flex items-center h-8 justify-between"
                            onClick={() => addCardLabel(label)}
                        >
                            <div className="flex justify-center items-center">
                                <Tag
                                    className="w-5 h-5 flex items-center justify-center outline-none border-0"
                                    style={{ backgroundColor: hexToRGBA('#ffffff', 0.2) }}
                                >
                                    {addedFilterLabels?.includes(label.id) && 
                                        <CheckOutlined style={{ color: determineTextColor(label.color) }} />
                                    }
                                </Tag>
                                <Text
                                    ellipsis
                                    style={{ color: determineTextColor(label.color), maxWidth: 200, minWidth: 100 }}
                                >
                                    {label.name}
                                </Text>
                            </div>
                        </Tag>
                    </div>
                ))}
            </div>
        )
    }

    return (
        <div className="flex flex-col w-full mt-1.5">
            <Space direction="vertical" className="w-full">
                <Search
                    placeholder={SEARCH_LABEL_PLACEHOLDER}
                    value={searchValue}
                    onChange={(e) => handleSearch(e.target.value)}
                    allowClear
                />
            </Space>
            <div className="h-auto max-h-72 overflow-y-auto mt-2" onScroll={handleScroll}>
                {getFilteredLabels()?.length > 0 ? (
                    renderLabels()
                ) : (
                    <div className="m-2">{capitalizeFirstLetter("No label found")}</div>
                )}
                {loading && (
                    <div className="flex justify-center items-center py-3">
                        <Spin />
                    </div>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    candidateLabels: state.opening.candidateLabels,
})

export default connect(mapStateToProps)(CandidateLabelFilter)