import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";
import { Dispatch } from "redux";
import { editListName } from "../../../lib/api";
import { updateListName } from "../../../redux/actions/opening/action";
import { EditListInputProps } from "../../../type/boardPanal";
import { SOCKET_CLIENT_ID } from "../../../constant";
import Typography from "antd/es/typography";
const { Text } = Typography

const EditListInput = (props: EditListInputProps) => {
  const [updatedlistName, setNewListName] = useState<string>();
  const [editable, isListEditable] = useState<boolean>(false);
  let txtareaRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    setNewListName(props.listName);
    return () => {};
  }, [props.listName]);
  const listEditable = () => {
    isListEditable(true);
  };

  /* here check button is clicking or key pressed, if pressed then check predded key is enter or not.  
  if match then check latest list value with trim is equal to existing, if yes then dont call api otherwise call with new listname.  
  */
  const updateListName = (e: any) => {
    if (
      (e.keyCode === 13 && !e.shiftKey) ||
      e.button == 0 ||
      e.type == "blur"
    ) {
      e.preventDefault();
      if (txtareaRef.current && txtareaRef.current.value) {
        let name = txtareaRef.current.value.trim();
        const req = {
          id: props.listID,
          name: name,
          socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
        };
        if (name !== "" && name !== props.listName) {
          editListName(req)
            .then((list) => {
              props.updateListName(list);
              isListEditable(false);
              txtareaRef.current?.blur();
            })
         
        } else {
          if (txtareaRef.current) {
            isListEditable(false);
            txtareaRef.current?.blur();
            setNewListName(props.listName);
          }
        }
      } else {
        if (txtareaRef.current) {
          txtareaRef.current?.blur();
          isListEditable(false);
          setNewListName(props.listName);
        }
      }
    }
  };

  return (
    <div className=" w-full items-center min-h-8 my-0.5">
      <Text ellipsis={editable?false:true}
       className={`${editable?"hidden":"  bg-black bg-opacity-0  flex items-center text-white focus:outline-none tracking-wider resize-none font-medium w-44 ell       text-sm truncate overflow-ellipsis whitespace-nowrap"} `} style={{paddingTop:5.3,paddingBottom:4.3}}  onClick={listEditable}>{updatedlistName}</Text>
    {editable&&(  
      <TextareaAutosize
        ref={txtareaRef}
        className={` bg-white text-black inputCSSOnlyForFocus rounded-default  tracking-wider resize-none font-medium w-full px-1  py-0.5  mt-0.5 -mb-1  text-sm h-6`}
        value={updatedlistName}
        onChange={(e) => setNewListName(e.target.value)}
        onKeyDown={(e) => {updateListName(e)}}
        maxLength={250}
        onBlur={(e) => {updateListName(e),isListEditable(false)}}
        data-testid="edit-list"
        onClick={listEditable}
        onFocus={(f) => f.target.select()}
        disabled={props.isLock ? true : false} 
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        style={{marginTop:'2.5px'}}
      />)}
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  clientId: state.opening.clientId,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  updateListName: (list: any) => dispatch(updateListName(list)),
});
export default connect(mapStateToProps, mapPropsToState)(EditListInput);
