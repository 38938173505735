import React, { useEffect, useState } from "react";
import ConfigProvider from "antd/es/config-provider";
import Tabs from "antd/es/tabs";
import FormFieldSettings from "./FormFieldSettings";
import FormSettings from "../jobDescription/FormSettings";
import { FormBuilder } from "../../type/type";
import { FIELD_SETTING, FORM_SETTING } from "../../constant";

const { TabPane } = Tabs;

type FormBuilderActionButtonType = {
  handleChangeRequest: (value: string) => void;
  selectedComponent: any;
  currentFormDetails: FormBuilder;
  handleChangeLabel: (changeValue: { [key: string]: string }) => void;
  clearSelectedElement: () => void;
  makeFieldRequired: (isRequired: any) => void;
};

const FormBuilderActionButton = (props: FormBuilderActionButtonType) => {
  const [activeTab, setActiveTab] = useState<string>(FORM_SETTING);

  const changeTab = (tab: string) => {
    if (tab === FORM_SETTING) {
      props.clearSelectedElement();
    }
    setActiveTab(tab);
  };

  useEffect(() => {
    if (props.selectedComponent.name.length > 0) setActiveTab(FIELD_SETTING);
  }, [props.selectedComponent]);

  return (
    <div className="w-full px-4">
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              inkBarColor: "#7152F2",
              cardBg: "red",
              itemColor: "black",
              itemSelectedColor: "#7152F2",
            },
          },
        }}
      >
        <Tabs className="text-black" activeKey={activeTab} onChange={changeTab}>
          <TabPane tab={FORM_SETTING} key={FORM_SETTING}>
            <div className="">
            <FormSettings
              handleChangeRequest={props.handleChangeRequest}
              currentFormDetails={props.currentFormDetails}
              />
            </div>
          </TabPane>
          <TabPane tab={FIELD_SETTING} key={FIELD_SETTING}>
            <FormFieldSettings
              handleChangeLabel={props.handleChangeLabel}
              selectedComponent={props.selectedComponent}
              handleChangeRequest={props.handleChangeRequest}
              makeFieldRequired={props.makeFieldRequired}
            />
          </TabPane>
        </Tabs>
      </ConfigProvider>
    </div>
  );
};

export default FormBuilderActionButton;
