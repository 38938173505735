import React, { useState } from 'react';
import Input from "antd/es/input";
import Form from "antd/es/form";


type TacitbaseInputProps = {
     type?: string,
    placeholder: string,
    value?: string,
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void|undefined,
    className?: string,
    size?: 'small' | 'middle' | 'large',
    prefix?: React.ReactNode,
    suffix?: React.ReactNode,
    autoFocus?: boolean,
    validator?: (value: string) => string | undefined,
}

const TacitbaseInput: React.FC<TacitbaseInputProps> = ({ type, placeholder, value, onChange, className, size = 'large', prefix, suffix, validator }) => {
    const [error, setError] = useState<string | undefined>();


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange?.(e);
        if (validator) {
            const errorMessage = validator(e.target.value);
            setError(errorMessage);
        }
    };

    return (
        <>
            <Form.Item
                validateStatus={error ? 'error' : undefined}
                help={error}
            >
                <Input
                    placeholder={placeholder}
                    type={type}
                    // maxLength={50}
                    value={value}
                    onChange={handleChange}
                    className={className}
                    size={size}
                    prefix={prefix}
                    suffix={suffix}
                />
            </Form.Item>
        </>
    );
}

export default TacitbaseInput;
