import React from "react";
import BoldOutlined from "@ant-design/icons/BoldOutlined";
import ItalicOutlined from "@ant-design/icons/ItalicOutlined";
import UnderlineOutlined from "@ant-design/icons/UnderlineOutlined";
import Skeleton from "antd/es/skeleton";

// after calling gpt api while response come showing the skeleton of text editor
const EditorSkeleton = () => {
  return (
    <div className="editor-description border rounded mb-2 animate-pulse">
      <div className="flex-1 bg-gray-200 rounded ">
        <div className="w-full h-8 bg-gray-300 mb-4 flex items-center  ">
          <div className=" text-gray-600 animate-pulse ml-3 mr-12">Normal</div>
          <div className="text-gray-600 mr-6 animate-pulse"><BoldOutlined /></div>
          <div className="text-gray-600 mr-6 animate-pulse"><ItalicOutlined /></div>
          <div className="text-gray-600 mr-6 animate-pulse"><UnderlineOutlined/></div>
        </div>
        <div className="editor-skeleton px-4">
          <Skeleton active />
        </div>
      </div>
    </div>
  );
};

export default EditorSkeleton;
