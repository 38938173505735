import CloseOutlined from "@ant-design/icons/CloseOutlined";
import FilePdfFilled from "@ant-design/icons/FilePdfFilled";
// import CloseIcon from "../../../assets/Close";
import React from "react";
import Tag from "antd/es/tag";
import Typography from "antd/es/typography";
const { Text } = Typography;


interface UploadedFileProps {
  name: string;
  progress: number;
  onClose: () => void;
}

const UploadedFile: React.FC<UploadedFileProps> = ({
  name,
  // progress,
  onClose,
}) => {
  return (
    <>
      {/* <Space direction="vertical" className="w-full"> */}
        <Tag
          className="cursor-pointer flex items-center border border-black border-opacity-10 p-2 h-6 bg-white mr-0"
          style={{ width: "32.072%" }}
          bordered={false}
        >
          <FilePdfFilled style={{ fontSize: "13px", color: "#FF0000" }} />
          <Text
            ellipsis={{ tooltip:name }}
            style={{ width: "100%", fontSize: "13px" }}
          >
            {name}
          </Text>
          <CloseOutlined onClick={onClose} size={13} />
        </Tag>
      {/* </Space> */}

    </>
  );
};
export default UploadedFile;
