import Spin from "antd/es/spin";
import Tabs from "antd/es/tabs";
import Empty from "antd/es/empty";
import Typography from "antd/es/typography";
// import { TabsProps } from 'antd/lib';
import React, { useEffect, useState } from "react";
import CandidateInPoolActivity from "./CandidateInPoolActivity";
import TabPane from "antd/es/tabs/TabPane";
import CandidateInAtsPoolTracker from "./CandidateInAtsPoolTracker";
import CandidateInAtsPoolReview from "./CandidateInAtsPoolReview";
import CandidateInAtsPoolMail from "./CandidateInAtsPoolMail";
import { connect, useDispatch } from "react-redux";
import { eSignDetails, loginUser, orgMember } from "../../../../../type/type";
import {  getAllDocumentsByCandidate, getAllDocumentsByCandidateId } from "../../../../../lib/api";
import { updateEsignDetails } from "../../../../../redux/actions/opening/action";
import { useParams } from "react-router-dom";
import GetDocumentById from "../../../../../styles/component/ESign/GetDocumentById";
import { countDocuments, setToString } from "../../../../../styles/component/ESign/Common";
// import { countDocuments } from "../../../../../styles/component/ESign/Common";
const {Text} = Typography;
type CandidateInPoolTabsProps = {
  eSignDetails: eSignDetails;
  loginUser: loginUser;
  members:orgMember;
};
const CandidateInPoolTabs = (props: CandidateInPoolTabsProps) => {
  const params: { candidate_id: string } = useParams();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState<string>("Activity"); // State to track active tab
  const [docLoading, setDocLoading] = useState(false);

  useEffect(()=>{
    setActiveTab("Activity")
  },[params.candidate_id])

  // Define tab titles and contents
  const tabData = [
    {
      key: "Activity",
      title: "Activity",
      content: <CandidateInPoolActivity  activeTab={activeTab}/>,
    },
    {
      key: "Tracker",
      title: <span>Tracker</span>,
      content: <CandidateInAtsPoolTracker activeTab={activeTab}/>,
    },
    {
      key: "Review",
      title: <span>Review</span>,
      content: <CandidateInAtsPoolReview activeTab={activeTab}/>,
    },
    {
      key: "Mail",
      title: <span>Mail</span>,
      content: <CandidateInAtsPoolMail activeTab={activeTab}/>,
    },
    {
      key: "documents",
      title: "E-sign",
      content: (
        <div
        className="overflow-y-auto p-2 flex flex-col gap-2"
        style={{
          height: "calc(100vh - 225px )",
        }}
      >
        {props.eSignDetails?.documents?.length === 0 &&
        props.eSignDetails?.allDocuments?.length === 0 ? (
          <Empty
            description="No documents found"
            className="flex flex-col h-full justify-center items-center"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        ) : docLoading ? (
          <div className="flex justify-center items-center h-full">
            <Spin size="small" />
          </div>
        ) : (
          <>
            {props.eSignDetails?.allDocuments?.length !== 0 && (
                 <div className="flex flex-col gap-5">
                   <div className="bg-white p-5 border rounded-lg">
                     <div className="flex items-center justify-between mb-4">
                       <Text>Documents Sent to Candidates</Text>
                       <Text type ="secondary">
                         {props.eSignDetails?.allDocuments?.length} total
                       </Text>
                     </div>
                     <ul className="space-y-4 text-sm">
                       {Object.entries(countDocuments(props.eSignDetails?.allDocuments)).map(([key, items]: any) => (
                         <li key={key} className="flex justify-between items-center">
                           <div className="flex items-center space-x-3">
                             <Text className="font-medium">{key}</Text>
                           </div>
                           <div className="flex items-center space-x-2">
                             <Text type="secondary"> {items.count} document{items.count > 1 ? "s" : ""} sent by</Text>
                             <Text className="font-medium">{setToString(items.userIds, props.members)}</Text>
                           </div>
                         </li>
                       ))}
                     </ul>
                   </div>
               </div>
            )}
            {props.eSignDetails?.documents?.map((doc) => (
              <GetDocumentById key={doc.id} doc={doc} />
            ))}
          </>
        )}
      </div>
      
      ),
    }
    

    // Add more tab data as needed
  ];

  const onChange = (key: string) => {
    setActiveTab(key);
    if (key === "documents") {
      if (props.eSignDetails.connected) {
        setDocLoading(true);
        getAllDocumentsByCandidate(params.candidate_id)
          .then((res) => {
            if (res.document) {
              dispatch(updateEsignDetails({ documents: res.document }));
              setDocLoading(false);
              return;
            }
            setDocLoading(false);
          })
          .catch(() => {
            setDocLoading(false);
            console.log("Failed to get all documents");
          });
      }
      getAllDocumentsByCandidateId(params.candidate_id)
        .then((res: any) => {
          if (res.offer_status) {
            dispatch(updateEsignDetails({ allDocuments: res.offer_status }));
            return;
          }
        })
    }
  };
  return (
    <div className="flex flex-col h-full justify-between items-baseline w-full comment-editor">
      <Tabs
        size="small"
        defaultActiveKey={activeTab}
        activeKey={activeTab}
        className="w-full "
        onChange={onChange} 
        tabBarStyle={{ marginBottom: -8 ,padding:"6.5px 16px" }}
      >
        {tabData.map((tab) => (
          <TabPane tab={tab.title} key={tab.key} className="py-3 ">
            {tab.content}
          </TabPane>
        ))}
      </Tabs> 
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  eSignDetails: state.opening.eSignDetails,
  loginUser: state.opening.loginUser,
  members:state.opening.members
});
export default connect(mapStateToProps) (CandidateInPoolTabs);
