import React, { lazy, useEffect, useRef, useState } from "react";
import VideoTranscript from "./VideoTranscript";
import LeftOutlined from "@ant-design/icons/LeftOutlined";
const TacitBaseModal = lazy(() => import("../../ant/TacitbaseModal"));
import { Overlay } from "../hook/Overlay";
import Button from "antd/es/button";
import Tabs from "antd/es/tabs";
import Tooltip from "antd/es/tooltip";
import Typography from "antd/es/typography";
import MeetingVideo from "./MeetingVideo";
import Summery from "./Summery";
import { getCandidate, getSingleMeeting } from "../../lib/api";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { renderCreatedAt } from "../../utils/renderCreatedAt";
import MeetingParticipants from "./MeetingParticipants";
import Feedback from "./Feedback";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { Candidate, loginUser } from "../../type/type";
import { getMeetingStatus, statusConfig } from "./MeetingsTable";
import Tag from "antd/lib/tag";
import CandidateDetails from "./CandidateDetails";
import Divider from "antd/lib/divider";
import SuspenseLoader from "../SuspenseLoader";
const { Text } = Typography;

interface VideoSummeryModalProps {
  applicationId: string;
  loginUser:loginUser,
}

const VideoSummeryModal = (props: VideoSummeryModalProps) => {
  const [data, setData] = useState<any>([]);
  const [candidate, setCandidate] = useState<Candidate>();
  const videoRef = useRef<HTMLVideoElement>(null);

  const history = useHistory();
  const params: { board_id: string; card_id: string; meeting_id: string } =
    useParams();

  useEffect(() => {
    const fetchMeeting = async () => {
      try {
        const response = await getSingleMeeting(params.meeting_id);
        setData(response); // Assuming the response has a transcript field
      } catch (error) {
        console.error("Error fetching meeting:", error);
      }
    };

    fetchMeeting();
  }, []);

  useEffect(() => {
    if(data && data.metadata?.candidate_id && !params.card_id){
    const fetchCandidate = async () => {
      try {
        const response = await getCandidate(data.metadata.candidate_id);
        setCandidate(response); // Assuming the response has a transcript field
      } catch (error) {
        console.error("Error fetching meeting:", error);
      }
    };
    fetchCandidate();}
  }, [data.metadata?.candidate_id]);

  const backToMeeting = () => {
    if (params.board_id && params.card_id) {
      history.push({
        pathname: `/o/${props.applicationId}/b/${params.board_id}/tracker/${params.card_id}`,
      });
    } else {
      history.push({
        pathname: `/o/${props.applicationId}/meetings`,
      });
    }
  };

  return (
    <SuspenseLoader>
    <TacitBaseModal
      keyboard={false}
      maskClosable={false}
      visibility={true}
      width={"100%"}
      title={
        <>
          <Overlay onClose={() => {}} scope={""}>
            <div
              className={`w-full flex justify-between items-center py-3`}
              style={{ height: 52 }}
            >
              <div className="flex">
                <Button
                  style={{
                    color: "#000000",
                    transition: "color 0.3s",
                  }}
                  icon={<LeftOutlined />}
                  type="link"
                  name="Back to pool"
                  onClick={backToMeeting}
                >
                  {params.board_id && params.card_id
                    ? "Back to Card"
                    : "Back to Meetings"}
                </Button>
                <div className="flex items-center gap-1 font-normal">
                  <MeetingParticipants
                    meetingParticipants={data?.meeting_participants || []}
                  />
                </div>
                <div  className="flex items-center px-4">
                <Tag color={statusConfig[getMeetingStatus(data.join_at)].color}> {statusConfig[getMeetingStatus(data.join_at)].text}</Tag>
                </div>
              </div>
              <div className="flex flex-row pr-4 font-normal">
                <Text>{renderCreatedAt(data?.join_at)}</Text>
              </div>
            </div>
          </Overlay>
        </>
      }
      closeIcon={null}
      content={
        <>
          <div
            style={{
              margin: 0,
              overflow: "hidden",
              width: "calc(100vw - 150px )",
              maxWidth: "calc(100vw - 150px )",
              height: "calc(100vh - 161px )",
              maxHeight: "calc(100vh - 100px )",
              borderRadius: "4px",
            }}
          >
            <div style={{ display: "flex" }} className="h-full  ">
              {/* Second Part */}
              <div
                style={{
                  width: "40%",
                }}
                className="flex flex-col h-full border-t border-r"
              >
                
                <MeetingVideo videoUrl={data.tb_video_url} ref={videoRef} />
                <Divider className="my-1" />
                {candidate&&
                 <div className="p-4">
                <CandidateDetails currentCandidateInVideo={candidate} /></div>}
               
              </div>{" "}
              {/* First Part */}
              <div
                style={{ width: "60%" }}
                className="flex flex-col h-full flex-shrink-0 border-l border-b border-t border-r"
              >
                <div className="flex space-x-4 items-center truncate align-middle justify-start">
                  <h3 className="text-lg font-semibold px-4 w-1/2 truncate ">
                    {data?.meeting_metadata?.title
                      ? capitalizeFirstLetter(data?.meeting_metadata?.title)
                      : "Video summery"}
                  </h3>
                </div>
                <Tabs
                  defaultActiveKey={
                    !data?.tb_video_url || !data?.transcript
                      ? "feedback"
                      : "summary"
                  }
                  className="px-4 -mt-3"
                  key={data.id}
                >
                  <Tabs.TabPane
                    tab={
                      <Tooltip
                        title={
                          !data?.tb_video_url && "Summary is not available"
                        }
                      >
                        <span>Summary</span>
                      </Tooltip>
                    }
                    key="summary"
                    disabled={
                      !data?.tb_video_url ||
                      !data.transcript ||
                      data.transcript === null
                    }
                  >
                    {/* Content for Summary tab */}
                    <div
                      className="w-full h-full overflow-y-scroll flex"
                      style={{
                        height: "calc(100vh - 280px)",
                      }}
                    >
                      {/* You can add your summary content here */}
                      <Summery />
                    </div>
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={
                      <Tooltip
                        title={
                          !data?.transcript && "Transcript is not available"
                        }
                      >
                        <span>Transcript</span>
                      </Tooltip>
                    }
                    key="transcript"
                    disabled={
                      !data?.tb_video_url ||
                      !data.transcript ||
                      data.transcript === null
                    }
                    // className="overflow-y-scroll"
                  >
                    <div
                      className="w-full h-full overflow-y-scroll flex"
                      style={{
                        height: "calc(100vh - 280px)",
                      }}
                    >
                      <VideoTranscript
                        transcript={data.transcript}
                        videoRef={videoRef}
                      />
                    </div>
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab="Feedback"
                    key="feedback"
                    className="-mr-6 -mt-4"
                  >
                    <div
                      className="w-full h-full overflow-y-scroll flex"
                      style={{
                        height: "calc(100vh - 274px)",
                      }}
                    >
                      {/* Add your feedback component or content here */}
                      <Feedback
                        aiKey={
                          !data?.tb_video_url ||
                          !data.transcript ||
                          data.transcript === null
                        }
                      />
                    </div>
                  </Tabs.TabPane>
                </Tabs>
              </div>
              
            </div>
          </div>
        </>
      }
      footer={<div className="m-0"></div>}
      style={{
        overflowY: "hidden",
        width: "100vw",
        height: "100vh",
        position: "fixed",
        top: "50px",
        left: "10px",
        bottom: "10px",
        right: "0px",
        display: "flex",
        alignItems: "start",
        justifyContent: "center",
        padding: "0px",
        borderRadius: "4px",
      }}
      className="review-modal-content"
    />
    </SuspenseLoader>
  );
};

const mapStateToProps = (state: any) => ({
  applicationId: state.opening.applicationId,
  loginUser: state.opening.loginUser,
});
export default connect(mapStateToProps)(VideoSummeryModal);
