import { AxiosError } from "axios";
import message from 'antd/es/message';
import { capitalizeFirstLetter } from "../utils/capitalizeFirstLetter";
const displayedErrorKeys = new Set<number>();
const messageStyle = {
  right: "0px",
  position: "fixed",
  zIndex: 1000,
  transform: 'translateY(-50%)',
  animation: 'slide-in 0.5s forwards',
};
let messageTop = 24; // Initial top position
const messageSpacing = 40; // Vertical spacing between messages
export default function throwError(error: AxiosError) {
  const { response } = error;
  if (response?.data.error) {
    const config: number[] = [503, 502, 401, 424];
    if (config.includes(response.status)) return;
    const errorKey = response.status;
    if (!displayedErrorKeys.has(errorKey)) {
      displayedErrorKeys.add(errorKey);
      message.error({
        content:capitalizeFirstLetter(response.data.error.message),
        style: { ...messageStyle, top: `${messageTop}px` }, // Set top position dynamically
      });
      setTimeout(() => {
        displayedErrorKeys.delete(errorKey);
      }, 5000);
      messageTop += messageSpacing; // Increment top position for next message
    }
  }
}
export function throwSuccess(msg: string) {
  message.success({ content: msg, style: { ...messageStyle, top: `${messageTop}px` } });
  messageTop += messageSpacing; // Increment top position for next message
  // Reset messageTop if a success message is displayed
  setTimeout(() => {
    messageTop = 24;
  }, 5000);
}

export function throwFrontEndError(msg: string) {
  message.error({ content: msg, style: { ...messageStyle, top: `${messageTop}px` } });
  messageTop += messageSpacing; // Increment top position for next message
  // Reset messageTop if a success message is displayed
  setTimeout(() => {
    messageTop = 24;
  }, 5000);
}

export function throwInfo(msg: string) {
  message.info({ content: msg, style: { ...messageStyle, top: `${messageTop}px` } });
  messageTop += messageSpacing; // Increment top position for next message
  // Reset messageTop if a success message is displayed
  setTimeout(() => {
    messageTop = 24;
  }, 5000);
}

export function throwWarning(msg: string) {
  message.warning({ content: msg, style: { ...messageStyle, top: `${messageTop}px` } });
  messageTop += messageSpacing; // Increment top position for next message
  // Reset messageTop if a success message is displayed
  setTimeout(() => {
    messageTop = 24;
  }, 5000);
}