import React, { useEffect, useMemo, useState } from "react";
import Divider from "antd/es/divider";
import Empty from "antd/es/empty";
import Tag from "antd/es/tag";
import Typography from "antd/es/typography";
import { Jd, ResumeAnalysis } from "../../type/type";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import parse from "html-react-parser"
import DislikeFilled from "@ant-design/icons/DislikeFilled";
import DislikeOutlined from "@ant-design/icons/DislikeOutlined";
import LikeFilled from "@ant-design/icons/LikeFilled";
import LikeOutlined from "@ant-design/icons/LikeOutlined";
import Editor from "../../ant/Editor/Editor";
// import { LabeledIconButton } from "../../ant/Button";
import { UnprivilegedEditor } from "react-quill";
import { customFormats, customModules } from "../../constant";
import { updateRelevancyFeedback } from "../../lib/api";
import { CurrentCandidateInPool } from "../../redux/reducers/initialState";
import { getRelevancyFeedback } from "../../lib/api";
import { throwSuccess } from "../../service/throwError";
import CandidateAiDetailView, { ListItem } from "./CandidateAiDetailView";

const { Text } = Typography;

type CandidateDetailAiScoreProps = {
  candidateAiData: ResumeAnalysis | null | undefined;
  currentTrackerJd: Jd;
  currentCandidate: CurrentCandidateInPool;
};

const CandidateDetailAiScore: React.FC<CandidateDetailAiScoreProps> = ({
  candidateAiData,
  currentTrackerJd,
  currentCandidate
}) => {
  if (!candidateAiData || !candidateAiData.summary) {
    return (
      <div className="w-full h-full items-center justify-center flex">
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={<span>No data available</span>}
        />{" "}
      </div>
    );
  } 
  const [feedback, setFeedback] = useState<'like' | 'dislike' | null>(null);
  const [Comment, setComment] = useState<string>("");
  const [showEditor, setShowEditor] = useState(true);

  useEffect(()=>{
    const fetchFeedback = async () => {
      try {
        const res = await getRelevancyFeedback(currentTrackerJd.id, currentCandidate.candidate.id);
          let feedback: any = res?.feedback?.feedback;
          feedback = feedback === true ? 'like' : feedback === false ? 'dislike' : null;
          setFeedback(feedback);        
          let comment = res?.feedback?.comment ? res?.feedback?.comment : "";
          if (comment) {
            setShowEditor(false);
          }
          setComment(comment);
      } catch (error) {
        console.log("error while getting feedback", error);
      }
    };
    setShowEditor(true);
    fetchFeedback();
    
  },[currentCandidate.candidate.id]);
  const tagStyle = useMemo(() => {
    const isIrrelevant = candidateAiData.remark.toLowerCase() === "irrelevant";
    return {
      color: isIrrelevant ? "#F5222D" : "#009D79",
      backgroundColor: isIrrelevant ? "#FFF1F0" : "#E3FFF9",
      border: `1px solid ${isIrrelevant ? "#FFA39E" : "#37C8A6"}`,
    };
  }, [candidateAiData.remark]);

  const handleFeedbackSubmission = async (type?: 'like' | 'dislike'|null) => {
    let mappedFeedback:any;
    if (type) {
      const newFeedback = type === feedback ? null : type;
      setFeedback(newFeedback);
      mappedFeedback = newFeedback === 'like' ? true: newFeedback === 'dislike' ? false : null;
    }else{
      setShowEditor(false);
      mappedFeedback = feedback === 'like' ? true 
      : feedback === 'dislike' ? false 
      : null;
    }
    const postData = {
      jd_id:currentTrackerJd.id,
      candidate_id: currentCandidate.candidate.id,
      feedback: mappedFeedback,
      comment: Comment
    };
    try {
      await updateRelevancyFeedback(postData);
      throwSuccess("Feedback submitted successfully!");
    } catch (error) {
      console.error('Failed to submit feedback:', error);
    }
  };
  

  const handleKeyDownComment = (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.keyCode === 13 && e.metaKey) {
        handleFeedbackSubmission();
      }
    };
  
    const handleEditorChange = (
      content: string,
      delta: any,
      source: string,
      editor: UnprivilegedEditor
    ) => {
      if (editor.getText().length > 0) {
        setComment(content);
      }
    };
  
  const { metadata } = candidateAiData;

  const experienceText = useMemo(() => {
    const experience = metadata?.total_years_of_experience;
    return experience && experience > 0 ?`${experience} years`
      : null;
  }, [metadata.candidate_data?.experience]);

  const educationText = useMemo(() => {
    const education = metadata.candidate_data?.education?.[0];
    return education
      ? `${education.degree} - ${education.university} (${education.year})`
      : null;
  }, [metadata.candidate_data?.education]);

  const listItems:ListItem[] = [
    { label: "Experience", value: experienceText },
    { label: "Years of Gap", value: metadata.total_gap_years?`${metadata.total_gap_years} years`:null },
    { label: "Current City", value: metadata.current_city },
    { label: "Primary Skills", value: metadata.matching_skills?.join(", ") },
    { label: "Last Organization", value: metadata.last_organization },
    { label: "Education", value: educationText },
  ];
  return (
    <div className="space-y-4">
      <div className="flex items-center mt-2 justify-between">
        <Text className="text-lg font-semibold">Resume Analysis</Text>
        <div className="flex items-center gap-2">
          <Tag style={tagStyle}>
            {capitalizeFirstLetter(candidateAiData?.remark)}
          </Tag>
        </div>
      </div>

      <CandidateAiDetailView listItems = {listItems}/>
      <div className="space-y-4">
        <div>
          <Text className="font-semibold block mb-2">{`Why ${candidateAiData.remark}?`}</Text>
          <Text type="secondary">
            <Text type="secondary">{parse(candidateAiData.summary)} </Text>
          </Text>
        </div>

        {metadata.missing_skills && metadata.missing_skills.length > 0 && (
          <div>
            <Text className="font-semibold block mb-2">Missing Skills</Text>
            <div className="flex flex-wrap gap-2">
              {metadata.missing_skills.map((skill) => (
                <Tag key={skill} color="red">
                  {skill}
                </Tag>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="flex justify-end items-center gap-4 mt-4">
        <div
          className="flex items-center gap-2 cursor-pointer"
          onClick={() => handleFeedbackSubmission("like")}
          role="button"
          onKeyDown={() => {}}
          tabIndex={0}
        >
          {feedback === "like" ? (
            <LikeFilled style={{ fontSize: "20px", color: "#4B48A5" }} />
          ) : (
            <LikeOutlined style={{ fontSize: "20px", color: "#4B48A5" }} />
          )}
          <Text>Helpful</Text>
        </div>
        <div
          className="flex items-center gap-2 cursor-pointer"
          onClick={() => handleFeedbackSubmission("dislike")}
          role="button"
          onKeyDown={() => {}}
          tabIndex={0}
        >
          {feedback === "dislike" ? (
            <DislikeFilled style={{ fontSize: "20px", color: "#4B48A5" }} />
          ) : (
            <DislikeOutlined style={{ fontSize: "20px", color: "#4B48A5" }} />
          )}
          <Text>Not Helpful</Text>
        </div>
      </div>
      <div>
        <div className="w-full  sticky bottom-0  bg-white">
          <Divider className="mb-3  mt-2 bg-white" />
          {!showEditor ? (
            <div>
              <div className="mb-2"><Text className="font-semibold">Feedback</Text></div>
              <div className="border rounded p-2">
              <Text type="secondary"> 
                {parse(Comment)}
              </Text>
              </div>
              <div className="flex w-full justify-end items-center">
                <Tag
                  bordered={false}
                  className="cursor-pointer flex w-9 justify-end mt-1 "
                  onClick={() => setShowEditor(true)}
                >
                  {"edit"}
                </Tag>
              </div>
            </div>
          ) : (
            <>
              <div
                className="editor-description border rounded mb-2"
                style={{
                  height: "calc(-200px + 67vh)",
                }}
              >
                <Editor
                  defaultValue={Comment}
                  initialValue={Comment}
                  onChange={handleEditorChange}
                  value={Comment}
                  modules={customModules}
                  formats={customFormats}
                  placeholder="Tell us more about your feedback..."
                  onKeyDown={handleKeyDownComment}
                />
              </div>
              <Text
                type="secondary"
                className="text-left"
                style={{ fontSize: "14px" }}
              >
                <sup style={{ color: "red" }}>*</sup>
                Please press command (ctrl) + Enter to save or update the
                feedback.
              </Text>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(CandidateDetailAiScore);
