import Button from "antd/es/button";
import ConfigProvider from "antd/es/config-provider";
import Empty from "antd/es/empty";
import Row from "antd/es/row";
import React, { useEffect } from "react";
import {
  archieveUnArchiveList,
  getArchivedLists,
} from "../../../../../lib/api";
import { Dispatch } from "redux";
import {
  initArchiveLists,
  sendBackArchivedList,
} from "../../../../../redux/actions/opening/action";
import { connect } from "react-redux";
import { Opening, currentApplicant } from "../../../../../type/type";
import RollbackOutlined from "@ant-design/icons/RollbackOutlined";
import { SOCKET_CLIENT_ID } from "../../../../../constant";

type ArchiveItemsProps = {
  click(): void;
  switcher: any;
  currentOpening: Opening;
  clientId: any;
  archiveList: any;
  initArchivedLists(list: any, openingID: string): void;
  sendBackArchivedList(list: any): void;
};

const ArchivedList = (props: ArchiveItemsProps) => {
  useEffect(() => {
    // Fetch archived lists when component mounts
    getArchivedLists(props.currentOpening.id).then((list: any) => {
      props.initArchivedLists(list, props.currentOpening.id);
    });
  }, []);

  const sendBackToBoard = (archivedItem: any) => {
    // Send archived item back to board
    const req = {
      id: archivedItem.id,
      archive: false,
      socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
    };
    archieveUnArchiveList(req).then((res: any) => {
      props.sendBackArchivedList(res);
    });
  };

  return (
    <div className="h-full w-full" style={{ height: "calc(-195px + 100vh)" }}>
      <div
        className="flex-1 overflow-y-auto pl-4 pr-2"
        style={{ height: "calc(-195px + 100vh)" }}
      >
        <div className="flex flex-col  space-y-4">
          {props.archiveList?.[props.currentOpening.id] ?props.archiveList?.[props.currentOpening.id].length>0? (
            // Render archived lists if available
            props.archiveList[props.currentOpening.id].map(
              (list: currentApplicant, index: number) => (
                <Row key={index} className="w-72" >
                  <ConfigProvider
                    theme={{
                      token: {
                        paddingLG: 16,
                      },
                    }}
                  >
                    {/* Render each archived list */}
                    <div
                      role="button"
                      tabIndex={0}
                      onKeyDown={() => {}}
                      style={{
                        boxShadow: "0px 1px 4px 0px #0000001A",
                      }}
                      className="flex items-center rounded  gap-1 w-full truncate  p-3 cursor-pointer"
                      key={list.id}
                      id={list.id}
                    >
                      <span className="text-base font-medium w-full truncate">
                        {list.name}
                      </span>

                      <div className="flex  text-lg justify-start">
                        {/* Button to send archived item back to board */}
                        <Button
                          onClick={() => sendBackToBoard(list)}
                          icon={<RollbackOutlined />}
                          type="link"
                          className=" text-xs font-normal"
                        >
                          Send to tracker
                        </Button>
                      </div>
                    </div>
                  </ConfigProvider>
                </Row>
              )
            )
          ) : (
            // Render nothing if no archived lists available
            <div className="flex">
            <Empty description="No archived lists" />
            </div>
        ):null}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  archiveList: state.opening.archiveList,
  clientId: state.opening.clientId,
});

const mapPropsToState = (dispatch: Dispatch) => ({
  initArchivedLists: (archiveList: any, openingID: string) =>
    dispatch(initArchiveLists(archiveList, openingID)),
  sendBackArchivedList: (archiveList: any) =>
    dispatch(sendBackArchivedList(archiveList)),
});

export default connect(mapStateToProps, mapPropsToState)(ArchivedList);
