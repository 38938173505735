import React, { useState } from "react";
import Popover from "antd/es/popover";
import Typography from "antd/es/typography";
import Divider from "antd/es/divider";
import TeamOutlined from "@ant-design/icons/TeamOutlined";
import { LabeledIconButton } from "../../ant/Button";
import TacitbaseAvtar from "../../ant/Avtar";

interface Participant {
  id: string;
  name: string;
}

interface ParticipantsPopoverProps {
  meetingParticipants: Participant[];
}

const ParticipantsPopover: React.FC<ParticipantsPopoverProps> = ({
  meetingParticipants,
}) => {
  const [visible, setVisible] = useState(false);

  const popoverContent = (
    <div className="w-60">
      <Typography.Title level={5} className="text-[clamp(1rem, 1.2vw, 1.5rem)]">
        Participants
      </Typography.Title>
      <Divider className="my-2" />
      {meetingParticipants.map(({ id, name }) => (
        <div key={id} className="flex items-center gap-2 py-2">
          <TacitbaseAvtar content={name.split(" ")[0][0]} />
          <Typography.Text>{name}</Typography.Text>
        </div>
      ))}
    </div>
  );

  return (
    <div className="flex items-center gap-0.5">
      <Popover
        content={popoverContent}
        open={visible}
        onOpenChange={setVisible}
        trigger="hover"
      >
        <LabeledIconButton
          label={meetingParticipants.length}
          size="small"
          type="text"
          icon={<TeamOutlined />}
          tooltipTitle="Participants"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </Popover>
    </div>
  );
};

export default ParticipantsPopover;
