import Tabs from "antd/es/tabs";
// import { TabsProps } from 'antd/lib';
import React, { useEffect, useState } from "react";
import CandidateInCDBActivity from "../tabs/CandidateInCDBActivity";
import TabPane from "antd/es/tabs/TabPane";
import CandidateInCDBTracker from "../tabs/CandidateInCDBTracker";
import CandidateInCDBReview from "../tabs/CandidateInCDBReview";
import CandidateInCDBMail from "../tabs/CandidateInCDBMail";
import { useParams } from "react-router-dom";
// import ShowDocumentStatus from "../../../styles/component/ESign/showDocumentStatus";

const CandidateInCDBTabs = () => {
  const [activeTab, setActiveTab] = useState<string>("Activity"); // State to track active tab
  const params: { candidate_id: string } = useParams();

  useEffect(()=>{
    setActiveTab("Activity")
  },[params.candidate_id])

  // Define tab titles and contents
  const tabData = [
    {
      key: "Activity",
      title: "Activity",
      content: <CandidateInCDBActivity activeTab={activeTab}/>,
    },
    {
      key: "Tracker",
      title: <span>Tracker</span>,
      content: <CandidateInCDBTracker activeTab={activeTab}/>,
    },
    {
      key: "Review",
      title: <span>Review</span>,
      content: <CandidateInCDBReview activeTab={activeTab}/>,
    },
    {
      key: "Mail",
      title: <span>Mail</span>,
      content: <CandidateInCDBMail activeTab={activeTab}/>,
    },
    // Add more tab data as needed
  ];

  const onChange = (key: string) => {
    setActiveTab(key);
  };
  return (
    <div className="flex flex-col h-full justify-between items-baseline w-full comment-editor">
      <Tabs
        size="small"
        activeKey={activeTab}
        defaultActiveKey={activeTab}
        className="w-full "
        onChange={onChange} 
        tabBarStyle={{ marginBottom: -8 ,padding:"6.5px 16px" }}
      >
        {tabData.map((tab) => (
          <TabPane tab={tab.title} key={tab.key} className="py-3 ">
            {tab.content}
          </TabPane>
        ))}
      </Tabs> 
    </div>
  );
};

export default CandidateInCDBTabs;
