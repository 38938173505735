import { useEffect } from 'react';
import { logoutFromAllTabs } from '../utils/logoutFromAllTabs';

export const useLogoutSync = () => {
  useEffect(() => {
    const handleMessage = (event: StorageEvent) => {
      if (event.key === 'logout') {
        logoutFromAllTabs();
      }
    };

    window.addEventListener('storage', handleMessage);
    return () => window.removeEventListener('storage', handleMessage);
  }, []);
}; 