// import UserOutlined from "@ant-design/icons/UserOutlined";
import Button from "antd/es/button";
import Divider from "antd/es/divider";
import Form from "antd/es/form";
import Typography from "antd/es/typography";
import React from "react";
import { RouteComponentProps } from "react-router";
import BrandingPanel from "./BrandingPanel";
const { Text } = Typography;
import { getUser, passwordlessLogin, resendEmailVerification } from "../../lib/api";
import { Dispatch } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { loginUserAc } from "../../redux/actions/opening/action";
import TB_ORANGE_LOGO from "../../images/TB full orange.png";
import { REGISTRATION_TITLE } from "../../constant";
import { loginUser } from "../../type/type";
import EmailVerificationContainer from "../signIn/EmailVerification";
import { EmailInput } from '../auth/EmailInput';
import { OAuthButton } from '../auth/OAuthButton';
import { EmailContinueButton } from '../auth/EmailContinueButton';
import Alert from "antd/es/alert";
import { CentralAuth } from "../../control-plane/AuthProvider";
import { handleAuthError } from "../signIn/AuthError";
import { registration } from "../../lib/api";
import Input from "antd/es/input";

type SignUpFormProps = {
  loginUserAc(token: string, data: any): void;
  loginUser: loginUser;
} & RouteComponentProps;

class SignupPage extends React.Component<SignUpFormProps> {
  formRef = React.createRef<any>();
  coolDownInterval: any;
  state = {
    emailLoading: false,
    oauthLoading: false,
    errorMessage: "",
    isUserCreated: false,
    resendLoading: false,
    coolDown: 0,
    isPageActive: true,
    showPasswordForm: false,
    loading: false,
  };

  // variables for polling mechanism
  polling: number | undefined;
  activeTimer: number | undefined;
  pollingInterval: number = 3000;
  startTime: number = Date.now();
  nextPollTime: number | null = null;
  isTimeout: boolean = false;

  componentDidMount = async () => {
    document.title = `${REGISTRATION_TITLE}`

    // resetting the cool down interval if user refresh the page
    if (this.coolDownInterval) {
      clearInterval(this.coolDownInterval);
    }

    // checking like if user is having a login token
    const token = await localStorage.getItem("login_token");
    try {
      if (!token) {
        return;
      }
      if (!this.props.loginUser?.user?.email) {
        localStorage.removeItem("login_token");
        return;
      }
      if (this.props.loginUser.user.verified) {
        this.props.history.push('/onboarding');
        return;
      }
      if (!this.props.loginUser.user.verified) {
        this.setState({ isUserCreated: true });
      }
    } catch (error) {
      localStorage.removeItem('login_token');
    }

    // implementing pooling mechanism to automatically redirect user based on user current state
    this.clearAllIntervals();
    // Set up visibility change listener
    document.addEventListener('visibilitychange', this.handleVisibilityChange);

    // Start polling if page is active
    if (document.visibilityState === 'visible' && this.state.isUserCreated) {
      this.startTime = Date.now();
      this.startPolling();
      this.trackActiveTime();
    }
  };

  componentWillUnmount() {
    this.cleanup();
  }

  pollServer = async () => {
    if (document.hidden || !this.state.isUserCreated) {
      return;
    }
    try {
      const userRes = await getUser(this.props?.loginUser?.user?.id);

      if (userRes?.verified) {
        this.stopPolling();
        window.location.href = '/onboarding';
      } else {
        this.stopPolling();
        this.pollingInterval *= 2;
        this.startPolling();
      }
    } catch (error) {
      console.error('Error checking email verification:', error);
      this.stopPolling();
    }
  }

  startPolling = () => {
    if (this.isTimeout || document.hidden || !this.state.isUserCreated) return;
    this.stopPolling();
    this.nextPollTime = Date.now() + this.pollingInterval;
    this.polling = window.setInterval(this.pollServer, this.pollingInterval);
  }

  stopPolling = () => {
    if (this.polling) {
      clearInterval(this.polling);
      this.polling = undefined;
      this.nextPollTime = null;
    }
  }

  resetAll = () => {
    this.stopPolling();
    if (this.activeTimer) {
      clearInterval(this.activeTimer);
    }
    this.pollingInterval = 3000;
    this.startTime = Date.now();
    this.isTimeout = false;
  }

  handleVisibilityChange = () => {
    if (document.hidden) {
      this.setState({ isPageActive: false });
      this.stopPolling();
      this.resetAll();
      if (this.activeTimer) {
        clearInterval(this.activeTimer);
      }
    } else {
      if (!this.isTimeout && this.state.isUserCreated) {
        this.setState({ isPageActive: true });
        this.pollServer();
        this.startTime = Date.now();
        this.pollingInterval = 3000;
        this.startPolling();
        this.trackActiveTime();
      }
    }
  }

  trackActiveTime = () => {
    if (this.activeTimer) {
      clearInterval(this.activeTimer);
    }

    this.activeTimer = window.setInterval(() => {
      const currentTime = Date.now();
      const elapsedSeconds = Math.floor((currentTime - this.startTime) / 1000);

      if (elapsedSeconds >= 200) {
        this.stopPolling();
        this.isTimeout = true;
        clearInterval(this.activeTimer);
      }
    }, 1000);
  }

  clearAllIntervals = () => {
    if (this.coolDownInterval) {
      clearInterval(this.coolDownInterval);
    }
    this.stopPolling();
    if (this.activeTimer) {
      clearInterval(this.activeTimer);
    }
  }

  cleanup = () => {
    this.resetAll();
    this.clearAllIntervals();
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  }

  setCoolDown = (seconds: number) => {
    this.setState({ coolDown: seconds });
    this.coolDownInterval = setInterval(() => {
      this.setState(
        (prevState: any) => ({
          coolDown: Math.max(prevState.coolDown - 1, 0),
        }),
        () => {
          if (this.state.coolDown === 0 && this.coolDownInterval) {
            clearInterval(this.coolDownInterval);
          }
        }
      );
    }, 1000);
  };

  resendEmail = () => {
    if (this.state.coolDown > 0) return;
    this.setState({ resendLoading: true });
    resendEmailVerification(this.props.loginUser.user.email)
      .then((res: any) => {
        if (res) {
          this.setCoolDown(30);
          this.setState({ resendLoading: false });
        }
      })
      .catch(() => {
        this.setState({ resendLoading: false });
      })
  }

  handleSubmit = async ({ email }: { email: string }) => {
    this.setState({ emailLoading: true, errorMessage: "" });

    try {
      const loginRes = await passwordlessLogin(email.toLowerCase());
      if (loginRes) {
        this.props.history.push({
          pathname: '/confirm-email',
          state: { email: email.toLowerCase() }
        });
        if (document.visibilityState === 'visible') {
          this.startTime = Date.now();
          this.startPolling();
          this.trackActiveTime();
        }
      }
    } catch (error: any) {
      this.handleError(error);
    } finally {
      this.setState({ emailLoading: false });
    }
  };

  handleError = (error: any) => {
    handleAuthError(error, {
      onSetError: (message: string) => this.setState({ errorMessage: message }),
      clearErrorAfterDelay: true
    });
  };

  handleOAuthLogin = async () => {
    this.setState({ oauthLoading: true, errorMessage: "" });

    try {
      await CentralAuth().handleLogin('google');
      // Success case is handled by handleAuthSuccess in CentralAuth
    } catch (error: any) {
      this.handleError(error);
    } finally {
      this.setState({ oauthLoading: false });
    }
  };

  validatePassword = (_: any, value: any) => {
    if (!value) {
      return Promise.reject(new Error("Please enter a password!"));
    }
    if (value.length < 8) {
      return Promise.reject(
        new Error("Password must be at least 8 characters")
      );
    }
    if (/\s/.test(value)) {
      return Promise.reject(
        new Error("Password cannot contain whitespace")
      );
    }
    return Promise.resolve();
  };

  handlePasswordSignup = async (values: any) => {
    this.setState({ loading: true, errorMessage: "" });
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    
    try {
      const userValues = {
        ...values,
        timezone: userTimezone,
      };
      
      const loginRes = await registration({ user: userValues });
      if (loginRes) {
        this.props.history.push({
          pathname: '/confirm-email',
          state: { email: values.email.toLowerCase() }
        });
        if (document.visibilityState === 'visible') {
          this.startTime = Date.now();
          this.startPolling();
          this.trackActiveTime();
        }
      }
    } catch (error: any) {
      this.handleSignupError(error);
    } finally {
      this.setState({ emailLoading: false });
    }
  };

  handleSignupError = (error: any) => {
    if (error.response?.status === 409) {
      this.formRef.current.setFields([{
        name: "email",
        errors: ["An account already exists with this email address"],
      }]);
    } else if (error.response?.status === 422) {
      this.formRef.current.setFields([{
        name: "email",
        errors: [error.response?.data?.error?.message],
      }]);
    } else if (error.response?.status === 400) {
      this.formRef.current.setFields([{
        name: "password",
        errors: [error.response?.data?.error?.message],
      }]);
    } else {
      this.handleError(error);
    }
  };

  renderSignupForm = () => {
    const { showPasswordForm, loading, emailLoading } = this.state;

    return (
      <Form
        requiredMark={false}
        name="normal_login"
        className="login-form text-xs w-full"
        initialValues={{ remember: true }}
        onFinish={showPasswordForm ? this.handlePasswordSignup : this.handleSubmit}
        layout="vertical"
        ref={this.formRef}
      >
        <EmailInput/>
        {showPasswordForm && (
          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: true, validator: this.validatePassword }]}
          >
            <Input.Password size="large" />
          </Form.Item>
        )}

        <Form.Item>
          {showPasswordForm ? (
            <Button
              size="large"
              loading={loading}
              htmlType="submit"
              type="primary"
              className="w-full mb-3"
            >
              Create Account
            </Button>
          ) : (
            <EmailContinueButton
              mode="signup"
              loading={this.state.emailLoading}
            />
          )}
        </Form.Item>

        <div className="flex items-start gap-2 bg-gray-50 p-3 rounded-md mb-6">
          <Text className="text-gray-600 text-xs">
            By signing up, you agree to our&nbsp;
            <a
              href="https://www.tacitbase.com/terms-of-use"
              style={{ color: "#009D79" }}
            >
              terms of use{" "}
            </a>
            &nbsp;and&nbsp;
            <a
              href="https://www.tacitbase.com/privacy"
              style={{ color: "#009D79" }}
            >
              {" "}
              privacy policy.
            </a>
          </Text>
        </div>
        <Divider />
        <div className="flex items-center">
        <Text className="text-gray-600 text-xs md:text-sm">
          Need help? Visit&nbsp;
          <a
            href="https://support.tacitbase.com/"
            style={{ color: "#009D79" }}
          >
            support.
          </a>
          </Text>
          <div className="pl-1 text-xs">
          <Button 
            type="link" 
            onClick={() => this.setState({ showPasswordForm: !showPasswordForm })}
            size="small"
          >
            {showPasswordForm ? "Sign up with magic link" : "Sign up with password"}
          </Button>
        </div>
        </div>
        <div className=" mt-3 text-gray-600">
          <Text className=" text-gray-600">Already have an account?</Text>{" "}
          <Button
            type="link"
            className="p-0"
            onClick={() => this.props.history.push("/login")}
          >
            Sign in
          </Button>
        </div>
      </Form>
    );
  };

  render() {
    if (this.state.isUserCreated) {
      return <EmailVerificationContainer/>
    }
    return (
      <div className="flex min-h-screen max-h-screen overflow-hidden ">
        {/* Left Panel */}
        <BrandingPanel />
        {/* Right Panel - Sign Up Form */}
        <div className="w-full lg:w-1/2 flex lg:items-center lg:justify-center p-8 ">
          <div className="w-full sm:w-full md:w-full lg:w-10/12 xl:9/12 2xl:w-8/12 space-y-8">
            <div className="lg:hidden flex  mb-8">
              <img
                src={TB_ORANGE_LOGO}
                alt="Tacitbase Logo"
                className="w-48"
                style={{ backgroundColor: "transparent" }}
              />
            </div>
            <div className="text-left flex flex-col gap-2">
              <Text className="lg:text-2xl text-xl  font-bold bg-gradient-to-r text-tacitbase-secondary3">
                Sign up{" "}
              </Text>
              <Text className="text-gray-600 text-xs md:text-sm">
                14-day free trial • No credit card needed
              </Text>
            </div>
            <div className="flex flex-col md:space-y-4 space-y-2 relative w-full">
              {this.state.errorMessage && (
                <Alert message={this.state.errorMessage} type="error" />
              )}
              <OAuthButton
                mode="signup"
                onLogin={this.handleOAuthLogin}
              />
              <Divider>Or</Divider>
              {this.renderSignupForm()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loginUserAc: (token: string, data: any) => dispatch(loginUserAc(token, data)),
});

const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SignupPage));
