import React, {  useEffect, useRef, useState } from "react";
import Empty from 'antd/es/empty';
import Popconfirm from 'antd/es/popconfirm';
import Popover from 'antd/es/popover';
import Space from 'antd/es/space';
import Table from 'antd/es/table';
import Tooltip from 'antd/es/tooltip';
import Typography from 'antd/es/typography';
import {  generateCandidateRelevancy, getApplicantStatus, getCandidates, getTable, searchCandidateDocument } from "../../lib/api"
import { Candidate, FormBuilder, Opening, relevancyFilter, ResumeAnalysis, searchDynamicQuery, TableColumn } from "../../type/type";
import TacitbaseAvtar from "../../ant/Avtar";
// import { generateSearchQuery } from "../../utils/SearchDynamicQuery";
import {  BETA_PRODUCT, IS, NOT_AVAILABLE, operatorMap, PAID_RELEVANCY_FEATURES, PoolRoute } from "../../constant";
import { connect } from "react-redux";
import TableAnt from "../../ant/TableAnt";
import { IconButton, SecondaryButton } from "../../ant/Button";
import EyeOutlined from "@ant-design/icons/EyeOutlined"
import LockOutlined from "@ant-design/icons/LockOutlined"
import ReloadOutlined from "@ant-design/icons/ReloadOutlined"
import WarningOutlined from "@ant-design/icons/WarningOutlined"
import SyncOutlined from "@ant-design/icons/SyncOutlined";
import { useHistory, useParams } from "react-router-dom";
import ApplicantDetailView from "../../components/jobPost/tabs/pool/ApplicantDetailView";
import { Dispatch } from "redux";
import {  applicantStatus, initCandidatesAtsPool, initCDBHead, initJobPostPoolSearchFilter,  initSearchQuery, setResumeRelevancyFilterNameAndCount } from "../../redux/actions/opening/action"
import { renderCreatedAt } from "../../utils/renderCreatedAt";
import SendPoolCandidateToTracker from "../../components/jobPost/tabs/pool/actions/SendPoolCandidateToTracker";
import SendPoolCandidateToReview from "../../components/jobPost/tabs/pool/actions/SendPoolCandidateToReview";
import AddBulkLabelToCandidateInAtsPool from "../../components/jobPost/tabs/pool/actions/AddBulkLabelToCandidateInAtsPool";
import SendMailToPoolCandidate from "../../components/jobPost/tabs/pool/actions/SendMailToPoolCandidate";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import Tag from 'antd/es/tag';
import ActionButton from "../../components/CDB/AddCandidateActionButton";
import ImportResumePool from "./ImportResumePool";
import Read from "../../Read";
import { useOrgMetadata } from "../setting/billingSetting/OrgMetadata";
import { newOrganisation } from "../../lib/apiRes";
import PoolTableFilter from "./PoolTableFilter";
import MoreAction from "../../components/CDB/CDBTableMoreActions/MoreAction";
import PoolSearchBox from "./PoolSearchBox";
import { generateSearchQuery } from "../../utils/SearchDynamicQuery";
import { convertUnixToISO } from "../../utils/ConvertUnixToIso";
import parse from "html-react-parser"
import CandidateAiDetailView, { ListItem } from "../../components/resumeRanking/CandidateAiDetailView";
import Title from "antd/es/typography/Title";
import Spin from "antd/es/spin";
const {Text} = Typography;

// Type definition for the Redux props
type TableReduxProps = {
  currentOpening: Opening;
  applicationId: string;
  initCDBHead: (table: any) => void;
  initCandidates: (members: Candidate[], currentPage: string) => void;
  candidatePool: any;
  currentTrackerForm: FormBuilder;
  currentTrackerJd:any;
  sources:{[key:string]:{name:string,profile:string}},
  applicantStatus: (applicants: { id: string, trackers: string[] }[]) => void,
  applicantionStatus: {
    [key: string]: Candidate[]
  },
  currentOrg:newOrganisation;
  relevancyFilter:relevancyFilter;
  addResumeRelevancyCount: (data: relevancyFilter) => void;
  searchQuery: searchDynamicQuery;
  initSearchQuery: (query: string) => void;
  initSearchFilter: (filterObj: any) => void;
};



const PoolTable = ({
  currentOpening,
  applicationId,
  initCandidates,
  candidatePool,
  currentTrackerJd,
  sources,
  applicantStatus,
  applicantionStatus,
  initCDBHead,
  currentOrg,
  relevancyFilter,
  addResumeRelevancyCount,
  initSearchQuery,
  initSearchFilter,
  searchQuery
}: TableReduxProps) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [syncCandidates, setSyncCandidates] = useState<string[]>([]);
  const [selectedRowKeyData, setSelectedRowKeyData] = useState<any[]>([]);
  const orgMetadata = useOrgMetadata(currentOrg)
  const [hoveredRow, setHoveredRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]|null>(null);
  const [selectedRowKey, setSelectedRowKey] = useState<string>("");
  const [openPopoverId, setOpenPopoverId] = useState<string | null>(null);
  const [tableParams, setTableParams] = useState<any>({
    pagination: {
      current: 1,
      pageSize: 25,
      position: ["bottomLeft"],
      size: "default",
      showSizeChanger: false,
      style: {
        marginTop: "0px",
        borderTop: "1px solid rgba(5, 5, 5, 0.06)",
        paddingTop: "9px",
      },
    },
  });
  const filter = relevancyFilter?.count !== 0 ?relevancyFilter?.name.toLowerCase():"";
  const history = useHistory();
  const params = useParams<{ board_id: string }>();
  useEffect(() => {
    initSearchQuery("");
    getTable()
      .then((res: { columns: any }) => {
        initCDBHead(res.columns);
      })
    return()=>{
      addResumeRelevancyCount({ name: "created_at", count: 0 });
    }
  }, []);

  useEffect(() => {
    setTableParams((prev: any) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        current: 1,
      },
    }));
  }, [ searchQuery]);

  useEffect(() => {
    // Dynamically set the height of the table body
    const tableBody: any = document.querySelector(".ant-table-body");
    if (tableBody) {
      tableBody.style.height = "calc(100vh - 294.8px)";
    }
  }, [data]);

  useEffect(() => {
    // Populate data from candidate pool when available
    if (candidatePool[tableParams.pagination.current]) {
      setData(generateData(Object.values(candidatePool[tableParams.pagination.current]))||[]);
    } 
  }, [candidatePool]);

  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);

useEffect(() => {
  // Clear any existing timer
  if (debounceTimeoutRef.current) {
    clearTimeout(debounceTimeoutRef.current);
  }

  // Set a new debounce timeout
  debounceTimeoutRef.current = setTimeout(() => {
    if (tableParams.pagination.current && currentTrackerJd) {
      fetchData({ currentPage: tableParams.pagination.current, filter });
    }
  }, 500); // Debounce delay of 150ms

  // Cleanup on unmount or before next effect run
  return () => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
      debounceTimeoutRef.current = null;
    }
  };
}, [relevancyFilter, searchQuery, tableParams.pagination.current, currentTrackerJd, filter]);
  // Generate data for the table from candidate pool
  const generateData = (result: any) => {
    return result?.map((candidate: Candidate) => ({
      key: candidate.id,
      id: candidate.id,
      phone: candidate.phone,
      source: candidate.source?.from,
      email: candidate.email,
      candidates: {
        first_name: candidate.first_name,
        last_name: candidate.last_name,
      },
      created_by: candidate.created_by,
      created_at: candidate.created_at,
      comment_count: candidate.comment_count,
      attachment_count: candidate.attachment_count,
      resume_analysis:candidate.resume_analysis,
    }));
  };

  const handleGenerateClick = async (candidateId:string) => {
    try {
      const req = {
        candidate_id:candidateId,
        jd_id: currentTrackerJd?.id,
      }
      setSyncCandidates([...syncCandidates, candidateId]);
      await generateCandidateRelevancy(req)
      fetchData({ currentPage: tableParams.pagination.current });
    } finally {
      setSyncCandidates(syncCandidates.filter(id => id!== candidateId));
    }
  };

  // Fetch data from the API
  const fetchData = (reqParams: { currentPage: number, filter?: string }) => {
    if (!currentTrackerJd.id) return;
    setLoading(true);
    const searchQueryParam =
      (searchQuery && searchQuery.query.trim().length > 0 || searchQuery?.filters?.length > 0) 
        ? generateSearchQuery(
            searchQuery.query,
            [{ column: "sources.jd", operator: operatorMap[IS], value: currentTrackerJd.id, view: "Source", operatorName: IS, id: "" },...(searchQuery.filters || []),],
            reqParams.currentPage,
            'candidates_candidates'
          )
        : null;
  
    const apiCall = searchQueryParam
      ? searchCandidateDocument(searchQueryParam)
      : getCandidates(reqParams.currentPage, 25, currentTrackerJd.id, reqParams.filter);
  
    apiCall
      .then((res) => {
        initCandidates(res.items, reqParams.currentPage.toString());
        setTableParams((prev: any) => ({
          ...prev,
          pagination: {
            ...prev.pagination,
            total: res.total_count,
          },
        }));
        getApplicantStatus({ "ids": res.items?.map((items:any) => items.id) }).then((res: any) => {
          applicantStatus(res)
        })
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  // Column definitions for the Ant Design table
  const columns:any = [
    {
      title: <span style={{ paddingLeft: '3px'}}>Name</span>,
      dataIndex: "candidates",
      ellipsis: true,
      fixed: "left",
      render: (_: any, record: any) => (
        <Space  className=" w-full">
          <TacitbaseAvtar content={record.candidates.first_name[0]} />
          <Text ellipsis={{ tooltip: `${capitalizeFirstLetter(record.candidates.first_name)} ${capitalizeFirstLetter(record.candidates.last_name)}`}} style={{ minWidth: 100, maxWidth: 300, margin: 0 }} className="truncate">{`${capitalizeFirstLetter(record.candidates.first_name)} ${capitalizeFirstLetter(record.candidates.last_name)}`}</Text>
        </Space>
      ),
    },
    {
      width: "15%",
      title: (
        <div className="flex gap-2">
          {!orgMetadata?.ai_feature && (
            <Tooltip title={PAID_RELEVANCY_FEATURES}>
              <LockOutlined />
            </Tooltip>
          )}
           {currentTrackerJd?.resume_ranking_settings?.is_limit_reached && (
            <Tooltip title="You have reached the maximum resume relevancy limit for this job post.">
            <WarningOutlined style={{ color: "orange", marginLeft: "8px" }} />
            </Tooltip>
            )}
          <div>Relevancy</div>
          <Tooltip title={BETA_PRODUCT}>
            <Tag bordered={false} color="gold">
              Beta
            </Tag>
          </Tooltip>
        </div>
      ),
      ellipsis: true,
      fixed: "left",
      render: (_: any, record: any) => {
        const candidateDetails = record.resume_analysis;
        if (!candidateDetails?.length && (currentTrackerJd?.resume_ranking_settings && !currentTrackerJd?.resume_ranking_settings.is_limit_reached)){
          return (
            <div className="flex items-center">

          <Tag 
           color={`${syncCandidates.includes(record.id) ? 'processing': 'cyan'}`}
            onClick={(e) => {
              e.stopPropagation();
              handleGenerateClick(record?.id); 
            }}   
                   
          >
            <SyncOutlined className="mr-1" spin={syncCandidates.includes(record?.id)} />
            {syncCandidates.includes(record?.id) ? 'Analyzing...' : 'Evaluate'}
          </Tag>
         </div>
          )
        } 
    
        const currentJdAnalysis = candidateDetails?.find(
          (item: ResumeAnalysis) => item.jd_id === currentTrackerJd?.id
        );
        if (!currentJdAnalysis) return null;
    
        const candidate = currentJdAnalysis;
        const metadata = candidate?.metadata || {};
        if (!candidate?.remark) return null;
    
        const isIrrelevant = candidate.remark.toLowerCase() === "irrelevant";
    
          const experience = metadata?.total_years_of_experience;
          const experienceText = experience && experience > 0 ? `${experience} years` : null;

          const education = metadata?.candidate_data?.education?.[0];
          const educationText = education
            ? `${education.degree} - ${education.university} (${education.year})`
            : null;

    
            const tagStyle = {
              color: isIrrelevant ? "#F5222D" : "#009D79",
              backgroundColor: isIrrelevant ? "#FFF1F0" : "#E3FFF9",
              border: `1px solid ${isIrrelevant ? "#FFA39E" : "#37C8A6"}`,
            };
    
        const listItems: ListItem[] = [
          { label: "Experience", value: experienceText },
          { label: "Years of Gap", value: metadata?.total_gap_years ? `${metadata.total_gap_years} years` : null },
          { label: "Current City", value: metadata?.current_city },
          { label: "Primary Skills", value: metadata?.matching_skills?.join(", ") },
          { label: "Last Organization", value: metadata?.last_organization },
          { label: "Education", value: educationText },
        ];
    
        const hoverContent = (
          <div 
            className="space-y-4 w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl"
            style={{
              maxHeight: "320px",
              overflowY: "auto",
            }}
          >
            <CandidateAiDetailView listItems={listItems} />
            <div className="space-y-4">
              <div>
                <Text className="font-semibold block mb-2">{`Why ${candidate.remark}?`}</Text>
                <Text type="secondary">{parse(candidate?.summary)}</Text>
              </div>
    
              {metadata.missing_skills?.length > 0 && (
                <div>
                  <Text className="font-semibold block mb-2">Missing Skills</Text>
                  <div className="flex flex-wrap gap-2">
                    {metadata.missing_skills?.map((skill: string) => (
                      <Tag key={skill} color="red">
                        {skill}
                      </Tag>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        );
    
        return (
          <div className="w-full flex items-center justify-start space-x-2">
            <div style={{ minWidth: "75px" }}>
              <Tag style={tagStyle}>
                {capitalizeFirstLetter(candidate.remark)}
              </Tag>
            </div>
            <div
            tabIndex={-1}
            role="button"
              onClick={(e) => {
                e.stopPropagation();
              }}
              onKeyDown={()=>{}}
            >
              <Popover
                content={hoverContent}
                placement="bottom"
                title={
                  <div className="flex items-center mt-2 justify-between">
                    <Text className=" font-semibold">Resume Analysis</Text>
                    <div className="flex items-center gap-2">
                      <Tag style={tagStyle}>
                        {capitalizeFirstLetter(candidate.remark)}
                      </Tag>
                    </div>
                  </div>
                }
                trigger="hover"
                open={openPopoverId === record.key}
                onOpenChange={(visible) => {
                  setOpenPopoverId(visible ? record.key : null);
                }}
              >
                <EyeOutlined />
              </Popover>
            </div>
          </div>
        );
      },
    },
    {
      width: "25%",
      title: "Email",
      ellipsis: true,
      dataIndex: "email",
    },
    // {
    //   width: "15%",
    //   title: "Phone",
    //   dataIndex: "phone",
    //   ellipsis: true,
    // },
    {
      width: "10%",
      title: "Status",
      dataIndex: "id",
      ellipsis: true,
      render: (sourceKey: string) => {
        return applicantionStatus?.[sourceKey]?.length > 0 &&
          applicantionStatus[sourceKey].some((applicants: Candidate) => applicants.opening_id == currentOpening.id)
          ? <Tag>{"In tracker"}</Tag>
      : null;    },
    },
    {
      width: "20%",
      title: "Source",
      dataIndex: "source",
      ellipsis: true,
      render: (sourceKey: string) => {
        const sourceName = sources[sourceKey] ? capitalizeFirstLetter(sources[sourceKey].name) : capitalizeFirstLetter(sourceKey);
        const sourceImage = sources[sourceKey]?.profile; // Assuming 'image' is a property in your source data
        return sourceKey.trim().length === 0 ? (
          NOT_AVAILABLE
        ) : (
          <Space className="truncate flex justify-start w-full" style={{minWidth:"188px"}}>
            {sourceImage ? (
              <img src={sourceImage} alt={sourceName} style={{ width: 20, height: 20, borderRadius: '50%' }} />
            ) : (
              <TacitbaseAvtar content={sourceName[0]} />
            )}
            <div className="truncate">{sourceName}</div>
          </Space>
        );
      },
    },
    // {
    //   width: "15%",
    //   title: "Sourcer name",
    //   dataIndex: "source",
    //   ellipsis: true,
    // },
    // {
    //   width: "10%",
    //   title: "Activity",
    //   dataIndex: "members",
    //   ellipsis: true,
    // },
    {
      width: "14%",
      title: "Submitted at",
      dataIndex: "created_at",
      ellipsis: true,
      render: (record: any) => <span>{(typeof record === "number")?renderCreatedAt(convertUnixToISO(record)):renderCreatedAt(record)}</span>,
    },
  ];
  // Handle row selection changes
  const onSelectChange = (selectedRowKeys: React.Key[], selectedRows: any) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedRowKeyData(selectedRows);

  };

  const handleTableChange = (pagination: any) => {
    setTableParams({
      pagination,
    });
    fetchData({ currentPage: pagination.current,filter });
  };

  // Handle row click event
  const onRowClick = (record: any) => {
    setOpenPopoverId(null);
    setSelectedRowKey(record.key);
    history.push(
      `/o/${applicationId}/b/${currentOpening.id}/${PoolRoute}/${record.key}`
    );
  };

  // Handle row hover event
  const handleRowHover = (record: any) => {
    setHoveredRow(record ? record.key : null);
  };

  const handleRefreshTab = () => {
    setTableParams((prev: any) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        current:1,
      },}))
      initSearchQuery("");
      initSearchFilter([]);
      addResumeRelevancyCount({ name: "created_at", count: 0 });
    //   fetchData({
    //   currentPage: 1,
    // });
  };

  // Render bulk action controls for selected rows
  const tableBulkAction = () => (
    <>
      <Space className="flex items-center px-4 justify-start">
        <strong>{selectedRowKeys.length}</strong> Selected
        <AddBulkLabelToCandidateInAtsPool selectedRowKeys={selectedRowKeys} />
        <SendPoolCandidateToTracker
          isBulk={true}
          candidateIds={selectedRowKeys} />
        <SendPoolCandidateToReview isBulk={true} candidateIds={selectedRowKeys} />
        <SendMailToPoolCandidate isBulk={true} candidateIds={selectedRowKeys} cadidatesData={selectedRowKeyData} />
        {/* <MoreAction  selectedCandidates={selectedRowKeys} isSelected={true}/> */}
        <Popconfirm
          title="Close review"
          onConfirm={() => { } }
          okText="Yes"
          cancelText="No"
          placement="bottom"
          key={2} />
      </Space><div className="flex flex-row gap-2 items-center justify-end pr-4">
        {/* <div className="flex gap-2 items-center "> */}
        <PoolSearchBox />
        {/* {currentTrackerJd?.resume_ranking_settings !== null && (<PoolTableFilter />)} */}
        <PoolTableFilter />
        <IconButton
          icon={<ReloadOutlined />}
          onClick={handleRefreshTab} />
        <MoreAction selectedCandidates={selectedRowKeys} isSelected={true} />
      </div></>
   
  );

  const goToRecommendedProfiles = () => {
    history.push({
      pathname: `/o/${applicationId}/b/${params.board_id}/pool`,
      search: '?tab=recommendation'
    });
  };
  
  return (
    <div className="flex flex-col -mt-4">
      <div className="bg-white flex flex-row w-full h-12 items-center rounded-t justify-between border-b">
        {selectedRowKeys.length > 0 ? (
          tableBulkAction()
        ) : (         
          <div className="flex flex-1 flex-row justify-between items-center">
            <div className="flex  justify-start">
              <Read >
                {currentTrackerJd?.id?.trim().length > 0 && (
                  <div className="flex flex-row space-x-2">
                    <ActionButton />
                    <ImportResumePool />
                  </div>
                )}
              </Read>

            </div>

            <div className="flex gap-2 justify-end items-center ">
            <PoolSearchBox />
             {/* {currentTrackerJd?.resume_ranking_settings !== null &&( */}
               <PoolTableFilter/>
               {/* )} */}
              <IconButton
                icon={<ReloadOutlined />}
                onClick={handleRefreshTab}
              />
              <MoreAction JdId={currentTrackerJd.id} isSelected={false}/>
            </div>
          </div>
        )}
      </div><div className=" h-full z-0" style={{ height: "calc(100vh - 212px)" }}>
        {data==null ? (
          <div className="flex flex-col h-full items-center justify-center">
            <Spin />
          </div>
        ) : (
        <div className="h-full">
          <TableAnt
            rowClassName={(record) => record.key === hoveredRow ? "cursor-pointer" : ""}
            size="small"
            loading={loading}
            columns={columns}
            data={data}
            onChange={handleTableChange}
            className="pool-table h-full w-full bg-white rounded-b overflow-y-hidden"
            pagination={tableParams.pagination}
            scroll={{ y: "calc(100vh - 130.8px)" ,x: 1500}}
            rowSelection={{
              selectedRowKeys,
              preserveSelectedRowKeys: true,
              onChange: onSelectChange,
              selections: [
                Table.SELECTION_ALL,
                Table.SELECTION_INVERT,
                Table.SELECTION_NONE,
                {
                  key: "odd",
                  text: "Select Odd Row",
                  onSelect: (changeableRowKeys) => {
                    const newSelectedRowKeys = changeableRowKeys.filter(
                      (_, index) => index % 2 === 0
                    );
                    setSelectedRowKeys(newSelectedRowKeys);
                  },
                },
                {
                  key: "even",
                  text: "Select Even Row",
                  onSelect: (changeableRowKeys) => {
                    const newSelectedRowKeys = changeableRowKeys.filter(
                      (_, index) => index % 2 !== 0
                    );
                    setSelectedRowKeys(newSelectedRowKeys);
                  },
                },
              ],
              columnWidth: 45,
            }}
            onRow={(record) => ({
              onMouseEnter: () => handleRowHover(record),
              onMouseLeave: () => handleRowHover(null),
              onClick: () => onRowClick(record),
            })}
            locale={{
              emptyText: (
                  <div className="flex flex-col h-full items-center justify-center" style={{height:"calc(100vh - 249.8px)"}}>
                      <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={null}
                      />
                      <Title level={5} className="-mt-4">Currently, there are no submissions</Title>
                      <Text type="secondary">
                       Our curated candidate suggestions are available for your review
                       </Text>
                      <SecondaryButton onClick={goToRecommendedProfiles} className="mt-6" >
                    View
                    </SecondaryButton>
                  </div>
              ),
          }}
          />
        </div>
        )}
        <ApplicantDetailView selectedRow={selectedRowKey} />
      </div></div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  initCDBHead: (table: TableColumn[]) => dispatch(initCDBHead(table)),
  initCandidates: (candidates: Candidate[], currentPage: string) =>
    dispatch(initCandidatesAtsPool(candidates, currentPage)),
  applicantStatus: (applicants: { id: string, trackers: string[] }[]) =>
    dispatch(applicantStatus(applicants)),
  addResumeRelevancyCount: (data: relevancyFilter) =>
    dispatch(setResumeRelevancyFilterNameAndCount(data)),
  initSearchQuery: (query: string) => dispatch(initSearchQuery(query)),
  initSearchFilter: (filterObj: any) => dispatch(initJobPostPoolSearchFilter(filterObj)),
});

const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  candidatePool: state.opening.candidatePool,
  currentTrackerForm: state.opening.currentTrackerForm,
  applicationId: state.opening.applicationId,
  currentTrackerJd: state.opening.currentTrackerJd,
  sources: state.opening.candidateSources,
  applicantionStatus: state.opening.applicantStatus,
  currentOrg:state.opening.currentOrg,
  relevancyFilter: state.opening.relevancyFilter,
  searchQuery: state.opening.JobPostPoolSearchQuery,
});

export default connect(mapStateToProps, mapDispatchToProps)(PoolTable);
