import React from "react"
import  DownloadOutlined  from '@ant-design/icons/DownloadOutlined'
import { Attachment } from "../../../type/type"
import Button from "antd/es/button";
const DownloadFile = ({
    attachment

}: {
        attachment: Attachment
}) => {
    const downloadFile = async (event: any) => {
        try {
            event.stopPropagation() // Stop the click event from propagating
            event.preventDefault() // Stop the click event from propagating

            const response = await fetch(attachment.presignedLink)
            const blob = await response.blob()

            const url = window.URL.createObjectURL(blob)
            const a = document.createElement("a")
            a.href = url
            // Extract file extension from objectKey
            const objectKeyExtension = attachment.objectKey.split('.').pop();

            // Extract file extension from attachment name if present
            const nameParts = attachment.name.split('.');
            const nameExtension = nameParts.length > 1 ? nameParts.pop() : '';

            // Determine final file name and extension
            let finalName = attachment.name;
            if (nameExtension !== objectKeyExtension) {
                finalName += `.${objectKeyExtension}`;
            }

            a.download = finalName;
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        } catch (error) {
            console.error("Error downloading the file", error)
        }

    }


    return (
        <>
             <Button type="text" size="small" icon={<DownloadOutlined   />} onClick={downloadFile} >Download</Button>
        </>
    )
}

export default DownloadFile
