import React, { useState } from 'react';
import { Calendar, DateLocalizer, dayjsLocalizer, Views } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import dayjs from 'dayjs';
import Popover from "antd/es/popover";
import Space from "antd/es/space";
import  ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';
import  MailOutlined from '@ant-design/icons/MailOutlined';
import Title from 'antd/es/typography/Title';

interface ResourceCalendarProps {
  resources: Array<{
    id: string;
    title: string;
  }>;
  events: Array<{ start: Date; end: Date; title: string; createdBy: string; creatorImage?: string }>;
}

const localizer: DateLocalizer = dayjsLocalizer(dayjs);

const ResourceCalendar: React.FC<ResourceCalendarProps> = ({ resources, events }) => {
  const [activeEvent, setActiveEvent] = useState<any>(null);

  const handleEventClick = (event: any) => {    
    setActiveEvent(event.id); // Toggle active event
  };

  const renderPopoverContent = (event: any) => (
    <Space direction='vertical' className='p-2'>
      <Title level={5}>{event.title}</Title>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '6px' }}>
        <Space>
        <MailOutlined/>
        <span>{event.resourceId}</span>
        </Space>
      </div>
      <div className='flex items-start justify-start gap-2'>
        <div className='mt-1'><ClockCircleOutlined  /></div>
        <div className='flex flex-col'>
        <span>{dayjs(event.start).format('dddd, MMMM D')}</span>
        <span>{`${dayjs(event.start).format('h:mm A')} – ${dayjs(event.end).format('h:mm A')}`}</span></div>
      </div>
     
      </Space>
  );

  return (
    <div>
      <Calendar
        onSelectEvent={handleEventClick} // Open popover on event click
        defaultView={Views.DAY}
        events={events}
        localizer={localizer}
        resourceIdAccessor="id"
        resources={resources}
        resourceTitleAccessor="title"
        step={60}
        style={{width:"100%"}}
        toolbar={false}
        components={{
          event: ({ event }:any) => (
            <Popover
              content={renderPopoverContent(event)}
              open={activeEvent === event.id} // Show popover only for the active event
              trigger="click" // Open on click
              onOpenChange={(visible) => {
                if (!visible) {
                  setActiveEvent(null); // Hide popover when clicked outside
                }
              }}
            >
              <div style={{ padding: '2px',cursor: 'pointer' }}>
                {event.title}
              </div>
            </Popover>
          ),
        }}
        views={{ day: true, work_week: true }}
      />
    </div>
  );
};

export default ResourceCalendar;
