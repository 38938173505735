import React, { useEffect, useState } from 'react';
import Table from 'antd/es/table';
import Space from 'antd/es/space';
import Typography from 'antd/es/typography';
import Empty from 'antd/es/empty';
import TacitbaseAvtar from "../../ant/Avtar";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { getRecommendations } from '../../lib/api';
import { connect } from 'react-redux';
import { Jd } from '../../type/type';
import Spin from "antd/es/spin";
import { SecondaryButton } from '../../ant/Button';
import Linkedin from '../../assets/Linkedin';
import UpgradePlanModal from '../setting/billingSetting/UpgradePlanModal';
const { Text } = Typography;

interface RecommendedProfile {
  id: string;
  title: string;
  link: string;
  snippet: string;
}

interface RecommendationsTableProps {
currentTrackerJd:Jd,
CurrentOrg:any
}

const RecommendationsTable: React.FC <RecommendationsTableProps>= ({currentTrackerJd,CurrentOrg}) => {
 const [loading, setLoading] = useState(true);
  const [profiles, setProfiles] = useState<RecommendedProfile[]|null>(null);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [tableParams, setTableParams] = useState<any>({
    pagination: {
      current: 1,
      pageSize: 10,
      position: ['bottomLeft'],
      size: "default",
      showSizeChanger: false,
      style: {
        marginTop: "0px",
        borderTop: "1px solid rgba(5, 5, 5, 0.06)",
        paddingTop: "9px",
      },
    }
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  
  const allowedRecommendations = CurrentOrg?.customer?.metadata?.google_search_recommendation || 0;

  useEffect(() => {
    if (currentTrackerJd?.id) {
      fetchRecommendations(currentTrackerJd?.id);
    }
  }, [currentTrackerJd, tableParams.pagination.current]);

  const fetchRecommendations = async (currentTrackerJd:string) => {
    setLoading(true);
    try {
      const response = await getRecommendations(
        currentTrackerJd,
        tableParams.pagination.current,
        tableParams.pagination.pageSize
      );
      setProfiles(response.data);
      setTableParams((prev: any) => ({
        ...prev,
        pagination: {
          ...prev.pagination,
          total: parseInt(allowedRecommendations)+1, // This will limit the total number of items to show
        },
      }));
    } catch (error) {
      console.error('Error fetching recommendations:', error);
      setProfiles([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const setTableHeight = () => {
      const tableBody: any = document.querySelector(".ant-table-body");
      if (tableBody) {
        tableBody.style.height = "calc(100vh - 258.8px)";
      }
    };

    // Initial set
    setTableHeight();

    // Handle window resize
    window.addEventListener('resize', setTableHeight);

    // Create ResizeObserver
    const resizeObserver = new ResizeObserver(() => {
      setTableHeight();
    });

    // Observe the table container
    const tableContainer = document.querySelector(".recommendations-table");
    if (tableContainer) {
      resizeObserver.observe(tableContainer);
    }

    // Cleanup
    return () => {
      window.removeEventListener('resize', setTableHeight);
      resizeObserver.disconnect();
    };
  }, [profiles]);

  const columns:any = [
    {
      title: <span style={{ paddingLeft: '3px' }}>Name</span>,
      dataIndex: 'title',
      ellipsis: true,
      fixed: 'left',
      width: '25%',
      render: (_: any, record: RecommendedProfile) => {
        const name = record.title.split('-')[0].trim();
        return (
          <Space className="w-full">
            <TacitbaseAvtar content={name[0]} />
            <Text ellipsis={{ tooltip: name }} style={{ maxWidth: 300, margin: 0 }} className="truncate">
              {capitalizeFirstLetter(name)}
            </Text>
            <Linkedin size={18}/>
          </Space>
        );
      },
    },
    {
        title: 'Skills & Experience',
        dataIndex: 'snippet',
        ellipsis: true,
        render: (snippet: string) => {
          return (
            <Text
              style={{
                margin: 0,
                display: 'block',
                whiteSpace: 'normal',
                wordBreak: 'break-word', // Ensures words break if needed
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '100%',
                lineHeight: '1.5em', // Adjust line height for better readability
                maxHeight: '3em', // Restrict to two lines
              }}
              title={snippet}
            >
              <span style={{ display: 'block', width: '100%' }}>{snippet}</span>
            </Text>
          );
        },
      },             
    {
      title: <span style={{ textAlign:'right',display:'flex',justifyContent:'right',paddingRight:'20px'}}>Action</span>,
      key: 'action',
      width: '20%',
      className: 'text-right',
      render: (_: any, record: RecommendedProfile) => (
        <div className='flex flex-row justify-end'><SecondaryButton
              //   type="primary"
              size='small'
              className='mr-4'
              onClick={() => window.open(record.link, '_blank')}
            //   icon={<Linkedin size={16}/>}
          >
              View Profile
          </SecondaryButton><SecondaryButton
              size='small'
              className='mr-2'
              onClick={() => window.open(record.link, '_blank')}
          >
                  Connect
              </SecondaryButton></div>
      ),
    },
  ];

   // Handle row hover event
   const handleRowHover = (record: any) => {
    setHoveredRow(record ? record.id : null);
  };

  // Handle row click event
  const onRowClick = (record: any) => {
    window.open(record.link, '_blank')
  };

  const handleTableChange = (pagination: any) => {
    const startIndex = (pagination.current - 1) * pagination.pageSize;
    if (startIndex >= allowedRecommendations) {
      setIsModalVisible(true);
      return;
    }
    setTableParams((prev:any) => ({
      ...prev,
      pagination: pagination,
    }));
  };

  const handleBillingModalCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="flex flex-col -mt-4">
      <div className="bg-white flex flex-row w-full h-12 items-center rounded-t justify-between border-b">
        <div className="flex flex-1 flex-row justify-between items-center">
          <div className="text-sm text-gray-600 font-medium">Discover professionals that match your requirements based on skills and experience.</div>
        </div>
      </div>
      <div className="h-full" style={{ height: "calc(100vh - 212px)" }}>
      {profiles !== null ? (
        <Table
          rowClassName={(record) => record.id === hoveredRow ? "cursor-pointer" : ""}
          columns={columns}
          dataSource={profiles || []}
          rowKey="id"
          size="small"
          loading={loading}
          className="recommendations-table h-full w-full bg-white rounded-b overflow-y-hidden"
          style={{
            '--table-body-height': 'calc(100vh - 258.8px)',
          } as React.CSSProperties}
          pagination={tableParams.pagination}
          scroll={{ y: "calc(100vh - 298.8px)"}}
          locale={{
            emptyText: profiles?.length === 0 ? (
              <div className="flex h-full items-center justify-center" style={{ height: "calc(100vh - 249.8px)" }}>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<span>No recommendations available</span>}
                />
              </div>
            ) : null,
          }}
          onRow={(record) => ({
            onMouseEnter: () => handleRowHover(record),
            onMouseLeave: () => handleRowHover(null),
            onClick: () => onRowClick(record),
          })}
          onChange={handleTableChange}
        />
      ) : (
        <div className='flex h-full items-center justify-center'>
          <Spin />
        </div>
      )}
      </div>
      <UpgradePlanModal 
        isModalVisible={isModalVisible} 
        handleCancel={handleBillingModalCancel}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
    currentOpening: state.opening.currentOpening,
    candidatePool: state.opening.candidatePool,
    currentTrackerForm: state.opening.currentTrackerForm,
    applicationId: state.opening.applicationId,
    currentTrackerJd: state.opening.currentTrackerJd,
    CurrentOrg:state.opening.currentOrg
  });
  
  export default connect(mapStateToProps)(RecommendationsTable);
  