// import jwtDecode from "jwt-decode";
import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import Space from "antd/es/space";
import Spin from "antd/es/spin";

import Ats from "./Ats";
import {
  getAllSources,
  getAvailableIntegrations,
  getBillingActivePlans,
  getDomain,
  getEsignIntegration,
  getOrgDetails,
  getSubscriptionDetails,
} from "./lib/api";
import {
  add_current_plan,
  addDomainOfOrg,
  addWsRequest,
  changeLoaderStatus,
  clearTabStates,
  current_org,
  getActiveBoard,
  getActiveMeetings,
  initJobPlatform,
  initMembership,
  initSources,
  updateEsignDetails,
} from "./redux/actions/opening/action";
import { tokenVerify } from "./service/tokenVerify";
// import {  Plan, Product, ThirdPartyIntegrations, loginUser } from "./type/type"
import { Domain, Plan, Product, ThirdPartyIntegrations, eSignDetails, loginUser } from "./type/type";
import WSComponent from "./WSComponent";
import { isMainProductURL } from "./utils/regExMatcher";
import { orgConfigurationMiddleware } from "./service/orgConfigurationMiddleware";
import LoadingScreen from "./components/PageLoader";
import Tacitbase1 from "./images/TB full orange.png";
import { Source } from "./lib/apiRes";
import BrowserCompatibility from "./BrowserCompatibility";
import BrowserWarning from "./BrowserWarning";
import { useNetworkStatus } from "./hooks/useNetworkStatus";
import { useLogoutSync } from './hooks/useLogoutSync';

type AppProps = {
  clearTabStates(): void;
  add_current_plan: (product: Product[]) => void;
  currentOrg(orgData: any): void;
  addWsRequest(actionRequest: any): void;
  initMembers(members: any): void;
  match?: any;
  loginUser: loginUser;
  clientId: string;
  initSources(sources: Source[]): void;
  initJobPlatform(Integrations: ThirdPartyIntegrations[]): void;
  getActiveBoard(data: any): void;
  getActiveMeetings(data: any): void;
  addDomainOfOrg(domainRes: Domain): void;
  updateEsignDetails(data: eSignDetails): void;
  eSignDetails: eSignDetails;
  changeInLoaderStatus(payload: {
    loader: boolean;
    message: string;
    skeleton: boolean;
  }): void;
};

const App: React.FC<AppProps> = (props) => {
  const [state, setState] = useState({
    success: false,
    loading: false,
    isBrowserCompatible: true,
  });  // Custom hook for initialization
  const useInitializeApp = () => {
    const initializeOrg = async (params: string[]) => {
      try {
        setState(prev => ({ ...prev, loading: true }));
        const appRes = await getOrgDetails(params[2]);
        props.currentOrg(appRes);
        
        const flag = await orgConfigurationMiddleware(appRes, props.loginUser);
        if (flag) {
          setState(prev => ({ ...prev, success: true }));
          
          // Parallel API calls using Promise.all
          await Promise.all([
            getSubscriptionDetails().then(res => {
              props.getActiveBoard(res?.subscriptions?.job_board);
              props.getActiveMeetings(res?.subscriptions?.meetings_count);
            }).catch(err => console.error("Error getting subscription details", err)),
            
            getEsignIntegration().then((res: any) => {
              if (res?.id) {
                props.updateEsignDetails({ connected: true, tools: [res?.client] });
              }
            }).catch(err => console.error("Error getting esign details", err)),
            
            getAllSources()
              .then(source => props.initSources(source))
              .catch(err => console.error("Error getting sources", err)),
            
            getAvailableIntegrations()
              .then(integ => props.initJobPlatform(integ))
              .catch(err => console.error("Error getting integrations", err)),
            
            getDomain()
              .then(res => props.addDomainOfOrg(res))
              .catch(err => console.error("Error getting domain", err))
          ]);
        }
      } catch (error) {
        console.error('Error initializing app:', error);
      } finally {
        setState(prev => ({ ...prev, loading: false }));
      }
    };

    useEffect(() => {
      props.changeInLoaderStatus({ loader: false, message: "", skeleton: false });
      
      if (tokenVerify()) {
        const params = window.location.pathname.split("/");
        if (isMainProductURL(location.pathname)) {
          props.clearTabStates();
          
          getBillingActivePlans("tacitbase")
            .then((res) => {
              const unique: Product[] = [];
              if (res.plans && Array.isArray(res.plans)) {
                res.plans.forEach((plan: Plan) => {
                  if (!unique.some((item) => item.id === plan.product.id)) {
                    unique.push(plan.product);
                  }
                });
                props.add_current_plan(unique);
              }
            })
            .catch(err => console.error("Error fetching billing plans:", err));

          initializeOrg(params);
        } else {
          setState(prev => ({ ...prev, success: true }));
        }
      } else {
        setState(prev => ({ ...prev, success: true }));
      }
    }, []);
  };

  // Use custom hooks
  useNetworkStatus();
  useInitializeApp();
  useLogoutSync();

  const handleIncompatibleBrowser = useCallback(() => {
    setState(prev => ({ ...prev, isBrowserCompatible: false }));
  }, []);

  if (!state.isBrowserCompatible) {
    return <BrowserWarning />;
  }

  return (
    <>
      {state.loading && (
        <LoadingScreen
          content={<></>}
          message=""
          footer={(
            <div className="flex justify-center">
              <Space>
                <img
                  src={Tacitbase1}
                  alt="tacitbase logo"
                  width="200"
                  height="200"
                  className="object-contain"
                />
              </Space>
            </div>
          )}
          loaderIcon={(
            <div className="flex flex-col space-y-6">
              <Spin size="large">
                <div className="content" />
              </Spin>
            </div>
          )}
        />
      )}
      
      {state.success && (
        <>
          <WSComponent />
          <BrowserCompatibility onIncompatible={handleIncompatibleBrowser} />
          <Ats />
        </>
      )}
    </>
  );
};

const mapPropsToState = (dispatch: Dispatch) => ({
  clearTabStates: () => dispatch(clearTabStates()),
  initMembers: (members: any) => dispatch(initMembership(members)),
  addWsRequest: (actionRequest: any) => dispatch(addWsRequest(actionRequest)),
  initSources: (sources: Source[]) => dispatch(initSources(sources)),
  initJobPlatform: (Integrations: ThirdPartyIntegrations[]) => dispatch(initJobPlatform(Integrations)),
  currentOrg: (orgData: any) => dispatch(current_org(orgData)),
  add_current_plan: (data: Product[]) => dispatch(add_current_plan(data)),
  getActiveBoard: (data: any) => dispatch(getActiveBoard(data)),
  getActiveMeetings: (data: any) => dispatch(getActiveMeetings(data)),
  addDomainOfOrg: (domainRes: Domain) => dispatch(addDomainOfOrg(domainRes)),
  updateEsignDetails: (data: eSignDetails) => dispatch(updateEsignDetails(data)),
  changeInLoaderStatus: (payload: {
    loader: boolean;
    message: string;
    skeleton: boolean;
  }) => dispatch(changeLoaderStatus(payload)),
});

const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  clientId: state.opening.clientId,
  eSignDetails: state.opening.eSignDetails
});

export default connect(mapStateToProps, mapPropsToState)(App);
