import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { RouteComponentProps, withRouter } from "react-router";
import { initWs, loginUserAc } from "../../redux/actions/opening/action";
import {  passwordlessLogin, login, setAnyTokenInSessionStorage, setTokenInLocalStorage, getInvitations } from "../../lib/api";
import { LOGIN_TITLE, LOGIN_TOKEN, SOCKET_CLIENT_ID } from "../../constant";
import { loginUser } from "../../type/type";

import Alert from "antd/es/alert";
import Divider from "antd/es/divider";
import Form from "antd/es/form";
import Typography from "antd/es/typography";
import Button from "antd/es/button";
import { CentralAuth } from "../../control-plane/AuthProvider";
import { EmailInput } from '../auth/EmailInput';
import { OAuthButton } from '../auth/OAuthButton';
import { EmailContinueButton } from '../auth/EmailContinueButton';
import { tokenVerify } from "../../service/tokenVerify";
import { handleAuthError } from "./AuthError";
import Input from "antd/es/input";

const { Text } = Typography;

type Props = RouteComponentProps & {
  loginUserAc: (token: any, userData: any) => void;
  loginUser: loginUser;
  applicationId: any;
  applicationName: string;
  initWs: (ws: WebSocket) => void;
};

type State = {
  emailLoading: boolean;
  oauthLoading: boolean;
  passwordLoading: boolean;
  errorMessage: string | JSX.Element | null;
  showPasswordForm: boolean;
};

class Login extends Component<Props, State> {
  state: State = {
    emailLoading: false,
    oauthLoading: false,
    passwordLoading: false,
    errorMessage: null,
    showPasswordForm: false
  };

  componentDidMount() {
    document.title = LOGIN_TITLE;

    if (tokenVerify()) {
      const { verified, registrations } = this.props.loginUser.user || {};
      this.props.history.replace(verified && registrations?.length ? "/o" : "/onboarding");
    }
  }

  handleSubmit = async ({ email }: { email: string }) => {
    this.setState({ emailLoading: true, errorMessage: "" });

    try {
      const loginRes = await passwordlessLogin(email.toLowerCase());
      if (loginRes) {
        this.props.history.push({ pathname: "/confirm-email", state: { email } });
      }
    } catch (error: any) {
      this.handleError(error);
    } finally {
      this.setState({ emailLoading: false });
    }
  };

  handleOAuthLogin = async () => {
    this.setState({ oauthLoading: true, errorMessage: "" });

    try {
      await CentralAuth().handleLogin("google");
    } catch (error: any) {
      this.handleError(error);
    } finally {
      this.setState({ oauthLoading: false });
    }
  };

  handlePasswordLogin = async (values: { email: string; password: string }) => {
    this.setState({ passwordLoading: true, errorMessage: "" });

    try {
      const loginRes = await login({
        loginId: values.email.toLowerCase(),
        password: values.password,
      });

      await setTokenInLocalStorage(LOGIN_TOKEN, loginRes.token);
      await setAnyTokenInSessionStorage(
        SOCKET_CLIENT_ID, 
        `${loginRes.user.id}_${new Date().getTime()}`
      );

      this.props.loginUserAc(loginRes.token, loginRes.user);

      const invitations :any= await getInvitations(loginRes.user.email);
      const { verified, registrations } = loginRes.user;
      
      const hasInvitations = invitations?.length > 0;
      const hasRegistrations = registrations && registrations.length !== 0;

      this.props.history.push(
        verified && (hasInvitations || hasRegistrations) 
          ? "/o" 
          : "/onboarding"
      );

    } catch (error: any) {
      this.handleError(error);
    } finally {
      this.setState({ passwordLoading: false });
    }
  };

  handleError = (error: any) => {
    handleAuthError(error, {
      onSetError: (message: string) => this.setState({ errorMessage: message }),
      clearErrorAfterDelay: true
    });
  };

  renderPasswordForm = () => {
    const { passwordLoading } = this.state;
    
    return (
      <Form 
        name="password-login" 
        className="login-form text-xs w-full" 
        onFinish={this.handlePasswordLogin}
        layout="vertical"
      >
        <Form.Item
          name="email"
          label="Work email"
          rules={[
            { required: true, message: "Please input your work email!" },
            { type: 'email', message: 'Invalid email format' },
          ]}
        >
          <Input size="large" placeholder="johndoe@yourcompany.com" />
        </Form.Item>

        <Form.Item
          name="password"
          label="Password"
          rules={[
            { required: true, message: "Please input your password!" },
          ]}
        >
          <Input.Password size="large" />
        </Form.Item>

        <div className="mb-4">
          <Button 
            type="link" 
            className="p-0" 
            onClick={() => this.props.history.push('/password/change')}
          >
            Forgot password?
          </Button>
        </div>

        <Form.Item>
          <Button
            size="large"
            loading={passwordLoading}
            type="primary"
            htmlType="submit"
            className="w-full"
          >
            Sign in
          </Button>
        </Form.Item>
      </Form>
    );
  };

  renderMagicLinkForm = () => {
    const { emailLoading } = this.state;

    return (
      <Form 
        name="magic-link-login" 
        className="login-form text-xs w-full" 
        onFinish={this.handleSubmit}
        layout="vertical"
      >
        <EmailInput />
        <Form.Item>
          <EmailContinueButton mode="signin" loading={emailLoading} />
        </Form.Item>

        <div className="flex items-start gap-2 bg-gray-50 p-3 rounded-md mb-6">
          <span className="text-gray-500">ℹ️</span>
          <Text className="text-gray-600 text-xs">
            We'll email you a magic code for a password-free sign-in.
          </Text>
        </div>
      </Form>
    );
  };

  render() {
    const { errorMessage, showPasswordForm } = this.state;

    return (
      <div className="flex flex-col md:space-y-4 space-y-2 w-full">
        {errorMessage === "locked" ? (
          <Alert message={(
            <>
              Your account is locked. <a 
                href="mailto:support@tacitbase.com?subject=Assistance Required: Account Locked&body=Dear Support Team,%0D%0A%0D%0AI am unable to access my account as it appears to be locked. Could you please assist me in unlocking it?%0D%0A%0D%0AThank you for your support.%0D%0A%0D%0ABest regards,"  
                target="_blank" 
                rel="noopener noreferrer"
                style={{ color: "#009D79" }}
              >
                Contact us
              </a>.
            </>
          )} type="error" />
        ) : (
          errorMessage && <Alert message={errorMessage} type="error" />
        )}

        <OAuthButton mode="signin" onLogin={this.handleOAuthLogin} />

        <Divider>Or</Divider>

        {showPasswordForm ? this.renderPasswordForm() : this.renderMagicLinkForm()}

        <div className="flex items-center text-gray-600 text-xs md:text-sm space-x-2">
          <Text>
            Need help? Visit{" "}
            <a 
              href="https://support.tacitbase.com/"
              style={{ color: "#009D79" }}
            >
              support
            </a>
          </Text>
          <Button 
            type="link" 
            className="p-0" 
            onClick={() => this.setState({ showPasswordForm: !showPasswordForm })}
          >
            {showPasswordForm ? "Sign in with email" : "Sign in with password"}
          </Button>
        </div>

        <div className="mt-3">
          <Text>Not a member?</Text>{" "}
          <Button type="link" className="p-0" onClick={() => this.props.history.push("/register")}>
            Sign up
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  applicationId: state.opening.applicationId,
  applicationName: state.opening.applicationName,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loginUserAc: (token: any, userData: any) => dispatch(loginUserAc(token, userData)),
  initWs: (ws: WebSocket) => dispatch(initWs(ws)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
