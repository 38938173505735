import Spin from 'antd/es/spin';
import React, { useEffect, useState } from "react";
import { getAccessToken } from "../../../lib/api";

const OAuthCallbackPage: React.FC = () => {
  const [isDocumentEvent, setIsDocumentEvent] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const state = urlParams.get("state");
    const accounts_server:any = urlParams.get("accounts-server");
    const location:any = urlParams.get("location");
    
     
    const pendingFileId = urlParams.get("pending_file_id");
    const envelopeId = urlParams.get("envelopeId");

    const processOAuthCallback = async () => {
      if (!code || !state) {
        window.opener?.postMessage(
          {
            type: "auth_error_message",
            error: "Missing code or state",
          },
          window.location.origin
        );
        window.close();
        return;
      }

      try {
        await getAccessToken(code, state,accounts_server,location);
        // Send success message to parent window
        window.opener?.postMessage(
          {
            type: "auth_success_message",
            result: "tokenResponse",
          },
          window.location.origin
        );
        window.close();
      } catch (error) {
        const { response }:any = error;
        window.opener?.postMessage(
          {
            type: "auth_error_message",
            error: response.data.error,
          },
          window.location.origin
        );
        window.close();
      }
    };

    const processDocumentSaveCallback = () => {
      if (!pendingFileId && !envelopeId) {
        window.opener?.postMessage(
          {
            type: "zoh_sign_doc_redirection",
            error: "Missing pending file ID",
          },
          window.location.origin
        );
        window.close();
        return;
      }

      // Send success message to parent window
      window.opener?.postMessage(
        {
          type: "e_sign_doc_redirection",
          result: pendingFileId || envelopeId,
        },
        window.location.origin
      );
      window.close();
    };

    // Determine which process to run
    if (state || code) {
      processOAuthCallback();
    } else {
      setIsDocumentEvent(true);
      processDocumentSaveCallback();
    }
  }, []);

  // Render a loading or processing message
  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <Spin size="large" />
      <div>
        <p>Processing {isDocumentEvent ? "document..." : "Authorization..."}</p>
        <p>Please do not close this window</p>
      </div>
    </div>
  );
};

export default OAuthCallbackPage;
