import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
// import { createCustomer } from "../../lib/api";
import { addCandidateInDB } from "../../redux/actions/opening/action";
import { Candidate } from "../../type/type";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { MAX_ATTACHMENT_SIZE, TACITBASEQA_MEDIUM } from "../../constant"

import PhoneInput from "react-phone-input-2";
import { validateInput } from "../../utils/Validations";
import Button from "antd/es/button";
import AttachmentIcon from "../Icons/AttachmentIcon";

export type QuickAddCandidatePopupProps = {
  addCandidateInDB: (candidate: any) => void;
    refer: any;
  click: () => void;
  loading: boolean,
  onCreateCandidate: (req: any, file: any) => void
};
const QuickAddCandidatePopup = (props: QuickAddCandidatePopupProps) => {
  const [call, setCall] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true)
  const [isValidName, setValidationMsg] = useState<string|boolean>()
  const [isvalidEmail, setValidationMsgOfemail] = useState<string|boolean>()

  let phoneEle: any = document.querySelector(".form-control");
  let phoneFlag: any = document.querySelector(".flag-dropdown");
  if (phoneFlag) {
    phoneFlag.removeAttribute("style");
    phoneFlag.setAttribute("style", "height:100%;");
  }
  if (phoneEle) {
    phoneEle.removeAttribute("style");
    phoneEle.style = "height:30px;width:100%;";
  }
  //below code remove existing css of phone input library

  let firstNameRef = useRef<HTMLInputElement | null>(null);
  let lastNameRef = useRef<HTMLInputElement | null>(null);
  let emailRef = useRef<HTMLInputElement | null>(null);
  
  let fileInput = useRef<HTMLInputElement>(null);
  const [file_Exceed, isExceed] = useState(false);
  type QuickAddCandidateType = {
    [key: string]: string | number;
  };
  let quickAddCndidateFormFieds: QuickAddCandidateType = {
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    country_code: 91,
  };
  const [customValue, setCustomValue] = useState<QuickAddCandidateType>(
    quickAddCndidateFormFieds
  );
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const [file, setFile] = useState<FormData>();

  useEffect(()=>{    
    if (
      firstNameRef.current &&
      emailRef.current &&
      lastNameRef.current &&
      lastNameRef.current.value.trim().length > 0 &&
      emailRef.current.value.trim().length > 0 &&
      firstNameRef.current.value.trim().length > 0 
      && call
    ) {
       setIsDisabled(false)
     }else{
       setIsDisabled(true)
     }
     
     },[firstNameRef.current?.value,lastNameRef.current?.value,emailRef.current?.value])
  const setCustomValues = (
    e: React.ChangeEvent<HTMLInputElement>,
    type?: string
  ) => {
    let ele: any = document.getElementById(e.target.name + "1");
    const validationMsg = validateInput(
      e.target.value,
      type ? type : "singleton"
    );
    setValidationMsg(validationMsg)
    if (validationMsg || e.target.value.length == 0) {
      ele.innerHTML = validationMsg;
      setCall(false)
    } else {
      // If the input is valid
      if(!isvalidEmail){
        setCall(true)
      }
      ele.innerHTML = "";
      if (type == "number") {
        if (e.target.value && e.target.value.length !== 3) {
          setIsDisabled(true)
          ele.innerHTML = "Extension must be exactly 3 characters long";
          return;
        }else{
          setIsDisabled((isValidName || isvalidEmail) ? true : false);
        }
        setCustomValue((prev: any) => ({
          ...prev,
          [e.target.name]: parseInt(e.target.value),
        }));
      } else {
        setCustomValue((prev: any) => ({
          ...prev,
          [e.target.name]: trimExtraWhiteSpaces(e.target.value.trim()),
        }));
      }
    }
  };
  const trimExtraWhiteSpaces = (value: string) => {
    return value.replace(/\s+/g, ' ').trim();
  };
  
  const validateEmail = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
     const emailValidationMessage = validateInput(e.target.value, e.target.name);
     setValidationMsgOfemail(emailValidationMessage)
      if (emailValidationMessage) {
        let ele: any = document.getElementById(e.target.name + "1");
        ele.innerHTML = emailValidationMessage;
        setCall(false)
    } else {
            setCustomValue((prev: any) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
      let ele: any = document.getElementById(e.target.name + "1");
      ele.innerHTML = "";
      if(!isValidName){
        setCall(true)
      }
    }
  };

  // create a ref for auto focus on first field
  useEffect(() => {
    if (firstNameRef.current) {
      firstNameRef.current.focus();
    }
  }, []);
  const onFileChange = (file: any) => {
    if (file.target.files[0].size > 10000000) {
      isExceed(true);
    } else {
      var element2 = document.getElementById("uploaded-file-name");

      isExceed(false);
      const formData = new FormData();
      for (let i = 0; i < file.target.files.length; i++) {
        formData.append(`file`, file.target.files[i]);
        if (element2) element2.innerText = file.target.files[i].name;
      }
      setFile(formData);
    }
  };
  const phoneInputCSS = {
    height: 32,
    borderTop: "0px ",
    borderLeft: "0px",
    borderRight: "0px",
    width: "100%",
    border: "1px solid #0F0A0D",
  };

  const phoneInputCSSError = {
    height: 32,
    borderTop: "0px ",
    borderLeft: "0px",
    borderRight: "0px",
    width: "100%",
    border: "1px solid red",
  };
  const phoneButtonCSS = {
    height: 32,
    borderRight: "1px solid rgba(15, 10, 13, 0.3)",
    borderBottom: "1px solid rgba(15, 10, 13, 0.3)",
    border: "1px solid #0F0A0D",
  };

  const phoneButtonCSSError = {
    border: "1px solid red",
    height: 32,
    borderRight: "1px solid rgba(15, 10, 13, 0.3)",
    borderBottom: "1px solid rgba(15, 10, 13, 0.3)",
  };

  const AddCandidate = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (
      firstNameRef.current &&
      emailRef.current &&
      lastNameRef.current &&
      lastNameRef.current.value.trim().length > 0 &&
      emailRef.current.value.trim().length > 0 &&
      firstNameRef.current.value.trim().length > 0 
    ) {
      const req1 = {
        ...customValue,
        ["phone"]: phoneNumber,
        source: {
          medium: TACITBASEQA_MEDIUM,
        },
      };
      props.onCreateCandidate(req1, file)




    } else {
      setCall(false);
    }
  };
  const removeCode = (phone: string, country: any) => {
    return phone.substring(country.dialCode.length);
  };
  const addPhone = (phone: string, country: any) => {
    const call = phone.length > 0
    setCall(call);
    const phoneNumber: string = removeCode(phone, country);
    setPhoneNumber(phoneNumber);
    setCustomValue((prev) => ({
      ...prev,
      country_code: parseInt(country.dialCode),
    }));
  };
  return (
    <React.Fragment>

        <div className="flex flex-wrap pl-3 mb-1">
          <div className="w-1/2 pr-3 mt-1 ">
            <div className="text-sm font-semibold mb-1">
              {" "}
              {capitalizeFirstLetter("First name")}
            </div>
            <input
              type="text"
              ref={firstNameRef}
              className={` ${
                typeof customValue["first_name"] === "string" &&
                customValue["first_name"].trim().length == 0 &&
                !call
                  ? "border-tacitbase-primary1"
                  : ""
              } isEditable inputCSS placeholder-italic w-full rounded p-1 text-sm`}
              name={"first_name"}
              data-testid={"first_name"}
              onChange={(e) => setCustomValues(e, "first name")}
            />
            <div className="text-xs text-red-400 h-4" id="first_name1"></div>
          </div>
          <div className="w-1/2 pr-3 mt-1 ">
            <div className="text-sm font-semibold mb-1">
              {" "}
              {capitalizeFirstLetter("last name")}
            </div>
            <input
              type="text"
              ref={lastNameRef}
              className={`${
                typeof customValue["last_name"] === "string" &&
                customValue["last_name"].trim().length == 0 &&
                !call
                  ? "border-tacitbase-primary1"
                  : ""
              } isEditable inputCSS placeholder-italic w-full rounded p-1 text-sm`}
              name={"last_name"}
              data-testid="last_name"
              onChange={(e) => setCustomValues(e, "last name")}
            />{" "}
            <div className="text-xs text-red-400 h-4" id="last_name1"></div>
          </div>
          <div className="w-1/2 pr-3  ">
            <div className="text-sm font-semibold mb-1">
              {" "}
              {capitalizeFirstLetter("email")}
            </div>
            <input
              type="text"
              ref={emailRef}
              className={`${
                typeof customValue["email"] === "string" &&
                customValue["email"].trim().length == 0 &&
                !call
                  ? "border-tacitbase-primary1"
                  : ""
              } inputCSS isEditable  inputCSS placeholder-italic w-full rounded p-1 text-sm`}
              name={"email"}
              onChange={(e) => validateEmail(e)}
              data-testid="email"
            />
            <div className="text-xs text-red-400 h-4" id="email1"></div>
          </div>
          <div className="w-1/2 flex space-x-3 pr-3">
            <div className="w-full">
              <div className="text-sm font-semibold pb-1">{"Phone"}</div>
              <PhoneInput
                enableSearch
                countryCodeEditable={false}
                inputStyle={
                  typeof customValue["phone"] === "string" &&
                  customValue["phone"].trim().length == 0 &&
                  !call
                    ? phoneInputCSSError
                    : phoneInputCSS
                }
                buttonStyle={
                  typeof customValue["phone"] === "string" &&
                  customValue["phone"].trim().length == 0 &&
                  !call
                    ? phoneButtonCSSError
                    : phoneButtonCSS
                }
                country={"in"}
                value={
                  typeof customValue["phone"] === "string"
                    ? customValue["phone"]
                    : ""
                }
                onChange={addPhone}
              />{" "}
              <div
                className="text-xs text-red-400 h-4 w-full"
                id="extension1"
              ></div>
            </div>

            <div className="w-16">
              <div className="text-sm font-semibold pb-1">{"Extension"}</div>
              <input
                type="number"
                className={`isEditable inputCSS placeholder-italic w-16 rounded p-1 text-sm`}
                placeholder={"###"}
                name={"extension"}
                onChange={(e) => setCustomValues(e, "number")}
              />{" "}
            </div>
          </div>
        </div>
        <div className="flex justify-start items-center text-xs pl-3 pr-2">
          <input
            type="file"
            ref={fileInput}
            data-testid="input-attachment"
            style={{ display: "none" }}
            onChange={(file: any) => onFileChange(file)}
            multiple
          />
          <button
            className={`
                bg-gray-200 hover:bg-gray-200
             w-auto    bg-opacity-60 py-1.5 flex-shrink-0 px-2  text-xs font-medium rounded align-middle flex items-center flex-row space-x-2`}
            onClick={() => fileInput.current?.click()}
            data-testid="add-attachment"
          >
            {" "}
            <AttachmentIcon />
            <div>{capitalizeFirstLetter("add attachments")}</div>
          </button>

          <div id="uploaded-file-name" className="px-1 truncate"></div>
        </div>
        <div
          className="mx-3 mb-3 flex items-end justify-end"
        >
          <Button
              type="primary"
                id="add-candidate"
                className="submit-btn text-sm"
                data-testid="add-candidate-button"
                onClick={AddCandidate}
          loading={props.loading}
                disabled={isDisabled}
        >Add</Button>
        </div>
        {file_Exceed && (
          <div className="bg-red-50 p-4 mx-2">
            {MAX_ATTACHMENT_SIZE}

          </div>
      )}{" "}
    </React.Fragment>
  );
};
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  members: state.opening.members,
  openingMembers: state.opening.openingMembers,
  currentApplicant: state.opening.currentApplicant,
  applicantMembers: state.opening.applicantMembers,
  applicationId: state.opening.applicationId,
  clientId: state.opening.clientId,
  orgRoles: state.opening.orgRoles,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  addCandidateInDB: (candidate: Candidate) =>
    dispatch(addCandidateInDB(candidate)),
});
export default connect(
  mapStateToProps,
  mapPropsToState
)(QuickAddCandidatePopup);
