import React from "react";
import Checkbox from "antd/es/checkbox";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
type TacitbaseSelectProps = {
  onChange: (e: CheckboxChangeEvent) => void;
  value: string;
  boxName: string;
  checked: boolean;
};

const CheckBox: React.FC<TacitbaseSelectProps> = ({
  onChange,
  value,
  boxName,
  checked,
}) => (
  <Checkbox
    value={value.toString()}
    checked={checked}
    name={boxName}
    onChange={onChange}
  ></Checkbox>
);

export default CheckBox;
