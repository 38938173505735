import React, { useEffect,useState } from "react";
import { getJDbyBoardId } from "../../lib/api";
import { connect } from "react-redux";
import Typography from "antd/es/typography";
import { JdLinkProps } from "../../type/sidePanalSlider";
import PopupTitle from "../common/popupTitle/PopupTitle";
import ExportOutlined from "@ant-design/icons/ExportOutlined"
const { Text, Paragraph } = Typography;


type jdLinkProps = {
  boardId: string;
  jdLinkProps:JdLinkProps;
};
const JdLink = (props: jdLinkProps) => {
  const [link, setLink] = useState("");

  // function when job post created opening link in another tab
  function openRoute() {
    const route = link
    window.open(route, '_blank')
  }
  // getting publish jd link by getJDbyBoardId api
  useEffect(() => {
    getJDbyBoardId(props.boardId)
      .then((res) => {
        setLink(res.jd_public_url);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, []);
  return (
    <>
      <div className="">
        <PopupTitle
          PopupTitle="Public URL"
          back={props.jdLinkProps.prev}
          font="text-base font-medium text-black"
          close={props.jdLinkProps.close}
        />
      </div>
      <div className="m-3">
        {link && (<><Paragraph
        className="flex justofy-between border p-2 items-center rounded"
        copyable={{ text: link }}
      >
          <Text className="line-clamp-1">{link}</Text> <ExportOutlined onClick={openRoute} />
        </Paragraph>
        </>)}

      {!link && <Text className="line-clamp-1">(Link not available; job saved as draft)</Text>}

    </div>
    </>
    
  );
};

const mapStateToProps = (state: any) => ({
  boardId: state.opening.currentOpening.id,
});
export default connect(mapStateToProps)(JdLink);
