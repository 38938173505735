import Typography from 'antd/es/typography';
import Input from 'antd/es/input';
import Select from 'antd/es/select';
import Button from 'antd/es/button';
import Divider from 'antd/es/divider';
import React, { useEffect, useState } from "react";
import {
  currentApplicant,
  Attachment,
  KeyWithArray,
  Opening,
  OrganizedBoard,
  JobBoard,
} from "../../../../type/type.d";
import {
  copyApplicant,
  copyAttachments,
  getListByListIDs,
  getOpenings,
} from "../../../../lib/api";
import {
  COPY_CARD,  SOCKET_CLIENT_ID,
} from "../../../../constant";
import { throwSuccess } from "../../../../service/throwError";
import CheckList from "../../../cardActions/copyCard/CheckList";
import SelectPosition from "../../../cardActions/copyCard/SelectPosition";
import { Dispatch } from "redux";
import {
  copyApplicantAction,
  copyAttachmentsAction,
  listOfBoards,
} from "../../../../redux/actions/opening/action";
import { connect } from "react-redux";
const { TextArea } = Input;
const { Text } = Typography;
type CopyCardProps = {
  click :()=>void;
  // refer:any;
  openings: OrganizedBoard;
  currentOpening: Opening;
  boardList: any;
  keep: any;
  tasks: any;
  blockUserFromCopycard: boolean;
  applicantlabels: KeyWithArray;
  members: any;
  currentApplicant: currentApplicant;
  copyApplicantAction(applicant: any, index: number): void;
  copyAttachmentsAction(
    record_id: string,
    list_id: string,
    data: Attachment[]
  ): void;
  clientId: any;
  attachments: Attachment[];
};
const CopyCard = (props: CopyCardProps) => {
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const applicantType: any[] = [];
  const keepType: string[] = [];
  useEffect(() => {
    let deafultCopyCardName = `copy of ${props.currentApplicant?.name}`;
    setApplicantName(deafultCopyCardName)
    let checkData = {
      labels: props.applicantlabels[props.currentApplicant.id] || [],
      members: props.members[props.currentApplicant.id] || [],
      attachments: props.attachments,
    };
    setData(checkData);
  }, [props.applicantlabels, props.members, props.attachments,props.currentApplicant]);
  let indexOfcurrentApplicant: number;
  indexOfcurrentApplicant = props?.boardList[props.currentApplicant?.list_id]
    ?.applicants
    ? props.boardList[props.currentApplicant?.list_id]?.applicants
        .map((item: any) => item.id)
        .indexOf(props.currentApplicant?.id)
    : 0;
  var currentList: any[] = Object.values(props.boardList);
  const [lists, setOpeningLists] = useState(currentList);
  const [openings, setOpenings] = useState<Opening[]>([]);
  const [openingID, setOpeningID] = useState(props.currentOpening.id);
  let deafultCopyCardName = `copy of ${props.currentApplicant?.name}`;
  const [applicantName, setApplicantName] = useState(deafultCopyCardName);
  const [applicants, setApplicants] = useState(applicantType);
  const [selectedKeep, setSelectedKeep] = useState(keepType);
  const [companyOptions, setCompanyOptions] = useState<
    Record<string, JobBoard>
  >({});
  const [selectedCompany, setSelectedCompany] = useState(
    props.currentOpening.company_name
  );
  const [selectedListID, setSelectedListID] = useState<string>("");
  const [selectedApplicant, setSelectedApplicant] = useState(
    indexOfcurrentApplicant.toString()
  );
  const fetchOpenings =  () => {
    getOpenings()
    .then((openings: any) => {
      openings = openings ? openings : [];
      setCompanyOptions(openings)
    })
  };

  useEffect(()=>{
    fetchOpenings()
  },[])
  useEffect(() => {
    var index = lists.map((item: any) => item.id).indexOf(selectedListID);
    var applicants: any = lists[index]?.applicants
      ? lists[index]?.applicants
      : [];
    setApplicants(applicants);
    setSelectedApplicant("0");
  }, [selectedListID]);

  useEffect(() => {
    if (selectedCompany) {
      // Access the selected company data directly from the object
      const selectedCompanyData = companyOptions[selectedCompany];
      if (selectedCompanyData) {
        // Extract the boards associated with the selected company
        const boards = selectedCompanyData.boards || [];

        setOpenings(boards);
      }
    }
  }, [selectedCompany, companyOptions]);

  const getApplicant = (id: string) => {
    getListByListIDs(id).then((lists_with_applicants: any) => {
      if(lists_with_applicants) {
         setOpeningLists(lists_with_applicants);
         if (props.currentOpening.id != id) {
           setApplicants(lists_with_applicants[0].applicants);
         }
      }
     });
  }
  const copy = () => {
    let index: number = parseInt(selectedApplicant);
    const copyReq = {
      src_applicant: {
        id: props.currentApplicant.id,
        opening_id: props.currentOpening.id,
        list_id: props.currentApplicant.list_id,
        name: "",
      },
      dst_applicant: {
        id:
          index === 0
            ? "00000000-0000-0000-0000-000000000000"
            : applicants[index - 1].id,
        opening_id: openingID,
        list_id: selectedListID,
      },
      keep: selectedKeep,
      socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
      position: selectedApplicant.toString(),
    };
    setLoading(true);
    applicantName !== ""
      ? (copyReq.src_applicant["name"] = applicantName)
      : copyReq;
    copyApplicant(copyReq).then((res: any) => {
      setLoading(false);
      props.click()
      setSelectedCompany("")
      setOpeningID("")
      setSelectedListID("")
      setSelectedApplicant("");
      if (selectedKeep.includes("attachments")) {
        let attachments: Attachment[] = [];
        props.attachments.map((at: Attachment) => {
          attachments.push({
            ...at,
            recordID: res.applicant.id,
            opening_id: openingID,
          });
        });
        let req = {
          attachments: attachments,
        };
        copyAttachments(req).then((resA) => {
          props.copyAttachmentsAction(res.applicant.id, selectedListID, resA);
        });
      }
      if (res.destination == "copy_to_same_opening") {
        props.copyApplicantAction(res, index);
      } else if (res.destination == "copy_to_another_opening") {
        throwSuccess(COPY_CARD);
      }
    }).catch(()=>{
      setLoading(false);
    });
  };

  const handleCompanyChange = (company:string)=>{
    setSelectedCompany(company)
    setOpeningID("")
    setSelectedListID("")
    setSelectedApplicant("");
  }
  const handleBoardChange = (value: string) => {
    getApplicant(value);
    setOpeningID(value);
  };
  return (
    <>
        <Divider type="horizontal" className="my-2" />
        <div className="flex w-72 flex-col" style={{ minHeight :"auto",maxHeight:"300px"}} >
        <div className="flex-1 overflow-y-auto">
          <div className="mb-2">
            <div className="mb-2">
            <Text className="font-semibold">Title</Text>
            </div>
            <TextArea
              defaultValue={applicantName}
              onChange={(e: any) => setApplicantName(e.target.value)}
              maxLength={512}
              data-testid="CopyCardPopupInputTestBtn"
              className="w-full mb-2 h-20 p-2   rounded inputCSS resize-none"
            />
            <CheckList
              checkLists={data}
              selectCheck={(e: any) => setSelectedKeep(e)}
            />
            <div className="my-2">
            <Text >Select Destination</Text>
            </div>
            <div className="w-full flex flex-col gap-1 mb-2">
              <Text strong>Workspace</Text>
              <Select
              showSearch
              className="w-full"
              onChange={(value) => handleCompanyChange(value)}             
              filterOption={(input, option:any) =>
                option?.children.toLowerCase().includes(input.toLowerCase())
            } >
                {Object.keys(companyOptions).map((companyId) => (
                  <Select.Option
                    key={companyId}
                    value={companyId}
                  >
                    {companyOptions[companyId].company_name}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className="w-full flex flex-col gap-1 mb-2">
              <Text strong >Job post</Text>
              <Select
              showSearch
                className="w-full"
                onChange={handleBoardChange}
                filterOption={(input, option:any) =>
                  option?.children.toLowerCase().includes(input.toLowerCase())
              }
              >
                {openings.map((opening: any, index: number) => (
                  <Select.Option
                    key={index}
                    className="w-full"
                    value={opening.id}
                  >
                    {opening.name}
                  </Select.Option >
                ))}
              </Select>
            </div>
            <div>
              <div className="w-full flex flex-col gap-1 mb-2">
                <Text strong>List</Text>
                {lists ? (
                  <Select
                  showSearch
                  className="w-full"
                  onChange={(value)=>setSelectedListID(value)}
                  filterOption={(input, option:any) =>
                    option?.children.toLowerCase().includes(input.toLowerCase())
                }
                >
                  {lists?.map((list: any, index: number) => (
                    <Select.Option
                      key={index}
                      className="w-full"
                      value={list.id}
                      selected={list.id === props.currentApplicant.list_id}
                    >
                      {list.name}
                    </Select.Option>
                  ))}
                </Select>
                ) : (
                  <>
                    <div className="text-xs">list</div>
                    <div className="w-full">No list</div>
                  </>
                )}
              </div>
              <div className="my-2">
                {(applicants.length > 0 && selectedListID) &&(
                  <SelectPosition
                    label="Position"
                    value={applicants ? applicants : []}
                    event={(e: any) => setSelectedApplicant(e)}
                    comparedId={props.currentApplicant.id}
                    move={false}
                    extraIndexCheck={null}
                    sourceId={null}
                  />
                )}
              </div>
            </div>
          </div>
          </div>
            <Button
            className="w-24 mt-2"
            type="primary"
              onClick={copy}
              disabled={
                lists ? (props.blockUserFromCopycard ? true : false) : true
              }
              loading={loading}
            >
              Copy
            </Button>
          </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  openings: state.opening.openings,
  currentOpening: state.opening.currentOpening,
  boardList: state.opening.boardList,
  currentApplicant: state.opening.currentApplicant,
  keep: state.opening.keepCopyApplicant,
  tasks: state.opening.tasks,
  applicantlabels: state.opening.applicantLabels,
  members: state.opening.applicantMembers,
  blockUserFromCopycard: state.opening.billingUser.blockFromCardCreation,

  clientId: state.opening.clientId,
  attachments:
    state.opening.attachment[state.opening.currentApplicant.id] || [],
});

const mapPropsToState = (dispatch: Dispatch) => ({
  copyApplicantAction: (applicant: any, index: number) =>
    dispatch(copyApplicantAction(applicant, index)),
  listOfBoards: (boardData: any) => dispatch(listOfBoards(boardData)),
  copyAttachmentsAction: (
    record_id: string,
    list_id: string,
    data: Attachment[]
  ) => dispatch(copyAttachmentsAction(record_id, list_id, data)),
});
export default connect(mapStateToProps, mapPropsToState)(CopyCard);
