import React, { useState, useEffect } from "react";
import Checkbox from "antd/es/checkbox";
import Space from "antd/es/space";
import Tag from "antd/es/tag";
import Typography from "antd/es/typography";
import { getAnalyticsLabelsDataType } from "../../../../../lib/apiRes";
import { connect } from "react-redux";

const { Text } = Typography;

// Define the shape of the props expected by the component
type label = {
  [key: string]: getAnalyticsLabelsDataType;
};

type AnalyticsLabelsProps = {
  analyticsLabels: label;
};

const AnalyticsLabels = (props: AnalyticsLabelsProps) => {
  // State to store the filtered labels
  const [filteredLabels, setFilteredLabels] = useState<getAnalyticsLabelsDataType[]>([]);

  // Effect to update the filtered labels when props.analyticsLabels changes
  useEffect(() => {
    // Log the props.analyticsLabels to the console for debugging purposes

    // Check if analyticsLabels exists and set the filteredLabels state
    if (props.analyticsLabels) {
      setFilteredLabels(Object.values(props.analyticsLabels));
    }
  }, [props.analyticsLabels]);

  // Define the stages for the tracker
  const trackerStages = [
    { list_name: "Applications", label_id: "", isSelected: true },
    { list_name: "First interview", label_id: "", isSelected: true },
    { list_name: "HR round", label_id: "", isSelected: true },
    { list_name: "Offered", label_id: "", isSelected: true },
    { list_name: "Offer accepted", label_id: "", isSelected: true },
    { list_name: "Offer declined", label_id: "", isSelected: true },
    { list_name: "Joined", label_id: "", isSelected: true },
    { list_name: "Interview rejected", label_id: "", isSelected: true },
    { list_name: "Did not join", label_id: "", isSelected: true },
  ];

  return (
    <>
      {/* Header */}
      <div className="flex items-center px-4 border-b" style={{ height: 44 }}>
        <Text>Analytics labels</Text>
      </div>

      {/* Container */}
      <div className="h-full w-full" style={{ height: "calc(100vh - 250px)" }}>
        <div className="flex flex-col h-full">
          {/* Sticky header with description */}
          <div className="sticky top-0 border-b flex space-x-2 bg-white z-10 p-4">
            <Space direction="vertical" className="w-full">
              <Text className="flex items-center">
                These stage labels will be utilized to generate reports, graphs, and analytics for candidates as well as for the board.
              </Text>
            </Space>
          </div>

          {/* Content area */}
          <div className="flex-1 overflow-y-auto p-4" style={{ height: "calc(100vh - 385px)" }}>
            <div className="border-inherit rounded border w-2/4">
              <div className="flex flex-col">
                {/* Log trackerStages to the console for debugging purposes */}

                {/* Map through trackerStages and match with corresponding labels */}
                {trackerStages.map((stage, index) => {
                  // Find the matching label by name
                  const label = filteredLabels.find(
                    (label) => label.name === stage.list_name
                  );

                  return (
                    <div
                      key={index}
                      className="flex items-center justify-between px-4 py-2"
                    >
                      <div className="flex items-center">
                        <Checkbox className="mr-2" checked disabled />
                        <span className="text-black">{stage.list_name}</span>
                      </div>
                      {/* If a matching label is found, display it */}
                      {label && (
                        <Tag
                          bordered
                          style={{
                            border: `1px solid ${label.color}`,
                            color: `${label.color}`,
                          }}
                        >
                          {label.name}
                        </Tag>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// Map the Redux state to component props
const mapStateToProps = (state: any) => ({
  analyticsLabels: state.opening.analayticsLabels,
});

// Connect the component to the Redux store
export default connect(mapStateToProps)(AnalyticsLabels);
