import React, { useEffect, useRef, useState } from "react";
import Table from 'antd/es/table';
import Input from 'antd/es/input';
import Form from 'antd/es/form';
import InputNumber from 'antd/es/input-number';
import Typography from 'antd/es/typography';
import '../../../src/components/candidateDB/ImportResumeModal.css'
import { getTable } from "../../lib/api";
import { CANDIDATE_REQUIRED_FIELD, regexPatterns } from "../../constant";
import EditOutlined from "@ant-design/icons/EditOutlined";

interface ResumeConversionTableProps {
  data: any[];
  onDataChange: (newData: any[]) => void;
//   onValidationError: (hasError: boolean) => void; // Add this prop
  sendValidationStatus: (hasError: boolean) => void; // Add this prop
  setValidationStatus: (validationOfFields: any) => void;

}

interface EditableCellProps {
  title: string;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: string;
  dataType:string,
  record: any;
  columnIndex: any;
  handleSave: (record: any) => void;
  onValidationError: (hasError: boolean) => void; // Add this prop
  setValidationStatus: (validationOfFields: any) => void;

}


// Editable Cell Component
const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  dataType,
  record,
  handleSave,
//   columnIndex,
  setValidationStatus,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const [form] = Form.useForm();
  const maxCharLimit = 200;
  const inputRef = useRef<any>(null);


  // Effect to focus on input element when editing starts
  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
      if (inputRef.current) {
        inputRef.current.focus({
          cursor: "end",
        });
      }
    }
  }, [editing]);


  const toggleEdit = () => {
    setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
  };

   

const save = async (e: any) => {
    const values = await form.validateFields();
    const fieldToValidate = Object.keys(values)[0];
    const fieldValue = values[fieldToValidate];
    try {
      if (CANDIDATE_REQUIRED_FIELD.includes(dataIndex) || regexPatterns[dataIndex] ) {
        
  
        await validateField(fieldToValidate, fieldValue, title);
  
        setValidationStatus((prevStatus: any) => ({
          ...prevStatus,
          [dataIndex]: false,
        }));
        handleSave({ ...record, ...values });
  
        if (e.key === "Enter" || e.type === "blur") {
          toggleEdit();
        }
      } 
    
      else {
        handleSave({ ...record, ...values });
        if (e.key === "Enter" || e.type === "blur") {
          toggleEdit();
        }
      }
    } catch (errInfo) {
  
      validationE(dataIndex);
      setValidationStatus((prevStatus: any) => ({
        ...prevStatus,
        [dataIndex]: true,
      }));
    }
  };

  const validateField = (dataIndex: string, value: string, title: any) => {
    if(dataType=="number"){
        const trimmedValue = value ? value: 0;
        if (
            dataIndex === "notice_period_in_days" &&
            !trimmedValue ) {
            
                return Promise.reject("Notice period must be greater than 0 and less than 365 days.");
              
          }
      
    }
    const trimmedValue = value ? value?.trim() : "";

    if (CANDIDATE_REQUIRED_FIELD.includes(dataIndex) && !trimmedValue) {
      return Promise.reject(`${title} is required.`);
    }

    if (
      dataIndex in regexPatterns &&
      trimmedValue &&
      !regexPatterns[dataIndex].test(trimmedValue)
    ) {
      return Promise.reject("Invalid URL.");
    }

    if (
      dataIndex === "email" &&
      trimmedValue &&
      !/\S+@\S+\.\S+/.test(trimmedValue)
    ) {
      return Promise.reject("Invalid email address.");
    }

    return Promise.resolve();
  };

  const validationE = (dataIndex: string) => {
    setValidationStatus((prevStatus: any) => ({
      ...prevStatus,
      [dataIndex]: true,
    }));
  };
  // Render editable cell
  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form form={form} style={{ margin: 0 }}>
        <Form.Item
          style={{ margin: 0, width: "200px" }}
          name={dataIndex}
          rules={[
            {
              validator: (_, value) => {
                if (
                  CANDIDATE_REQUIRED_FIELD.includes(dataIndex) &&
                  (!value || value.trim() === "")
                ) {
                  validationE(dataIndex);
                  return Promise.reject(`${title} is required.`);
                }
                if (
                  dataIndex in regexPatterns &&
                  value &&
                  !regexPatterns[dataIndex].test(value)
                ) {
                  validationE(dataIndex);

                  return Promise.reject("Invalid URL.");
                }
                if (
                  dataIndex === "email" &&
                  value &&
                  !/\S+@\S+\.\S+/.test(value)
                ) {
                  validationE(dataIndex);

                  return Promise.reject("Invalid email address.");
                }
                // if (
                //     dataIndex === "notice_period_in_days" &&
                //     value  ) {
                //     if (value <= 0 || value >= 365) {
                //         validationE(dataIndex);
                //         return Promise.reject("Notice period must be greater than 0 and less than 365 days.");
                //       }
                //   }
                return Promise.resolve();
              },
            },
          ]}
        >
           {dataType === "text" ? (
            <Input
            
              ref={inputRef}
              onPressEnter={save}
              onBlur={save}
              onChange={(e) => save(e)}
            />
          ) : dataType === "number" ? (
            <InputNumber
              ref={inputRef}
              onPressEnter={save}
              onBlur={save}
            onChange={(e:any)=>{e&&save(e)}}
            />
          ) : (
            // Add other types of input components here based on your data types
            <Input
              ref={inputRef}
              onPressEnter={save}
              onBlur={save}
              onChange={(e) => save(e)}
            />
          )}
        </Form.Item>
      </Form>
    ) : (
      <div
        style={{
          paddingRight: "px",
          width: "200px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          height:'34px'
        }}
        onClick={toggleEdit}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            toggleEdit();
          }
        }}
        role="button"
        tabIndex={0}
      >
        <Typography.Text 
        ellipsis={{tooltip:children}} style={{ minWidth: 200, maxWidth: 200, minHeight:40 }}>
          {
          dataType == "number" ?(
            record[dataIndex]?children=record[dataIndex]:
            children=0
          ):
            (String(record[dataIndex]).length > 0 
              ? String(record[dataIndex]).length > maxCharLimit
                ? `${String(children)
                    .replace(/[^a-zA-Z0-9 ]/g, "")
                    .trim()
                    .slice(0, maxCharLimit)}...`
                : children
              : String(record[dataIndex]).length == 0
              ? (<div className="hover-div relative w-full h-full">
              {"\u00A0"}
              <EditOutlined className="absolute inset-0 edit-icon opacity-0 hover:opacity-100 transition-opacity duration-200" />
            </div>)
              : children )
          }
        </Typography.Text>
      </div>
    );
  }


  return <td {...restProps}>{childNode}</td>;
};



const ResumeConversionTablePool: React.FC<ResumeConversionTableProps> = ({
  data,
  onDataChange,
  sendValidationStatus
}) => {
  const [tableData, setTableData] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [columnal, setData] = useState<any>();
  const [validationStatus, setValidationStatus] = useState({});


  // Effect to send validation status
  useEffect(() => {
    sendValidationStatus(
      Object.values(validationStatus).some((status) => status)
    );
  }, [validationStatus]);


  useEffect(()=>{
    const fetchData = async () => {
      try {
        const response = await getTable();  
        if (response) {
          setData(response?.columns);
      }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  
  },[])

  useEffect(() => {
    const response_metadata = data?.map((item) => item?.metadata);

    setTableData(response_metadata);
    onDataChange(response_metadata);
    const transformedColumns = columnal?.map((header: any) => ({
      title: header.view,
      dataIndex: header.name,
      dataType:header.type,
      key: header.name,
      editable: true,
    }));

    setColumns(transformedColumns);
  }, [data, columnal]);

const sendValidationErrorStatus=(value:boolean)=>{
    sendValidationStatus(value)
}
  const handleSave = (row: any) => {
    const newData = [...tableData];
    const index = newData.findIndex((item) => row.key === item.key);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, { ...item, ...row });
      setTableData(newData);
      onDataChange(newData);
    }
  };


  const mergedColumns = columns?.map((col, index) => ({
    ...col,
    onCell: (record: any) => ({
      record,
      editable: col.editable,
      dataIndex: col.dataIndex,
      title: col.title,
      dataType:col.dataType,
      handleSave,
      columnIndex: index,
      setValidationStatus: setValidationStatus,
      onValidationError: sendValidationErrorStatus
      , // Pass validationE function

      
    }),
  }));

  return (
    <>
      
        <Table
          className="custom-resume-table"
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          rowClassName={() => "editable-row"}
          bordered
          dataSource={tableData}
          columns={mergedColumns}
          pagination={false}
          scroll={{x:1300}}
          style={{ overflow: "scroll", maxWidth: "1700px" ,maxHeight:'500px'}}
        />
    </>
  );
};



export default ResumeConversionTablePool;


