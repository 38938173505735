import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loginUser } from "../../type/type";
import Button from "antd/es/button";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Typography from "antd/es/typography";
import Card from "antd/es/card";
import Alert from "antd/es/alert";
import {
  ValidateOrganizationCareerPageURL,
  ValidateOrganizationCompanyName,
  ValidateOrganizationDisplayName,
  initOrganisationSetup,
} from "../../lib/api";

import {
  current_org,
  showOrg,
  tokenExchange,
  updateOrgNameId,
} from "../../redux/actions/opening/action";
import { Dispatch } from "redux";
// import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Role } from "../../lib/apiRes";
import InfoCircleOutlined from "@ant-design/icons/InfoCircleOutlined";
import Tooltip from "../../ant/Tooltip";
const {  Text } = Typography;
type nameProps = {
  loginUser: loginUser;
  addOrg(orgData: any): void;
  updateIdName(orgData: any): void;
  tokenExchange(
    token: any,
    applicant_id: string,
    applicantion_name: string,
    application_desc: string,
    application_imgURL: string,
    application_roles: Role[]
  ): void;
  currentOrg(orgData: any): void;
};

const SetupOrgname: React.FC<nameProps> = ({
  // loginUser,
  updateIdName,
  addOrg,
  currentOrg,
  // tokenExchange
}) => {
  const [username, setUsername] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyUrl, setCompanyUrl] = useState("");
  const [loadingState, setLoadingState] = useState({
    createNewOrgButton: false,
  });

  const [form] = Form.useForm();
  const history = useHistory();
  const match = useRouteMatch('/create-org');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    sessionStorage.removeItem("orgCreated");
  }, []);


  useEffect(() => {
    let debounceTimer: NodeJS.Timeout;
    // Function to perform validation and update state
    const validateAndSetButtonState = () => {
      if(username.length>0 && companyName.length>0){

        form.validateFields().then(
          () => setIsButtonDisabled(false),
          () => setIsButtonDisabled(true)
        );
      }
      else{
        setIsButtonDisabled(true)
      }
    };
  

    // Function to debounce the validate function
    const debounceValidate = () => {
      clearTimeout(debounceTimer);
            debounceTimer = setTimeout(validateAndSetButtonState, 1000);
    };
    debounceValidate();
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [form, username, companyName, companyUrl]);

  
  const onFinish = async (values: any) => {
    localStorage.setItem('orgCreationStatus',"started");
    const formattedDisplayName = values.displayName.toLowerCase().replace(/\s+/g, '-');
    const fullCareerPageSuffix = values.careerPageUrl ?`${formattedDisplayName}-${values.careerPageUrl}`:formattedDisplayName;
    let req = {
      // type: true,
      name: values.name,
      // user_id: loginUser.user.id,
      display_name: values.displayName,
      career_page_url_suffix: fullCareerPageSuffix,
    };
    setLoadingState({ createNewOrgButton: true });

    initOrganisationSetup(req)
      .then((orgRes) => {
        currentOrg(orgRes);
        setLoadingState({ createNewOrgButton: false });

        let neworg = {
          applicationId: orgRes.id,
          id: orgRes.user_id,
          username: orgRes.name,
          verified: true,
          data: {
            logo: "",
            description: "",
          },
          roles: ["OWNER"],
          display_name:orgRes.display_name?orgRes.display_name:"",
          career_page_url_suffix:orgRes.career_page_url_suffix?orgRes.career_page_url_suffix:""
        };
        localStorage.setItem("newUser","newUser");
        addOrg(neworg);
        let updatedOrg = {
          applicationId: orgRes.id,
          id: orgRes.user_id,
          applicationName: orgRes.name,
          verified: true,
          data: {
            logo: "",
            description: "",
          },
          roles: ["OWNER"],
        };
        updateIdName(updatedOrg);
        localStorage.setItem("orgId", orgRes.id);
        history.push('/o/start-setup')
      })
      .catch(() => {
        // setUsernameError(error.response?.data.error.message);
        setLoadingState({ createNewOrgButton: false });
      });
  };


  const validateFromBackend = async (field: string, value: string,displayName?: string) => {
    try {
      if (field === "name") {
        await ValidateOrganizationCompanyName(value);
      } else if (field === "displayName") {
        await ValidateOrganizationDisplayName(value)
      } else if (field === "careerPageUrl") {
        const fullCareerPageUrl = `${displayName?.toLowerCase().replace(/\s+/g, '-')}-${value}`;
        await ValidateOrganizationCareerPageURL(fullCareerPageUrl)
      }
      return Promise.resolve();
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : String(error);
      return Promise.reject(new Error(errorMessage));
    }
  };

  const handleOnchange = (value:string)=>{
    setCompanyName(value);
    setUsername(value);
    form.setFieldsValue({
      displayName:value ,
    });
  }
  const handleCareerUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase().replace(/\s+/g, '-');
    setCompanyUrl(value);
  }
  return (
    <div className="w-full h-full  overflow-y-scroll rounded-md bg-white">
      <div className="w-full h-full flex justify-start items-start">
        <Card bordered = {false}  className=" w-full p-6 create-org-card" style={{ boxShadow: 'none' }}>
          <div className="space-y-6">
            <div className="space-y-2 flex flex-col">
              <Text className="text-lg font-semibold">
                Setup Your Organization
              </Text>
              <Text className="text-gray-500">
                Fill in your organization details to get started
              </Text>
            </div>
            <Alert message = {
              <div className="flex items-center space-x-2">
                <InfoCircleOutlined className="text-blue-500" />
                <Text className="text-sm">
                  This information will be used across your Tacitbase account
                </Text>
              </div>
            } 
            className="bg-blue-50 text-blue-800 border border-blue-100"/>
            <Form
              name="normal_login"
              requiredMark={false}
              className="space-y-6"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              layout="vertical"
              form={form}
            >
              <div className="border rounded-lg px-6 py-4">
                <Form.Item
                  name="name"
                  label={
                    <div className="flex items-center space-x-2 font-medium text-gray-700">
                      <span>Company legal name</span>
                      <Tooltip content="This is your official registered company name">
                        <InfoCircleOutlined className="text-gray-400 hover:text-gray-600" />
                      </Tooltip>
                    </div>
                  }
                  extra={
                    <Text className="text-gray-500 text-sm mt-1">
                      This will appear on your invoice.
                    </Text>
                  }
                  validateDebounce={1000}
                  rules={[
                    {
                      required: true,
                      message: "Company legal name is required",
                    },

                    {
                      validator: async (_, value) => {
                        if (!value) return Promise.resolve();

                        const regex = /^[a-zA-Z0-9\s]+$/;
                        if (!regex.test(value)) {
                          return Promise.reject(
                            "Only letters, numbers and spaces allowed"
                          );
                        }

                        try {
                          await validateFromBackend("name", value);
                          return Promise.resolve();
                        } catch (error) {
                          const errorMessage =
                            error instanceof Error
                              ? error.message
                              : String(error);
                          return Promise.reject(new Error(errorMessage));
                        }
                      },
                    },
                  ]}
                >
                  <Input
                    placeholder="Super Nice Inc"
                    className="h-8"
                    maxLength={50}
                    showCount
                    value={companyName}
                    onChange={(e) => handleOnchange(e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  name="displayName"
                  label={
                    <span className="font-medium text-gray-700">
                      Display name
                    </span>
                  }
                  extra={
                    <Text className="text-gray-500 text-sm mt-1">
                      This will appear on your company emails and career page.
                    </Text>
                  }
                  validateDebounce={1000}
                  rules={[
                    { required: true, message: "Display name is required" },

                    {
                      validator: async (_, value) => {
                        if (!value) return Promise.resolve();

                        const regex = /^[a-zA-Z0-9\s]+$/;
                        if (!regex.test(value)) {
                          return Promise.reject(
                            "Only letters, numbers and spaces allowed"
                          );
                        }

                        try {
                          await validateFromBackend("displayName", value);
                          return Promise.resolve();
                        } catch (error) {
                          const errorMessage =
                            error instanceof Error
                              ? error.message
                              : String(error);
                          return Promise.reject(new Error(errorMessage));
                        }
                      },
                    },
                  ]}
                >
                  <Input
                    placeholder="Super Nice"
                    className="h-8"
                    maxLength={50}
                    showCount
                    value={username}
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="careerPageUrl"
                  label={
                    <span className="font-medium text-gray-700">
                      Career page URL
                    </span>
                  }
                  extra={
                    <Text className="text-gray-500 text-sm mt-1">
                      Your company career page URL in Tacitbase.
                    </Text>
                  }
                  validateDebounce={1000}
                  rules={[

                    {
                      validator: async (_, value) => {
                        if (!value) return Promise.resolve();

                        const regex = /^[a-zA-Z0-9-]+$/;
                        if (!regex.test(value)) {
                          return Promise.reject(
                            "Only letters, numbers and hyphens allowed"
                          );
                        }

                        try {
                          await validateFromBackend("careerPageUrl", value,username);
                          return Promise.resolve();
                        } catch (error) {
                          const errorMessage =
                            error instanceof Error
                              ? error.message
                              : String(error);
                          return Promise.reject(new Error(errorMessage));
                        }
                      },
                    },
                  ]}
                >
                  <Input
                    className="h-10 rounded-lg"
                    addonBefore={
                      <Tooltip content={`${import.meta.env.VITE_CAREER_PAGE_URL}/${username ? `${username.trim().toLowerCase().replace(/\s+/g, '-')}-` : ''}`}>
                      <span className="whitespace-nowrap text-gray-600 flex items-center flex-wrap truncate" style={{maxWidth:'50vw'}}>
                        {`${import.meta.env.VITE_CAREER_PAGE_URL}/${username ? `${username.trim().toLowerCase().replace(/\s+/g, '-')}-` : ''}`}
                      </span>
                      </Tooltip>
                    } 
                    value={companyUrl}
                    onChange={handleCareerUrlChange}
                  />
                </Form.Item>
              </div>
              <div className="flex justify-end space-x-3 space-y-2">
                <Form.Item shouldUpdate dependencies={["name"]} style={{marginBottom:"0px"}}>
                  {() => (
                    <div className="flex justify-end w-full space-x-2">
                       {match &&(<Button
                        className="px-6 h-10"
                        onClick={()=>history.goBack()}
                      >
                        Cancel
                      </Button>)}
                      <Button
                        htmlType="submit"
                        type="primary"
                        className="px-6 h-10"
                        loading={loadingState.createNewOrgButton}
                        disabled={isButtonDisabled}
                      >
                        Create
                      </Button>
                    </div>
                  )}
                </Form.Item>
              </div>
            </Form>
          </div>
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  applicationId: state.opening.applicationId,
  applicationName: state.opening.applicationName,
});

const mapPropsToState = (dispatch: Dispatch) => ({
  addOrg: (orgData: any) => dispatch(showOrg(orgData)),
  updateIdName: (orgData: any) => dispatch(updateOrgNameId(orgData)),
  tokenExchange: (
    token: any,
    applicant_id: string,
    applicantion_name: string,
    application_desc: string,
    application_imgURL: string,
    application_roles: Role[]
  ) =>
    dispatch(
      tokenExchange(
        token,
        applicant_id,
        applicantion_name,
        application_desc,
        application_imgURL,
        application_roles
      )
    ),
  currentOrg: (orgData: any) => dispatch(current_org(orgData)),
});

export default connect(mapStateToProps, mapPropsToState)(SetupOrgname);
