import React, { useState } from "react";
import Tabs from "antd/es/tabs";
import ConfigProvider from "antd/es/config-provider";
import UnsplashImages from "./UnsplashImages";
import ColorPalette from "./ColorPalette";

const { TabPane } = Tabs;

export interface ChangeBoardBackgroundProps {}

export interface BoardBackgroundSection {
  caption: string;
  component: React.FC<any>;
}

// Define sections for the board background change options
const boardBackgroundSections: BoardBackgroundSection[] = [
  {
    caption: "Photos",
    component: UnsplashImages,
  },
  {
    caption: "Colors",
    component: ColorPalette,
  },
];

const ChangeBoardBackground: React.FC<ChangeBoardBackgroundProps> = () => {
  const [activeKey, setActiveKey] = useState<string>("1");

  // Function to handle tab change
  const handleTabChange = (key: string) => {
    setActiveKey(key);
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          paddingLG: 16,
        },
      }}
    >
      {/* Tabs component to switch between different board background options */}
      <Tabs activeKey={activeKey} onChange={handleTabChange} className="h-full">
        {boardBackgroundSections.map((section, index) => (
          <TabPane
            tab={
              // Tab label displaying the caption
              <div className="text-center px-4">
                <div className="text-sm">{section.caption}</div>
              </div>
            }
            key={index + 1}
          >
            {/* Render the selected component */}
            <section.component isActive={activeKey === (index + 1).toString()} />
          </TabPane>
        ))}
      </Tabs>
    </ConfigProvider>
  );
};

export default ChangeBoardBackground;
