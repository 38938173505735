import Avatar from "antd/es/avatar";
import Divider from "antd/es/divider";
import Layout from "antd/es/layout";
import Menu from "antd/es/menu";
import Popover from "antd/es/popover";
import Skeleton from "antd/es/skeleton";
import Space from "antd/es/space";
import Spin from "antd/es/spin";
import Typography from "antd/es/typography";
import React from "react";
import BarChartOutlined from "@ant-design/icons/BarChartOutlined"
import CalendarOutlined from "@ant-design/icons/CalendarOutlined"
import NotificationOutlined from "@ant-design/icons/NotificationOutlined"
import SearchOutlined from "@ant-design/icons/SearchOutlined"
import SettingOutlined from "@ant-design/icons/SettingOutlined"
import { connect } from "react-redux";
import JobPostIcon from "../Icons/JobPostIcon";
import ReviewIcon from "../Icons/ReviewIcon";
import { loginUser } from "../../type/type";
import { LabeledIconButton } from "../../ant/Button";
import { NAV_TEXTBOX_PLACEHOLDER, SIDENAV_MENU_ITEMS } from "../../constant";
import TacitbaseLogoMenu from "../AppSideMenu/TacitbaseLogoMenu";
import MailIcon from "../Icons/MailIcon";
import TalentPoolIcon from "../Icons/TalentPoolIcon";
import JobBoardCard from "./JobBoardCardPreview";
const { Text } = Typography;
const { Sider } = Layout;

interface PreviewSectionProps {
  pageAndContentLoader: { loader: boolean; message: string; skeleton: boolean };
  loginUser: loginUser;
  renderComponent: React.ComponentType<any>;
}

const settingMenuItem: any[] = [
  {
    label: SIDENAV_MENU_ITEMS.UPDATES,
    icon: <NotificationOutlined />,
    key: `1`,
  },
  {
    key: `2`,
    icon: <SettingOutlined />,
    label: "Settings",
  },
];
const menuItems: any[] = [
  {
    label: SIDENAV_MENU_ITEMS.BOARDS,
    icon: <JobPostIcon />,
    key: `1`,
  },
  {
    label: SIDENAV_MENU_ITEMS.REVIEW,
    key: `2`,
    icon: <ReviewIcon />,
  },
  {
    label: SIDENAV_MENU_ITEMS.INBOX,
    key: `3`,
    icon: <MailIcon />,
  },
  {
    label: SIDENAV_MENU_ITEMS.TALENT_POOL,
    key: `4`,
    icon: <TalentPoolIcon />,
  },
  {
    label: SIDENAV_MENU_ITEMS.ANALYTICS,
    icon: <BarChartOutlined />,
    key: `5`,
  },
  {
    label: SIDENAV_MENU_ITEMS.CALENDAR,
    icon: <CalendarOutlined />,
    key: `6`,
  },
];
const colors = [
  "red",
  "green",
  "blue",
  "orange",
  "magenta",
  "maroon",
];
const OrgPreviewSection: React.FC<PreviewSectionProps> = ({
  loginUser,
  renderComponent: RenderComponent,
  pageAndContentLoader,
}) => {
  const content = (
    <>
      <div className="flex items-start justify-start" style={{ minWidth: 250 }}>
        <Space>
          <Avatar
            shape="square"
            size="default"
            className="bg-tacitbase-primary1 uppercase"
          >
            {loginUser.user?.email[0]?loginUser.user?.email[0]:null}
          </Avatar>
          <div className="flex flex-col">
            <strong>
              <Text
                style={{ maxWidth: 200, minWidth: 100, margin: 0 }}
                ellipsis={{ tooltip: loginUser.user?.email }}
              >
                {loginUser.user?.email}
              </Text>
            </strong>
          </div>
        </Space>
      </div>
      <Divider style={{ margin: "10px" }} />
    </>
  );
  return (
    <div className="relative h-screen w-full overflow-hidden">
      <div className="absolute inset-0 filter blur-sm">
        <div className="rounded-lg shadow-sm bg-white overflow-hidden h-full w-full">
          <div className="w-full h-full flex justify-center">
            <div className="relative w-full h-full">
              <Layout className={`h-screen flex fixed w-full`}>
                <Sider
                  style={{
                    borderRight: "1px solid white",
                    borderRightWidth: "1px",
                    borderRightStyle: "solid",
                    left: 0,
                  }}
                  theme="light"
                  trigger={null}
                >
                  <div
                    style={{
                      borderBottomWidth: "1px",
                      borderBottomStyle: "solid",
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    <TacitbaseLogoMenu isCollaps={false} />
                    <>
                      <div
                        className="w-full justify-between items-center focus:outline-none"
                        style={{
                          backgroundColor: "transparent",
                          borderRight: "1px solid #e8e8e8",
                        }}
                      >
                        <>
                          <div className="w-full justify-between items-center">
                            <LabeledIconButton
                              label={NAV_TEXTBOX_PLACEHOLDER}
                              icon={<SearchOutlined />}
                              onClick={() => {}}
                              className="left-align-button pl-5 border-none"
                              style={{
                                color: "black",
                                width: "182px",
                                marginLeft: "8px",
                                marginRight: "8px",
                                marginTop: "8px",
                                marginBottom: "4px",
                              }}
                            />
                          </div>
                        </>
                      </div>
                    </>
                    {/* tacitbase menues */}
                    <>
                      <Menu
                        style={{
                          height: "100%",
                        }}
                        mode="inline"
                        onClick={() => {}}
                      >
                        {menuItems.map((menuItem: any) => (
                          <Menu.Item
                            key={menuItem.key}
                            icon={menuItem.icon}
                            title={menuItem.label}
                          >
                            {menuItem.label}
                          </Menu.Item>
                        ))}
                      </Menu>
                    </>
                    {/* setting meue */}
                    <div className="border-t">
                      <Menu theme="light" mode="inline" onClick={() => {}}>
                        {settingMenuItem.map((menuItem: any) => (
                          <Menu.Item
                            key={menuItem.key}
                            icon={menuItem.icon}
                            title={menuItem.label}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                placeItems: "center",
                                alignItems: "center",
                              }}
                            >
                              {menuItem.label}
                            </div>
                          </Menu.Item>
                        ))}
                      </Menu>
                    </div>
                  </div>
                </Sider>
                <Layout className="bg-white w-full h-full">
                  <div className="h-12 px-3 py-2 flex items-center border-b border-gray-100">
                    <div className="flex h-12 justify-between items-center w-full">
                      <div className="flex animate-pulse items-center">
                        <Skeleton.Input
                          size="small"
                          style={{
                            width: 130,
                            height: 32,
                            borderRadius: "4px",
                          }}
                        />
                      </div>
                      <div className="flex items-center gap-2">
                        <Popover placement="bottomRight" content={content}>
                          <Avatar
                            shape="square"
                            className="!bg-gray-100 !text-gray-600 flex-shrink-0"
                            size={"small"}
                          >
                            {loginUser.user?.email[0]? loginUser.user?.email[0].toUpperCase():null}
                          </Avatar>
                        </Popover>
                      </div>
                    </div>
                  </div>

                  <div className="w-full flex items-center px-3 py-2 border-b border-gray-100">
                    <div className="flex gap-1">
                      <Skeleton.Button
                        size="small"
                        style={{
                          width: 120,
                          height: 30,
                          borderRadius: "4px",
                        }}
                      />
                      <Avatar.Group size={"small"}>
                        {[1, 2].map((_, index) => (
                          <Skeleton.Avatar
                            key={index}
                            size={"large"}
                            shape="circle"
                            className=""
                            style={{
                              width: 30,
                              height: 30,
                            }}
                          />
                        ))}
                      </Avatar.Group>
                      <Skeleton.Avatar
                        size={"small"}
                        shape="square"
                        className="rounded"
                        style={{
                          borderRadius: 4,
                          width: 30,
                          height: 30,
                        }}
                      />
                    </div>
                  </div>
                  <div className="py-4 px-6">
                    <div className="flex flex-wrap gap-4">
                      {colors.map((color, index) => (
                        <JobBoardCard key={index} bgColor={color} />
                      ))}
                    </div>
                  </div>
                </Layout>
              </Layout>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute inset-0 bg-white/50 z-30" />
      <div className="fixed inset-0 z-40 flex flex-col gap-4 items-center justify-center bg-black bg-opacity-40">
        {pageAndContentLoader?.loader ? (
           <div 
           className={`flex flex-col items-center justify-center`}
           style={{ opacity:"0.7" }}
         >
           <Spin size="large" />
           {" "}
           <p className="mt-2 text-xl font-semibold text-white">{pageAndContentLoader?.message && pageAndContentLoader.message}</p>
         </div>
        ) : (
          <div className="responsive-modal-container">
            <div className="responsive-modal-content">
              {<RenderComponent />}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  pageAndContentLoader: state.opening?.pageAndContentLoader,
});

export default connect(mapStateToProps)(OrgPreviewSection);
