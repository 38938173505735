import React, { memo, useCallback, useState } from "react";
import { Calendar, DateLocalizer, dayjsLocalizer, Views } from "react-big-calendar";
import dayjs, { Dayjs } from "dayjs";
import Spin from "antd/es/spin";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { deleteEvent } from "../../../lib/api";
import { useDispatch, useSelector } from "react-redux";
import { deleteEventFromCalendar } from "../../../redux/reducers/calendarSlice";
import { loginUser } from "../../../type/type";
import EventDetailsModal from "./EventPopup";

const localizer: DateLocalizer = dayjsLocalizer(dayjs);

interface CalendarMainProps {
  view: any;
  events: any[];
  currentDate: Dayjs;
  onViewChange: (view: string) => void;
  onNavigate: (date: Dayjs) => void;
  isLoading?: boolean; // Add loading prop
  collapse?: boolean;
}

const CalendarMain = memo(function CalendarMain({
  view,
  events,
  currentDate,
  onViewChange,
  onNavigate,
  isLoading,
  collapse,
}: CalendarMainProps) {
  const dispatch = useDispatch();
  const [selectedEvent, setSelectedEvent] = useState<any | null>(null);
  const history = useHistory();
  const Urllocation = useLocation();
  const loginUserData: loginUser = useSelector(
    (state: any) => state.opening.loginUser
  );
  const calendarContainerClasses = `flex-1 ${
    collapse ? "sidebar-collapsed" : "sidebar-expanded"
  }`;

  const eventPropGetter = useCallback(
    (event: { [key: string]: any }) => ({
      className: `event-${event.calendarId}`,
      style: {
        backgroundColor: event.color,
        border:
          !dayjs(event.start).isSame(event.end, "day") && !event.allDay
            ? `1px solid ${event.color}`
            : "none",
      },
    }),
    []
  );
  const handleEditClick = (id: string, calId: string) => {
    history.push(`${Urllocation.pathname}/${calId}/${id}`);
    setSelectedEvent(null);
  };

  const handleEventDelete = (eventId: string, calendarId: string) => {
    deleteEvent(calendarId, eventId, loginUserData.user.id).then(() => {
      dispatch(
        deleteEventFromCalendar({ calendarId: calendarId, eventId: eventId })
      );
      setSelectedEvent(null);
    });
  };

  const EventComponent: React.FC<{ event: any }> = ({ event }) => {
    const endDate =
      dayjs(event.end).hour() === 0 && dayjs(event.end).minute() === 0
        ? dayjs(event.end).subtract(1, "day")
        : dayjs(event.end);
    const isMultiDay = !dayjs(event.start).isSame(endDate, "day");
    const isFirstDay = dayjs(event.start).isSame(dayjs(), "day");
    const isLastDay = endDate.isSame(dayjs(), "day");
    const isOrganizer = event.organizer.self;

    const handleClick = () => {
      setSelectedEvent(event);
    };

    return (
      <div
        onKeyDown={() => {}}
        role="button"
        tabIndex={0}
        onClick={handleClick}
        className={`font-normal text-xs h-full cursor-pointer
            ${isMultiDay ? "multi-day-event" : "single-day-event"}
            ${isFirstDay ? "first-day" : ""}
            ${isLastDay ? "last-day" : ""}`}
        style={{
          height: "100%",
          backgroundColor: event.allDay ? event.color : "transparent",
          borderRadius: isMultiDay
            ? `${isFirstDay ? "4px" : "4px"} ${isLastDay ? "4px" : "4px"} ${
                isLastDay ? "4px" : "4px"
              } ${isFirstDay ? "4px" : "4px"}`
            : "4px",
          color: event.all_day_event ? event.textColor : "#000",
          margin: "0 -3px",
        }}
      >
        <div style={{ padding: "4px 8px" }}>
          {!isMultiDay && !event.allDay && (
            <div
              style={{
                width: "8px",
                height: "8px",
                borderRadius: "50%",
                backgroundColor: isOrganizer ? event.color : "transparent",
                border: `1.5px solid ${event.color}`,
                display: "inline-block",
                marginRight: "4px",
              }}
            />
          )}
          {!event.allDay
            ? `${
                dayjs(event.start).minute() === 0
                  ? dayjs(event.start).format("ha")
                  : dayjs(event.start).format("h:mma")
              } ${event.title}`
            : event.title}
        </div>
      </div>
    );
  };

  // Custom loading component
  const LoadingComponent = () => (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(255, 255, 255, 0.7)",
        zIndex: 1000,
      }}
    >
      <Spin size="small" />
    </div>
  );
  const handleRangeChange = (range: any) => {
    if (Array.isArray(range)) {
      const [start, end] = range;
      console.log("Start:", start);
      console.log("End:", end);
    } else {
      console.log("Date:", range);
    }
  };

  return (
    <div
      style={{ width: collapse ? "100%" : "calc(100% - 235px)" }}
      className={calendarContainerClasses}
    >
      <Calendar
        onRangeChange={handleRangeChange}
        localizer={localizer}
        defaultDate={currentDate.toDate()}
        events={events}
        startAccessor={(event) => new Date(event.start)}
        endAccessor={(event) => new Date(event.end)}
        style={{
          height: "calc(100vh - 100px)",
          borderRadius: 4,
          width: "100%",
        }}
        view={view}
        onView={onViewChange}
        date={currentDate.toDate()}
        onNavigate={(newDate) => onNavigate(dayjs(newDate))}
        components={{
          event: EventComponent,
          toolbar: () => null,
        }}
        {...view!=Views.AGENDA&& eventPropGetter}
        popup
        selectable
      />
      {isLoading && <LoadingComponent />}
      {selectedEvent && (
        <EventDetailsModal
          visible={!!selectedEvent}
          event={selectedEvent}
          onClose={() => setSelectedEvent(null)}
          onEdit={() =>
            handleEditClick(selectedEvent.id, selectedEvent.calendarId)
          }
          onDelete={() =>
            handleEventDelete(selectedEvent.id, selectedEvent.calendarId)
          }
        />
      )}
    </div>
  );
});

export default CalendarMain;
