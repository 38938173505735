import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/reducers/root';

const useFaviconNotification = () => {
  const [originalFavicon, setOriginalFavicon] = useState<string | null>(null);
  const [notificationFavicon, setNotificationFavicon] = useState<string | null>(null);

  // Get showDot directly from Redux state
  const showDot = useSelector((state: RootState) => state.opening.notificationCenter.showDotOnUpdates);

  // Initial setup of favicons
  useEffect(() => {
    const favicon = document.getElementById('favicon') 
    if (favicon instanceof HTMLLinkElement) {
      setOriginalFavicon(favicon.href);
      createNotificationFavicon(favicon.href);
    }

    return () => {
      if (originalFavicon) {
        const favicon = document.getElementById('favicon')
        if (favicon instanceof HTMLLinkElement) {
          favicon.href = originalFavicon;
        }
      }
    };
  }, []);

  // Update favicon based on showDot state
  useEffect(() => {
    const favicon = document.getElementById('favicon') 
    if (favicon instanceof HTMLLinkElement) {
      if (showDot && notificationFavicon) {
        favicon.href = notificationFavicon;
      } else {
        favicon.href = originalFavicon || '';
      }
    }
  }, [showDot, notificationFavicon, originalFavicon]);

  const createNotificationFavicon = (faviconUrl: string) => {
    const img = new Image();
    img.src = faviconUrl;
    img.onload = () => {
      const size = 32; // Common favicon size
      const canvas = document.createElement('canvas');
      canvas.width = size;
      canvas.height = size;
      const context = canvas.getContext('2d');
      if (context) {
        // Draw the original favicon
        context.drawImage(img, 0, 0, size, size);

        // Draw a smaller notification dot
        const dotSize = size / 3; // Smaller dot size (1/6 of the favicon)
        const dotX = size - dotSize / 3; // X position (top-right corner)
        const dotY = dotSize / 2; // Y position (top-right corner)

        context.beginPath();
        context.arc(dotX, dotY, dotSize / 2, 0, 2 * Math.PI);
        context.fillStyle = '#ff0000'; // Bright red color for better visibility
        context.fill();

        setNotificationFavicon(canvas.toDataURL('image/png'));
      }
    };
  };
};

export default useFaviconNotification;
