import React, { Suspense } from 'react';
import Spin from "antd/es/spin";// Ant Design spinner

const SuspenseLoader = ({ children }: { children: React.ReactNode }) => {
  return (
    <Suspense
      fallback={
        <div className="flex justify-center items-center h-full">
          <Spin tip="Loading..." size="default" />
        </div>
      }
    >
      {children}
    </Suspense>
  );
};

export default SuspenseLoader;