import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { CDBMember, OWNER, roles } from "../../constant"
import { addMemberToCDB } from "../../lib/api";
import { AddMemberReq } from "../../lib/apiReq";
import { Role } from "../../lib/apiRes";
import { addMemberOnCDB } from "../../redux/actions/opening/action";
import { Member, OpeningMembers, Scope, orgMember } from "../../type/type.d";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import DropdownWithDesc from "../common/customDropdowm/DropdownWithDesc"
import SearchedResultPopup from "../subNavbar/addBoardMember/SearchedResultPopup";
import ShowMeberInInput from "../subNavbar/addBoardMember/ShowMeberInInput";
import { Overlay } from "../hook/Overlay";
import Checkbox from "antd/es/checkbox";
import Divider from "antd/es/divider";
import { LabeledIconButton } from "../../ant/Button";

export type AddBoardMemberPopupProps = {
  members: orgMember;
  addMemberOnCDB(members: Member[]): void;
  applicationId: string;
  clientId: string;
  refer: any;
  click: () => void;
  orgRoles: Role[];
  servicesMembership: OpeningMembers;
};
const AddBoardMemberPopup = (props: AddBoardMemberPopupProps) => {
  const [isSecretVisible, setSecretVisible] = useState<boolean>(false);
  const [isRoleOpen, openRolePopup] = useState(false);
  const refer=useRef(null);

  const [selectedRole, selectRole] = useState<string>("member");
  const inputRef = useRef<HTMLInputElement>(null);
  const [checked, setChecked] = useState<any[]>([]);
  const [searchedMember, getSearchedMember] = useState("");
  const [loading, setLoading] = useState(false);
  let orgMembers = Object.values(props.members).filter(
    (member: Member) =>
      member.registrations[0].data.activated && // Ensure the member is activated
      member.registrations[0].roles.some(role => ["Member", "Owner"].includes(role)) && // Check for "Member" or "Owner" role
      (
        member.first_name.toUpperCase().includes(searchedMember.toUpperCase()) ||
        member.last_name.toUpperCase().includes(searchedMember.toUpperCase()) ||
        member.email.toUpperCase().includes(searchedMember.toUpperCase()) ||
        member.username.toUpperCase().includes(searchedMember.toUpperCase())
      )
  );
  
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = "";
      inputRef.current.focus();
      getSearchedMember("");
    }
  }, [checked]);
  const setRole = (role: string) => {
    if (role == roles.ADMIN) { setSecretVisible(true) }
    else setSecretVisible(false)
    selectRole(role);
    openRolePopup(!isRoleOpen);
  };

  const searchKeyword = (e: any) => {
    getSearchedMember(e.target.value);
  };
  const handleInputChange = (member: any) => {
    var updatedList = [...checked];
    if (!checked.includes(member)) {
      updatedList = [...checked, member.id];
    }
    setChecked(updatedList);
  };
  const removeMemberFromSelectedList = (index: number) => {
    setChecked((a: Member[]) => {
      a.splice(index, 1);
      return [...a];
    });
  };

  const AddMemberOnBoard = () => {
    if (checked.length > 0) {
      setLoading(true)
      const req: AddMemberReq = {
        id: "",
        members: checked,
        role: selectedRole,
        is_secrete_visible: isSecretVisible,
        socket_client_id: props.clientId,
      };
      addMemberToCDB(req).then((res: Member[]) => {
        props.addMemberOnCDB(res);
        setLoading(false)
        props.click();
        setChecked([]);
        selectRole("member");
      });
    }
  };
  const handleCheckboxChange = (e: any) => {
    // Update the isSecretVisible state based on the checkbox's checked state
    setSecretVisible(e.target.checked)
  };

  useEffect(() => {
    if (inputRef.current && inputRef.current.value.trim() == "") {
      inputRef.current.focus();
    }
  }, [inputRef.current]);
  if (!props.members) return null;
  return (
    <React.Fragment>
      <Divider className="my-2" />

      <div
        className="w-96"
        ref={props.refer}
      >

        <div className="justify-center items-center flex">
          <div className="w-full relative m-2">
            <div
              className={`rounded inputCSSOnlyForFocus ${
                inputRef.current
                  ? "border border-tacitbase-secondary3"
                  : "border border-tacitbase-tertiary1 border-opacity-25"
              }  flex flex-wrap items-center w-full `}
            >
              <div
                className={`${
                  checked.length == 0 ? "w-full" : ""
                } flex px-2 pb-1 flex-wrap`}
              >
                {checked.length > 0
                  ? checked.map(
                      (memberID: string, index: number) =>
                        memberID && (
                          <ShowMeberInInput
                            index={index}
                            key={index}
                            memberId={memberID}
                            removeMemberFromSelectedList={(idx: number) =>
                              removeMemberFromSelectedList(idx)
                            }
                          />
                        )
                    )
                  : null}
                <input
                  type="text"
                  className={`${
                    checked.length == 0 ? "w-full" : ""
                  } placeholder-italic outline-none text-sm mt-1`}
                  placeholder={"Add email or name"}
                  onChange={(e: any) => searchKeyword(e)}
                  ref={inputRef}
                  onBlur={() => inputRef.current?.blur()}
                />
              </div>
            </div>

            {searchedMember.trim() ? (
                <Overlay onClose={() => getSearchedMember("")
              } scope={Scope.Popover} reference={refer} >
              <SearchedResultPopup
                click={(member: Member) => handleInputChange(member)}
                refer={refer}
                selectedMember={checked}
                searchResult={orgMembers}
                searchInObject={props.servicesMembership}
                searchInObjectKey={CDBMember}
              /></Overlay>
            ) : null}
          </div>
        </div>

        <div className="flex-col flex space-y-1 mx-2">
          <div className=" flex text-xs text-black space-x-1 items-center font-normal">
            <span>{capitalizeFirstLetter("role")}</span>
            <i className="fa fa-info-circle" aria-hidden="true"></i>
          </div>
          <div className="flex justify-between items-center">
            <DropdownWithDesc
              selectedItem={(item: string) => setRole(item)}
              options={
                props.orgRoles
                  ? props.orgRoles.filter(
                      (role: Role) => role.name !== capitalizeFirstLetter(OWNER)
                    )
                  : []
              }
              selectedRole={selectedRole}
            />

            <Checkbox
              onChange={handleCheckboxChange}
              disabled={selectedRole === roles.ADMIN}
              checked={isSecretVisible}
            >
              {capitalizeFirstLetter("secrets visible")}
            </Checkbox>
          </div>
        </div>
        <div className="justify-start flex mx-1 my-2">
          <LabeledIconButton type="primary" label="Add" className="submit-btn text-xs " onClick={AddMemberOnBoard} loading={loading}/>
        </div>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  members: state.opening.members,
  servicesMembership: state.opening.servicesMembership,
  currentApplicant: state.opening.currentApplicant,
  applicantMembers: state.opening.applicantMembers,
  applicationId: state.opening.applicationId,
  clientId: state.opening.clientId,
  orgRoles: state.opening.orgRoles,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  addMemberOnCDB: (members: Member[]) => dispatch(addMemberOnCDB(members)),
});
export default connect(mapStateToProps, mapPropsToState)(AddBoardMemberPopup);
