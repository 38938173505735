import React from "react";

import { connect } from "react-redux";
import { loginUser, orgMember } from "../../../type/type";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
type ActivityProps = {
  activity: any;
  loginUser: loginUser;
  members: orgMember;
  sources:{[key:string]:{name:string,strategy_name:string}},
};
const ActivityMsg = (props: ActivityProps) => {

  var applicantActivity: any = {};
  applicantActivity = props.activity;
  if (!applicantActivity) return null;
  if (
    applicantActivity.action == "added" &&
    applicantActivity.sub_trackable == "card"
  ) {
    return (
      <>
        {applicantActivity.action} this {applicantActivity.sub_trackable} to{" "}
        {applicantActivity.trackable} {applicantActivity.trackable_name}
      </>
    );
  } else if (
    applicantActivity.action == "added" &&
    applicantActivity.sub_trackable == "label"
  ) {
    return (
      <>
        {applicantActivity.action} {applicantActivity.sub_trackable}{" "}
        {applicantActivity.sub_trackable_name} to this{" "}
        {applicantActivity.trackable}
      </>
    );
  } else if (
    applicantActivity.action == "removed" &&
    applicantActivity.sub_trackable == "label"
  ) {
    return (
      <>
        {applicantActivity.action} {applicantActivity.sub_trackable}{" "}
        {applicantActivity.sub_trackable_name} from this{" "}
        {applicantActivity.trackable}
      </>
    );
  } else if (
    applicantActivity.action == "added" &&
    applicantActivity.sub_trackable == "attachment"
    && props.members[applicantActivity.owner_id]
  ) {
    return (
      <>
        {applicantActivity.action} {applicantActivity.sub_trackable}{" "}
        {applicantActivity.sub_trackable_name} to this{" "}
        {applicantActivity.trackable}
      </>
    );
  }  else if (
    applicantActivity.action == "added" &&
    applicantActivity.sub_trackable == "attachment"
  ) {
    return (
      <>
        {applicantActivity.action} {applicantActivity.sub_trackable}{" "}
        {applicantActivity.sub_trackable_name} 
      </>
    );
  }
  else if (
    applicantActivity.action == "added" &&
    applicantActivity.sub_trackable == "member"
  ) {
    if (applicantActivity.sub_trackable_id === applicantActivity.owner_id)
      return <>joined this {applicantActivity.trackable}</>;
    else
      return (
        <>
          {applicantActivity.action}{" "}
          <span className="font-semibold">{props.members[applicantActivity.sub_trackable_id] && props.members[applicantActivity.sub_trackable_id].first_name}{" "}
            {props.members[applicantActivity.sub_trackable_id] && props.members[applicantActivity.sub_trackable_id].last_name}</span> to this{" "}
          {applicantActivity.trackable}
        </>
      );
  }
  else if (
    applicantActivity.action == "deleted" &&
    applicantActivity.sub_trackable == "attachment"
  ) {
    return (
      <>
        {applicantActivity.action} {applicantActivity.sub_trackable}{" "}
        {applicantActivity.sub_trackable_name}
        {/* <span>to</span>
        <span>this</span>
        <span>{applicantActivity.trackable}</span> */}
      </>
    );
  } else if (
    applicantActivity.action == "removed" &&
    applicantActivity.sub_trackable == "member"
  ) {
    if (applicantActivity.sub_trackable_id === applicantActivity.owner_id)
      return <>left this {applicantActivity.trackable}</>;
    else {
      return (
        <>
          {applicantActivity.action}{" "}
          <span className="font-semibold">{props.members[applicantActivity.sub_trackable_id] && props.members[applicantActivity.sub_trackable_id].first_name}{" "}
            {props.members[applicantActivity.sub_trackable_id] && props.members[applicantActivity.sub_trackable_id].last_name}</span> from{" "}
          this {applicantActivity.trackable}
        </>
      );
    }
  } else if (
    applicantActivity.action == "updated" &&
    applicantActivity.sub_trackable == "attachment"
  ) {
    return (
      <>
        {applicantActivity.action} the {applicantActivity.sub_trackable}
        {applicantActivity.old_val} to the
        {applicantActivity.sub_trackable_name}
      </>
    );
  } else if (applicantActivity.action == "moved") {
    return (
      <>
        {applicantActivity.action} this {applicantActivity.sub_trackable} from{" "}
        {applicantActivity.old_val} to {applicantActivity.new_val}
      </>
    );
  } else if (applicantActivity.action == "archived") {
    return (
      <>
        {applicantActivity.action} this {applicantActivity.sub_trackable}
      </>
    );
  } else if (
    applicantActivity.action == "updated" &&
    applicantActivity.sub_trackable == "description"
  ) {
    return (
      <>
        {applicantActivity.action} {applicantActivity.sub_trackable}
      </>
    );
  } else if (applicantActivity.action == "unarchived") {
    return (
      <>
        {applicantActivity.action} this {applicantActivity.sub_trackable}
      </>
    );
  } else if (
    applicantActivity.action == "added" &&
    applicantActivity.sub_trackable == "candidate"
  ) {
    return (
      <>
        {applicantActivity.action} the {applicantActivity.trackable}
      </>
    );
  } else if (
    applicantActivity.action == "updated" &&
    applicantActivity.sub_trackable == "candidate"
  ) {
    return (
      <>
        {applicantActivity.action} the {applicantActivity.trackable} profile
      </>
    );
  }else if (
    applicantActivity.action == "updated"
  ) {
    return (
      <>
        {applicantActivity.action} the {applicantActivity.trackable} {applicantActivity.sub_trackable}
      </>
    );
  } else if (
    applicantActivity.action == "created" &&
    applicantActivity.sub_trackable == "review"
  ) {
    return (
      <>
        {applicantActivity.action} the {applicantActivity.sub_trackable}
      </>
    );
  } else if (
    applicantActivity.action == "added" &&
    applicantActivity.sub_trackable == "board"
  ) {
    return (
      <>
        {applicantActivity.action} {applicantActivity.trackable_name} to the{" "}
        {applicantActivity.sub_trackable_name}
      </>
    );
  } else if (
    applicantActivity.action == "closed" &&
    applicantActivity.sub_trackable == "review"
  ) {
    return (
      <>
        {applicantActivity.action} the {applicantActivity.sub_trackable}
      </>
    );
  }else if (
    applicantActivity.action === "applied"
    && props.sources[applicantActivity.sub_trackable]?.strategy_name === "referral"
  ) {
    return (
      <>
      {applicantActivity.action} for the job post{" "}
      {applicantActivity.sub_trackable_name} through{" "}
      {props.sources[applicantActivity.sub_trackable]
        ? capitalizeFirstLetter(props.sources[applicantActivity.sub_trackable].name)
        : applicantActivity.sub_trackable}{" "}
      referral link
    </>
    );
  }
  else if (
    applicantActivity.action === "applied"
  ) {
    return (
      <>
        {applicantActivity.action} for the job post {" "} 
        {applicantActivity.sub_trackable_name} via{" "}
        { props.sources[applicantActivity.sub_trackable] ? capitalizeFirstLetter(props.sources[applicantActivity.sub_trackable].name) : applicantActivity.sub_trackable}
      </>
    );
  }
  return <></>;
};

const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  members: state.opening.members,
});

export default connect(mapStateToProps)(ActivityMsg);
