import { getOAuthAccessToken } from "./getOAuthAccessToken";

export const oAuthProviders = ['google'];
export type OAuthProvider = 'google' ;

export const oAuthProviderName: Record<OAuthProvider, string> = {
  google: 'Google',
  // facebook: 'Facebook',
};

export interface InitialOAuthResponse {
  url: string;
  token?: string;
}
interface AuthenticateWithOAuthParams {
  provider: OAuthProvider;
}

export const authenticateWithOAuth = async ({
  provider,
}: AuthenticateWithOAuthParams): Promise<InitialOAuthResponse> => {
  try {
    if (!oAuthProviders.includes(provider)) {
      throw new Error(`Unsupported OAuth provider: ${provider}`);
    }

    const token: InitialOAuthResponse = await getOAuthAccessToken({ provider });
    if (!token?.url) {
      throw new Error("Invalid OAuth response received.");
    }

    return token;
  } catch (error) {
    console.error("OAuth authentication failed:", error);
    throw new Error("Failed to authenticate with OAuth provider.");
  }
};

