import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import data from "@emoji-mart/data"
import SmileTwoTone from "@ant-design/icons/SmileTwoTone"

import Picker from "@emoji-mart/react"
import Popover from "antd/es/popover";
import { Overlay } from "./hook/Overlay"
import { Scope } from "../type/type.d"

type EmojiProps = {
    onEmojiSelect: (emoji: string) => void,
    usePortal?: boolean
}

const Emoji = (props: EmojiProps) => {
    const [visible, setVisible] = useState(false)

    const handleEmojiPicker = () => {
        setVisible(true)

    }
    const handleEmojiSelect = (emoji: string): void => {
        props.onEmojiSelect(emoji)
        setVisible(!open)
    }
    useEffect(() => {
        if (!visible) {
            setVisible(false)
        }
    }, [visible])
    return (
        <>
            <Popover
                onOpenChange={(isVisible) => setVisible(isVisible)}

                open={visible} trigger='click'
                content={<Overlay scope={Scope.ChildPopover} onClose={handleEmojiPicker}>
                    <Picker

                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus
                        data={data}
                        // onClickOutside={handleCloseEmojiPicker}
                        onEmojiSelect={(emoji: any) => handleEmojiSelect(emoji.native)}
                    />            </Overlay>
                }>
                <button
                    data-testid="testBtn"
                    onClick={handleEmojiPicker}
                >
                    <SmileTwoTone />
                </button>
            </Popover>
        </>
    )
}

const mapStateToProps = (state: any) => ({
    currentApplicant: state.opening.currentApplicant,
})

export default connect(mapStateToProps)(Emoji)